import React, {
  useState,
  memo,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import * as PIXI from 'pixi.js-legacy';
import * as ReactPIXI from '@inlet/react-pixi/legacy';
import styled from 'styled-components';

import { useInteraction } from '../../../atoms/GraphInteractionLayer/GraphInteractionContext';
import { useSelectInteraction } from '../../../charts/atoms/select-interaction-layer';
import { useBarGraphContext } from '../hooks/BarGraphContext';
import { useColumn } from '../hooks/useColumn';
import { ChartAreaContext } from '../../../charts/atoms/ChartAreaProvider/ChartAreaContext';
import { lerp, normalize } from '../../../utils/mathUtils';
import { Color } from '../../../theme/primitives';
import { SortDirection } from '../../../constants';

import { GraphColumn } from './GraphColumn';

const { Container, Graphics, Stage } = ReactPIXI;

export interface BarGraphCanvasProps {
  width: number;
  height: number;
  showBackgroundLines?: boolean;
  className?: string;
  onSelectDataPoint?: (index: number | null) => void;
  selectedDataPointIndex?: number;
  selectedSerie?: string | null;
  sortDirection?: SortDirection;
}

export const BarGraphCanvasComponent: React.FC<BarGraphCanvasProps> = ({
  width,
  height,
  showBackgroundLines,
  className,
  onSelectDataPoint,
  selectedDataPointIndex,
  selectedSerie,
  sortDirection,
}) => {
  const [interactionState, setInteractionState] = useInteraction();
  const selectInteractionContext = useSelectInteraction();
  const [selectInteractionState, setSelectInteractionState] =
    selectInteractionContext || [];
  const { data, graphId } = useBarGraphContext();
  const [isHovered, setIsHovered] = useState(false);
  const area = useContext(ChartAreaContext);

  const { columnDrawData } = useColumn(sortDirection);

  const drawBackgroundLines = useCallback(
    graphics => {
      graphics.clear();
      const { y } = area;

      y.scaleMarks.forEach(position => {
        const normalizedPosition = normalize(y.min, y.max, position);

        graphics.lineStyle({
          width: 1,
          color: PIXI.utils.string2hex(Color.grey100),
          alignment: 0.5,
        });
        graphics.moveTo(0, height - normalizedPosition * height);
        graphics.lineTo(width, height - normalizedPosition * height);
      });
    },
    [area, width, height],
  );

  const lerpCoordinates = (event: PIXI.InteractionEvent) => {
    const x = lerp(area.x.min, area.x.max, event.data.global.x / width);
    const y = lerp(area.y.max, area.y.min, event.data.global.y / height);

    return { x, y };
  };

  useEffect(() => {
    if (
      selectedDataPointIndex !== undefined &&
      columnDrawData[selectedDataPointIndex]
    ) {
      setSelectInteractionState?.({
        bounding: columnDrawData[selectedDataPointIndex].column.bounding,
        data: data[selectedDataPointIndex],
      });
      const newInteractionState = interactionState
        ? { ...interactionState, showMarker: false }
        : null;
      setInteractionState(newInteractionState);
    }
  }, [selectedDataPointIndex, width]); // eslint-disable-line react-hooks/exhaustive-deps

  const getColumnHoveredState = () => {
    if (!isHovered || !selectInteractionContext) {
      return false;
    }

    const { x: hoverX = 0, y: hoverY = 0 } = interactionState || {};
    const normalizedX = normalize(area.x.min, area.x.max, hoverX) * width;
    const normalizedY = normalize(area.y.min, area.y.max, hoverY) * height;

    return columnDrawData.some(
      columnData =>
        normalizedX >= columnData.column.bounding.x &&
        normalizedX <=
          columnData.column.bounding.x + columnData.column.bounding.width &&
        normalizedY <= columnData.column.bounding.y,
    );
  };

  const isColumnHovered = getColumnHoveredState();

  const handleClick = (event: PIXI.InteractionEvent) => {
    if (!isColumnHovered) {
      return;
    }

    const { x, y } = lerpCoordinates(event);
    const normalizedX = normalize(area.x.min, area.x.max, x) * width;
    const normalizedY = normalize(area.y.min, area.y.max, y) * height;
    const clickedColumnIndex = columnDrawData.findIndex(
      columnData =>
        normalizedX >= columnData.column.bounding.x &&
        normalizedX <=
          columnData.column.bounding.x + columnData.column.bounding.width &&
        normalizedY <= columnData.column.bounding.y,
    );

    if (clickedColumnIndex !== -1) {
      onSelectDataPoint?.(clickedColumnIndex);
      return;
    }

    onSelectDataPoint?.(null);
  };

  const handleMouseMove = (event: PIXI.InteractionEvent) => {
    if (!isHovered) {
      return;
    }

    const { x, y } = lerpCoordinates(event);
    let showMarker = true;
    if (selectInteractionState) {
      const { bounding } = selectInteractionState;
      const normalizedX = normalize(area.x.min, area.x.max, x) * width;
      if (
        normalizedX >= bounding.x &&
        normalizedX <= bounding.x + bounding.width
      ) {
        showMarker = false;
      }
    }

    setInteractionState({ x, y, graphId, showMarker });
  };

  const isSelectedColumnExist =
    !!selectInteractionContext &&
    selectedDataPointIndex !== undefined &&
    selectedDataPointIndex !== null &&
    selectedDataPointIndex >= 0;

  return (
    <BarGraphCanvasContainer isColumnHovered={isColumnHovered}>
      <Stage
        options={{
          backgroundAlpha: 0,
          autoDensity: true,
          antialias: true,
        }}
        width={width}
        height={height}
        className={className}
        raf={false}
        onMount={app => {
          // eslint-disable-next-line no-param-reassign
          app.renderer.plugins.interaction.moveWhenInside = true;
        }}
        onMouseOut={() => {
          setInteractionState(null);
          setIsHovered(false);
        }}
        onMouseOver={() => {
          setIsHovered(true);
        }}
        renderOnComponentChange
      >
        <Container
          interactive
          hitArea={new PIXI.Rectangle(0, 0, width, height)}
          mousemove={handleMouseMove}
          mouseout={() => {
            setInteractionState(null);
          }}
          mouseup={handleClick}
        >
          {showBackgroundLines && <Graphics draw={drawBackgroundLines} />}
          {data.map((_, index) => (
            <GraphColumn
              isFaded={
                isSelectedColumnExist && selectedDataPointIndex !== index
              }
              selectedSerie={selectedSerie}
              key={index}
              drawData={columnDrawData[index].column.series}
            />
          ))}
        </Container>
      </Stage>
    </BarGraphCanvasContainer>
  );
};

export const BarGraphCanvas = memo(BarGraphCanvasComponent);

const BarGraphCanvasContainer = styled.div<{ isColumnHovered: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  cursor: ${({ isColumnHovered }) => (isColumnHovered ? 'pointer' : 'auto')};
`;
