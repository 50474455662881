import React, { ReactElement } from 'react';
import styled from 'styled-components';

export interface PieChartLayoutProps {
  title?: ReactElement;
  legend: ReactElement | null;
  chart: ReactElement;
}

const PieChartWrapper = styled.div``;

const Header = styled.header`
  margin-bottom: 10px;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const PieChartLayout: React.FC<PieChartLayoutProps> = ({
  title,
  legend,
  chart,
}) => (
  <PieChartWrapper>
    {title && <Header>{title}</Header>}
    <Content>
      {legend}
      {chart}
    </Content>
  </PieChartWrapper>
);
