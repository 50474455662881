import { Color, Size } from '../../theme/primitives';

const theme = {
  textarea: {
    shape: {
      default: {
        padding: `9px ${Size.space200}`,
        borderRadius: Size.radius200,
        borderStyle: 'solid',
        borderWidth: '1px',
      },
    },
    color: {
      default: {
        inactive: {
          text: Color.grey800,
          background: Color.white,
          border: Color.grey200,
        },
        hover: {
          border: Color.grey300,
        },
        placeholder: {
          text: Color.grey500,
        },
        disabled: {
          text: Color.grey800,
          background: Color.grey100,
          border: Color.grey100,
        },
      },
      invalid: {
        inactive: {
          border: Color.red200,
        },
        hover: {
          border: Color.red300,
        },
      },
      siteKeySelect: {
        inactive: {
          text: Color.grey600,
          background: Color.white,
          border: Color.grey400,
        },
        hover: {
          border: Color.grey500,
        },
        placeholder: {
          text: Color.grey600,
        },
        disabled: {
          text: Color.grey600,
          background: Color.grey100,
          border: Color.grey100,
        },
      },
    },
  },
};

export default theme;
