import React from 'react';
import styled from 'styled-components';
import ReactLoading from 'react-loading';

import { Color } from '../../theme/primitives';

interface LoadingIndicatorProps {
  label?: string;
  height?: string;
  marginTop?: number;
  loaderSize?: number;
  className?: string;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  label,
  height,
  marginTop,
  loaderSize,
  className,
}) => (
  <LoadingContainer height={height} marginTop={marginTop} className={className}>
    <ReactLoading
      type="bars"
      height={loaderSize || 'auto'}
      width={loaderSize || '10%'}
      color={Color.grey500}
    />
    {label && <LoadingLabel>{label}</LoadingLabel>}
  </LoadingContainer>
);

LoadingIndicator.defaultProps = {
  label: undefined,
  height: undefined,
  marginTop: undefined,
  className: undefined,
};

const LoadingContainer = styled.div<{
  marginTop?: number;
  height?: string;
}>`
  margin-top: ${props => props.marginTop || 0}px;
  width: 100%;
  height: ${props => props.height || 'auto'};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LoadingLabel = styled.div`
  @media (max-width: 720px) {
    font-size: 16px;
  }

  font-size: 24px;
  color: ${Color.grey500};
`;

export default LoadingIndicator;
