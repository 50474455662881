import React from 'react';
import styled from 'styled-components';

import Input from '../../atoms/Input';

export interface AdvancedSelectionInputProps {
  placeholder: string;
  onChange?: (value: string) => void;
  __type?: 'AdvancedSelectionInput';
}

export const AdvancedSelectionInput: React.FC<AdvancedSelectionInputProps> = ({
  placeholder,
  onChange,
}) => {
  return (
    <CustomInput
      placeholder={placeholder}
      onChange={e => onChange?.(e.target.value)}
    />
  );
};

AdvancedSelectionInput.defaultProps = {
  __type: 'AdvancedSelectionInput',
};

const CustomInput = styled(Input)`
  border-radius: 8px;
`;
