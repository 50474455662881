import React, { useState, useMemo, useCallback } from 'react';

import { range } from '../../../../utils/mathUtils';
import { ChartArea } from '../../ChartAreaProvider/ChartAreaContext';
import { useChartAreaContext } from '../../ChartAreaProvider/useChartAreaContext';
import {
  CheckpointsConfig,
  CheckpointsContextValue,
  CheckpointsGraphData,
} from '../constants';

import { GraphCheckPointsContext } from './GraphCheckpointsContext';

export interface GraphCheckpointsProviderProps {
  config?: CheckpointsConfig;
}

export const GraphCheckpointsProvider: React.FC<GraphCheckpointsProviderProps> = ({
  config,
  children,
}) => {
  const areaContext: ChartArea | null = useChartAreaContext();

  const [hoveredMarkerId, setHoveredMarkerId] = useState<string | null>(null);
  const [activeCheckpointId, setActiveCheckpointId] = useState<string | null>(
    null,
  );

  if (areaContext === null) {
    throw new Error(
      `ChartArea context not found.
       Likely, ModelCheckpointsProvider is not placed within ChartAreaProvider in the JSX declaration`,
    );
  }

  const { x: xAxis } = areaContext;

  const checkpointsGraphData: CheckpointsGraphData[] = useMemo(
    () =>
      config
        ? Object.entries(config.checkpoints)
            .map(([key, value]) => {
              const activeDeployment = value.active_deployment;

              return value.deployments.map(deployment => ({
                x: range(
                  new Date(deployment.deployment_timestamp).getTime(),
                  xAxis.min,
                  xAxis.max,
                  0,
                  config.width,
                ),
                id: deployment.checkpoint_id,
                label: `${key} | ${deployment.checkpoint_id}`,
                isRequested: deployment.checkpoint_id === activeDeployment,
              }));
            })
            // since as of this writing we're using pre-ES2019 syntax, .flat() is unavailable
            .reduce((accumulated, current) => {
              accumulated.push(...current);
              return accumulated;
            }, [])
        : [],
    [config, xAxis.min, xAxis.max],
  );

  const handleMarkerHighlight = (id: string | null) => {
    if (id) {
      setHoveredMarkerId(id);
    }
  };

  const handleMarkerTipClick = useCallback(
    (id: string | null) => {
      if (!id) {
        return;
      }

      const previousRollback = activeCheckpointId;

      try {
        setActiveCheckpointId(id);
        config?.onMarkerTipClick?.(id);
      } catch (error) {
        setActiveCheckpointId(previousRollback);
        config?.onMarkerTipClickError?.();
      }
    },
    [config, activeCheckpointId],
  );

  const contextValue: CheckpointsContextValue = useMemo(
    () => ({
      checkpoints: checkpointsGraphData,
      checkpointClickActionLabel: config?.checkpointClickActionLabel,
      checkpointMarkerIcon: config?.checkpointMarkerIcon,
      hoveredMarkerId,
      handleMarkerHighlight,
      activeCheckpointId,
      handleMarkerTipClick,
    }),
    [
      checkpointsGraphData,
      config?.checkpointClickActionLabel,
      config?.checkpointMarkerIcon,
      hoveredMarkerId,
      activeCheckpointId,
      handleMarkerTipClick,
    ],
  );

  return (
    <GraphCheckPointsContext.Provider value={contextValue}>
      {children}
    </GraphCheckPointsContext.Provider>
  );
};
