import React from 'react';
import styled, { DefaultTheme } from 'styled-components';

type Variant = keyof DefaultTheme['actionMenuSectionDivider']['color'] &
  keyof DefaultTheme['actionMenuSectionDivider']['shape'];

interface ActionMenuItemsGroupProps {
  children: React.ReactNode | React.ReactNode[];
  variant?: Variant;
}

export const ActionMenuItemsGroup: React.FC<ActionMenuItemsGroupProps> = ({
  children,
  variant,
}) => (
  <Wrapper data-testid="action-menu-group" variant={variant}>
    {children}
  </Wrapper>
);

export const wrapperStyles = `
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 8px;
`;

const Wrapper = styled.div<{ variant?: Variant }>`
  ${wrapperStyles};
  width: 100%;
  border-bottom: ${({ variant, theme }) =>
    `${
      theme.actionMenuSectionDivider.shape[variant || 'default'].borderWidth
    } ${
      theme.actionMenuSectionDivider.shape[variant || 'default'].borderStyle
    } ${theme.actionMenuSectionDivider.color[variant || 'default'].border}`};
  padding-bottom: 8px;

  &:last-of-type {
    padding: 0;
    border: none;
  }
`;
