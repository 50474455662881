import React from 'react';
import styled from 'styled-components';

import { Color } from '../../theme/primitives';
import { PageContent } from '../../templates/PageContent';

export interface TabsProps {
  className?: string;
  children: React.ReactNode;
}

export const Tabs: React.FC<TabsProps> = ({ className, children }) => {
  return (
    <Container className={className}>
      <TabsContainer>{children}</TabsContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  margin: 0 -64px;
  padding: 0 64px;
  overflow-x: hidden;

  &::before {
    content: '';
    position: absolute;
    bottom: 1px;
    height: 1px;
    background-color: ${Color.grey200};
    width: 150%;
    padding: 0 25%;
    left: -25%;
  }
`;

const TabsContainer = styled(PageContent)`
  white-space: nowrap;
  overflow: auto clip;
  max-width: 1120px;
  display: flex;
  gap: 24px;
  padding: 0 10px;
`;

export default Tabs;
