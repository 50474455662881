import React from 'react';

export interface TableSection {
  variant: 'head' | 'body';
  rowsNumber?: number;
  rowHeight?: number;
}

const TableSectionContext = React.createContext<TableSection | null>(null);

export default TableSectionContext;
