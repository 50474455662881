import React, { ReactNode } from 'react';

import { PieChart } from '../PieChart';
import { WidgetContainerWithFilter } from '../../molecules/WidgetContainerWithFilter';
import { WidgetContainer } from '../../molecules/WidgetContainer';
import { WithFilterProps } from '../../molecules/WidgetContainerWithFilter/WidgetFilter';
import { FormattedCircleData } from '../PieChart/helpers/formatPieChartData';

export interface PieSliceItem {
  id: string;
  value: number;
  label?: string;
  color: string;
}

export const PieChartWidgetWithFilter = ({
  items,
  header,
  description,
  pieTitle,
  filterOptions,
  selectedFilter,
  onFilterOptionChange,
  tooltip,
  tooltipDocsReference,
  isLoading,
  customLoadingIndicator,
  renderHoveredRow,
}: WithFilterProps<PieChartWidgetProps>) => (
  <WidgetContainerWithFilter
    header={header}
    onFilterOptionChange={onFilterOptionChange}
    filterOptions={filterOptions}
    selectedFilter={selectedFilter}
    headerIndent={10}
    tooltip={tooltip}
    tooltipDocsReference={tooltipDocsReference}
    isLoading={isLoading}
    customLoadingIndicator={customLoadingIndicator}
  >
    <PieChart
      description={description}
      renderHoveredRow={renderHoveredRow}
      data={items}
      title={pieTitle}
    />
  </WidgetContainerWithFilter>
);

export interface PieChartWidgetProps {
  items: PieSliceItem[];
  header: string;
  description?: string;
  pieTitle?: string;
  tooltip?: string;
  tooltipDocsReference?: string;
  isLoading?: boolean;
  customLoadingIndicator?: ReactNode;
  bottomContent?: ReactNode;
  className?: string;
  renderHoveredRow?: (data: FormattedCircleData) => React.ReactNode | null;
}

export const PieChartWidget = ({
  items,
  header,
  description,
  pieTitle,
  tooltip,
  tooltipDocsReference,
  isLoading,
  customLoadingIndicator,
  bottomContent,
  className,
}: PieChartWidgetProps) => (
  <WidgetContainer
    header={header}
    headerIndent={10}
    tooltip={tooltip}
    tooltipDocsReference={tooltipDocsReference}
    isLoading={isLoading}
    customLoadingIndicator={customLoadingIndicator}
    className={className}
  >
    <PieChart description={description} data={items} title={pieTitle} />
    {bottomContent && <>{bottomContent}</>}
  </WidgetContainer>
);
