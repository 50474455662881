import React, { useEffect, useState } from 'react';
import styled, { DefaultTheme } from 'styled-components';

import { Icon } from '../Icon';
import Text from '../Text';
import { Color } from '../../theme/primitives';
import { color } from '../../theme/themeUtils';

type Variant = keyof DefaultTheme['checkbox']['color'];

export interface CheckboxProps {
  variant?: Variant;
  label?: string;
  iconSize?: string;
  iconType?: string;
  value: boolean;
  isDisabled?: boolean;
  className?: string;
  onChange: Function;
  isRounded?: boolean;
  size?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  variant,
  onChange,
  label,
  iconSize = '24',
  iconType = 'checkMark',
  value,
  isDisabled,
  className,
  isRounded,
  size,
}) => {
  const [isChecked, setIsChecked] = useState(value || false);

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  return (
    <Label>
      <Container className={className}>
        <Input checked={isChecked} disabled={isDisabled} onChange={onChange} />
        <StyledCheckbox
          checked={isChecked}
          variant={variant}
          rounded={isRounded}
          size={size}
        >
          <CheckmarkIcon
            iconSize={iconSize}
            icon={iconType}
            variant={variant}
          />
        </StyledCheckbox>
        {label && <InputText variant="default">{label}</InputText>}
      </Container>
    </Label>
  );
};

const Label = styled.label`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input.attrs({ type: 'checkbox' })<{
  onChange: any;
}>`
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  width: 1px;
`;

const CheckmarkIcon = styled(Icon)<{
  icon?: any;
  variant?: Variant;
}>`
  path {
    fill: ${({ theme, variant }) =>
      theme.checkbox.color[variant || 'default'].selected.color};
  }
`;

const StyledCheckbox = styled.div<{
  checked?: boolean;
  variant?: Variant;
  rounded?: boolean;
  size?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.size || '20px'};
  height: ${props => props.size || '20px'};
  background-color: ${Color.white};
  border: 1px solid ${Color.grey400};
  border-radius: ${props => (props.rounded ? '50%' : '4px')};
  cursor: pointer;
  transition: all 150ms;

  ${({ theme, variant, checked }) =>
    color(
      theme.checkbox.color[variant || 'default'][
        checked ? 'selected' : 'inactive'
      ],
    )};

  ${Input}:disabled + & {
    cursor: not-allowed;

    ${({ theme, variant }) =>
      color(theme.checkbox.color[variant || 'default'].disabled)};

    ${CheckmarkIcon} {
      path {
        fill: ${({ theme, variant }) =>
          theme.checkbox.color[variant || 'default'].disabled.color};
      }
    }
  }

  ${Input}:focus + & {
    ${({ theme, variant }) =>
      `box-shadow:  0 0 5px -2px ${
        theme.checkbox.color[variant || 'default'].focus.boxShadowColor
      }`}};
  }

  ${CheckmarkIcon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const InputText = styled(Text)`
  margin-left: 8px;
  cursor: pointer;
`;
