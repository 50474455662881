import { Color, Size } from '../../theme/primitives';

const theme = {
  radioButton: {
    color: {
      default: {
        inactive: {
          background: Color.white,
          text: Color.grey600,
          border: Color.grey600,
        }, // todo: fix if not actual
        active: {
          background: Color.blue400,
          text: Color.white,
          border: 'transparent',
        },
        disabled: {
          background: Color.grey100,
          text: Color.grey400,
          border: Color.grey400,
        },
      },
    },
    shape: {
      default: {
        padding: `${Size.space100} 12px`,
        borderRadius: '100px',
        borderStyle: 'solid',
        borderWidth: '1px',
      },
    },
  },
};

export default theme;
