import { useContext } from 'react';

import { ChartAreaContext } from '../../../charts/atoms/ChartAreaProvider/ChartAreaContext';
import { range } from '../../../utils/mathUtils';
import { AXIS_X_HEIGHT, AXIS_Y_WIDTH } from '../constants';
import { ColumnBounding, Serie, SerieSetting } from '../types';
import { SortDirection } from '../../../constants';

import { useBarGraphContext } from './BarGraphContext';

// eslint-disable-next-line import/prefer-default-export
export const useColumn = (sortDirection?: SortDirection) => {
  const {
    data,
    columnStep,
    columnPadding,
    columnSeriesSettings,
    width: graphWidth,
    height: graphHeight,
    xProperty,
    yProperty,
  } = useBarGraphContext();
  const { x: chartAreaX, y: chartAreaY } = useContext(ChartAreaContext);

  const stepRatio =
    (columnStep || (chartAreaX as any).step) / (chartAreaX as any).step;

  const xAreaVariationByStep =
    (chartAreaX.max - chartAreaX.min) / (chartAreaX as any).step;
  const stepRegionWidth = (graphWidth - AXIS_Y_WIDTH) / xAreaVariationByStep;

  const width = stepRegionWidth * stepRatio - (columnPadding || 0);

  const columnDrawData = data.map(columnData => {
    // if data[xProperty] is a string, it is assumed a formatted date
    const xPropertyAsNumber: number =
      typeof (columnData as any)[xProperty] === 'number'
        ? (columnData as any)[xProperty]
        : new Date((columnData as any)[xProperty]).getTime();
    const xPropertyDifference = xPropertyAsNumber - chartAreaX.min;
    const xAreaRange = chartAreaX.max - chartAreaX.min;
    const xPropertyDifferenceRatio = xPropertyDifference / xAreaRange;
    const x = xPropertyDifferenceRatio * (graphWidth - AXIS_Y_WIDTH);

    const yPropertyDifference = (columnData as any)[yProperty] - chartAreaY.min;
    const yAreaRange = chartAreaY.max - chartAreaY.min;
    const yPropertyDifferenceRatio = yPropertyDifference / yAreaRange;
    const y = yPropertyDifferenceRatio * (graphHeight - AXIS_X_HEIGHT);

    const height =
      range(
        (columnData as any)[yProperty],
        chartAreaY.min,
        chartAreaY.max,
        0,
        graphHeight - AXIS_X_HEIGHT,
      ) || 0;

    const total = (columnData as any)[yProperty];

    let serieBoundingY = graphHeight - AXIS_X_HEIGHT - height;

    if (sortDirection) {
      columnSeriesSettings.sort((a, b) => {
        const { name: nameA } = a;
        const { name: nameB } = b;
        const valueA = (columnData as any)[nameA] || 0;
        const valueB = (columnData as any)[nameB] || 0;

        if (valueA < valueB) {
          return sortDirection === SortDirection.ASC ? -1 : 1;
        }

        if (valueA > valueB) {
          return sortDirection === SortDirection.ASC ? 1 : -1;
        }

        return 0;
      });
    }

    const series: Serie[] = [];
    for (const serie of columnSeriesSettings) {
      const { name, color } = serie as SerieSetting;

      const value = (columnData as any)[name];

      const ratio = value / total;

      const bounding: ColumnBounding = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      };

      if (value) {
        bounding.x = x || 0;
        bounding.y = serieBoundingY || 0;
        bounding.width = width;
        bounding.height = ratio * height || 0;
        serieBoundingY = bounding.y + bounding.height;
      }

      series.push({
        property: name,
        value,
        bounding,
        color,
      });
    }

    return {
      column: {
        total: (columnData as any)[yProperty],
        series,
        bounding: { x, y, width, height },
      },
    };
  });

  return { columnDrawData };
};
