import { Color, Size } from '../../theme/primitives';

const ERROR_TEXT_COLOR = Color.red500;

const theme = {
  formFieldLabel: {
    color: {
      default: {
        text: Color.grey500,
      },
      invalid: {
        text: ERROR_TEXT_COLOR,
      },
    },
    layout: {
      gap: Size.space100,
    },
  },
  formFieldError: {
    color: {
      text: ERROR_TEXT_COLOR,
    },
    layout: {
      gap: Size.space200,
    },
  },
};

export default theme;
