import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface AdvancedSelectionPanelProps {
  children: ReactNode;
}

export const AdvancedSelectionPanel: React.FC<AdvancedSelectionPanelProps> = ({
  children,
}) => {
  return <Container>{children}</Container>;
};

const Container = styled.div`
  position: relative;
`;
