import React, { useContext } from 'react';
import styled from 'styled-components';

import { normalize } from '../../../utils/mathUtils';
import { color } from '../../../theme/themeUtils';
import {
  ChartAreaContext,
  formatValueOnAxis,
} from '../ChartAreaProvider/ChartAreaContext';

import { AxisProps } from './types';
import { Label, Name } from './components';

const AxisY: React.FC<AxisProps> = ({ className, hideTicks }) => {
  const { y: axis } = useContext(ChartAreaContext);

  return (
    <ContainerY className={className} hideTicks={hideTicks}>
      <Name>{axis.name}</Name>
      {axis.scaleMarks.map((value, index) => {
        const position = normalize(axis.min, axis.max, value);

        return (
          <LabelY
            data-cy={`y-label-value=${value}`}
            hideTicks={hideTicks}
            position={position}
            key={index}
          >
            {formatValueOnAxis(axis, value)}
          </LabelY>
        );
      })}
    </ContainerY>
  );
};

export default AxisY;

const LabelY = styled(Label)<{
  position: number;
}>`
  bottom: ${props => `${props.position * 100}%`};
  right: 0px;
  transform: translateY(50%) translateY(-1px);
  text-align: right;
  padding-right: 10px;

  ::after {
    height: 1px;
    width: 5px;
    right: 0;
    top: 50%;
  }
`;

const ContainerY = styled.div<{ hideTicks?: boolean }>`
  ${({ theme }) => color(theme.axis.color.default)};
  display: flex;
  position: relative;
  border: ${({ hideTicks }) => (hideTicks ? 'none' : 'solid')};
  border-width: 0;
  border-right-width: 1px;

  ${Name} {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0%, -50%) rotateZ(-90deg);
  }
`;
