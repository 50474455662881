import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { ArrowPagination } from '../../molecules/ArrowPagination';
import { typography } from '../../theme/themeUtils';
import { WidgetContainer } from '../../molecules/WidgetContainer';
import { Color } from '../../theme/primitives';

export interface LogsWidgetWidgetProps {
  items: string[];
  header: string;
  logsDetails?: string;
  tooltip?: string;
  isLoading?: boolean;
  customLoadingIndicator?: ReactNode;
}

export const LogsWidget = ({
  items,
  header,
  logsDetails,
  tooltip,
  isLoading,
  customLoadingIndicator,
}: LogsWidgetWidgetProps) => {
  return (
    <WidgetContainer
      header={<Header>{header}</Header>}
      tooltip={tooltip}
      headerIndent={15}
      widgetUtility={<Details>{logsDetails}</Details>}
      isLoading={isLoading}
      customLoadingIndicator={customLoadingIndicator}
    >
      <ArrowPagination items={items}>
        {pageItems =>
          pageItems.map((item, index) => <LogItem key={index}>{item}</LogItem>)
        }
      </ArrowPagination>
    </WidgetContainer>
  );
};

const LogItem = styled.div`
  ${typography('small')}
  color: ${Color.grey500};
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 20px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  
  &:nth-child(odd) {
    background-color: ${Color.grey050};
  }
`;

const Header = styled.div`
  ${typography('h5')};
`;

const Details = styled.span`
  text-align: end;
  ${typography('h5')};
  font-weight: 400;
`;
