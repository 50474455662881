import React from 'react';
import styled from 'styled-components';

import { IconPlaceholder } from '../../atoms/Icon';

export interface SidebarProps {
  expanded: boolean;
  header: React.ReactNode;
  content: React.ReactNode;
  onToggleExpand: (expanded: boolean) => void;
}

export const Sidebar: React.FC<SidebarProps> = ({
  expanded,
  header,
  content,
  onToggleExpand,
}) => {
  return (
    <Container expanded={expanded}>
      <Header>{header}</Header>
      <Main>{content}</Main>
      <ExpandToggle onClick={(): void => onToggleExpand(!expanded)} />
    </Container>
  );
};

const Container = styled.nav<{ expanded: boolean }>`
  width: ${({ expanded }): string => (expanded ? '160px' : '64px')};
  background: ${({ theme }) => theme.panel.color.default.background};
  border-right: 1px solid ${({ theme }) => theme.global.color.border};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
`;

const Header = styled.header`
  margin: ${({ theme }) => theme.sidebar.layout.gap};
`;

const Main = styled.div`
  margin-top: 160px;
`;

const ExpandToggle = styled(IconPlaceholder)`
  margin: ${({ theme }) => theme.sidebar.layout.gap};
  position: absolute;
  bottom: 0;
`;
