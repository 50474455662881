import { useContext, useMemo } from 'react';

import { ChartAreaContext } from '../../../charts/atoms/ChartAreaProvider/ChartAreaContext';
import { floorWithStep } from '../../../utils/mathUtils';
import useId from '../../../utils/useId';
import { CellsTable, HeatMapDataConfig } from '../types';

import { HeatMapState } from './HeatMapDataContext';

export default function useHeatMapState(
  dataConfig: HeatMapDataConfig,
  palette: string[],
): HeatMapState {
  const area = useContext(ChartAreaContext);
  const graphId = useId('heatmap');

  return useMemo(() => {
    let zMin: number | null = null;
    let zMax: number | null = null;

    let cells: CellsTable = dataConfig.data.reduce<CellsTable>(
      (cells, datum) => {
        const x = dataConfig.getX(datum);
        const y = floorWithStep(
          dataConfig.getY(datum),
          area.y.step!,
          area.y.min,
          1000,
        );
        const z = dataConfig.getZ(datum);

        let total = z;

        if (cells.has(x)) {
          const column = cells.get(x)!;

          if (column.has(y)) {
            total += column.get(y)!;
          }

          column.set(y, total);
        } else {
          cells.set(x, new Map([[y, z]]));
        }

        zMax = zMax === null ? total : Math.max(zMax, total);
        zMin = zMin === null ? total : Math.min(zMin, total);

        return cells;
      },
      new Map(),
    );

    cells = new Map(
      Array.from(cells.entries())
        .map<[number, Map<number, number>]>(([x, column]) => {
          return [
            x,
            new Map(Array.from(column.entries()).sort(([y1], [y2]) => y2 - y1)),
          ];
        })
        .sort(([x1], [x2]) => x1 - x2),
    );

    return {
      graphId,
      cells,
      palette,
      legend: {
        min: zMin === null ? 0 : zMin,
        max: zMax === null ? 1 : zMax,
        palette,
      },
    };
  }, [dataConfig, area, palette, graphId]);
}
