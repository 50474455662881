import React, { createContext, useContext, useMemo } from 'react';

import useId from '../../../utils/useId';

type BarGraphProviderProps = {
  // Raw data passed to the graph
  data: object[];
  // Width of the graph container (in px)
  width: number;
  // Height of the graph container (in px)
  height: number;
  // Name of x property (used for referencing a property in the data)
  xProperty: string;
  // List of column colors
  colors: string[];
  // Label for y axis property
  yLabel?: string;
  // Amount of padding for each column (in px)
  columnPadding?: number;
};

export interface BarGraphState extends BarGraphProviderProps {
  graphId: string;
}

const defaultState: BarGraphState = {
  data: [],
  graphId: '',
  colors: [],
  columnPadding: 0,
  xProperty: '',
  yLabel: '',
  width: 1,
  height: 1,
};

const BarGraphContext = createContext<BarGraphState>(defaultState);
export const useBarGraphContext = () => useContext(BarGraphContext);

export const BarGraphProvider: React.FC<BarGraphProviderProps> = ({
  data,
  width,
  height,
  colors,
  xProperty,
  yLabel,
  columnPadding,
  children,
}) => {
  const graphId = useId('bar-graph');

  const state = useMemo(() => {
    return {
      data,
      columnPadding,
      colors,
      xProperty,
      yLabel,
      width,
      height,
      graphId,
    };
  }, [data, columnPadding, colors, xProperty, yLabel, width, height, graphId]);

  return (
    <BarGraphContext.Provider value={state}>
      {children}
    </BarGraphContext.Provider>
  );
};
