import React from 'react';
import styled from 'styled-components';

import Text from '../Text';
import { Color } from '../../theme/primitives';

export enum BadgeType {
  SUCCESS = 'success',
  PENDING = 'pending',
  ERROR = 'error',
  WARNING = 'warning',
  DISABLED = 'disabled',
  BETA = 'beta',
  PRIMARY = 'primary',
  EPIC = 'epic',
}

interface BadgeProps {
  className?: string;
  type: BadgeType;
  title: string;
  width?: number;
}

export const Badge: React.FC<BadgeProps> = ({
  className = '',
  title = '',
  type,
  width = 0,
}) => {
  return (
    <Container width={width} className={className} type={type} title={title}>
      <Title variant="small">{title}</Title>
    </Container>
  );
};

const Title = styled(Text)`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
`;

const Container = styled.div<{
  type?: BadgeType;
  width?: number;
}>`
  display: inline-flex;
  border-radius: 100px;
  border: 1px solid;
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px;
  width: ${({ width }) => (width ? `${String(width)}px` : 'auto')};
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ type }) => {
    switch (type) {
      case BadgeType.PRIMARY:
        return `
          background-color: ${Color.blue100};
          border-color: ${Color.blue400};
          color: ${Color.blue700};
        `;
      case BadgeType.ERROR:
        return `
          background-color: ${Color.red0};
          border-color: ${Color.red400};
          color: ${Color.red400};
        `;
      case BadgeType.WARNING:
        return `
          background-color: ${Color.orange100};
          border-color: ${Color.orange500};
          color: ${Color.orange500};
        `;
      case BadgeType.PENDING:
        return `
          background-color: ${Color.yellow100};
          border-color: ${Color.yellow300};
          color: ${Color.yellow600};
        `;
      case BadgeType.SUCCESS:
        return `
          background-color: ${Color.teal100};
          border-color: ${Color.teal600};
          color: ${Color.teal600};
        `;
      case BadgeType.DISABLED:
        return `
          background-color: ${Color.grey200};
          border-color: ${Color.grey700};
          color: ${Color.grey700};
        `;
      case BadgeType.EPIC:
        return `
          background-color: ${Color.purple100};
          border-color: ${Color.purple400};
          color: ${Color.purple700};
        `;
      case BadgeType.BETA:
        return `
          background-color: ${Color.yellow300};
          border-color: ${Color.yellow300};
          color: ${Color.black};

          ${Title} {
            font-weight: 700;
            font-size: 12px;
            text-transform: uppercase;
          }
        `;
      default:
        return '';
    }
  }}
`;
