import React from 'react';
import styled from 'styled-components';

import { Icon, IconProp } from '../../../../atoms/Icon';
import { Color } from '../../../../theme/primitives';
import { useCheckpointsContext } from '../hooks';

export interface CheckpointMarkerTipProps {
  xPosition: number;
  id: string;
  onHover: () => void;
  isHovered: boolean;
  isRequested: boolean;
}

export const CheckpointMarkerTip: React.FC<CheckpointMarkerTipProps> = ({
  xPosition,
  id,
  onHover,
  isHovered,
  isRequested,
}) => {
  const {
    handleMarkerTipClick,
    checkpointMarkerIcon,
  } = useCheckpointsContext();

  const isAlwaysVisible = !isHovered && isRequested;

  const handleClick = () => {
    handleMarkerTipClick?.(id);
  };

  return (
    <Container
      xPosition={xPosition}
      onMouseEnter={onHover}
      onMouseLeave={onHover}
      isRequested={isRequested}
      onClick={handleClick}
    >
      <Icon
        icon={(checkpointMarkerIcon || 'history') as IconProp}
        iconSize="12"
        fill={isAlwaysVisible ? Color.white : Color.blue100}
        stroke={isAlwaysVisible ? Color.grey500 : Color.blue500}
      />
    </Container>
  );
};

const Container = styled.div<{ xPosition: number; isRequested: boolean }>`
  position: absolute;
  width: 16px;
  height: 16px;
  top: -12px;
  left: ${({ xPosition }) => xPosition - 4}px;
  border-radius: 100%;
  z-index: 1;
  opacity: ${({ isRequested }) => (isRequested ? 1 : 0)};
  transition: opacity 0.25s;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;
