import React from 'react';
import styled from 'styled-components';

import { Color, Size } from '../../../theme/primitives';
import { Icon } from '../../../atoms/Icon';
import Text from '../../../atoms/Text';
import { InfoButton } from '../../InfoButton';

export interface TagProps {
  className?: string;
  maxTagWidth?: string;
  label?: string;
  operator?: string;
  value?: string;
  tag: string;
  onRemove?: (tag: string) => void;
  onClick?: (tag: string) => void;
  isValid?: boolean;
  validationMessage?: string;
}

const Tag: React.FC<TagProps> = ({
  className = '',
  tag = '',
  label = '',
  operator = '',
  value = '',
  maxTagWidth,
  onRemove,
  onClick,
  isValid = true,
  validationMessage,
}) => {
  const handleRemoveIconClick = () => {
    onRemove?.(tag);
  };

  const handleTagClick = () => {
    onClick?.(tag);
  };

  return (
    <Container isValid={isValid} className={className}>
      {onRemove && (
        <RemoveIconContainer onClick={handleRemoveIconClick}>
          <RemoveIcon icon="close" iconSize="10" />
        </RemoveIconContainer>
      )}
      <TagContainer>
        <TagTextContainer maxTagWidth={maxTagWidth} onClick={handleTagClick}>
          <TagText variant="default">
            {label}
            {operator && <Operator>{operator}</Operator>}
            {value}
          </TagText>
        </TagTextContainer>
        {!isValid && validationMessage && (
          <StyledInfoButton text={validationMessage || ''} size={18} />
        )}
      </TagContainer>
    </Container>
  );
};

const Container = styled.div<{ isValid: boolean }>`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 4px;
  height: 30px;
  box-sizing: border-box;

  ${({ isValid }) =>
    !isValid &&
    `
  ${RemoveIconContainer}, ${TagContainer} {
    border-color: ${Color.red600};
    background-color: ${Color.red0};
    color: ${Color.red600}
  }

  ${TagText} {
    color: ${Color.red600}
  }

  ${RemoveIcon} {
    fill: ${Color.red600};
  }

  ${RemoveIconContainer}:hover {
    border: 1px solid ${Color.red600};
    background-color: ${Color.red200};

    ${RemoveIcon} {
      fill: ${Color.red600};
    }
  }

  ${RemoveIconContainer}:hover + ${TagContainer} {
    border-color: ${Color.red600};
    background-color: ${Color.red0};
    color: ${Color.red600};

    ${TagText} {
      color: ${Color.red600};
    }
  }
  `}
`;

const TagContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 4px 4px 0;
  border: 1px solid ${Color.blue600};
  border-left: none;
  background-color: ${Color.blue100};
  gap: 8px;
  padding: 0 8px;
`;

const TagTextContainer = styled.div<{
  maxTagWidth?: string;
}>`
  max-width: ${({ maxTagWidth }) => maxTagWidth || '200px'};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
`;

const TagText = styled(Text)`
  font-size: ${Size.font200};
  color: ${Color.blue600};
`;

const Operator = styled(Text)`
  font-weight: bold;
  margin: 0 5px;
`;

const RemoveIcon = styled(Icon)`
  cursor: pointer;
  fill: ${Color.blue600};
`;

const StyledInfoButton = styled(InfoButton)`
  color: ${Color.red600};

  &:hover {
    color: ${Color.red700};
  }
`;

const RemoveIconContainer = styled.div`
  height: 100%;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${Color.blue600};
  border-radius: 4px 0 0 4px;
  border: 1px solid ${Color.blue600};
  background-color: ${Color.blue100};

  &:hover {
    fill: ${Color.red200};
    border: 1px solid ${Color.red200};
    background-color: ${Color.red0};

    ${RemoveIcon} {
      fill: ${Color.red500};
    }

    & + ${TagContainer} {
      background-color: ${Color.grey050};
      border-color: ${Color.grey200};

      ${TagText} {
        color: ${Color.grey600};
      }
    }
  }
`;

export default Tag;
