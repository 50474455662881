import panelTheme from '../atoms/Panel/theme';
import buttonTheme from '../atoms/Button/theme';
import inputTheme from '../atoms/Input/theme';
import textareaTheme from '../atoms/Textarea/theme';
import multiSelectTheme from '../atoms/MultiSelect/theme';
import iconTheme from '../atoms/Icon/theme';
import linearProgressTheme from '../atoms/LinearProgress/theme';
import typographyTheme from '../atoms/Typography/theme';
import cardTheme from '../templates/CardLayout/theme';
import formTheme from '../atoms/Form/theme';
import formFieldTheme from '../templates/FormFieldLayout/theme';
import tableTheme from '../atoms/Table/theme';
import loaderTheme from '../atoms/Loader/theme';
import sidebarTheme from '../organisms/Sidebar/theme';
import sidebarNavItemTheme from '../atoms/SidebarNavItem/theme';
import pageLayoutTheme from '../templates/PageLayout/theme';
import pageNavItemTheme from '../atoms/PageNavItem/theme';
import paginationItemTheme from '../atoms/PaginationItem/theme';
import siteKeySelectTheme from '../molecules/SiteKeySelect/theme';
import graphAxisTheme from '../atoms/GraphAxis/theme';
import tooltipTheme from '../atoms/Tooltip/theme';
import widgetFilterTheme from '../molecules/WidgetContainerWithFilter/theme';
import optionsListTheme from '../molecules/OptionsList/theme';
import radioButtonTheme from '../atoms/RadioButton/theme';
import actionMenuTheme from '../molecules/ActionMenu/theme';
import inputFieldTheme from '../atoms/InputField/theme';
import cardsDropdownTheme from '../molecules/CardsDropdown/theme';
import checkboxTheme from '../atoms/Checkbox/theme';
import dropdownTheme from '../atoms/Dropdown/theme';
import searchFieldTheme from '../atoms/SearchField/theme';

import { Color } from './primitives';

const defaultTheme = {
  global: {
    color: {
      text: Color.grey600,
      background: Color.grey100,
      border: Color.grey100,
    },
  },
  panel: panelTheme.panel,
  navItem: {
    color: {
      border: Color.teal200,
      background: Color.grey100,
      hover: Color.grey200,
    },
  },
  button: buttonTheme.button,
  input: inputTheme.input,
  textarea: textareaTheme.textarea,
  multiSelectInput: multiSelectTheme.multiSelectInput,
  select: multiSelectTheme.select,
  selectAction: multiSelectTheme.selectAction,
  selectOption: multiSelectTheme.selectOption,
  multiSelectOption: multiSelectTheme.multiSelectOption,
  multiSelectActions: multiSelectTheme.multiSelectActions,
  icon: iconTheme.icon,
  linearProgress: linearProgressTheme.linearProgress,
  typography: typographyTheme.typography,
  card: cardTheme.card,
  cardHeader: cardTheme.cardHeader,
  form: formTheme.form,
  formFieldLabel: formFieldTheme.formFieldLabel,
  formFieldError: formFieldTheme.formFieldError,
  table: tableTheme.table,
  tableRow: tableTheme.tableRow,
  loader: loaderTheme.loader,
  sidebar: sidebarTheme.sidebar,
  sidebarNavItem: sidebarNavItemTheme.sidebarNavItem,
  page: pageLayoutTheme.page,
  tab: pageNavItemTheme.tab,
  pagination: paginationItemTheme.pagination,
  siteKeysSelectOptionState: siteKeySelectTheme.siteKeysSelectOptionState,
  axis: graphAxisTheme.axis,
  tooltip: tooltipTheme.tooltip,
  widgetFilter: widgetFilterTheme.widgetFilter,
  widgetFilterOption: widgetFilterTheme.widgetFilterOption,
  optionsList: optionsListTheme.optionsList,
  radioButton: radioButtonTheme.radioButton,
  actionMenu: actionMenuTheme.actionMenu,
  actionMenuItem: actionMenuTheme.actionMenuItem,
  actionMenuSectionDivider: actionMenuTheme.actionMenuSectionDivider,
  inputField: inputFieldTheme.inputField,
  cardsDropdown: cardsDropdownTheme.cardsDropdown,
  checkbox: checkboxTheme.checkbox,
  dropdown: dropdownTheme.dropdown,
  searchField: searchFieldTheme.searchField,
};

export default defaultTheme;
