import React, { useEffect } from 'react';

import {
  ZoomInteractionStateValue,
  ZOOM_CANVAS_CLASS_NAME,
  INITIAL_STATE,
} from '../zoomConstants';

export const useCloseOnClickOutsideCanvas = (
  setZoomInteractionState: React.Dispatch<
    React.SetStateAction<ZoomInteractionStateValue | null>
  >,
) => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const handleMouseClick = (event: MouseEvent) => {
      const isEventTargetCanvas = event.target instanceof HTMLCanvasElement;
      const isGraphZoomCanvas = (event as any).target.classList.contains(
        ZOOM_CANVAS_CLASS_NAME,
      );

      if (!isEventTargetCanvas && !isGraphZoomCanvas) {
        setZoomInteractionState(INITIAL_STATE);
      }
    };

    document.addEventListener('mousedown', handleMouseClick);

    return () => {
      document.removeEventListener('mousedown', handleMouseClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
