import React, {
  RefCallback,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, { css, keyframes } from 'styled-components';

import { Color, Size } from '../../theme/primitives';
import Browser from '../../utils/browserUtil';
import { Icon } from '../../atoms/Icon';
import { KeyCode } from '../../constants';
import { PageContent } from '../PageContent';

import { Position, DrawerPortal } from './DrawerPortal';
import { NAV_OFFSET, TRANSITION_DURATION } from './constants';

interface DrawerProps {
  popUp?: React.ReactNode;
  children: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  isExpanded?: boolean;
  isExpansionDisabled?: boolean;
  isOpen: boolean;
  position?: Position;
  onToggleViewState?: (isExpanded: boolean) => void;
  onClose: () => void;
  onDelete?: () => void;
  wrapperRef: RefCallback<HTMLDivElement>;
}

export const Drawer: React.FC<DrawerProps> = ({
  popUp,
  children,
  position = 'right',
  isExpanded,
  isExpansionDisabled = false,
  onToggleViewState,
  isOpen,
  onClose,
  onDelete,
  header,
  footer,
  wrapperRef,
}) => {
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(isOpen);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);

  const handleToggleViewState = () => {
    if (onToggleViewState) {
      onToggleViewState(!isExpanded);
    }
  };

  const handleEnterKeyDown = (callback: Function) => (
    event: React.KeyboardEvent<HTMLElement>,
  ) => {
    const { key } = event;

    if (key === KeyCode.ENTER) {
      callback();
    }
  };

  const handleDelete = useCallback(() => {
    onDelete?.();
  }, [onDelete]);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleEscKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const { key } = event;

      if (key === KeyCode.ESC) {
        handleClose();
      }
    },
    [handleClose],
  );

  useEffect(() => {
    if (Browser.isMobile && !isExpanded) {
      onToggleViewState?.(true);
    }
  }, [onToggleViewState, isExpanded]);

  useEffect(() => {
    document.addEventListener('keydown', handleEscKeyPress);
    return () => {
      document.removeEventListener('keydown', handleEscKeyPress);
    };
  }, [handleEscKeyPress]);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      return;
    }

    if (isExpanded) {
      onToggleViewState?.(false);
      return;
    }

    const timer = setTimeout(() => {
      setIsVisible(false);
    }, TRANSITION_DURATION - 50);

    // eslint-disable-next-line consistent-return
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isOpen, isExpanded, onToggleViewState]);

  useEffect(() => {
    if (isExpanded) {
      document.body.style.overflow = 'hidden';
      return;
    }

    document.body.style.overflow = 'auto';
  }, [isExpanded]);

  useEffect(() => {
    if (!isVisible && isExpanded) {
      onToggleViewState?.(false);
    }
  }, [isExpanded, isVisible, onToggleViewState]);

  const setHeaderRefAndCalculateHeight = useCallback(node => {
    if (!node || !!headerRef.current) {
      return;
    }

    headerRef.current = node;
    const { height = 0 } = headerRef.current?.getBoundingClientRect() || {};

    setHeaderHeight(height);
  }, []);

  const setFooterRefAndCalculateHeight = useCallback(
    node => {
      if (!node) {
        footerRef.current = null;
        setFooterHeight(0);
        return;
      }

      if (footerRef.current) {
        return;
      }

      footerRef.current = node;
      const { height = 0 } = footerRef.current?.getBoundingClientRect() || {};

      setFooterHeight(height);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [footer],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <DrawerPortal position={position} setRef={wrapperRef}>
      {!!popUp && isOpen && (
        <Overlay position={position} isOpen={isOpen}>
          {popUp}
        </Overlay>
      )}
      <DrawerWrapper
        key="drawer-wrapper"
        isOpen={isOpen}
        isExpanded={!!isExpanded}
        position={position}
        ref={drawerRef}
      >
        <HeaderWrapper ref={setHeaderRefAndCalculateHeight}>
          <Header isExpanded={!!isExpanded}>
            {header}
            <ButtonsWrapper>
              {!Browser.isMobile && !isExpansionDisabled && (
                <ToggleViewButtonWrapper
                  tabIndex={0}
                  onClick={handleToggleViewState}
                  onKeyDown={handleEnterKeyDown(handleToggleViewState)}
                >
                  <Icon
                    icon={isExpanded ? 'minimize' : 'maximize'}
                    iconSize="s"
                  />
                </ToggleViewButtonWrapper>
              )}
              {onDelete && (
                <ButtonWrapper
                  tabIndex={0}
                  onClick={handleDelete}
                  onKeyDown={handleEnterKeyDown(handleDelete)}
                >
                  <Icon icon="trash" iconSize="s" />
                </ButtonWrapper>
              )}
              <ButtonWrapper
                tabIndex={0}
                onClick={handleClose}
                onKeyDown={handleEnterKeyDown(handleClose)}
              >
                <Icon icon="close" iconSize="s" />
              </ButtonWrapper>
            </ButtonsWrapper>
          </Header>
        </HeaderWrapper>
        <ContentWrapper
          isExpanded={!!isExpanded}
          headerHeight={headerHeight}
          footerHeight={footerHeight}
        >
          <StyledPageContent
            isExpanded={!!isExpanded}
            headerHeight={headerHeight}
            footerHeight={footerHeight}
          >
            {children}
          </StyledPageContent>
        </ContentWrapper>
        {footer && (
          <FooterWrapper ref={setFooterRefAndCalculateHeight}>
            {footer}
          </FooterWrapper>
        )}
      </DrawerWrapper>
    </DrawerPortal>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Overlay = styled.div<{ position: Position; isOpen: boolean }>`
  animation: ${({ isOpen }) =>
    isOpen
      ? css`
          ${fadeIn} 0.5s ease-out
        `
      : 'none'};
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(12px);
  z-index: 100;
  width: calc(100% - 680px);
  height: calc(100vh - ${NAV_OFFSET}px);
  position: fixed;
  ${({ position }) =>
    position === 'left' ? 'right: 0; left: auto;' : 'left: 0; right: auto;'}
  & > * {
    ${({ position }) =>
      position === 'left'
        ? 'right: auto; left: 16px'
        : 'left: auto; right: 16px;'}
  }
`;

const slideIn = (position: Position) => {
  const startPoint = position === 'left' ? '-100%' : '100%';
  return keyframes`
  from {
    transform: translateX(${startPoint});
  }
  to {
    transform: translateX(0);
  }
`;
};

const slideOut = (position: Position) => {
  const endPoint = position === 'left' ? '-100%' : '100%';
  return keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(${endPoint});
  }
`;
};

const DrawerWrapper = styled.div<{
  isExpanded: boolean;
  position: Position;
  isOpen: boolean;
}>`
  animation: ${({ position, isOpen }) =>
      isOpen ? slideIn(position) : slideOut(position)}
    ${TRANSITION_DURATION / 1000}s ease-out
    ${({ isExpanded }) => (isExpanded ? 0 : 1)};
  width: ${({ isExpanded }) => (isExpanded ? '100%' : '680px')};
  box-sizing: border-box;
  padding: ${({ isExpanded }) =>
    isExpanded ? '32px 0 24px 0' : '32px 32px 24px 32px'};
  background-color: ${Color.white};
  height: calc(100vh - ${NAV_OFFSET}px);
  position: fixed;
  ${({ isExpanded, position }) => !isExpanded && `${position}: 0;`};
  ${({ isExpanded }) => isExpanded && 'left: 0;'};
  z-index: 102;
  ${({ position, isExpanded }) =>
    isExpanded
      ? ''
      : position === 'left'
      ? `border-right: 1px solid ${Color.grey400}`
      : `border-left: 1px solid ${Color.grey400}`};

  @media (max-width: ${Size.tablet}) {
    width: 100%;
    left: 0;
    border: none;
    padding: 32px 32px 24px 32px;
  }
`;

const ContentWrapper = styled.div<{
  isExpanded: boolean;
  headerHeight: number;
  footerHeight: number;
}>`
  height: ${({ headerHeight, footerHeight }) =>
    `calc(100% - ${headerHeight}px - ${footerHeight}px)`};
  overflow: auto;
`;

const StyledPageContent = styled(PageContent)<{
  isExpanded: boolean;
  headerHeight: number;
  footerHeight: number;
}>`
  ${({ isExpanded }) => !isExpanded && 'width: 100%;'}
  height: 100%;
  overflow: visible;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  float: right;
  margin-left: 16px;

  position: absolute;
  top: 0;
  right: 0;
`;

const ButtonWrapper = styled.div`
  border-radius: 4px;
  cursor: pointer;
  padding: 4px;
  color: ${Color.grey600};
  align-self: flex-start;

  &:hover {
    background-color: ${Color.grey100};
    color: ${Color.grey700};
  }
`;

const ToggleViewButtonWrapper = styled(ButtonWrapper)`
  cursor: pointer;
  padding: 4px;
  color: ${Color.grey600};

  @media (max-width: ${Size.tablet}) {
    display: none;
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
`;

const Header = styled(PageContent)<{
  isExpanded: boolean;
}>`
  ${({ isExpanded }) => !isExpanded && 'width: 100%;'}
  display: grid;
  grid-template-columns: 1fr auto;
  background-color: ${Color.white};
  position: relative;
`;

const FooterWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
`;
