import React from 'react';
import styled from 'styled-components';

import { Color, Size } from '../../theme/primitives';
import { InfoButton } from '../../molecules/InfoButton';

export interface LabelProps {
  className?: string;
  title?: string;
  isRequired?: boolean;
  htmlFor?: string;
  isError?: boolean;
  infoButtonText?: string;
}

const Label: React.FC<LabelProps> = ({
  className,
  title,
  htmlFor,
  isRequired,
  isError,
  infoButtonText,
}) => {
  if (!title) {
    return null;
  }

  return (
    <Container className={className} htmlFor={htmlFor} isError={isError}>
      <Wrapper>
        <span>{title}</span>
        {isRequired && <Asterisk>*</Asterisk>}
        {infoButtonText && <StyledInfoButton text={infoButtonText} />}
      </Wrapper>
    </Container>
  );
};

const Container = styled.label<{
  isError?: boolean;
}>`
  display: block;
  font-size: 14px;
  line-height: 17px;
  color: ${({ isError }) => (isError ? Color.red400 : Color.grey600)};
  margin-bottom: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInfoButton = styled(InfoButton)`
  margin-left: ${Size.space100};
`;

const Asterisk = styled.span``;

export default Label;
