import React from 'react';
import styled from 'styled-components';
import { Size, LoadingIndicator, OptionsList } from '@packages/ui';

import { Border } from '../Border';
import { AutoComplete } from '../AutoComplete';

import { OptionType } from 'main/data/types/OptionType';

const StyledBorder = styled(Border)`
  border-radius: 4px;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  transition: all 0.25s;
  overflow: hidden;

  @media (max-width: ${Size.mobile}) {
    max-width: 100%;
  }
`;
type Props = {
  ariaRequired?: boolean;
  autoComplete?: string;
  label?: string;
  height?: number;
  width?: number;
  options: Array<OptionType>;
  placeholder: string;
  onFocusPlaceholder?: String;
  selected?: string;
  className?: string;
  dataCy?: string;
  onChange: (value: string, label?: string) => void;
  // todo: revisit optionsCallback return type
  optionsCallback?: (value: string) => any;
};
type State = {
  isOpen: boolean;
  isLoading: boolean;
  results: Array<OptionType>;
  value: string;
  placeholder: string;
}; // Component

class Selection extends React.PureComponent<Props, State> {
  // eslint-disable-next-line react/sort-comp
  ref = React.createRef<AutoComplete>();

  timeoutId?: ReturnType<typeof setTimeout> = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      placeholder: props.placeholder,
      isLoading: false,
      value: props.selected || '',
      results: props.options || [],
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { selected } = this.props;
    const { value } = this.state;

    if (prevProps.selected !== selected && value !== selected) {
      this.handleChange(selected || '');
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  handleFocus = () => {
    const { onFocusPlaceholder } = this.props;
    const setStateDate: any = {
      isOpen: true,
    };

    if (onFocusPlaceholder) {
      setStateDate.placeholder = onFocusPlaceholder;
    }

    this.setState(setStateDate);
  };

  handleBlur = () => {
    const { placeholder } = this.props;
    this.setState({
      isOpen: false,
      placeholder,
    });
  };

  filterMethod = async value => {
    const { optionsCallback } = this.props;
    if (!optionsCallback) return [];

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(async () => {
      this.timeoutId = undefined;
      this.setState({
        isLoading: true,
      });

      try {
        const results = await optionsCallback(value);
        this.setState({
          isLoading: false,
        });
        results.length &&
          this.setState({
            results,
          });
      } catch (error) {
        this.setState({
          isLoading: false,
        });
      }
    }, 1000);
    return this.state.results;
  };

  getOption = (value: string) => {
    const { options } = this.props;
    const { results } = this.state;

    let option = results?.find(
      ({ value: resultValue }) => resultValue === value,
    );

    if (!option) {
      option = options?.find(({ value: resultValue }) => resultValue === value);
    }

    return option;
  };

  handleChange = (value: string) => {
    const { onChange } = this.props;
    const option = this.getOption(value);

    this.ref.current?.focus();
    this.setState({
      isOpen: value === '',
      value,
    });

    onChange?.(value, option?.label);
  };

  handleFiltered = (filtered: Array<any>) => {
    const { options, onChange } = this.props;

    if (filtered.length === 1 && !!filtered[0].value) {
      // If filtered options length is 1, set the value of the option (e.g US) as selected option,
      // and label (e.g United States) as the visible text in the visible UI. This should fix the chrome autofill problem
      this.setState({
        value: filtered[0].label,
      });
      onChange(filtered[0].value, filtered[0].label);
    } else {
      const results = filtered.length > 0 ? filtered : options;

      this.setState({
        results,
      });
    }
  };

  render() {
    const {
      ariaRequired,
      autoComplete,
      options,
      optionsCallback,
      width,
      height = 40,
      selected = '',
      className,
      dataCy,
      label,
    } = this.props;
    const { results, placeholder, isLoading, isOpen, value } = this.state;
    const current = this.getOption(value);
    const selectedValue = current ? current.label : '';

    return (
      <OptionsList
        dataCy={dataCy || 'selection'}
        width={width}
        // @ts-ignore todo: consider removing this prop as unused
        height={height}
        open={isOpen}
        options={results}
        selected={selected}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        className={className}
      >
        {/* @ts-ignore todo: fix types */}
        <StyledBorder width={width} open={isOpen}>
          <AutoComplete
            ariaRequired={ariaRequired}
            autoComplete={autoComplete}
            label={label}
            height={height}
            width={width}
            options={options}
            value={selectedValue}
            placeholder={placeholder}
            onClear={() => this.handleChange('')}
            onFilter={this.handleFiltered}
            filterMethod={optionsCallback ? this.filterMethod : undefined}
            onFocus={this.handleFocus}
            ref={this.ref}
          />
          {isLoading && <LoadingIndicator loaderSize={32} marginTop={-35} />}
        </StyledBorder>
      </OptionsList>
    );
  }
}

export default Selection;
