/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';

import { Color, DEFAULT_FONT_FAMILY } from '../../theme/primitives';
import { useForwardRef } from '../../hooks';
import { KeyCode } from '../../constants';
import Text from '../Text';
import { Label } from '../Label';
import { Icon } from '../Icon';

import { usePreview } from './hooks';

export interface TextAreaFieldProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  isError?: boolean;
  errorMessage?: string;
  label?: string;
  isRequired?: boolean;
  isClearable?: boolean;
  isPositionAbsolute?: boolean;
  isEnterDisabled?: boolean;
  onKeyPress?: any;
  className?: string;
  inputHeight?: number;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onClear?: () => void;
  isPreview?: boolean;
  value?: string;
}

const TextAreaField = React.forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
  (
    {
      className,
      isError,
      name = '',
      inputHeight = 40,
      label,
      isRequired,
      isClearable,
      isPositionAbsolute,
      isEnterDisabled,
      isPreview,
      onKeyPress,
      errorMessage,
      value: inputValue = '',
      onChange,
      onClear,
      ...props
    },
    ref,
  ) => {
    const [value, setValue] = useState(inputValue);
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useForwardRef(ref);
    const preview = usePreview(value, inputRef?.current);

    useEffect(() => {
      setValue(inputValue);
    }, [inputValue]);

    useLayoutEffect(() => {
      const getNumberValue = (value = '') => {
        return parseInt(value, 10) || 0;
      };

      const getFullHeight = (element: HTMLElement) => {
        if (!element) {
          return 0;
        }

        const elementStyle = window.getComputedStyle(element);
        const height =
          element.scrollHeight +
          getNumberValue(elementStyle.borderTopWidth) +
          getNumberValue(elementStyle.borderBottomWidth);

        return height;
      };

      const autoExpandTextarea = () => {
        const input = inputRef?.current;

        if (!input) {
          return;
        }

        input.style.height = `${inputHeight}px`;

        if (isFocused || !isPreview) {
          const height = getFullHeight(input);
          input.style.height = `${height}px`;
        }
      };

      autoExpandTextarea();
    }, [value, inputHeight, inputRef, isFocused, isPreview]);

    const handleBlur = () => {
      setIsFocused(false);
    };

    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleClear = () => {
      inputRef?.current?.focus?.();
      setValue('');
      onClear?.();
    };

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
      onChange?.(event);
    };

    const handleKeyPress = (
      event: React.KeyboardEvent<HTMLTextAreaElement>,
    ) => {
      const { key } = event;

      if (isEnterDisabled && key === KeyCode.ENTER) {
        event.preventDefault();
      }

      onKeyPress?.(event);
    };

    const handleClearIconKeyPress = (
      event: React.KeyboardEvent<SVGElement>,
    ) => {
      event.preventDefault();
      handleClear();
    };

    return (
      <Container className={className}>
        {label && (
          <Label
            htmlFor={name}
            title={label}
            isRequired={isRequired}
            isError={isError}
          />
        )}
        <Field>
          <TextArea
            {...props}
            id={name}
            isClearable={!!(isClearable && value)}
            isPositionAbsolute={isPositionAbsolute}
            isError={isError}
            ref={inputRef}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            onBlur={handleBlur}
            onFocus={handleFocus}
            value={isFocused || !isPreview ? value : preview}
          />
          {isClearable && value && (
            <ClearIcon
              icon="close"
              iconSize="s"
              tabIndex={0}
              onClick={handleClear}
              onKeyPress={handleClearIconKeyPress}
            />
          )}
        </Field>
        {errorMessage && (
          <ErrorMessage variant="default">{errorMessage}</ErrorMessage>
        )}
      </Container>
    );
  },
);

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

const TextArea = styled.textarea<{
  isError?: boolean;
  isClearable?: boolean;
  isPositionAbsolute?: boolean;
  resize?: boolean;
}>`
  display: flex;
  width: 100%;
  padding: ${({ isClearable }) =>
    isClearable ? '8px 44px 8px 16px' : '8px 16px'};
  border: 1px solid;
  border-color: ${({ isError }) => (isError ? Color.red400 : Color.grey400)};
  border-radius: 4px;
  color: ${Color.grey600};
  font-size: 14px;
  line-height: 20px;
  resize: ${({ resize }) => (resize ? 'unset' : 'none')};
  box-sizing: border-box;
  font-family: ${DEFAULT_FONT_FAMILY};
  align-items: center;

  &:hover,
  &:focus {
    border-color: ${({ isError }) => (isError ? Color.red400 : Color.grey800)};
    outline: none;
  }

  &:focus {
    ${({ isPositionAbsolute }) =>
      isPositionAbsolute
        ? `
            position: absolute; top: 0;
            left: 0;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.24);
          `
        : ''};
  }

  &::placeholder {
    color: ${Color.grey400};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }
`;

const Field = styled.div`
  position: relative;
  width: 100%;
`;

const ClearIcon = styled(Icon)`
  position: absolute;
  top: 12px;
  right: 16px;
  z-index: 9991;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    color: ${Color.grey800};
  }
`;

const ErrorMessage = styled(Text)`
  display: flex;
  margin: 4px 0 0;
  font-size: 10px;
  line-height: 14px;
  color: ${Color.red400};
`;

export default TextAreaField;
