import React, { useState } from 'react';
import { startOfMonth, subMilliseconds, isBefore } from 'date-fns';
import styled from 'styled-components';
import { enGB } from 'date-fns/locale';

import { Color } from '../../theme/primitives';
import { DayCellStateModifiers } from '../DateRangePicker/utils';
import useControllableState from '../DateRangePicker/helpers/useControllableState';
import CalendarNavigation from '../../molecules/CalendarNavigation';
import CalendarWeekHeader from '../../molecules/CalendarWeekHeader';
import { DateInput } from '../DateInput';
import CalendarGrid from '../../molecules/CalendarGrid';
import { FocusState } from '../../organisms/DateRangePickerCalendar/constants';
import { Button } from '../Button';
import { TimePeriod } from '../TimePeriod';
import { TimePeriodConfig } from '../TimePeriodConfig';
import { TimePeriodGranularity } from '../Granularity';
import { TimePeriodButton } from '../TimePeriodBackBtn';

import CalendarContext from './CalendarContext';
import { useCalendarValidation } from './useCalendarValidation';

const CalendarContainer = styled.div`
  background-color: ${({ theme }) => theme.panel.color.default.background};
  border: 1px solid;
  border-color: ${({ theme }) => theme.global.color.text};
  border-radius: 5px;
  padding: 5px 10px;
  min-width: 430px;
  width: 100%;
  min-height: 465px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
`;

const SliderContainer = styled.div<{ slide?: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
  height: 100%;
  width: 160px;
  transform: ${({ slide }) => (slide ? 'translateX(0%)' : 'translateX(-100%)')};
  transition: 300ms;
  background-color: ${({ theme }) => theme.panel.color.default.background};
  padding: ${({ slide }) => (slide ? '0 10px' : '0')};
`;

const TimePeriodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${Color.grey100};
  padding-right: 15px;
  padding-left: 5px;
  width: 160px;
  gap: 15px;
  margin-top: 10px;
  flex-grow: 1;
`;

const CalendarWrapper = styled.div`
  width: 260px;
  padding: 0 5px;
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const DateInputWrapper = styled.div`
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  &:last-of-type {
    margin-left: 10px;
  }
`;

const CalendarGridWrapper = styled.div``;

interface CalendarProps {
  startDate: Date | null;
  endDate: Date | null;
  onMonthChange?: (value: Date) => void;
  weekdayFormat: string;
  currentDate?: Date;
  onCurrentDateChange?: (value: Date) => void;
  modifiers?: DayCellStateModifiers;
  minimumDate?: Date;
  maximumDate?: Date;
  onDayHover?: (value: Date | null) => void;
  onDayClick?: (value: Date) => void;
  onStartDateChange: (value: Date | null) => void;
  onEndDateChange: (value: Date | null) => void;
  focus?: string;
  onFocusChange: (value: FocusState) => void;
  onApply: (start: Date, end: Date, granularity: number | null) => void;
  currentFormat: '12' | '24';
  setCurrentFormat: (value: '12' | '24') => void;
  granularityItems: number[];
  defaultGranularity: number;
  granularity: number | null;
  onGranularityChange: (value: number | null) => void;
  defaultTimePeriod: number;
  timePeriodState: number | null;
  setTimePeriodState: (value: number | null) => void;
  showGranularity?: boolean;
  className?: string;
}

/**
 * Draws a calendar
 * @param locale - localization
 * @param receivedMonth - start date of the current month
 * @param receivedModifiers - a set of methods for the selected date in the calendar:
 *    disabled - a method that returns a boolean value, indicates whether the user can select a date or not.
 *    selected - a method that returns a boolean value, indicates whether this date was previously selected
 *    selectedStart - a method that returns a boolean value, indicates whether the given date is a start date
 *    selectedMiddle - a method that returns a boolean value, indicates whether the given date is a date between the start date and the end
 *    selectedEnd - a method that returns a boolean value, indicates whether the given date is a end date
 * @param minimumDate - an optional parameter that allows you to set the minimum date that the user can select
 * @param maximumDate - an optional parameter that allows you to set the maximum date that the user can select
 * @param onCurrentDateChange - method that changes the current date
 * @param onDayHover - event triggered when hovering over a date in the calendar
 * @param onDayClick - event triggered when click on a date in the calendar
 * @param weekdayFormat - the format for displaying the days of the week in the calendar
 * @param onStartDateChange - method changing the selected start date
 * @param onEndDateChange - method changing the selected end date
 * @param focus - indicates which current date the user is going to select: Start or End
 * @param startDate - start date
 * @param endDate - end date
 * @param onChange - apply the date on the calendar component
 * @constructor
 */
const Calendar: React.FC<CalendarProps> = ({
  focus,
  currentDate: receivedMonth,
  modifiers: receivedModifiers,
  minimumDate,
  maximumDate,
  onCurrentDateChange,
  onDayHover,
  onDayClick,
  weekdayFormat,
  onStartDateChange,
  onEndDateChange,
  startDate,
  endDate,
  onFocusChange,
  onApply,
  currentFormat,
  setCurrentFormat,
  defaultGranularity,
  granularityItems,
  granularity,
  onGranularityChange,
  defaultTimePeriod,
  timePeriodState,
  setTimePeriodState,
  showGranularity = true,
  className,
}) => {
  // TODO: replace all locale usages with value from context
  const { translations, locale = enGB } = React.useContext(CalendarContext);
  const [slideGranularity, setSlideGranularity] = useState(false);
  const [date, setDate] = useControllableState(
    receivedMonth || startOfMonth(new Date()),
    onCurrentDateChange,
  );

  const {
    hasValidationErrors,
    validationErrors,
    resetValidation,
    validateField,
  } = useCalendarValidation({
    values: { startDate, endDate },
    validators: { minimumDate, maximumDate },
  });

  const reset = () => {
    const now = Date.now();

    resetValidation();
    setDate(startOfMonth(new Date(now - defaultTimePeriod)));
    onStartDateChange(new Date(now - defaultTimePeriod));
    onEndDateChange(new Date(now));
    setTimePeriodState(defaultTimePeriod);
  };

  const handleStartDateInputChange = (startDate: Date) => {
    const startDateTime = startDate?.getTime();
    const startTimeLimit = Date.now() - defaultTimePeriod;

    setTimePeriodState(null);

    if (!startDateTime) {
      onStartDateChange(new Date(startTimeLimit));

      return;
    }

    onStartDateChange(startDate);
  };

  const handleEndDateInputChange = (endDate: Date) => {
    const endDateTime = endDate?.getTime();
    const dateTimeNow = Date.now();

    setTimePeriodState(null);

    if (!endDateTime) {
      onEndDateChange(new Date(dateTimeNow));

      return;
    }

    onEndDateChange(endDate);
  };

  return (
    <CalendarContainer className={className}>
      {showGranularity && (
        <SliderContainer slide={slideGranularity}>
          <TimePeriodGranularity
            options={granularityItems}
            value={granularity}
            handlerClick={value => {
              onGranularityChange(value);
              setSlideGranularity(false);
            }}
            locale={locale}
            defaultValue={defaultGranularity}
          />
          <TimePeriodButton handlerClick={setSlideGranularity} />
        </SliderContainer>
      )}
      <TimePeriodWrapper>
        <TimePeriod
          value={timePeriodState}
          onChange={value => {
            setTimePeriodState(value);

            if (value !== null) {
              let start;
              let end;

              if (value >= 0) {
                end = new Date();
                start = subMilliseconds(end, value);
              } else {
                start = new Date();
                end = subMilliseconds(start, value);
              }

              onStartDateChange(start);
              onEndDateChange(end);
              onFocusChange(FocusState.END_DATE);
              setDate(startOfMonth(end));
              onGranularityChange(null);

              onApply(start, end, null);
            }
          }}
        />
        {showGranularity && (
          <TimePeriodConfig
            onGranularityChange={() => {
              setSlideGranularity(true);
            }}
            onFormatChange={setCurrentFormat}
            granularity={granularity}
            format={currentFormat}
          />
        )}
      </TimePeriodWrapper>
      <CalendarWrapper>
        <CalendarNavigation
          locale={locale}
          minimumDate={minimumDate}
          maximumDate={maximumDate}
          month={date || null}
          onMonthChange={setDate}
        />

        <CalendarWeekHeader locale={locale} weekdayFormat={weekdayFormat} />

        <CalendarGridWrapper>
          <CalendarGrid
            transitionDuration={300}
            modifiers={receivedModifiers || {}}
            month={date || null}
            onDayHover={onDayHover}
            onDayClick={value => {
              onDayClick && onDayClick(value);
              setTimePeriodState(null);
            }}
          />
        </CalendarGridWrapper>

        <DateInputWrapper>
          <DateInputWrapper>
            <DateInput
              width={200}
              height={30}
              label={translations.dateInputStart}
              dateTime={startDate}
              timeFormat={currentFormat}
              isActive={
                focus === FocusState.END_DATE || focus === FocusState.NONE
              }
              onChange={handleStartDateInputChange}
              validationError={validationErrors.startDate}
              onValidate={date => validateField('startDate', date)}
            />
            <DateInput
              width={200}
              height={30}
              label={translations.dateInputEnd}
              dateTime={endDate}
              timeFormat={currentFormat}
              isActive={focus === FocusState.START_DATE}
              onChange={handleEndDateInputChange}
              validationError={validationErrors.endDate}
              onValidate={date => validateField('endDate', date)}
            />
          </DateInputWrapper>
        </DateInputWrapper>

        <BtnWrapper>
          <StyledButton
            variant="legacyOutlinedSecondary"
            onClick={reset}
            disabled={!startDate || !endDate}
          >
            {translations.resetButton}
          </StyledButton>
          <StyledButton
            variant="legacyOutlinedPrimary"
            disabled={!startDate || !endDate || hasValidationErrors}
            onClick={() => {
              if (!startDate || !endDate) {
                return;
              }

              if (isBefore(startDate, endDate)) {
                onApply(startDate, endDate, granularity);
              } else {
                onApply(endDate, startDate, granularity);
              }
            }}
          >
            {translations.applyButton}
          </StyledButton>
        </BtnWrapper>
      </CalendarWrapper>
    </CalendarContainer>
  );
};

export default Calendar;
