/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';

import { PaginationItem } from '../../atoms/PaginationItem';

export interface PaginationProps {
  total: number;
  current: number;
  adjacentItems?: number;

  onChange?: (page: number) => void;

  getHref?: (page: number) => string;
  LinkComponent?: React.ComponentType<any>;
  prevLabel?: string;
  nextLabel?: string;
}

export const Pagination: React.FC<PaginationProps> = ({
  total,
  current,
  adjacentItems = 2,
  onChange,
  ...paginationItemProps
}) => {
  const items: React.ReactNode[] = generatePaginationLayout(
    1,
    total,
    current,
    adjacentItems,
  ).map(paginationItem => {
    const key =
      paginationItem.type === 'page'
        ? `${paginationItem.type}${paginationItem.page}`
        : paginationItem.type;

    // eslint-disable-next-line prettier/prettier
    return (
      <PaginationItem
        {...paginationItemProps}
        {...paginationItem}
        onClick={
          onChange &&
          (page => {
            if (current !== page) {
              onChange(page);
            }
          })
        }
        current={current}
        key={key}
      />
    );
  });

  return <List>{items}</List>;
};

const List = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  user-select: none;
  justify-content: center;
`;

// Pagination layout

export type PaginationItemState =
  | {
      type: 'page' | 'first' | 'last' | 'next' | 'previous';
      page: number;
    }
  | {
      type: 'start-ellipsis' | 'end-ellipsis' | 'next' | 'previous';
    };

function generatePaginationLayout(
  first: number,
  last: number,
  current: number,
  adjacent: number,
): PaginationItemState[] {
  const result: PaginationItemState[] = [];
  const amount = last - first + 1;
  const gapIndex = adjacent + 1;
  const edgeIndex = adjacent + 2;
  const slotsAmount = 1 + 2 * edgeIndex;

  if (current > first) {
    result.push({
      page: current - 1,
      type: 'previous',
    });
  } else {
    result.push({
      type: 'previous',
    });
  }

  if (amount <= slotsAmount) {
    for (let page = first; page <= last; page += 1) {
      result.push({
        page,
        type: 'page',
      });
    }
  } else {
    const middle = Math.min(
      Math.max(current, first + edgeIndex),
      last - edgeIndex,
    );

    result.push({
      page: first,
      type: 'first',
    });

    const leftGapPage = first + 1;

    if (leftGapPage === middle - gapIndex) {
      result.push({ page: leftGapPage, type: 'page' });
    } else {
      result.push({ type: 'start-ellipsis' });
    }

    // Fill the center
    for (let index = -adjacent; index <= adjacent; index += 1) {
      result.push({
        type: 'page',
        page: middle + index,
      });
    }

    const rightGapPage = last - 1;

    if (rightGapPage === middle + gapIndex) {
      result.push({ page: rightGapPage, type: 'page' });
    } else {
      result.push({ type: 'end-ellipsis' });
    }

    result.push({ page: last, type: 'last' });
  }

  if (current < last) {
    result.push({
      page: current + 1,
      type: 'next',
    });
  } else {
    result.push({
      type: 'next',
    });
  }

  return result;
}
