import {
  Size,
  DEFAULT_FONT_FAMILY,
  MONO_FONT_FAMILY,
} from '../../theme/primitives';

const theme = {
  typography: {
    default: {
      fontFamily: DEFAULT_FONT_FAMILY,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: Size.font200,
      lineHeight: Size.space400,
    },
    mono: {
      fontFamily: MONO_FONT_FAMILY,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: Size.font200,
      lineHeight: Size.space400,
    },
    h1: {
      fontFamily: DEFAULT_FONT_FAMILY,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: Size.font500,
      lineHeight: Size.space800,
    },
    h2: {
      fontFamily: DEFAULT_FONT_FAMILY,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: Size.font500,
      lineHeight: Size.space600,
    },
    h3: {
      fontFamily: DEFAULT_FONT_FAMILY,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: Size.font400,
      lineHeight: Size.space400,
    },
    h4: {
      fontFamily: DEFAULT_FONT_FAMILY,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: Size.font300,
      lineHeight: Size.space400,
    },
    h5: {
      fontFamily: DEFAULT_FONT_FAMILY,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: Size.font300,
      lineHeight: Size.space500,
    },
    h6: {
      fontFamily: DEFAULT_FONT_FAMILY,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: Size.font200,
      lineHeight: Size.space500,
    },
    small: {
      fontFamily: DEFAULT_FONT_FAMILY,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: Size.font100,
      lineHeight: Size.space300,
    },
  },
};

export default theme;
