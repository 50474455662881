import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import {
  MultiSelect,
  MultiSelectDefaultOptionType,
  MultiSelectOptionComponent,
  MultiSelectSelectedComponent,
} from '../../atoms/MultiSelect';
import Text from '../../atoms/Text';
import { Icon } from '../../atoms/Icon';
import { Theme } from '../../styled';

import { SingleSelect } from './SingleSelect';

export interface Option {
  value: string;
  label: string;
}

interface SitekeySelectProps {
  initialValue: string[];
  onChange: (sitekeyId: string[] | string) => void;
  selectedSiteKeysLabel?: string;
  options: MultiSelectDefaultOptionType[];
  clearLabel?: string;
  selectAllLabel?: string;
  inputPlaceholder: string;
  noOptionsFoundText?: string;
  isAlwaysExpanded?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  errorMessage?: string;
  className?: string;
  isMulti?: boolean;
  dataTestId?: string;
}

const getSelectedSiteKeysComponent = (
  title = 'Selected',
): MultiSelectSelectedComponent => ({ selected, options }) => (
  <>
    {title}: {`${selected.length} / ${options.length}`}
  </>
);

const SitekeyOption: MultiSelectOptionComponent = ({ option, isSelected }) => {
  return (
    <OptionExternalContainer>
      <OptionInternalContainer>
        <StyledName
          whiteSpace="nowrap"
          overflow="hidden"
          maxWidth="290px"
          textOverflow="ellipsis"
          variant="small"
        >
          {option.label || '<noname>'}
        </StyledName>
        <StyledSitekey variant="small">{option.value}</StyledSitekey>
      </OptionInternalContainer>
      <SelectionState isSelected={isSelected} />
    </OptionExternalContainer>
  );
};

const filterSitekey = (
  { value, label }: MultiSelectDefaultOptionType,
  rawSearch: string,
): boolean => {
  const search = rawSearch.trim().toLowerCase();

  return (
    label.toLowerCase().includes(search) || value.toLowerCase().includes(search)
  );
};

export const SitekeySelect: React.FC<SitekeySelectProps> = ({
  className,
  initialValue,
  onChange,
  options,
  clearLabel,
  selectAllLabel,
  inputPlaceholder,
  selectedSiteKeysLabel,
  noOptionsFoundText,
  isAlwaysExpanded,
  isDisabled,
  isError,
  errorMessage,
  isMulti = true,
  dataTestId,
}) => {
  const filterOptions = () =>
    options.filter(({ value }) => initialValue.includes(value));

  const [selected, setSelected] = useState<MultiSelectDefaultOptionType[]>(
    filterOptions(),
  );

  const isFirstRender = useRef(true);

  // This is needed for cases where initialValue is dynamic and controlled by an external state
  // (also makes sure otherwise an unnecessary re-render is not triggered)
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      setSelected(filterOptions());
    }
  }, [initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (values: Option[]) => {
    const selectedValues = values.filter(({ value }) => {
      return options.some(({ value: optionValue }) => value === optionValue);
    });

    setSelected(selectedValues);
    onChange(selectedValues.map(({ value }) => value));
  };

  const handleSingleValueChange = (value: Option) => {
    onChange(value.value as string);
    setSelected([value]);
  };

  if (!isMulti) {
    return (
      <SingleSelect
        className={className}
        options={options}
        selected={selected[0]}
        filterOptions={filterSitekey}
        inputPlaceholder={inputPlaceholder}
        onChange={handleSingleValueChange}
        error={isError}
        errorMessage={errorMessage}
        noOptionsFoundText={noOptionsFoundText}
        isDisabled={isDisabled}
        dataTestId={dataTestId}
      />
    );
  }

  return (
    <MultiSelect
      className={className}
      variant="siteKeySelect"
      options={options}
      selected={selected}
      SelectedComponent={getSelectedSiteKeysComponent(selectedSiteKeysLabel)}
      OptionComponent={SitekeyOption}
      filterOptions={filterSitekey}
      clearLabel={clearLabel || 'Clear all'}
      selectAllLabel={selectAllLabel || 'Select all'}
      inputPlaceholder={inputPlaceholder}
      onChange={handleChange}
      error={isError}
      errorMessage={errorMessage}
      noOptionsFoundText={noOptionsFoundText}
      isAlwaysExpanded={isAlwaysExpanded}
      isDisabled={isDisabled}
    />
  );
};

const SelectionState = ({ isSelected }: { isSelected: boolean }) => (
  <SelectionStateWrapper isSelected={isSelected}>
    <Icon
      icon="checkMark"
      iconSize="s"
      fill={isSelected ? 'white' : 'transparent'}
    />
  </SelectionStateWrapper>
);

const SelectionStateWrapper = styled.div`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected
        ? theme.siteKeysSelectOptionState.color.selected.border
        : theme.siteKeysSelectOptionState.color.default.border};
  background-color: ${({
    isSelected,
    theme,
  }: {
    isSelected: boolean;
    theme: Theme;
  }) =>
    isSelected
      ? theme.siteKeysSelectOptionState.color.selected.background
      : theme.siteKeysSelectOptionState.color.default.background};
`;

const StyledName = styled(Text)`
  font-weight: 700;
  color: ${({ theme }: { theme: Theme }) =>
    theme.multiSelectOption.color.siteKeySelect.text};
`;

const StyledSitekey = styled(Text)`
  color: ${({ theme }: { theme: Theme }) =>
    theme.multiSelectOption.color.siteKeySelect.text};
`;

const OptionExternalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const OptionInternalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
