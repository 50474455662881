/* eslint-disable react-hooks/exhaustive-deps */
import { isBefore, isAfter, startOfDay, endOfDay } from 'date-fns';
import { useContext, useState } from 'react';

import CalendarContext from './CalendarContext';

type CalendarDataShape = {
  startDate: Date | null;
  endDate: Date | null;
};

type UseDateValidationProps = {
  values: CalendarDataShape;
  validators: {
    maximumDate?: Date;
    minimumDate?: Date;
  };
};

const DEFAULT_ERROR_STATE = { startDate: '', endDate: '' };

export const useCalendarValidation = ({
  values,
  validators,
}: UseDateValidationProps) => {
  const { translations } = useContext(CalendarContext);

  const [validationErrors, setValidationErrors] = useState(DEFAULT_ERROR_STATE);

  const isDateOutsideRange = (
    date: Date | null,
    minDate: Date | null,
    maxDate: Date | null,
  ) => {
    return (
      (minDate && isBefore(date!, minDate)) ||
      (maxDate && isAfter(date!, maxDate))
    );
  };

  const validateStartDate = (
    startDate: Date | null,
    endDate: Date | null,
    minDate: Date | null,
    maxDate: Date | null,
  ) => {
    if (startDate) {
      if (isDateOutsideRange(startDate, minDate, maxDate)) {
        return translations.dateOutsideRange;
      }
      if (endDate && isAfter(startDate, endDate)) {
        return translations.startDateAfterEnd;
      }
    }
    return '';
  };

  const validateEndDate = (
    startDate: Date | null,
    endDate: Date | null,
    minDate: Date | null,
    maxDate: Date | null,
  ) => {
    if (endDate) {
      if (isDateOutsideRange(endDate, minDate, maxDate)) {
        return translations.dateOutsideRange;
      }
      if (startDate && isBefore(endDate, startDate)) {
        return translations.endDateBeforeStart;
      }
    }
    return '';
  };

  const validator = (
    valuesToValidate: CalendarDataShape,
    fieldToValidate?: keyof CalendarDataShape,
  ) => {
    const errors = { ...DEFAULT_ERROR_STATE };

    const { startDate, endDate } = valuesToValidate;
    const { maximumDate, minimumDate } = validators;

    const adjustedStartDate = startDate ? startOfDay(startDate) : null;
    const adjustedEndDate = endDate ? endOfDay(endDate) : null;
    const adjustedMinimumDate = minimumDate ? startOfDay(minimumDate) : null;
    const adjustedMaximumDate = maximumDate ? endOfDay(maximumDate) : null;

    if (!fieldToValidate || fieldToValidate === 'startDate') {
      errors.startDate = validateStartDate(
        adjustedStartDate,
        adjustedEndDate,
        adjustedMinimumDate,
        adjustedMaximumDate,
      );
    }

    if (!fieldToValidate || fieldToValidate === 'endDate') {
      errors.endDate = validateEndDate(
        adjustedStartDate,
        adjustedEndDate,
        adjustedMinimumDate,
        adjustedMaximumDate,
      );
    }

    return errors;
  };

  const resetValidation = () => {
    setValidationErrors(DEFAULT_ERROR_STATE);
  };

  const validateField = (
    field: keyof CalendarDataShape,
    newValue: CalendarDataShape[keyof CalendarDataShape],
  ) => {
    const newValuesToValidate = {
      ...values,
      [field]: newValue ?? values[field],
    };
    const fieldErrors = validator(newValuesToValidate, field);
    setValidationErrors(prevErrors => ({ ...prevErrors, ...fieldErrors }));
  };

  const hasValidationErrors = Object.values(validationErrors).some(Boolean);

  return {
    validationErrors,
    hasValidationErrors,
    resetValidation,
    validateField,
  };
};
