export const DEFAULT_ITEMS_PER_PAGE = 10;

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none',
}

export enum ActionTableTheme {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DISABLED = 'disabled',
}
