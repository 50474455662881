import 'polyfills';
import 'mobxConfig';
import 'pixiConfig';
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import { GlobalStyle, defaultTheme, ToastProvider } from '@packages/ui';
import { ThemeProvider } from 'styled-components';
import { PostHogProvider } from 'posthog-js/react';

import './errorReporting';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './shared/components/ErrorBoundary';
import history from './shared/models/History';
import { RootStoreProvider } from './shared/stores';
import Main from './main';

import './i18n';
import './index.css';

const root = document.getElementById('root');

if (
  process.env.APP_MODE === 'production' &&
  // eslint-disable-next-line no-underscore-dangle
  typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object'
) {
  // eslint-disable-next-line no-underscore-dangle,func-names
  window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = function() {};
}

const posthogOptions = {
  api_host: process.env.API_POSTHOG,
  autocapture: false,
};

if (root !== null) {
  render(
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      <Suspense fallback={null}>
        <ErrorBoundary>
          <RootStoreProvider>
            <Router history={history}>
              <ToastProvider>
                <PostHogProvider
                  apiKey={process.env.AUTH_TOKEN_POSTHOG}
                  options={posthogOptions}
                >
                  <Main />
                </PostHogProvider>
              </ToastProvider>
            </Router>
          </RootStoreProvider>
        </ErrorBoundary>
      </Suspense>
    </ThemeProvider>,
    root,
  );
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
