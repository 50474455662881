import { Color } from '../../theme/primitives';

const theme = {
  axis: {
    color: {
      default: {
        text: Color.grey500,
        border: Color.grey500,
      },
    },
  },
};

export default theme;
