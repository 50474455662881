import React, { useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { Color, Size } from '../../theme/primitives';
import { Icon } from '../../atoms/Icon';
import { Button } from '../../atoms/Button';

export interface ModalProps {
  className?: string;
  onClose?: () => void;
  isHiddenOverflow?: boolean;
  actions?: React.ReactNode;
  children: React.ReactNode;
  isSaving?: boolean;
  id?: string;
}

export const Modal: React.FC<ModalProps> = ({
  className = '',
  isHiddenOverflow = true,
  actions,
  children,
  onClose,
  id,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    (document.querySelector(
      '.modal-close-button',
    ) as HTMLButtonElement)?.blur();

    function handleClickOutside(event: MouseEvent) {
      if (!onClose || !modalRef.current) {
        return;
      }

      const isModalClicked = modalRef.current.contains?.(event.target as Node);

      const isClickedOutside =
        !isModalClicked && event.target !== modalRef.current;

      if (isClickedOutside) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose?.();
      }
    };

    document.addEventListener('keydown', handleEscKeyPress);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscKeyPress);
    };
  }, [modalRef, onClose]);

  return (
    <ModalContainer id={id}>
      {isHiddenOverflow && <GlobalStyles />}
      <ModalWindow className={className} ref={modalRef}>
        {onClose && (
          <CloseButtonContainer
            variant="link"
            autoFocus
            onClick={onClose}
            onKeyPress={onClose}
            className="modal-close-button"
          >
            <CloseButton icon="close" iconSize="16" />
          </CloseButtonContainer>
        )}
        <ContentWrapper className="contentWrapperStyles">
          {children}
        </ContentWrapper>
        {actions && <ActionsContainer>{actions}</ActionsContainer>}
      </ModalWindow>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalWindow = styled.div`
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 40%;
  height: auto;
  padding: 32px;
  z-index: 999991;
  background-color: ${Color.white};
  border: 1px solid ${Color.grey400};
  border-radius: 8px;

  @media (max-width: ${Size.mobile}) {
    width: 70%;
  }
`;

const CloseButtonContainer = styled(Button)`
  position: absolute;
  top: 30px;
  right: 30px;
  padding: 0;
  cursor: pointer;
`;

const CloseButton = styled(Icon)`
  color: ${Color.grey600};

  &:hover {
    color: ${Color.grey400};
  }
`;

const ContentWrapper = styled.div`
  max-height: 85vh;
  overflow: auto;
`;

const ActionsContainer = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  margin-top: ${Size.space200};

  & > * {
    margin-left: ${Size.space200};
  }

  & > *:first-child {
    margin-left: 0;
  }
`;

const GlobalStyles = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;
