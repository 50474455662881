import React, { memo } from 'react';
import styled from 'styled-components';

import { IconProp } from '../../../atoms/Icon';

interface ListOption {
  name?: string;
  value: string;
  label: string;
  icon?: IconProp;
  badge?: React.ReactNode;
}

interface Props {
  selected: string;
  options: ListOption[];
  onChange: (value: string) => void;
  onFocus: () => void;
  onBlur: () => void;
}

const NativeList: React.FC<Props> = props => {
  const { options, selected, onChange, onFocus, onBlur } = props;

  const handleChange = (evt: any) => {
    onChange?.(evt.target.value);
  };

  const renderOptions = (): Array<React.ReactNode> => {
    return options.map(option => (
      <option
        key={option.label}
        value={option.value}
        data-cy={`option-${option.label.toLowerCase()}`}
      >
        {option.label}
      </option>
    ));
  };

  return (
    <Selection
      value={selected}
      onChange={handleChange}
      onClick={onFocus}
      onBlur={onBlur}
    >
      {renderOptions()}
    </Selection>
  );
};

const Selection = styled.select`
  width: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  z-index: 50;
  height: 100%;
  opacity: 0;
`;

export default memo(NativeList);
