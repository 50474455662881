/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, useMemo } from 'react';

import { getSortedObjectArray } from '../../../utils/arrayUtils';
import { SortDirection } from '../../../constants';
import {
  DataReportTableConfig,
  DataReportProviderValue,
  SORT_DIRECTIONS,
} from '../constants';

import { DataReportTableContext } from './DataReportTableContext';

interface DataReportTableProviderProps {
  config: DataReportTableConfig;
}

export const DataReportTableProvider: React.FC<DataReportTableProviderProps> = ({
  config,
  children,
}) => {
  const {
    columns,
    sourceData,
    itemsPerPage,
    onHeaderCellClick,
    sortField,
    sortDirection,
    isLoading,
    isError,
  } = config;

  const initialTableState = useMemo(
    () => ({
      headerState: {
        isHovered: false,
        direction: SORT_DIRECTIONS[0],
        sortedColumnId: '',
      },
      columns,
      sourceData,
      currentData: sourceData,
      // those are not used for server-side pagination
      itemsPerPage: itemsPerPage || null,
      displayedData: itemsPerPage
        ? sourceData.slice(0, itemsPerPage)
        : sourceData,
      currentPage: 1,
    }),
    [columns, sourceData, itemsPerPage],
  );
  const [tableState, setTableState] = useState(initialTableState);

  useEffect(() => {
    setTableState({
      ...initialTableState,
      sourceData: config.sourceData,
    });
  }, [config, initialTableState]);

  const handleTableHeaderMouseIn = () => {
    setTableState(tableState => {
      const { headerState } = tableState;

      return {
        ...tableState,
        headerState: {
          ...headerState,
          isHovered: true,
        },
      };
    });
  };

  const handleTableHeaderMouseOut = () => {
    setTableState(tableState => {
      const { headerState } = tableState;

      return {
        ...tableState,
        headerState: {
          ...headerState,
          isHovered: false,
        },
      };
    });
  };

  const handleSorting = (columnId: string) => {
    setTableState(tableState => {
      const { headerState, sourceData } = tableState;
      const { sortedColumnId, direction } = headerState;

      const customSorter = columns.find(column => column.id === columnId)
        ?.customSorter;

      const isDifferentColumn = columnId !== sortedColumnId;
      const indexOfCurrentDirection = SORT_DIRECTIONS.indexOf(
        direction as SortDirection,
      );
      const isLastDirection =
        !isDifferentColumn &&
        indexOfCurrentDirection === SORT_DIRECTIONS.length - 1;

      let nextDirection: SortDirection;
      if (isLastDirection) {
        nextDirection = SORT_DIRECTIONS[0];
      } else if (isDifferentColumn) {
        nextDirection = SORT_DIRECTIONS[1];
      } else {
        nextDirection = SORT_DIRECTIONS[indexOfCurrentDirection + 1];
      }

      let nextCurrentData;
      if (nextDirection !== SortDirection.NONE && customSorter) {
        nextCurrentData = customSorter([...sourceData], nextDirection);
      } else if (nextDirection !== SortDirection.NONE) {
        nextCurrentData = getSortedObjectArray(
          [...sourceData],
          columnId,
          nextDirection,
        );
      } else {
        nextCurrentData = sourceData;
      }

      let nextDisplayedData;
      if (itemsPerPage) {
        nextDisplayedData = nextCurrentData.slice(
          0 * itemsPerPage,
          itemsPerPage,
        );
      } else {
        nextDisplayedData = nextCurrentData;
      }

      return {
        ...tableState,
        headerState: {
          ...headerState,
          sortedColumnId: columnId,
          direction: nextDirection,
        },
        currentData: nextCurrentData,
        currentPage: 1,
        displayedData: nextDisplayedData,
      };
    });
  };

  // use this handler for client-side pagination
  // (for server-side, handle pagination state from outside the component)
  const handlePageChange = (page: number) => {
    setTableState(tableState => {
      const { currentData } = tableState;

      return {
        ...tableState,
        currentPage: page,
        displayedData: currentData.slice(
          (page - 1) * (itemsPerPage as number),
          page * (itemsPerPage as number),
        ),
      };
    });
  };

  const handleTableHeaderCellClick = (columnId: string) => {
    // onHeaderCellClick !== undefined implies sorting is handled externally
    if (onHeaderCellClick) {
      onHeaderCellClick(columnId);
      return;
    }
    handleSorting(columnId);
  };

  const providerValue: DataReportProviderValue = {
    isError,
    isLoading,
    tableState,
    handleTableHeaderMouseIn,
    handleTableHeaderMouseOut,
    handleTableHeaderCellClick,
    handlePageChange,
    sortField,
    sortDirection,
  };

  return (
    <DataReportTableContext.Provider value={providerValue}>
      {children}
    </DataReportTableContext.Provider>
  );
};
