import React from 'react';
import styled from 'styled-components';

import { TableCell } from '../../../atoms/Table';
import { SortDirection } from '../../../constants';

import { SortIcon } from './SortIcon';

export interface HoverableHeaderCellProps {
  isHovered: boolean;
  direction: SortDirection;
  sortedColumnId: string;
  onClick?: (columnId: string) => void;
  id: string;
  width?: number;
}

export const HoverableHeaderCell: React.FC<HoverableHeaderCellProps> = ({
  isHovered,
  direction,
  sortedColumnId,
  onClick,
  id,
  width,
  children,
}) => {
  const isSortedColumn = id === sortedColumnId;

  const isSortIconVisible =
    isHovered || (direction !== SortDirection.NONE && isSortedColumn);

  const handleClick = () => {
    onClick?.(id);
  };

  return (
    <StyledTableCell width={width} onClick={handleClick}>
      <CellWrapper>
        {children}
        {isSortIconVisible && (
          <SortIcon
            direction={
              isSortedColumn ? (direction as SortDirection) : SortDirection.NONE
            }
          />
        )}
      </CellWrapper>
    </StyledTableCell>
  );
};

const StyledTableCell = styled(TableCell)<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
`;

const CellWrapper = styled.div`
  display: flex;
  align-items: center;
`;
