import React from 'react';
import styled from 'styled-components';

import ChartAreaProvider from '../../../charts/atoms/ChartAreaProvider';
import GraphInteractionLayer from '../../../atoms/GraphInteractionLayer';
// import AxisX from '../../../charts/atoms/GraphAxis/AxisX';
import AxisY from '../../../charts/atoms/GraphAxis/AxisY';
import { GroupedBarGraphProvider } from '../context';
import { GroupedBarGraphAxisX, GroupedBarGraphAxisY } from '../types';
import { AXIS_X_HEIGHT, AXIS_Y_WIDTH } from '../constants';

import AxisX from './AxisX';
import GraphCanvas from './GraphCanvas';

export interface CanvasWrapperProps {
  data: object[];
  width: number;
  height: number;
  xAxis: GroupedBarGraphAxisX;
  yAxis: GroupedBarGraphAxisY;
  tooltip: React.ReactNode;
  tooltipProperty?: string;
  tooltipLabel?: string;
  showMarker?: boolean;
  markerWidth?: number;
  markerValueDisplay?: React.ReactNode;
}

const CanvasWrapper: React.FC<CanvasWrapperProps> = ({
  data,
  width,
  height,
  xAxis,
  yAxis,
  tooltip,
  tooltipProperty,
  tooltipLabel,
  showMarker,
  markerWidth,
  markerValueDisplay,
}) => {
  const xAxisMarks = data.map(item => {
    const markValue = (item as any)[xAxis.source.property];
    const { format } = xAxis.scale;

    // if markValue is string, it is assumed a date value
    return format
      ? format(
          typeof markValue === 'string'
            ? new Date(markValue).getTime()
            : markValue,
        )
      : markValue;
  });

  return (
    <Wrapper width={width} height={height}>
      <ChartAreaProvider x={xAxis.scale} y={yAxis.scale}>
        <GroupedBarGraphProvider
          data={data}
          width={width}
          height={height}
          xProperty={xAxis?.source?.property}
          xLabel={xAxis?.source?.propertyLabel}
          groupPadding={xAxis.source?.groupPadding}
          columnSeries={yAxis.source.series}
          tooltipProperty={tooltipProperty}
          tooltipLabel={tooltipLabel}
        >
          <GraphCanvas
            width={width - AXIS_Y_WIDTH}
            height={height - AXIS_X_HEIGHT}
          />
          <GraphAxisX
            width={width - AXIS_Y_WIDTH}
            height={AXIS_X_HEIGHT}
            marks={xAxisMarks}
            padding={xAxis.source?.groupPadding || 0}
          />
          <GraphAxisY height={height - AXIS_X_HEIGHT} />
          <GroupedBarGraphInteractionLayer
            width={width - AXIS_Y_WIDTH}
            height={height - AXIS_X_HEIGHT}
            tooltip={tooltip}
            showMarker={showMarker}
            markerWidth={markerWidth}
            markerValueDisplay={markerValueDisplay}
          />
        </GroupedBarGraphProvider>
      </ChartAreaProvider>
    </Wrapper>
  );
};

export default CanvasWrapper;

const Wrapper = styled.div<{ width: number; height: number }>`
  position: relative;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

const GraphAxisX = styled(AxisX)<{ width: number }>`
  width: ${props => props.width}px;
  height: ${AXIS_X_HEIGHT}px;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const GraphAxisY = styled(AxisY)<{ height: number }>`
  height: ${props => props.height}px;
  width: ${AXIS_Y_WIDTH}px;
  position: absolute;
  top: 0;
  left: 0;
`;

const GroupedBarGraphInteractionLayer = styled(GraphInteractionLayer)`
  position: absolute;
  top: 0;
  right: 0;
`;
