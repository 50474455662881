import { Color, Size } from '../../theme/primitives';

const theme = {
  button: {
    color: {
      legacyOutlinedPrimary: {
        inactive: {
          text: Color.grey700,
          background: Color.white,
          border: Color.teal200,
        },
        hover: {
          background: Color.teal100,
        },
        active: {
          background: Color.teal100,
        },
        disabled: {
          background: Color.grey100,
          text: Color.grey500,
          border: Color.grey400,
        },
      },
      legacyOutlinedSecondary: {
        inactive: {
          text: Color.grey700,
          background: Color.white,
          border: Color.grey200,
        },
        hover: {
          background: Color.grey050,
        },
        active: {
          background: Color.grey100,
        },
        disabled: {
          background: Color.grey100,
          text: Color.grey500,
          border: Color.grey400,
        },
      },
      outlined: {
        inactive: {
          text: Color.blue400,
          background: Color.white,
          border: Color.blue400,
        },
        hover: {
          background: Color.blue100,
        },
        active: {
          background: Color.blue200,
        },
        disabled: {
          background: Color.white,
          text: Color.grey400,
          border: Color.grey400,
        },
      },
      contained: {
        inactive: {
          text: Color.white,
          background: Color.blue400,
          border: Color.blue400,
        },
        hover: {
          background: Color.blue400,
          border: Color.blue400,
        },
        active: {
          background: Color.blue600,
          border: Color.blue600,
        },
        disabled: {
          background: Color.grey400,
          border: Color.grey400,
        },
      },
      base: {
        inactive: {
          text: Color.white,
          background: Color.grey500,
          border: Color.grey500,
        },
        hover: {
          background: Color.grey700,
          border: Color.grey700,
        },
        active: {
          background: Color.grey600,
          border: Color.grey600,
        },
        disabled: {
          background: Color.grey300,
          border: Color.grey300,
        },
      },
      cancel: {
        inactive: {
          text: Color.red400,
          background: 'transparent',
          border: Color.red400,
        },
        hover: {
          text: Color.red400,
          background: Color.red0,
          border: Color.red400,
        },
        disabled: {
          background: Color.grey300,
          border: Color.grey400,
        },
      },
      confirm: {
        inactive: {
          text: Color.grey700,
          background: 'transparent',
          border: Color.teal300,
        },
        hover: {
          text: Color.grey800,
          background: Color.teal300,
          border: Color.teal300,
        },
        disabled: {
          background: Color.grey300,
          border: Color.grey400,
        },
      },
      simple: {
        inactive: {
          text: Color.grey700,
          background: 'transparent',
          border: Color.grey300,
        },
        hover: {
          text: Color.grey800,
          background: Color.grey300,
          border: Color.grey300,
        },
        disabled: {
          background: Color.grey300,
          border: Color.grey400,
        },
      },
      default: {
        inactive: {
          text: Color.white,
          background: Color.blue400,
          border: Color.blue400,
        },
        hover: {
          background: Color.blue500,
          border: Color.blue500,
        },
        active: {
          background: Color.blue600,
          border: Color.blue600,
        },
        disabled: {
          background: Color.grey400,
          border: Color.grey400,
        },
      },
      warning: {
        inactive: {
          text: Color.black,
          background: Color.yellow300,
          border: Color.yellow300,
        },
        hover: {
          text: Color.grey600,
          background: Color.yellow200,
          border: Color.yellow200,
        },
        active: {
          background: Color.yellow200,
          border: Color.yellow200,
        },
        disabled: {
          text: Color.grey600,
          background: Color.grey400,
          border: Color.grey400,
        },
      },
      danger: {
        inactive: {
          text: Color.white,
          background: Color.red400,
          border: Color.red400,
        },
        hover: {
          background: Color.red400,
          border: Color.red400,
        },
        active: {
          background: Color.red500,
          border: Color.red500,
        },
        disabled: {
          background: Color.grey400,
          border: Color.grey400,
        },
      },
      link: {
        inactive: {
          text: Color.blue400,
          background: Color.transparent,
          border: Color.transparent,
        },
        hover: {
          text: Color.blue500,
          background: Color.transparent,
          border: Color.transparent,
        },
        active: {
          text: Color.blue600,
          background: Color.transparent,
          border: Color.transparent,
        },
        disabled: {
          text: Color.grey400,
          background: Color.transparent,
          border: Color.transparent,
        },
      },
    },
    shape: {
      default: {
        padding: `${Size.space200} ${Size.space300}`,
        borderRadius: Size.radius200,
        borderStyle: 'solid',
        borderWidth: '1px',
      },
    },
  },
};

export default theme;
