import React, { useEffect, useState, memo } from 'react';
import styled from 'styled-components';

import Text from '../../atoms/Text';
import { Dropdown } from '../../atoms/Dropdown';
import { Button } from '../../atoms/Button';
import { Icon } from '../../atoms/Icon';
import { Color } from '../../theme/primitives';
import { usePrevious } from '../../hooks';

const ITEMS_PER_PAGE_OPTIONS = [10, 20, 50, 100].map(itemsPerPageOption => ({
  value: itemsPerPageOption,
  label: String(itemsPerPageOption),
}));

interface ItemsPerPageOptionInterface {
  value: any;
  label: string;
}

export interface PaginatorProps {
  className?: string;
  isItemsPerPageSelectorShown?: boolean;
  itemsPerPageDropdownPosition?: string;
  itemsPerPageOptions?: ItemsPerPageOptionInterface[];
  itemsTotal: number;
  selectedPage?: number;
  onChange?: (page: number) => void;
  onItemsPerPageChange?: (itemsPerPage: number) => void;
  itemsPerPage?: number;
}

const Paginator: React.FC<PaginatorProps> = ({
  className = '',
  itemsTotal,
  selectedPage = 1,
  isItemsPerPageSelectorShown = false,
  itemsPerPageDropdownPosition = 'auto',
  itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS,
  onItemsPerPageChange,
  onChange,
  itemsPerPage,
}) => {
  const [page, setPage] = useState(selectedPage);
  const [selectedItemsPerPageOption, setSelectedItemsPerPageOption] = useState(
    itemsPerPage
      ? itemsPerPageOptions.find(({ value }) => value === itemsPerPage) ||
          itemsPerPageOptions[0]
      : itemsPerPageOptions[0],
  );
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(
    itemsPerPage || selectedItemsPerPageOption?.value,
  );
  const prevItemsPerPageValue = usePrevious(selectedItemsPerPage);

  useEffect(() => {
    setPage(selectedPage);
  }, [selectedPage]);

  useEffect(() => {
    setSelectedItemsPerPage(itemsPerPage);
  }, [itemsPerPage]);

  useEffect(() => {
    if (
      !prevItemsPerPageValue ||
      prevItemsPerPageValue === selectedItemsPerPage
    ) {
      return;
    }
    onItemsPerPageChange?.(selectedItemsPerPage);
    setPage?.(1);
  }, [selectedItemsPerPage, prevItemsPerPageValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onChange?.(page);
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  const isFirstPage = page === 1;
  const isLastPage = page * selectedItemsPerPage >= itemsTotal;
  const totalNumberOfPages = Math.ceil(itemsTotal / selectedItemsPerPage);

  const handleFirstPageClick = () => {
    if (isFirstPage) {
      return;
    }

    setPage(1);
  };

  const handlePrevPageClick = () => {
    if (isFirstPage) {
      return;
    }

    setPage(page => page - 1);
  };

  const handleNextPageClick = () => {
    if (isLastPage) {
      return;
    }

    setPage(page => page + 1);
  };

  const handleLastPageClick = () => {
    if (isLastPage) {
      return;
    }

    setPage(totalNumberOfPages);
  };

  const handleSelectedItemsPerPageOptionChange = (
    option: ItemsPerPageOptionInterface,
  ) => {
    setSelectedItemsPerPageOption(option);
    setSelectedItemsPerPage(option.value);
  };

  return (
    <Container className={className}>
      {itemsTotal > selectedItemsPerPage && (
        <ButtonsContainer className="paginatorInnerContainer">
          <ButtonWrapper
            data-testid="prev-page-arrow"
            isDisabled={isFirstPage}
            onClick={handleFirstPageClick}
          >
            <PrevPageIcon icon="chevronShiftRight" iconSize="16" />
          </ButtonWrapper>
          <ButtonWrapper
            data-testid="prev-page-arrow"
            isDisabled={isFirstPage}
            onClick={handlePrevPageClick}
          >
            <PrevPageIcon icon="chevronRight" iconSize="16" />
          </ButtonWrapper>
          <PaginationTitle variant="default">
            {`${page} / ${totalNumberOfPages}`}
          </PaginationTitle>
          <ButtonWrapper
            data-testid="next-page-arrow"
            isDisabled={isLastPage}
            onClick={handleNextPageClick}
          >
            <NextPageIcon icon="chevronRight" iconSize="16" />
          </ButtonWrapper>
          <ButtonWrapper
            data-testid="next-page-arrow"
            isDisabled={isLastPage}
            onClick={handleLastPageClick}
          >
            <NextPageIcon icon="chevronShiftRight" iconSize="16" />
          </ButtonWrapper>
        </ButtonsContainer>
      )}
      {isItemsPerPageSelectorShown && (
        <ItemsPerPageContainer>
          <Dropdown
            isInline
            menuPlacement={itemsPerPageDropdownPosition}
            options={itemsPerPageOptions}
            value={selectedItemsPerPageOption}
            onChange={handleSelectedItemsPerPageOptionChange}
          />
        </ItemsPerPageContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
`;

const ItemsPerPageContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: none;

  @media (min-width: 600px) {
    display: flex;
  }
`;

const PaginationTitle = styled(Text)`
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaginationIcon = styled(Icon)``;

const PrevPageIcon = styled(PaginationIcon)`
  transform: rotate(180deg);
`;

const NextPageIcon = styled(PaginationIcon)``;

const ButtonWrapper = styled(Button)<{
  isDisabled?: boolean;
}>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-radius: 4px;

  &,
  &:hover,
  &:active,
  &:focus {
    border: 1px solid ${Color.grey400};
    background-color: ${Color.white};
  }

  ${PaginationIcon} {
    color: ${({ isDisabled }) => (isDisabled ? Color.grey200 : Color.blue400)};
  }

  &:hover {
    ${PaginationIcon} {
      color: ${({ isDisabled }) =>
        isDisabled ? Color.grey200 : Color.blue500};
    }
  }

  & + & {
    margin-left: 8px;
  }
`;

export default memo(Paginator);
