import { useContext } from 'react';

import { useInteraction } from '../../../atoms/GraphInteractionLayer/GraphInteractionContext';
import { ChartAreaContext } from '../../../charts/atoms/ChartAreaProvider/ChartAreaContext';
import { Point } from '../types';

import { useLineGraphState } from './LineGraphContext';

export interface LineGraphDataPoint {
  id: string;
  label: string;
  point: Point;
  color: string;
}

export const useLineGraphInteraction = (): {
  nearestDataColumn: LineGraphDataPoint[];
  nearestDataPoint: LineGraphDataPoint;
  isActive: boolean;
} | null => {
  const { lines, graphId } = useLineGraphState();
  const area = useContext(ChartAreaContext);
  const [interactionState] = useInteraction();

  if (
    lines.length === 0 ||
    interactionState === null ||
    interactionState.x < area.x.min ||
    interactionState.x >= area.x.max
  ) {
    return null;
  }

  const nearestDataColumn = lines
    .map<LineGraphDataPoint>(({ id, color, points, label }) => {
      const { point } = points
        .map(point => {
          return {
            point,
            distance: Math.abs(interactionState.x - point.x),
          };
        })
        .sort((a, b) => a.distance - b.distance)[0];

      return {
        id,
        color,
        point,
        label,
      };
    })
    .sort((a, b) => b.point.y - a.point.y);

  const nearestDataPoint = getNearestPoint(
    nearestDataColumn,
    interactionState.y,
  );

  return {
    nearestDataColumn,
    nearestDataPoint,
    isActive: graphId === interactionState.graphId,
  };
};

function getNearestPoint(
  items: LineGraphDataPoint[],
  value: number,
): LineGraphDataPoint {
  return items
    .map(item => {
      return {
        item,
        distance: Math.abs(item.point.y - value),
      };
    })
    .sort((a, b) => a.distance - b.distance)[0].item;
}
