import { useEffect } from 'react';

import { Cursor, KeyCode } from '../../../../constants';
import { ZoomInteractionState } from '../zoomConstants';

export const useCloseOnKeyDown: (
  zoomInteractionContext: ZoomInteractionState | null,
) => void = zoomInteractionContext => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (zoomInteractionContext) {
      const [
        zoomInteractionState,
        setZoomInteractionState,
      ] = zoomInteractionContext;

      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === KeyCode.ESC) {
          setZoomInteractionState({ bounds: null, cursor: Cursor.COL_RESIZE });
        }
      };

      if (zoomInteractionState) {
        document.addEventListener('keydown', handleKeyDown);
      }

      return () => document.removeEventListener('keydown', handleKeyDown);
    }
  }, [zoomInteractionContext]);
};
