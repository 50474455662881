/**
 * @module MathUtil
 * Set of util functions that the native Math Module is missing
 */

/**
 * @desc
 * Clamp an input value to a range of numbers
 * @parma {number} input - Value to restrict
 * @parma {number} min - Minimum number value can be
 * @parma {number} max - Maximum number value can be
 */
export function clamp(input: number, min: number, max: number) {
  return Math.min(Math.max(input, min), max);
}

/**
 * @desc
 * Clamp an input value to a range of numbers
 * @parma {number} input - Value to alter
 * @parma {number} oldMin - Orignal minimum value could be
 * @parma {number} oldMax - Orignal maximum value could be
 * @parma {number} newMin - New minimum value can be
 * @parma {number} newMax - New maximum value can be
 * @parma {boolean} [clampOutput=true] - Clamp value to new range
 */
export function range(
  input: number,
  oldMin: number,
  oldMax: number,
  newMin: number,
  newMax: number,
  clampOutput: boolean = true,
) {
  const oldRange = oldMax - oldMin;
  const newRange = newMax - newMin;
  const newValue = ((input - oldMin) * newRange) / oldRange + newMin;
  return clampOutput === false
    ? newValue
    : clamp(newValue, Math.min(newMin, newMax), Math.max(newMin, newMax));
}

export const round = (value: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};
