import { useState, useEffect, useMemo } from 'react';

export default function usePreview(
  value = '',
  element: HTMLElement,
  overflowText = '........',
) {
  const [previewValue, setPreviewValue] = useState('');
  const canvasContext = useMemo(() => {
    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d');

    return canvasContext;
  }, []);

  const getNumberValue = (value = '') => {
    return parseInt(value, 10) || 0;
  };

  const getFont = (element: HTMLElement) => {
    const props = [
      'font-style',
      'font-variant',
      'font-weight',
      'font-size',
      'font-family',
    ];

    const font = props.reduce((fontAcc, prop) => {
      const elementStyle = window.getComputedStyle(element, null);
      const propValue = elementStyle?.getPropertyValue(prop);

      return `${fontAcc} ${propValue}`;
    }, '');

    return font;
  };

  const getTextWidth = (element: HTMLElement, value = '') => {
    if (!canvasContext) {
      return 0;
    }

    canvasContext.font = getFont(element);
    const textWidth = canvasContext.measureText(value)?.width;

    return Math.ceil(Number(textWidth));
  };

  const getTextMaxWidth = (element: HTMLElement) => {
    const elementStyle = window.getComputedStyle(element);

    if (!elementStyle) {
      return 0;
    }

    const elementPadding =
      getNumberValue(elementStyle.paddingLeft) +
      getNumberValue(elementStyle.paddingRight);
    const elementBorder =
      getNumberValue(elementStyle.borderLeftWidth) +
      getNumberValue(elementStyle.borderRightWidth);
    const textMaxWidth =
      getNumberValue(elementStyle.width) - elementPadding - elementBorder;

    return Math.floor(Number(textMaxWidth));
  };

  const getCutValue = (value = '', toCut: number) => {
    let [cutValueStart, cutValueEnd] = value.split(overflowText);
    const isTextOverflow = cutValueStart && cutValueEnd;
    const cutValueMiddle = Math.ceil(value.length / 2);
    const toCutfromStart = Math.floor(toCut / 2);
    const toCutfromEnd = toCut - toCutfromStart;

    cutValueStart = isTextOverflow
      ? cutValueStart.slice(0, cutValueStart.length - toCutfromStart)
      : value.slice(0, cutValueMiddle - toCutfromStart);
    cutValueEnd = isTextOverflow
      ? cutValueEnd.slice(toCutfromEnd)
      : value.slice(cutValueMiddle + toCutfromEnd);

    return cutValueStart + overflowText + cutValueEnd;
  };

  const getPreview = (element: HTMLElement, value = '') => {
    if (!value || !element) {
      return '';
    }

    const textMaxWidth = getTextMaxWidth(element);
    let cutValue = value.replace(/\n/g, ' ');
    let textWidth = getTextWidth(element, cutValue);

    if (!textWidth) {
      return '';
    }

    while (textWidth > textMaxWidth) {
      const toCut = Math.ceil((1 - textMaxWidth / textWidth) * cutValue.length);

      cutValue = getCutValue(cutValue, toCut);
      textWidth = getTextWidth(element, cutValue);
    }

    return cutValue;
  };

  const setPreview = (element: HTMLElement, value = '') => {
    setPreviewValue(getPreview(element, value));
  };

  useEffect(() => {
    const inputObserver = new ResizeObserver(() => {
      setPreview(element, value);
    });

    if (element) {
      inputObserver.observe(element);
    }

    return () => {
      if (element) {
        inputObserver.unobserve(element);
      }
    };
  }, [element, value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setPreview(element, value);
    }, 500);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [element, value]); // eslint-disable-line react-hooks/exhaustive-deps

  return previewValue;
}
