import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Color, Size, Text, useToast } from '@packages/ui';

import AccountCard from './AccountCard';

import {
  AllowedAccount,
  FavoriteAction,
  AccountType,
  AccountSection,
} from 'main/data/types/AllowedAccount';
import { Api } from 'main/data/api';
import { getSortedObjectArray } from 'shared/utils/objectUtils';
import { useWindowSize } from 'shared/hooks';

interface AccountsSectionProps {
  type: string;
  title: string;
  accounts: AllowedAccount[];
  onSelect: (account: AllowedAccount) => Promise<void>;
  onFavorite: (target: AllowedAccount, action: FavoriteAction) => void;
  isPageView?: boolean;
}

export default function AccountsSection({
  type,
  title,
  accounts,
  onSelect,
  onFavorite,
  isPageView,
}: AccountsSectionProps): React.ReactElement | null {
  const { t } = useTranslation('generic');
  const toast = useToast();
  const { width } = useWindowSize();

  const filteredAccounts = getSortedObjectArray(
    type === AccountSection.OWNER
      ? [accounts.find(account => account.account_type === AccountType.OWNER)]
      : accounts.filter(account => {
          switch (type) {
            case AccountSection.FAVORITE:
              return account.is_favorite;
            case AccountSection.SHARED:
              return account.account_type === AccountType.REGULAR;
            default:
              return account.account_type === AccountType.ZONE;
          }
        }),
    'email',
  );

  const handleFavorite = async (
    action: FavoriteAction,
    account: AllowedAccount,
  ) => {
    try {
      onFavorite(account, action);

      const apiCall = account.is_favorite
        ? () => Api.deleteFavorite(account.email)
        : () => Api.addFavorite(account.email);

      await apiCall();
    } catch (error) {
      const reverseAction =
        action === FavoriteAction.FAVORITE
          ? FavoriteAction.REMOVE
          : FavoriteAction.FAVORITE;

      onFavorite(account, reverseAction);

      toast.error(t('Could not change favorite status, please try again'));
    }
  };

  const isSingleRow = !isPageView || width <= parseInt(Size.mobile, 10);

  if (!filteredAccounts.length) {
    return null;
  }

  return (
    <>
      {type !== AccountSection.OWNER && <SectionDivisor />}
      <SectionTitle>{title}</SectionTitle>
      <SectionCards
        numOfRows={
          isSingleRow
            ? filteredAccounts.length
            : Math.ceil(filteredAccounts.length / 2)
        }
        isPageView={isPageView}
      >
        {filteredAccounts.map((account: AllowedAccount) => (
          <AccountCard
            key={`${type}-${account.id}`}
            id={`${type}-${account.id}`}
            account={account}
            onFavorite={handleFavorite}
            onSelect={onSelect}
            isPageView={isPageView}
          />
        ))}
      </SectionCards>
    </>
  );
}

const SectionDivisor = styled.hr`
  width: 100%;
  height: 1px;

  background-color: ${Color.grey200};
  border: 0;

  margin: 24px 0;
`;

const SectionTitle = styled(Text)`
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  color: ${Color.grey800};
  text-transform: capitalize;
`;

const SectionCards = styled.div<{
  numOfRows: number;
  isPageView?: boolean;
}>`
  width: 100%;
  margin-top: 16px;

  transition: height 0.25s ease;
  height: ${({ numOfRows }) =>
    numOfRows > 1 ? 74 + (numOfRows - 1) * 90 : 74}px;

  display: flex;
  flex-direction: ${({ isPageView }) => (isPageView ? 'row' : 'column')};
  flex-wrap: ${({ isPageView }) => (isPageView ? 'wrap' : 'nowrap')};
  gap: ${({ isPageView }) => (isPageView ? '16px' : '12px')};

  @media (max-width: ${Size.mobile}) {
    height: auto;
  }
`;
