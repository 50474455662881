import { createContext } from 'react';

import { CheckpointsContextValue, CheckpointsGraphData } from '../constants';

const emptyValue: CheckpointsContextValue = {
  checkpoints: [] as CheckpointsGraphData[],
  hoveredMarkerId: null,
  activeCheckpointId: null,
};

export const GraphCheckPointsContext = createContext<CheckpointsContextValue>(
  emptyValue,
);
