/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled, { DefaultTheme } from 'styled-components';

import { colorStates, shape, typography } from '../../theme/themeUtils';

export interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  error?: boolean;
  variant?: keyof DefaultTheme['input']['color'];
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ error, ...inputProps }, ref) => {
    return <StyledInput {...inputProps} error={error} ref={ref} />;
  },
);

const StyledInput = styled.input<{
  variant?: keyof DefaultTheme['input']['color'];
  error?: boolean;
}>`
  ${typography('default')};
  ${({ theme }) => shape(theme.input.shape.default)};
  ${({ theme, variant }) =>
    colorStates(theme.input.color[variant || 'default'])};
  ${({ theme, error }) => error && colorStates(theme.input.color.invalid)};

  display: block;
`;

export default Input;
