import React from 'react';
import styled from 'styled-components';
import { Color, Icon } from '@packages/ui';

import { useCopyToClipboard } from 'shared/hooks';
import { KeyCode } from 'shared/constants';

interface CopyableProps {
  children: React.ReactNode | React.ReactNode[];
  value?: string;
  iconSize?: number;
  className?: string;
}

export const Copyable: React.FC<CopyableProps> = ({
  children,
  value,
  className,
  iconSize = 16,
}) => {
  const copyToClipboard = useCopyToClipboard();

  const handleKeyPressCopy = (event: React.KeyboardEvent<HTMLElement>) => {
    const { key } = event;

    if (key === KeyCode.ENTER) {
      event.preventDefault();
      copyToClipboard(value);
    }
  };

  const handleClickCopy = () => {
    copyToClipboard(value);
  };

  return (
    <ClickToCopyWrapper
      className={className}
      tabIndex={0}
      onClick={handleClickCopy}
      onKeyPress={handleKeyPressCopy}
    >
      {children}
      <CopyIcon icon="copy" iconSize={iconSize} />
    </ClickToCopyWrapper>
  );
};

const CopyIcon = styled(Icon)`
  color: ${Color.blue500};
  display: none;
`;

const ClickToCopyWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: ${Color.blue500};

    * {
      color: ${Color.blue500};
    }

    ${CopyIcon} {
      display: block;
    }
  }
`;
