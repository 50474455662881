import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

import { colorStates, shape, typography } from '../../theme/themeUtils';
import { Color } from '../../theme/primitives';
import Text from '../../atoms/Text';
import Input from '../../atoms/Input';
import Panel from '../../atoms/Panel';
import { Icon } from '../../atoms/Icon';
import { Theme } from '../../styled';

const SingleSelectContext = React.createContext<SingleSelectProps | null>(null);

export interface SingleSelectProps<OptionType = SingleSelectOptionType> {
  className?: string;
  options: OptionType[];
  selected: OptionType;
  onChange: (selected: OptionType) => void;
  filterOptions?: (option: OptionType, search: string) => boolean;

  inputPlaceholder?: string;
  error?: boolean;
  errorMessage?: string;
  noOptionsFoundText?: string;
  isDisabled?: boolean;
  dataTestId?: string;
}

export const SingleSelect: React.FC<SingleSelectProps> = ({
  className,
  children,
  dataTestId,
  ...props
}) => {
  const [isLocalExpanded, setIsLocalExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { isDisabled = false, errorMessage } = props;
  useEffect(() => {
    if (!isLocalExpanded) {
      return;
    }

    const eventHandler = (e: Event): void => {
      if (
        !containerRef.current ||
        containerRef.current.contains(e.target as Node)
      )
        return;
      setIsLocalExpanded(false);
    };

    document.addEventListener('mousedown', eventHandler);
    document.addEventListener('focusin', eventHandler);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('mousedown', eventHandler);
      document.removeEventListener('focusin', eventHandler);
    };
  }, [isLocalExpanded]);

  const handleOnChange = (option: SingleSelectOptionType) => {
    props.onChange(option);
    setIsLocalExpanded(false);
  };

  return (
    <SingleSelectContext.Provider
      value={{ ...props, onChange: handleOnChange }}
    >
      <Container
        data-testid={dataTestId}
        ref={containerRef}
        className={className}
      >
        <Main
          isExpanded={isLocalExpanded}
          setIsExpanded={setIsLocalExpanded}
          isDisabled={isDisabled}
        />
        {isLocalExpanded && <Options isDisabled={isDisabled} />}
        {errorMessage && (
          <ErrorMessage variant="default">{errorMessage}</ErrorMessage>
        )}
      </Container>
    </SingleSelectContext.Provider>
  );
};

const Main: React.FC<{
  setIsExpanded: (isExpanded: boolean) => void;
  isExpanded: boolean;
  isDisabled: boolean;
}> = ({ setIsExpanded, isExpanded, isDisabled }) => {
  const { error, selected } = useContext(SingleSelectContext)!;

  return (
    <InputContainer
      isExpanded={isExpanded}
      error={error}
      tabIndex={0}
      onMouseDownCapture={e => {
        setIsExpanded(!isExpanded);
        e.stopPropagation();
      }}
      onKeyDown={({ key }) => {
        if (key === 'Enter' || key === ' ') {
          setIsExpanded(!isExpanded);
        }
      }}
      isDisabled={isDisabled}
    >
      <InputText>{selected.label || selected.value}</InputText>
      {!isDisabled && (
        <IconWrapper isExpanded={isExpanded}>
          <Icon icon="arrowDown" iconSize="12" />
        </IconWrapper>
      )}
    </InputContainer>
  );
};

const Options: React.FC<{ isDisabled: boolean }> = ({ isDisabled }) => {
  const { inputPlaceholder, error } = useContext(SingleSelectContext)!;
  const [search, setSearch] = useState('');

  return (
    <OptionsContainer>
      <OptionsPanel isError={error}>
        <OptionsContainerContents>
          <Input
            placeholder={inputPlaceholder}
            onChange={e => setSearch(e.target.value)}
          />
          <OptionsList search={search} isDisabled={isDisabled} />
        </OptionsContainerContents>
      </OptionsPanel>
    </OptionsContainer>
  );
};

const OptionsList: React.FC<{ search: string; isDisabled: boolean }> = ({
  search,
  isDisabled,
}) => {
  const {
    options,
    filterOptions = defaultSearch,
    noOptionsFoundText,
    onChange,
  } = useContext(SingleSelectContext)!;

  const filteredOptions = options.filter(option =>
    filterOptions(option, search),
  );

  return (
    <OptionsListContainer>
      {filteredOptions.length ? (
        filteredOptions.map((option, index) => {
          return (
            <OptionContainer
              tabIndex={0}
              onClick={() => onChange(option)}
              onKeyDown={({ key }) => {
                if (key === 'Enter' || key === ' ') {
                  onChange(option);
                }
              }}
              key={index}
              isDisabled={isDisabled}
            >
              <SitekeyOption option={option} />
            </OptionContainer>
          );
        })
      ) : (
        <NoResultsFoundWrapper>
          {noOptionsFoundText || 'No options found'}
        </NoResultsFoundWrapper>
      )}
    </OptionsListContainer>
  );
};

const defaultSearch = (
  option: SingleSelectOptionType,
  search: string,
): boolean => {
  return option.label.toLowerCase().includes(search.trim().toLowerCase());
};

const SitekeyOption: React.FC<{ option: SingleSelectOptionType }> = ({
  option,
}) => {
  return (
    <OptionExternalContainer>
      <OptionInternalContainer>
        <StyledName
          whiteSpace="nowrap"
          overflow="hidden"
          maxWidth="290px"
          textOverflow="ellipsis"
          variant="small"
        >
          {option.label || '--'}
        </StyledName>
        <StyledSitekey variant="small">{option.value}</StyledSitekey>
      </OptionInternalContainer>
    </OptionExternalContainer>
  );
};

export interface SingleSelectOptionType {
  label: string;
  value: string;
}

const StyledName = styled(Text)`
  font-weight: 700;
  color: ${({ theme }: { theme: Theme }) =>
    theme.multiSelectOption.color.siteKeySelect.text};
`;

const StyledSitekey = styled(Text)`
  color: ${({ theme }: { theme: Theme }) =>
    theme.multiSelectOption.color.siteKeySelect.text};
`;

const Container = styled.div`
  position: relative;
`;

const OptionsListContainer = styled.div`
  overflow-y: auto;
  margin-top: 10px;

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: ${Color.grey400};
    border-radius: 8px;
  }
`;

const OptionContainer = styled.div<{
  isDisabled: boolean;
}>`
  ${({ theme }) => shape(theme.selectOption.shape)};

  cursor: ${({ isDisabled }) => (isDisabled ? 'inherit' : 'pointer')};

  &:hover {
    background: ${({ theme, isDisabled }) =>
      isDisabled ? 'inherit' : theme.selectOption.color.selected.background};
  }

  border-bottom: 1px solid
    ${({ theme }) => theme.selectOption.color.default.border};

  :last-child {
    border-bottom: none;
  }
`;

const OptionsContainer = styled.div`
  width: 100%;
  position: absolute;
  z-index: 999;
  max-height: 300px;
  display: flex;

  margin-top: ${({ theme }) => theme.select.layout.gap};
`;

const OptionsPanel = styled(Panel)<{
  isError?: boolean;
}>`
  flex: 1;
  border-radius: 8px;
  padding: 15px;
  ${({ theme, isError }) =>
    isError && colorStates(theme.multiSelectInput.color.invalid)};
`;

const OptionsContainerContents = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div<{
  variant?: keyof DefaultTheme['multiSelectInput']['color'];
  error?: boolean;
  isExpanded?: boolean;
  isDisabled?: boolean;
  isAlwaysExpanded?: boolean;
}>`
  ${({ theme }) => shape(theme.multiSelectInput.shape.default)};
  ${({ theme }) => colorStates(theme.multiSelectInput.color.default)};
  ${({ theme, error }) => {
    return error && colorStates(theme.multiSelectInput.color.invalid);
  }};

  & svg {
    fill: ${({ theme, variant }) =>
      theme.multiSelectInput.color[variant || 'default'].inactive.border};
  }

  &:focus svg,
  &:hover svg {
    fill: currentColor;
  }

  ${({ isExpanded, theme, variant, error }) =>
    isExpanded &&
    !error &&
    css`
      border-color: ${theme.multiSelectInput.color[variant || 'default'].focus
        .border};
      color: ${theme.multiSelectInput.color[variant || 'default'].focus.text};
    `}

  cursor: ${({ isDisabled, isAlwaysExpanded }) =>
    isDisabled || isAlwaysExpanded ? 'inherit' : 'pointer'};
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;


`;

const InputText = styled.span`
  ${typography('default')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  transform: ${({ isExpanded }: { isExpanded: boolean }) =>
    isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const NoResultsFoundWrapper = styled.div`
  padding: 35px 0;
  text-align: center;
  color: ${({ theme }) => theme.multiSelectOption.color.default.text};
`;

const OptionExternalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const OptionInternalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled(Text)`
  display: flex;
  margin: 4px 0 0;
  font-size: 10px;
  line-height: 14px;
  color: ${Color.red400};
`;
