import { Color } from '../../theme/primitives';

const theme = {
  dropdown: {
    color: {
      default: {
        inactive: {
          background: Color.white,
          text: Color.grey600,
          border: Color.grey400,
          placeholderText: Color.grey600,
          optionBackground: Color.white,
        },
        hover: {
          background: Color.white,
          text: Color.grey600,
          border: Color.grey600,
          optionBackground: Color.grey500,
        },
        focus: {
          background: Color.white,
          text: Color.grey600,
          border: Color.grey600,
          optionBackground: Color.grey100,
        },
        selected: {
          optionBackground: Color.grey500,
          optionColor: Color.white,
        },
        disabled: {
          background: Color.grey100,
          border: Color.grey400,
          text: Color.grey600,
          placeholderText: Color.grey600,
        },
      },
    },
    shape: {
      default: {
        padding: '8px 16px',
        borderRadius: '4px',
        borderStyle: 'solid',
        borderWidth: '1px',
      },
    },
  },
};

export default theme;
