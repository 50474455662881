import React, { useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';

export interface FormProps {
  onEnter?: Function;
  children: React.ReactNode | React.ReactElement;
  padding?: number;
  width?: number;
  minHeight?: number;
  role?: string;
  ariaLabel?: string;
}

const Form: React.FC<FormProps> = ({
  onEnter = () => {},
  children,
  padding,
  width,
  minHeight,
  role,
  ariaLabel,
}) => {
  const $wrapper: any = useRef();

  const childrenWithProps = React.Children.map(
    children,
    (child: React.ReactNode | React.ReactElement) =>
      React.isValidElement(child)
        ? React.cloneElement(child, { width } as React.ComponentProps<any>)
        : child,
  );

  const handleKeyPress = useCallback(
    e => {
      if (e.keyCode === 13) {
        onEnter();
      }
    },
    [onEnter],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return function cleanup() {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    $wrapper.current?.focus?.();
  }, []);

  return (
    <Wrapper
      ref={$wrapper}
      padding={padding}
      width={width}
      minHeight={minHeight}
      role={role}
      aria-label={ariaLabel}
    >
      {childrenWithProps}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: ${props =>
    props.padding !== undefined
      ? `calc(100% - ${2 * props.padding}px)`
      : '100%'};

  width: ${props => props.width || 300}px;
  height: auto;
  min-height: ${props =>
    props.minHeight !== undefined ? props.minHeight : 470}px;

  position: relative;
  margin: 0 auto;
  padding: ${props => (props.padding !== undefined ? props.padding : 20)}px;

  z-index: 10;

  border-radius: 8px;
`;

export default Form;
