import React from 'react';
import styled from 'styled-components';

import { Color } from '../../theme/primitives';

type MessageProps = {
  message: string;
};

const Message = ({ message }: MessageProps) => {
  return (
    <Animated>
      <Label>{message}</Label>
    </Animated>
  );
};

const Animated = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  background-color: ${Color.grey100};
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-height: 150px;
  height: auto;
  border-radius: 5px;
  border: 1px solid;
`;

const Label = styled.div`
  padding: 20px;
  font-size: 18px;
  text-align: center;
`;

export default Message;
