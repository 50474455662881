import React, { useContext } from 'react';
import styled from 'styled-components';

import { normalize } from '../../../utils/mathUtils';
import { color } from '../../../theme/themeUtils';
import {
  ChartAreaContext,
  formatValueOnAxis,
} from '../ChartAreaProvider/ChartAreaContext';

import { AxisProps } from './types';
import { Label, Name } from './components';

const AxisX: React.FC<AxisProps> = ({ className, hideTicks }) => {
  const { x: axis } = useContext(ChartAreaContext);

  return (
    <ContainerX className={className} hideTicks={hideTicks}>
      <Name>{axis.name}</Name>
      {axis.scaleMarks.map((value, index) => {
        const position = normalize(axis.min, axis.max, value);

        return (
          <LabelX hideTicks={hideTicks} position={position} key={index}>
            {formatValueOnAxis(axis, value)}
          </LabelX>
        );
      })}
    </ContainerX>
  );
};

export default AxisX;

const LabelX = styled(Label)<{
  position: number;
}>`
  left: ${props => `${props.position * 100}%`};
  top: 0;
  transform: translateX(-50%) translateX(-0.5px);
  text-align: center;
  padding-top: 10px;
  white-space: nowrap;
  font-size: 11px;
  font-weight: 600;

  ::after {
    width: 1px;
    height: 5px;
    top: 0;
    left: 50%;
  }
`;

const ContainerX = styled.div<{
  hideTicks?: boolean;
}>`
  ${({ theme }) => color(theme.axis.color.default)};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border: ${({ hideTicks }) => (hideTicks ? 'none' : 'solid')};
  border-width: 0;
  border-top-width: 1px;

  ${Name} {
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
`;
