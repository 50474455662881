import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Color, Size } from '../../../theme/primitives';
import { Icon } from '../../../atoms/Icon';
import List from '../../../atoms/List';

export interface ConditionControlsProps {
  isEditMode?: boolean;
  selectedConditionControl: string;
  conditionControls?: string[];
  onViewModeConditionControlClick?: () => void;
  onChange: (conditionControl: string) => void;
}

const ConditionControls: React.FC<ConditionControlsProps> = ({
  selectedConditionControl = '',
  conditionControls = [],
  isEditMode = false,
  onChange,
  onViewModeConditionControlClick,
}) => {
  const [
    isConditionControlsListVisible,
    setIsConditionControlsListVisible,
  ] = useState(false);

  useEffect(() => {
    if (!isEditMode) {
      setIsConditionControlsListVisible(false);
    }
  }, [isEditMode]);

  const toggleConditionControlsVisibility = () => {
    setIsConditionControlsListVisible(isVisible => !isVisible);
  };

  const handleConditionControlChange = (conditionControl = '') => {
    setIsConditionControlsListVisible(false);

    onChange?.(conditionControl);
  };

  const handleViewModeConditionControlClick = () => {
    onViewModeConditionControlClick?.();
  };

  return (
    <>
      {isEditMode ? (
        <>
          <ConditionControlsContainer
            isListVisible={isEditMode && isConditionControlsListVisible}
          >
            <ConditionControlContainer
              onClick={toggleConditionControlsVisibility}
            >
              <ConditionControl>{selectedConditionControl}</ConditionControl>
              <ConditionControlArrowIcon
                iconSize="s"
                icon="arrowDownStroked"
                isRotated={isEditMode && isConditionControlsListVisible}
              />
            </ConditionControlContainer>
            {!!conditionControls.length && (
              <ConditionControlsList>
                {conditionControls?.map(conditionControl => (
                  <ConditionControlOption
                    key={conditionControl}
                    onClick={() =>
                      handleConditionControlChange(conditionControl)
                    }
                  >
                    {conditionControl}
                  </ConditionControlOption>
                ))}
              </ConditionControlsList>
            )}
          </ConditionControlsContainer>
        </>
      ) : (
        <SelectedConditionControl onClick={handleViewModeConditionControlClick}>
          {selectedConditionControl}
        </SelectedConditionControl>
      )}
    </>
  );
};

const ConditionControlsContainer = styled.div<{
  isListVisible?: boolean;
}>`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${List} {
    display: ${({ isListVisible }) => (isListVisible ? 'block' : 'none')};
  }
`;

const ConditionControlArrowIcon = styled(Icon)<{
  isRotated?: boolean;
}>`
  transform: ${({ isRotated }) => (isRotated ? 'rotate(180deg)' : 'none')};
  }
`;

const ConditionControlContainer = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const ConditionControl = styled.span`
  cursor: pointer;
  display: inline-flex;
  color: ${Color.grey600};
  font-size: ${Size.font100};
  font-weight: 500;
  line-height: ${Size.space300};
  text-transform: uppercase;
`;

const SelectedConditionControl = styled(ConditionControl)`
  border-bottom: 1px solid ${Color.blue400};
  color: ${Color.grey600};
  background-color: unset;
  padding: 0 4px 4px;
`;

const ConditionControlsList = styled(List)`
  display: none;
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  width: auto;
  max-height: 200px;
  border-radius: 4px;
  border: 1px solid ${Color.grey600};
  background-color: ${Color.white};
  overflow: auto;
  box-sizing: border-box;
  z-index: 11;

  &:hover {
    display: block;
  }
`;

const ConditionControlOption = styled.li`
  cursor: pointer;
  padding: 8px 16px;
  text-transform: uppercase;
  font-size: ${Size.font100};

  &:hover {
    background-color: ${Color.blue100};
  }
`;

export default ConditionControls;
