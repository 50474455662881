import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { ChartArea } from '../../ChartAreaProvider/ChartAreaContext';
import { Coordinates, Cursor, MouseEventType } from '../../../../constants';
import { useMouseEvent, useCanvasCoordinates } from '../../../../hooks';
import { useChartAreaContext } from '../../ChartAreaProvider/useChartAreaContext';
import { useCloseOnKeyDown, useZoomInteraction } from '../hooks';
import {
  getBoundValue,
  getIsWithinHandleBoundsX,
  getSortedBounds,
  getBoundsRange,
  getIsWithinCloseButtonX,
  getIsWithinCloseButtonY,
  getWidgetCenterX,
  getIsWithinZoomButtonX,
  getIsWithinZoomButtonY,
} from '../utils';
import {
  CROP_THRESHOLD,
  HANDLE_BOUND_EDGE_X,
  INITIAL_STATE,
  ZoomInteractionStateValue,
} from '../zoomConstants';
import { CropWidget } from '../crop/CropWidget';

interface ZoomInteractionLayerProps {
  width: number;
  height: number;
  markerWidth?: number;
  onZoomIn?: (newMinX: number, newMaxX: number) => void;
  formatValueIndicator?: (displayValue: number) => string;
}

export const ZoomInteractionLayer: React.FC<ZoomInteractionLayerProps> = ({
  width,
  height,
  markerWidth,
  onZoomIn,
  formatValueIndicator,
}) => {
  const zoomInteractionContext = useZoomInteraction();
  const [mouseEvent] = useMouseEvent();
  const [coordinates] = useCanvasCoordinates();
  const area = useChartAreaContext();

  const { x: currentGraphX } = area as ChartArea;
  const { min, max } = currentGraphX;

  const fixedBoundX = useRef<number | null>(null);
  const dragReferenceX = useRef<{
    leftBoundDistance: number;
    rightBoundDistance: number;
  } | null>(null);
  const isLefHandleClickedRef = useRef(false);
  const isRightHandleClickedRef = useRef(false);
  const isBackgroundclickRef = useRef(false);
  const isActionButtonClicked = useRef(false);

  // also set zoom in callback

  useCloseOnKeyDown(zoomInteractionContext);

  useEffect(() => {
    // mouse event updates are controlled in the canvas element of graph components
    // (note that pointer events are shut of for this component; graph interactions break otherwise)
    if (zoomInteractionContext && mouseEvent) {
      const { eventType, isActive } = mouseEvent;

      if (eventType === MouseEventType.DOWN && coordinates) {
        onMouseDown(
          (coordinates as Coordinates).x,
          (coordinates as Coordinates).y,
        );
      } else if (isActive && eventType === MouseEventType.MOVE) {
        onMouseDrag((coordinates as Coordinates).x);
      } else if (!isActive && eventType === MouseEventType.MOVE) {
        onMouseHover(
          (coordinates as Coordinates).x,
          (coordinates as Coordinates).y,
        );
      } else if (eventType === MouseEventType.UP) {
        onMouseUp();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mouseEvent, coordinates]);

  if (zoomInteractionContext === null) {
    return null;
  }

  const [
    zoomInteractionState,
    setZoomInteractionState,
  ] = zoomInteractionContext;

  const widgetCenterY = height / 2;

  const onMouseDown = (x: number, y: number) => {
    if (!zoomInteractionState?.bounds) {
      // if there are no bounds, set value for fixed bound (move event will render widget)
      fixedBoundX.current = x;
      setZoomInteractionState(INITIAL_STATE);
    } else {
      // if bounds are truthy, then click can be inside/outside the widget
      const widgetLeftBound =
        zoomInteractionState?.bounds?.[0]?.canvasValue || 0;
      const widgetRightBound =
        zoomInteractionState?.bounds?.[1]?.canvasValue || 0;

      const isCursorOnWidget = zoomInteractionState?.bounds
        ? x >= widgetLeftBound! - HANDLE_BOUND_EDGE_X &&
          x <= widgetRightBound! + HANDLE_BOUND_EDGE_X
        : false;

      // on clicking outside the widget, clear registered values + set new fixed bound
      if (!isCursorOnWidget) {
        setZoomInteractionState(INITIAL_STATE);
        isLefHandleClickedRef.current = false;
        isRightHandleClickedRef.current = false;
        isBackgroundclickRef.current = false;
        fixedBoundX.current = x;
      } else {
        // click within bounds can be on a handle, a button, or the background

        // close button check
        const isCursorOnCloseButtonX = getIsWithinCloseButtonX(
          x,
          widgetRightBound,
        );
        const isCursorOnCloseButtonY = getIsWithinCloseButtonY(y);

        const isCursorOnCloseButton =
          isCursorOnCloseButtonX && isCursorOnCloseButtonY;

        if (isCursorOnCloseButton) {
          setZoomInteractionState(INITIAL_STATE);
          // prevents interaction from breaking if user clicks a button, then drags the mouse
          isActionButtonClicked.current = true;
          return;
        }

        // zoom button check
        const widgetCenterX = getWidgetCenterX(
          widgetLeftBound,
          widgetRightBound,
        );

        const isWithinZoomButtonX = getIsWithinZoomButtonX(x, widgetCenterX);
        const isWithinZoomButtonY = getIsWithinZoomButtonY(y, widgetCenterY);

        const isHoveringZoomButton = isWithinZoomButtonX && isWithinZoomButtonY;

        if (isHoveringZoomButton) {
          const newMinX = zoomInteractionState.bounds?.[0]?.dateValue;
          const newMaxX = zoomInteractionState.bounds?.[1]?.dateValue;

          if (newMinX && newMaxX) {
            onZoomIn?.(newMinX, newMaxX);
          }

          setZoomInteractionState(INITIAL_STATE);
          isActionButtonClicked.current = true;
          return;
        }

        // handle click check
        const isLeftHandleClick = getIsWithinHandleBoundsX(x, widgetLeftBound);
        const isRightHandleClick = getIsWithinHandleBoundsX(
          x,
          widgetRightBound,
        );

        // if handle is clicked, register the event and set opposite bound as fixed
        // (note that registered handle clicks are purged on mouse up)
        if (isLeftHandleClick) {
          isLefHandleClickedRef.current = true;
          fixedBoundX.current = widgetRightBound;
        } else if (isRightHandleClick) {
          isRightHandleClickedRef.current = true;
          fixedBoundX.current = widgetLeftBound;
        } else {
          // as button clicks return early, no handles clicked means background was hit
          isBackgroundclickRef.current = true;

          // set references for calculating new bound values on widget dragging
          dragReferenceX.current = {
            leftBoundDistance: x - widgetLeftBound,
            rightBoundDistance: widgetRightBound - x,
          };

          setZoomInteractionState({
            ...zoomInteractionState,
            cursor: Cursor.GRABBING,
          });
        }
      }
    }
  };

  const onMouseDrag = (x: number) => {
    // if  one of the butons were clicked, do nothing while dragging
    if (isActionButtonClicked.current) {
      return;
    }

    // if widget background was clicked, dragging resets bounds based on cursor x position
    if (isBackgroundclickRef.current) {
      const leftDistanceX =
        x - (dragReferenceX.current?.leftBoundDistance as number);
      const rightDistanceX =
        x + (dragReferenceX.current?.rightBoundDistance as number);

      // don't reset if one of the new bound values would overshoot the canvas
      if (leftDistanceX < 0 || rightDistanceX > width) {
        return;
      }

      const newLeftBound = getBoundValue(leftDistanceX, min, max, width);

      const newRightBound = getBoundValue(
        x + (dragReferenceX.current?.rightBoundDistance as number),
        min,
        max,
        width,
      );
      setZoomInteractionState({
        ...zoomInteractionState,
        bounds: [newLeftBound, newRightBound],
      } as ZoomInteractionStateValue);

      return;
    }

    const movingBound = getBoundValue(x, min, max, width);

    // having a fixed bound set from the outside affords crossing bounds on drag
    const fixedBound = getBoundValue(
      fixedBoundX.current as number,
      min,
      max,
      width,
    );

    const sortedBounds = getSortedBounds([movingBound, fixedBound]);

    setZoomInteractionState({
      bounds: sortedBounds,
      cursor: Cursor.COL_RESIZE,
    });
  };

  const onMouseHover = (x: number, y: number) => {
    // as bounds can be [], this check ensures we're covering edge cases
    if (!zoomInteractionState?.bounds?.length) {
      return;
    }

    const widgetLeftBound = zoomInteractionState.bounds[0].canvasValue;
    const widgetRightBound = zoomInteractionState.bounds[1].canvasValue;

    const isLeftBoundX = getIsWithinHandleBoundsX(x, widgetLeftBound);
    const isRightBoundX = getIsWithinHandleBoundsX(x, widgetRightBound);
    const isWithinWidgetBounds = widgetLeftBound <= x && x <= widgetRightBound;

    if (isLeftBoundX || isRightBoundX) {
      setZoomInteractionState({
        ...zoomInteractionState,
        cursor: Cursor.COL_RESIZE,
      });
    } else {
      setZoomInteractionState({
        ...zoomInteractionState,
        cursor: Cursor.DEFAULT,
      });
    }

    const isHoveringCloseButtonX = getIsWithinCloseButtonX(x, widgetRightBound);
    const isHoveringCloseButtonY = getIsWithinCloseButtonY(y);

    const isHoveringCloseButton =
      isHoveringCloseButtonX && isHoveringCloseButtonY;

    const widgetCenterX = getWidgetCenterX(widgetLeftBound, widgetRightBound);

    const isWithinZoomButtonX = getIsWithinZoomButtonX(x, widgetCenterX);
    const isWithinZoomButtonY = getIsWithinZoomButtonY(y, widgetCenterY);

    const isHoveringZoomButton = isWithinZoomButtonX && isWithinZoomButtonY;

    if (isHoveringCloseButton) {
      setZoomInteractionState({
        ...zoomInteractionState,
        cursor: Cursor.POINTER,
      });
    } else if (isHoveringZoomButton) {
      setZoomInteractionState({
        ...zoomInteractionState,
        cursor: Cursor.POINTER,
      });
    } else if (isWithinWidgetBounds) {
      setZoomInteractionState({
        ...zoomInteractionState,
        cursor: Cursor.GRAB,
      });
    }
  };

  const onMouseUp = () => {
    // purge all registered clicks/values once interaction stops
    isLefHandleClickedRef.current = false;
    isRightHandleClickedRef.current = false;
    isBackgroundclickRef.current = false;
    isActionButtonClicked.current = false;

    // if widget width is beneath threshold, also purge bounds
    if (zoomInteractionState) {
      const widgetLeftBound = zoomInteractionState.bounds?.[0]?.canvasValue;
      const widgetRightBound = zoomInteractionState.bounds?.[1]?.canvasValue;

      const widgetWidth = getBoundsRange(widgetLeftBound, widgetRightBound);

      if (widgetWidth <= CROP_THRESHOLD) {
        setZoomInteractionState(INITIAL_STATE);
      } else {
        setZoomInteractionState({
          ...zoomInteractionState,
          cursor: Cursor.DEFAULT,
        });
      }
    }
  };

  return (
    <Container width={width} height={height}>
      <Wrapper>
        {zoomInteractionState && (
          <CropWidget
            graphHeight={height}
            bounds={zoomInteractionState.bounds}
            markerWidth={markerWidth}
            formatValueIndicator={formatValueIndicator}
          />
        )}
      </Wrapper>
    </Container>
  );
};

const Container = styled.div<{ width: number; height: number }>`
  position: absolute;
  top: 0;
  right: 0;

  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  pointer-events: none;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
