import React from 'react';

import { CellsTable } from '../types';

import { Legend } from './types';

export interface HeatMapState {
  graphId: string;
  cells: CellsTable;
  legend: Legend;
}

const HeatMapDataContext = React.createContext<HeatMapState>({
  graphId: '',
  legend: {
    min: 0,
    max: 0,
    palette: [],
  },
  cells: new Map(),
});

export default HeatMapDataContext;
