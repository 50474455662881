import { Color, Size } from '../../theme/primitives';

const theme = {
  loader: {
    color: {
      background: Color.grey100,
      foreground: Color.grey500,
    },
    size: Size.space800,
  },
};

export default theme;
