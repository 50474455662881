import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Color, Button, Icon, Text } from '@packages/ui';

import { TextInput } from '../../../components/TextInput';
import { OAuth } from '../../../components/auth/OAuth';

import { AuthCallbackProps } from 'main/components/auth/OAuthButton/OAuthButton';
import { OptionPanel } from 'shared/components/OptionPanel';
import { handleEnterKeyDown, arePasskeysBrowserSupported } from 'shared/utils';
import { OAuthButtonType, OAuthClientType } from 'main/data/types/OAuth';

export interface LoginFieldsProps {
  buttons: any | Array<OAuthButtonType>;
  clientType?: OAuthClientType;
  showForm: boolean;
  isLoggingIn?: boolean;
  onChange: (formData: { email: string; password: string }) => void;
  onSubmit: () => void | Promise<void>;
  onPasskeyLogin: () => void | Promise<void>;
  onConditionalUiPasskeyLogin?: () => void | Promise<void>;
  onOAuth: ({ authType, accountType }: AuthCallbackProps) => void;
}

const LoginFields: React.FC<LoginFieldsProps> = ({
  buttons,
  clientType,
  showForm,
  isLoggingIn,
  onChange,
  onSubmit,
  onPasskeyLogin,
  onConditionalUiPasskeyLogin,
  onOAuth,
}) => {
  const { t } = useTranslation('portal');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isNextView, setIsNextView] = useState(false);

  useEffect(() => {
    const handleConditionalUiPasskeyLogin = async () => {
      const arePasskeysSupported = await arePasskeysBrowserSupported();

      if (!arePasskeysSupported) {
        return;
      }

      onConditionalUiPasskeyLogin?.();
    };

    handleConditionalUiPasskeyLogin();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEmail = value => {
    setEmail(value);
    onChange({ email: value, password });
  };

  const handlePassword = value => {
    setPassword(value);
    onChange({ email, password: value });
  };

  const handleNextButtonClick = () => {
    setIsNextView(true);
  };

  if (!showForm) {
    return (
      <OAuth
        role="login"
        clientType={clientType}
        buttons={buttons}
        showDivider={false}
        onSelect={onOAuth}
      />
    );
  }

  return (
    <Container>
      <InputWrapper>
        {!isNextView ? (
          <>
            <OAuth
              role="login"
              clientType={clientType}
              buttons={buttons}
              showDivider={buttons.length > 0}
              onSelect={onOAuth}
            />
            <StyledLabel htmlFor="email">{t('Email')}</StyledLabel>
            <InputEmail
              placeholder={t('Email')}
              value={email}
              label="email"
              ariaRequired
              autoComplete="username webauthn"
              onChange={handleEmail}
              // @ts-ignore todo: fix types
              onKeyDown={handleEnterKeyDown(handleNextButtonClick)}
            />
            <ActionButton
              aria-live="polite"
              aria-relevant="text"
              aria-label={t('Next')}
              onClick={handleNextButtonClick}
            >
              {t('Next')}
            </ActionButton>
            {clientType !== 'organization' && (
              <NoteLinkWrapper>
                {t('Need an account?')}
                <NoteLink to="/signup" data-cy="link-signup">
                  {t('Sign Up')}
                </NoteLink>
              </NoteLinkWrapper>
            )}
          </>
        ) : (
          <>
            <SelectedDataBlock>
              <BackButton variant="link" onClick={() => setIsNextView(false)}>
                <BackIcon icon="arrowRightAlternate" iconSize="16" />
                {t('Back')}
              </BackButton>
              {email && <SelectedEmail>({email})</SelectedEmail>}
            </SelectedDataBlock>
            <ActionsContainer>
              <OptionPanel
                icon="passkey"
                title={t('Sign in with a passkey')}
                isDisabled={isLoggingIn}
                onClick={onPasskeyLogin}
              />
              <OptionPanel
                isExpandible
                icon="password"
                title={t('Sign in with a password')}
              >
                <InputPassword
                  placeholder={t('Password')}
                  type="password"
                  label="password"
                  value={password}
                  ariaRequired
                  onChange={handlePassword}
                  // @ts-ignore todo: fix types
                  onKeyDown={handleEnterKeyDown(onSubmit)}
                />
                <ActionButton
                  aria-live="polite"
                  aria-relevant="text"
                  onClick={onSubmit}
                  aria-label={isLoggingIn ? t('Signing in...') : t('Submit')}
                  disabled={isLoggingIn}
                >
                  {isLoggingIn ? t('Signing in...') : t('Submit')}
                </ActionButton>
              </OptionPanel>
            </ActionsContainer>
            <NoteLinkWrapper>
              <NoteLink to="/password_reset" data-cy="link-forgot">
                {t('Forgot password?')}
              </NoteLink>
            </NoteLinkWrapper>
          </>
        )}
      </InputWrapper>
    </Container>
  );
};

const SelectedDataBlock = styled.div``;

const SelectedEmail = styled(Text)``;

const BackButton = styled(Button)`
  display: flex;
  color: ${Color.grey600};
  padding-left: 0;
`;

const BackIcon = styled(Icon)`
  transform: rotate(180deg);
  margin-right: 8px;
  cursor: pointer;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
`;

const ActionButton = styled(Button)`
  width: 100%;
`;

const Container = styled.div`
  margin-top: 15px;
`;

const StyledLabel = styled.label`
  color: ${Color.grey600};
  font-weight: 500;
  margin-bottom: 8px;
  display: flex;
`;

const InputWrapper = styled.div`
  margin-top: 15px;
`;

const InputEmail = styled(TextInput)`
  margin-bottom: 15px;
  position: relative;
`;

const InputPassword = styled(TextInput)`
  margin-bottom: 5px;
  position: relative;
`;

const NoteLinkWrapper = styled.div`
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: ${Color.grey600};
  font-weight: 300;
  margin-top: 16px;
`;

const NoteLink = styled(Link)`
  color: ${Color.grey500};
  margin-left: 3px;
  text-decoration: none;
  font-weight: 500;

  :hover {
    color: ${Color.grey600};
    text-decoration: underline;
  }
`;

export default LoginFields;
