/* eslint-disable react/jsx-props-no-spreading */
import styled from 'styled-components';
import React from 'react';
import {
  components as ReactSelectComponents,
  IndicatorProps,
  MultiValueProps,
  MenuListComponentProps,
  OptionProps,
} from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { Icon } from '../Icon';
import { Color } from '../../theme/primitives';

import {
  OptionType,
  GroupType,
  BaseMultiSelectTagsProps,
} from './MultiSelectTags.types';
import { getStyles } from './MultiSelectTags.styles';

export const CustomOption = (props: OptionProps<any, true>) => {
  return (
    <ReactSelectComponents.Option {...props}>
      <StyledPill>{props.children}</StyledPill>
    </ReactSelectComponents.Option>
  );
};

export const CustomMenuList = (
  props: MenuListComponentProps<OptionType, true, GroupType>,
) => {
  const { selectProps } = props;

  return (
    <>
      <ReactSelectComponents.MenuList {...props}>
        <StyledMenuListDescription>
          {selectProps.menuDescriptionLabel ??
            'Select existing tags or type and hit enter to create a new one'}
        </StyledMenuListDescription>
        {props.children}
      </ReactSelectComponents.MenuList>
    </>
  );
};

export const CustomMultiValueRemove = (props: MultiValueProps<OptionType>) => {
  return (
    <ReactSelectComponents.MultiValueRemove {...props}>
      <Icon icon="closeAlt" iconSize="16" />
    </ReactSelectComponents.MultiValueRemove>
  );
};

export const CustomDropdownIndicator = (
  props: IndicatorProps<OptionType, true, GroupType>,
) => {
  return (
    <ReactSelectComponents.DropdownIndicator {...props}>
      <Icon icon="chevronDown" iconSize="16" />
    </ReactSelectComponents.DropdownIndicator>
  );
};

export function BaseMultiSelectTags({
  options,
  onChange,
  value,
  placeholder,
  name,
  hasError = false,
  menuDescriptionLabel,
}: BaseMultiSelectTagsProps) {
  return (
    <CreatableSelect
      value={value}
      onChange={onChange}
      inputId={name}
      styles={getStyles({ hasError })}
      components={{
        ClearIndicator: null,
        IndicatorSeparator: null,
        DropdownIndicator: CustomDropdownIndicator,
        MultiValueRemove: CustomMultiValueRemove,
        MenuList: CustomMenuList,
        Option: CustomOption,
      }}
      isMulti
      isSearchable
      options={options}
      placeholder={placeholder}
      menuPosition="fixed"
      menuDescriptionLabel={menuDescriptionLabel}
    />
  );
}

const StyledMenuListDescription = styled.div`
  padding: 12px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: ${Color.grey500};
`;

const StyledPill = styled.div`
  padding: 5px 8px;
  box-shadow: inset 0px 0px 0px 1px ${Color.blue500};
  width: fit-content;
  border-radius: 4px;
  background-color: ${Color.blue100};
  color: ${Color.blue500};
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
`;
