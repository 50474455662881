import { MutableRefObject } from 'react';
import { InteractionEvent } from 'pixi.js-legacy';

import { ChartArea } from '../ChartAreaProvider/ChartAreaContext';
import { Cursor } from '../../../constants';

export type GraphZoomConfig = {
  xMin: number;
  xMax: number;
  width: number;
  height: number;
  onZoomButtonClick?: (newMinX: number, newMaxX: number) => void;
  markerWidth?: number;
  formatMarker: (date: Date) => string;
};

export type CropMouseClickEventConfig = {
  event: InteractionEvent;
  zoomContext: GraphZoomContextValue | null;
  onHoverWidgetDisplay: (event: InteractionEvent) => void;
  isLeftHandleClickedRef: MutableRefObject<boolean>;
  isRightHandleClickedRef: MutableRefObject<boolean>;
  isCloseButtonClickedRef: MutableRefObject<boolean>;
  isZoomButtonClickedRef: MutableRefObject<boolean>;
  xFixedRef: MutableRefObject<number | null>;
  canvasHeight: number;
};

export type CropMouseMoveEventConfig = CropMouseClickEventConfig & {
  area: ChartArea;
};

export type CropWidgetBoundValue = {
  canvasValue: number;
  dateValue: number;
};

export type GraphZoomContextValue = {
  xMin: number;
  xMax: number;
  height: number;
  width: number;
  cropWidgetBounds: CropWidgetBoundValue[] | null;
  isCreatingCrop: boolean;
  markerWidth?: number;
  markersValues?: number[] | null;
  formatMarker: (date: Date) => string;
  handleZoom?: (min: number, max: number) => void;
  handleCropWidgetCreation?: () => void;
  handleCropWidgetBoundSetting?: (
    bounds: CropWidgetBoundValue[] | null,
  ) => void;
};

export type ZoomInteractionStateValue = {
  bounds: CropWidgetBoundValue[] | null;
  cursor: Cursor;
};

export type ZoomInteractionState = [
  ZoomInteractionStateValue | null,
  React.Dispatch<React.SetStateAction<ZoomInteractionStateValue | null>>,
];

export const CROP_THRESHOLD = 50;
export const HANDLE_BOUND_EDGE_X = 6;
export const CLOSE_BUTTON_PADDING = 5;
export const CLOSE_BUTTON_DIAMETER = 24;
export const ZOOM_BUTTON_DIAMETER = 40;
export const DEFAULT_MARKER_WIDTH = 70;
export const ZOOM_CANVAS_CLASS_NAME = 'graph-zoom-canvas';

export const INITIAL_STATE: ZoomInteractionStateValue = {
  bounds: null,
  cursor: Cursor.COL_RESIZE,
};
