import React, { useCallback, useState, useContext } from 'react';
import * as PIXI from 'pixi.js-legacy';
import * as ReactPIXI from '@inlet/react-pixi/legacy';

import { Cursor, MouseEventType } from '../../../constants';
import { lerp, range } from '../../../utils/mathUtils';
import { useInteraction } from '../../../atoms/GraphInteractionLayer/GraphInteractionContext';
import { Color } from '../../../theme/primitives';
import { useLineGraphInteraction } from '../hooks/useLineGraphInteraction';
import { useLineGraphState } from '../hooks/LineGraphContext';
import {
  useZoomInteraction,
  ZOOM_CANVAS_CLASS_NAME,
} from '../../../charts/atoms/graph-zoom';
import { useCanvasCoordinates, useMouseEvent } from '../../../hooks';
import AxisYGuideLines from '../../../charts/atoms/AxisYGuideLines';
import { ChartAreaContext } from '../../../charts/atoms/ChartAreaProvider/ChartAreaContext';
import { Point } from '../types';

const { Container, Stage, Graphics } = ReactPIXI;

// TODO: use better categorical palette
// https://spectrum.adobe.com/page/color-for-data-visualization/#Categorical
// Current colors is copy-paste from https://www.carbondesignsystem.com/data-visualization/color-palettes/#categorical-palettes
// const COLORS = [
//   0x6929c4,
//   0x1192e8,
//   0x005d5d,
//   0x9f1853,
//   0xfa4d56,
//   0x570408,
//   0x198038,
//   0x002d9c,
//   0xee538b,
//   0xb28600,
//   0x009d9a,
//   0x012749,
//   0x8a3800,
//   0xa56eff,
// ];

export interface LineGraphProps {
  width: number;
  height: number;
  className?: string;
  threshold?: number;
}

export const LineGraphCanvas: React.FC<LineGraphProps> = ({
  width: rawWidth,
  height: rawHeight,
  className,
  threshold = 0,
}) => {
  const [, setInteractionState] = useInteraction();
  const area = useContext(ChartAreaContext);
  const lineGraphInteractionState = useLineGraphInteraction();
  const { lines, graphId } = useLineGraphState();
  const zoomInteractionContext = useZoomInteraction();
  const zoomInteractionState = zoomInteractionContext?.[0];

  const [currentMouseEvent, setCurrentMouseEvent] = useMouseEvent();
  const [, setCoordinates] = useCanvasCoordinates();

  const [isHovered, setIsHovered] = useState(false);

  const handleHoverWidgetDisplay = (event: PIXI.InteractionEvent) => {
    if (!isHovered) {
      setInteractionState(null);
      return;
    }

    const x = lerp(area.x.min, area.x.max, event.data.global.x / width);
    const y = lerp(area.y.max, area.y.min, event.data.global.y / height);

    setInteractionState({ x, y, graphId });
  };

  const width = Math.max(rawWidth, 1);
  const height = Math.max(rawHeight, 1);

  // TODO: make mouse handlers reusable across graph components
  const handleMouseMove = (event: PIXI.InteractionEvent) => {
    const { x, y } = event.data.global;

    const isMouseActive = currentMouseEvent?.isActive;

    setCoordinates({ x, y });
    setCurrentMouseEvent({
      eventType: MouseEventType.MOVE,
      // needed as initial state value is null
      isActive: isMouseActive || false,
    });

    // show hover widget on move when mouse is unpressed and there's no crop widget rendered
    if (!zoomInteractionState?.bounds && !isMouseActive) {
      handleHoverWidgetDisplay(event);
    }
  };

  const handleMouseDown = () => {
    if (!zoomInteractionContext) {
      return;
    }

    setCurrentMouseEvent({ eventType: MouseEventType.DOWN, isActive: true });

    setInteractionState(null);
  };

  const handleMouseUp = (event: PIXI.InteractionEvent) => {
    if (!zoomInteractionContext) {
      return;
    }

    const [zoomInteractionState] = zoomInteractionContext;

    // if zoom interaction context is setup but state is empty, show hover widget
    if (!zoomInteractionState?.bounds) {
      handleHoverWidgetDisplay(event);
    }

    setCurrentMouseEvent({ eventType: MouseEventType.UP, isActive: false });
  };

  const drawLines = useCallback(
    (graphics: PIXI.Graphics) => {
      graphics.clear();

      lines.forEach(({ points, color }) => {
        let previousPoint: Point;
        points.forEach((point, index) => {
          if (index === 0) {
            previousPoint = point;
            return;
          }

          const thresholdY = range(
            threshold,
            area.y.min,
            area.y.max,
            height,
            0,
          );
          const currentX = range(point.x, area.x.min, area.x.max, 0, width);
          const currentY = range(point.y, area.y.min, area.y.max, height, 0);
          const previousX = range(
            previousPoint.x,
            area.x.min,
            area.x.max,
            0,
            width,
          );
          const previousY = range(
            previousPoint.y,
            area.y.min,
            area.y.max,
            height,
            0,
          );

          const crossesThreshold =
            (previousY > thresholdY && currentY <= thresholdY) ||
            (previousY <= thresholdY && currentY > thresholdY);

          if (crossesThreshold) {
            const ratio = (thresholdY - previousY) / (currentY - previousY);
            const crossingX = previousX + ratio * (currentX - previousX);

            // Draw the first part
            graphics.lineStyle(
              1.5,
              previousY > thresholdY
                ? PIXI.utils.string2hex(Color.grey300)
                : PIXI.utils.string2hex(color),
              1,
            );
            graphics.moveTo(previousX, previousY);
            graphics.lineTo(crossingX, thresholdY);
            graphics.endFill();

            graphics.moveTo(crossingX, thresholdY);
            graphics.lineStyle(
              1.5,
              currentY > thresholdY
                ? PIXI.utils.string2hex(Color.grey300)
                : PIXI.utils.string2hex(color),
              1,
            );
          } else {
            graphics.lineStyle(
              1.5,
              previousY > thresholdY
                ? PIXI.utils.string2hex(Color.grey300)
                : PIXI.utils.string2hex(color),
              1,
            );
            graphics.moveTo(previousX, previousY);
          }

          graphics.lineTo(currentX, currentY);
          graphics.endFill();

          previousPoint = point;
        });
      });
    },
    [lines, height, width, area, threshold],
  );

  const drawPoint = useCallback(
    graphics => {
      graphics.clear();

      if (lineGraphInteractionState && lineGraphInteractionState.isActive) {
        const { point, color } = lineGraphInteractionState.nearestDataPoint;

        const xPos = range(point.x, area.x.min, area.x.max, 0, width);
        const yPos = range(point.y, area.y.min, area.y.max, height, 0);

        graphics.lineStyle({
          color: PIXI.utils.string2hex(Color.white),
          width: 1.5,
        });
        graphics.beginFill(PIXI.utils.string2hex(color));
        graphics.drawCircle(xPos, yPos, 3.5);
        graphics.endFill();
      }
    },
    [lineGraphInteractionState, width, height, area],
  );

  return (
    <Stage
      options={{
        backgroundAlpha: 0,
        autoDensity: true,
        antialias: true,
      }}
      width={width}
      height={height}
      className={
        zoomInteractionContext
          ? `${className} ${ZOOM_CANVAS_CLASS_NAME}`
          : className
      }
      raf={false}
      onMount={app => {
        // eslint-disable-next-line no-param-reassign
        app.renderer.plugins.interaction.moveWhenInside = true;
      }}
      onMouseOut={() => {
        setInteractionState(null);
        setIsHovered(false);
      }}
      onMouseOver={() => {
        setIsHovered(true);
      }}
      renderOnComponentChange
    >
      <Container
        interactive
        hitArea={new PIXI.Rectangle(0, 0, width, height)}
        mousedown={handleMouseDown}
        mouseup={handleMouseUp}
        mousemove={handleMouseMove}
        mouseout={() => {
          // if cursor leaves canvas, treat as mouse up
          setCurrentMouseEvent({
            eventType: MouseEventType.UP,
            isActive: false,
          });
          setInteractionState(null);
        }}
        cursor={
          zoomInteractionContext ? zoomInteractionState?.cursor : Cursor.DEFAULT
        }
      >
        <AxisYGuideLines width={width} height={height} />
        <Graphics draw={drawLines} />
        <Graphics draw={drawPoint} />
      </Container>
    </Stage>
  );
};
