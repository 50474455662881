import React from 'react';
import styled from 'styled-components';

import Panel from '../../atoms/Panel';
import { typography } from '../../theme/themeUtils';

export interface CardLayoutProps {
  title?: React.ReactNode;
}

export const CardLayout: React.FC<CardLayoutProps> = ({ title, children }) => {
  return (
    <Panel>
      {title && <Header>{title}</Header>}
      <Body>{children}</Body>
    </Panel>
  );
};

const Header = styled.div`
  ${typography('h3')};
  padding-bottom: ${({ theme }) => theme.cardHeader.shape.padding};
  margin-bottom: ${({ theme }) => theme.cardHeader.layout.gap};
  border-bottom: 1px solid ${({ theme }) => theme.global.color.border};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;

  margin: -${({ theme }) => theme.card.layout.gap};

  & > * {
    margin: ${({ theme }) => theme.card.layout.gap};
  }
`;
