/* eslint-disable no-underscore-dangle */
import React from 'react';

// children: component element that you want to assign
// listener: a function that's going to be assigned to the children
// type: a special "defaultProps" defined in the children component level ("__TYPE")

interface Binding {
  type?: string; // React.defaultProps can possibly be undefined
  events: { [key: string]: any };
}

export function addPropsToChildren(children: any, bindings: Binding[]) {
  return recursiveChildren(children, bindings);
}

function recursiveChildren(children: any, bindings: Binding[]) {
  let items = children;
  if (!Array.isArray(children)) {
    items = [children];
  }

  return items.map((item: any, index: number) => {
    const matchingBindings = bindings.filter(binding =>
      Array.isArray(item.props?.__type)
        ? item.props.__type.includes(binding.type)
        : item.props?.__type === binding.type,
    );

    // If there are no matching bindings, return the original item
    if (!matchingBindings.length) {
      return item;
    }

    if (Array.isArray(item)) {
      // If children is an array, iterate over them
      return recursiveChildren(item, bindings);
    }

    const props = createProps(item.props, matchingBindings);

    // Clone element to attach props
    return React.cloneElement(item, {
      key: `item-${index}`,
      ...props,
    });
  });
}

function createProps(props: any, bindings: Binding[]) {
  const propsWithEvents = { ...props };

  bindings.forEach((binding: Binding) => {
    Object.keys(binding.events).forEach((key: string) => {
      const currentEvent = binding.events[key];

      if (propsWithEvents[key] && typeof propsWithEvents[key] === 'function') {
        const previousEvent = propsWithEvents[key];

        propsWithEvents[key] = (event: any) => {
          previousEvent(event);
          currentEvent(event);
        };
      } else {
        propsWithEvents[key] = currentEvent;
      }
    });
  });

  // Example of object returned
  /* onClick: (event) => {
    props.onClick(event);
    listeners.onClick(event);
  }, */

  return propsWithEvents;
}
