import { Color, Size } from '../../theme/primitives';

const theme = {
  widgetFilter: {
    default: {
      inActive: {
        background: Color.white,
        text: Color.grey500,
        border: 'transparent',
        borderRadius: Size.radius200,
        iconColor: Color.grey400,
      },
      hover: {
        background: Color.grey100,
        text: Color.grey700,
        border: 'transparent',
        borderRadius: Size.radius200,
        iconColor: Color.grey700,
      },
      active: {
        background: Color.white,
        text: Color.grey500,
        border: Color.grey500,
        borderRadius: Size.radius200,
        iconColor: Color.grey500,
      },
      focus: {
        background: Color.white,
        text: Color.grey500,
        border: Color.grey500,
        borderRadius: Size.radius200,
        iconColor: Color.grey500,
      },
    },
  },
  widgetFilterOption: {
    shape: {
      default: {
        padding: Size.space200,
      },
    },
    color: {
      default: {
        inActive: {
          border: Color.grey100,
          background: 'transparent',
        },
        hover: {
          border: Color.grey100,
          background: Color.grey100,
        },
      },
    },
  },
};

export default theme;
