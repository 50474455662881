import { useState } from 'react';

const PREFIX = 'ui';
let counter = -1;

export default function useId(name: string): string {
  const [id] = useState<string>(() => {
    counter += 1;

    return `${PREFIX}-${name}-${counter}`;
  });

  return id;
}
