import { useStore, createStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { SessionInfo } from '../types/session.type';

type State = {
  sessionInfo: SessionInfo | null;
};

type Actions = {
  setSessionInfo: (user: Partial<SessionInfo>) => void;
  resetSessionInfo: () => void;
};

const initialStates = {
  sessionInfo: {
    csrfToken: null,
    isLoggedIn: false,
    isMFA: false,
  },
};

export const SessionStore = createStore(
  immer<State & Actions>(set => ({
    ...initialStates,

    setSessionInfo: (session: Partial<SessionInfo>) =>
      set(prevState => ({
        sessionInfo: { ...prevState.sessionInfo, ...session },
      })),

    resetSessionInfo: () => set(() => ({ ...initialStates })),
  })),
);

export const useSessionStore = <T = State & Actions>(
  selector: (state: State & Actions) => T = state => state as T,
): T => useStore(SessionStore, selector);
