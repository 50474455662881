import React, { useState } from 'react';
import styled from 'styled-components';

import { Color } from '../../../../theme/primitives';
import { useCheckpointsContext } from '../hooks';

import { CheckpointMarkerTip } from './CheckpointMarkerTip';
import { CheckpointMarkerLabel } from './CheckpointMarkerLabel';

export interface CheckpointMarkerProps {
  xPosition: number;
  label: string;
  id: string;
  isRequested: boolean;
}

export const CheckpointMarker: React.FC<CheckpointMarkerProps> = ({
  xPosition,
  label,
  id,
  isRequested,
}) => {
  const {
    checkpointClickActionLabel,
    hoveredMarkerId,
    handleMarkerHighlight,
  } = useCheckpointsContext();

  const [isMarkerHovered, setIsMarkerHovered] = useState(false);
  const [isMarkerTipHovered, setIsMarkerTipHovered] = useState(false);

  const handleMarkerHoverIn = () => {
    setIsMarkerHovered(true);
    handleMarkerHighlight?.(id);
  };
  const handleMarkerHoverOut = () => {
    setIsMarkerHovered(false);
    handleMarkerHighlight?.(null);
  };

  const handleMarkerTipHover = () => {
    setIsMarkerTipHovered(isMarkerTipHovered => !isMarkerTipHovered);
  };

  const markerLabel = isMarkerTipHovered
    ? `${checkpointClickActionLabel} – ${label}`
    : label;

  const isMarkerHighlighted =
    (isMarkerHovered && hoveredMarkerId === id) || hoveredMarkerId === null;

  return (
    <HoverArea
      onMouseEnter={handleMarkerHoverIn}
      onMouseLeave={handleMarkerHoverOut}
      isRequested={isRequested}
      isHighlighted={isMarkerHighlighted}
    >
      {isMarkerHovered && (
        <CheckpointMarkerLabel xPosition={xPosition} label={markerLabel} />
      )}
      <CheckpointMarkerTip
        xPosition={xPosition}
        id={id}
        onHover={handleMarkerTipHover}
        isHovered={isMarkerTipHovered}
        isRequested={isRequested}
      />
      <DashedLine xPosition={xPosition} />
    </HoverArea>
  );
};

const HoverArea = styled.div<{ isRequested: boolean; isHighlighted: boolean }>`
  width: 8px;
  height: calc(100% + 8px);

  transition: opacity 0.25s;

  opacity: ${({ isHighlighted }) => (isHighlighted ? 1 : 0.4)};
`;

const DashedLine = styled.div<{
  xPosition: number;
}>`
  position: absolute;
  width: 1px;
  padding: 0 4px;
  top: 0;
  bottom: 0;
  left: ${({ xPosition }) => `${xPosition}px`};
  background-image: linear-gradient(
    to bottom,
    ${Color.grey500} 50%,
    transparent 0%
  );
  background-position: center;
  background-size: 1px 10px;
  background-repeat: repeat-y;

  ::after {
    content: '';
    display: block;
    border: 1px solid ${Color.grey500};
    border-radius: 100%;
    width: 6px;
    height: 6px;
    position: absolute;
    bottom: 100%;
    transform: translateX(calc(-50% + 0.5px));
  }

  &:hover {
    ::after {
      border-color: ${Color.blue500};
      outline: 3px solid ${Color.blue100};
    }
  }
`;
