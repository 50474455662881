import React from 'react';
import styled from 'styled-components';

import CalendarContext, {
  CalendarConfig,
  defaultCalendarConfig,
} from '../../atoms/Calendar/CalendarContext';
import DateRangePicker from '../../atoms/DateRangePicker/DateRangePicker';

import { FocusState } from './constants';

const DateRangePickerContainer = styled.div`
  min-width: 450px;
  width: 100%;
`;

export interface DateRangePickerCalendarProps {
  startDate: Date | null;
  endDate: Date | null;
  weekdayFormat?: string;
  minimumLength?: number;
  maximumLength?: number;
  focus: FocusState;
  setStartDate: (value: Date | null) => void;
  setEndDate: (value: Date | null) => void;
  handleFocusChange: (value: FocusState) => void;
  onApply: (start: Date, end: Date, granularity: number | null) => void;
  currentFormat: '12' | '24';
  setCurrentFormat: (value: '12' | '24') => void;
  granularityItems: number[];
  granularity: number;
  defaultGranularity: number;
  onGranularityChange: (value: number | null) => void;
  defaultTimePeriod: number;
  timePeriodState: number | null;
  setTimePeriodState: (value: number | null) => void;
  minimumDate?: Date;
  maximumDate?: Date;
  calendarConfig?: CalendarConfig;
  showGranularity?: boolean;
}

/**
 * The actual range of times is 8,640,000,000,000,000 milliseconds to either side of 01 January, 1970 UTC.
 */
const MIN_DATE = -8640000000000000;
const MAX_DATE = 8640000000000000;

export const DateRangePickerCalendar: React.FC<DateRangePickerCalendarProps> = ({
  startDate,
  endDate,
  weekdayFormat = 'eeeeee',
  minimumLength = 0,
  maximumLength = 0,
  focus,
  setStartDate,
  setEndDate,
  handleFocusChange,
  onApply,
  currentFormat,
  setCurrentFormat,
  defaultGranularity,
  granularityItems,
  granularity,
  onGranularityChange,
  defaultTimePeriod,
  timePeriodState,
  setTimePeriodState,
  minimumDate = new Date(MIN_DATE),
  maximumDate = new Date(MAX_DATE),
  calendarConfig = defaultCalendarConfig,
  showGranularity = true,
}) => (
  <CalendarContext.Provider value={calendarConfig}>
    <DateRangePickerContainer>
      <DateRangePicker
        month={new Date()}
        minimumDate={minimumDate}
        maximumDate={maximumDate}
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        focus={focus}
        onFocusChange={handleFocusChange}
        weekdayFormat={weekdayFormat}
        minimumLength={minimumLength}
        maximumLength={maximumLength}
        onApply={onApply}
        currentFormat={currentFormat}
        setCurrentFormat={setCurrentFormat}
        granularityItems={granularityItems}
        granularity={granularity}
        defaultGranularity={defaultGranularity}
        onGranularityChange={onGranularityChange}
        defaultTimePeriod={defaultTimePeriod}
        timePeriodState={timePeriodState}
        setTimePeriodState={setTimePeriodState}
        showGranularity={showGranularity}
      />
    </DateRangePickerContainer>
  </CalendarContext.Provider>
);
