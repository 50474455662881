import ApiClient from 'shared/api/apiClient';

const switchUser = async (email: string) =>
  ApiClient().patch('/auth/enterprise/switch_accounts', {
    email,
  });

const fetchAvailableAccounts = async () =>
  ApiClient().get('/auth/enterprise/allowed_accounts');

const getLoginOptions = async (org: string) =>
  ApiClient().get(`/org/${org}/login_options`);

const addFavorite = async (email: string) =>
  ApiClient().post('/auth/enterprise/favorite_account', {
    email,
  });

const searchAccount = async (searchTerm: string) =>
  ApiClient().get(
    `/auth/enterprise/allowed_accounts?q=${encodeURIComponent(searchTerm)}`,
  );

const deleteFavorite = async (email: string) =>
  ApiClient().delete(`/auth/enterprise/favorite_account/${email}`);

const Api = {
  switchUser,
  fetchAvailableAccounts,
  getLoginOptions,
  addFavorite,
  searchAccount,
  deleteFavorite,
};

export default Api;
