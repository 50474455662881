import React from 'react';

import { WidgetContainer, WidgetContainerProps } from '../WidgetContainer';

import { WidgetFilter } from './WidgetFilter';

export interface WidgetContainerWithFilterProps extends WidgetContainerProps {
  filterOptions: Array<{ value: string; label: string }>;
  onFilterOptionChange: (value: string) => void;
  selectedFilter: string;
  isEmpty?: boolean;
}

export const WidgetContainerWithFilter = ({
  children,
  filterOptions,
  onFilterOptionChange,
  selectedFilter,
  header,
  headerIndent,
  tooltip,
  tooltipDocsReference,
  isLoading,
  isEmpty,
  customLoadingIndicator,
  onTooltipFocus,
  onTooltipMouseOver,
}: WidgetContainerWithFilterProps) => {
  return (
    <WidgetContainer
      header={header}
      widgetUtility={
        <WidgetFilter
          filterOptions={filterOptions}
          onFilterOptionChange={onFilterOptionChange}
          selectedFilter={selectedFilter}
        />
      }
      tooltip={tooltip}
      tooltipDocsReference={tooltipDocsReference}
      headerIndent={headerIndent}
      isLoading={isLoading}
      customLoadingIndicator={customLoadingIndicator}
      onTooltipFocus={onTooltipFocus}
      onTooltipMouseOver={onTooltipMouseOver}
    >
      {children}
    </WidgetContainer>
  );
};
