import React from 'react';
import styled, { keyframes } from 'styled-components';

import { Button } from '../Button';
import { Icon } from '../Icon';
import { Color } from '../../theme/primitives';

export interface ActionToastProps {
  description?: string;
  saveButtonText?: string;
  closeButtonText?: string;
  closeOnSave?: boolean;
  canSave?: boolean;
  isSaving?: boolean;
  isOpen?: boolean;
  centerInFrame?: boolean;
  type?: 'regular' | 'danger';
  onSave?: () => void | Promise<void>;
  onClose?: () => void;
}

export const ActionToast: React.FC<ActionToastProps> = ({
  description,
  saveButtonText,
  closeButtonText,
  closeOnSave,
  canSave,
  isSaving,
  isOpen,
  centerInFrame = false,
  type = 'regular',
  onSave,
  onClose,
}) => {
  if (!isOpen) return null;

  const getSaveButtonText = () => {
    return isSaving ? 'Saving...' : saveButtonText;
  };

  const onSubmit = () => {
    if (onSave) onSave();
    if (onClose && closeOnSave) onClose();
  };

  return (
    <ToastsWrapper centerInFrame={centerInFrame}>
      <ToastContainer>
        <TextContainer>
          {type === 'regular' && (
            <IconContainer>
              <Icon icon="infoAlt" iconSize="18" fill={Color.white} />
            </IconContainer>
          )}
          <ToastText>{description}</ToastText>
        </TextContainer>
        <ActionsContainer>
          <RegularButton data-cy="save" onClick={onClose} variant="outlined">
            {closeButtonText}
          </RegularButton>

          <RoundedButton
            data-cy="save"
            onClick={onSubmit}
            disabled={!canSave || isSaving}
            variant={type === 'danger' ? 'danger' : 'contained'}
          >
            {getSaveButtonText()}
          </RoundedButton>
          {/* <ToastCloseBtn onClick={onClose}>{getSaveButtonText()}</ToastCloseBtn> */}
        </ActionsContainer>
      </ToastContainer>
    </ToastsWrapper>
  );
};

const RegularButton = styled(Button)`
  border-radius: 8px;
  border: 1px solid ${Color.white};
  background-color: transparent;
  color: ${Color.white};

  &:hover {
    background-color: ${Color.white};
    color: ${Color.blue700};
  }
`;

const RoundedButton = styled(Button)`
  border-radius: 8px;
`;

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  50%  {
    transform: translateY(-10%);
  }
  to {
    transform: translateY(0);
  }
`;

const ToastsWrapper = styled.div<{ centerInFrame?: boolean }>`
  position: ${props => !props.centerInFrame && 'fixed'};
  bottom: 20px;
  z-index: 10000;
  left: 0;
  transform: ${props =>
    !props.centerInFrame && 'translateX(calc(50vw - 50%));'};
  padding: 0px 10px;
`;

const ToastContainer = styled.div`
  border: 2px solid transparent;
  background-color: ${Color.grey700};
  border-radius: 4px;
  max-width: 530px;
  min-height: 56px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.16);
  margin-top: 10px;
  display: flex;
  position: relative;
  cursor: pointer;
  animation: ${slideIn} 0.7s ease-in-out;
  box-sizing: border-box;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

const TextContainer = styled.div`
  align-items: center;
  display: flex;
`;

const IconContainer = styled.div`
  margin-left: 15px;
  background-color: ${Color.blue400};
  border-radius: 100%;
  padding: 1px;
`;

const ToastText = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  min-width: 209px;
  color: ${Color.white};
  padding: 5px 20px 5px 15px;
`;

const ActionsContainer = styled.span`
  display: flex;
  gap: 10px;
  padding: 5px 7.5px;
`;

ActionToast.defaultProps = {
  description: 'You have unsaved changes',
  saveButtonText: 'Save changes',
  closeButtonText: 'Cancel',
  closeOnSave: true,
  canSave: true,
  isSaving: false,
  isOpen: false,
  onSave: undefined,
  onClose: undefined,
};
