import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Color } from '../../../theme/primitives';

import { useSelectInteraction } from './context/ContextSelectInteraction';

interface SelectInteractionLayerProps {
  width: number;
  height: number;
  className?: string;
  tooltip?: React.ReactNode;
  tooltipRenderer?: (value: number | null) => JSX.Element;
  showMarker?: boolean;
  markerWidth?: number;
  markerValueDisplay?: React.ReactNode;
  selectedMarkerDisplay?: React.ReactNode;
  onSelectDataPoint?: (data: any) => void;
  selectedDataPoint?: any;
}

export const SelectInteractionLayer: React.FC<SelectInteractionLayerProps> = ({
  width,
  height,
  className,
  markerWidth = 30,
  selectedMarkerDisplay,
}) => {
  const selectInteractionContext = useSelectInteraction();
  const [selectInteractionState] = selectInteractionContext || [];

  const groupRef = useRef<HTMLDivElement>(null);

  const { x: value = null, width: boundingWidth = 0 } =
    selectInteractionState?.bounding || {};

  let position: null | number = null;

  if (value !== null) {
    position = value + boundingWidth / 2;
  }

  useEffect(() => {
    if (groupRef.current) {
      if (position !== null) {
        groupRef.current.style.opacity = '1';
        groupRef.current.style.transform = `translateX(${position}px)`;
      } else {
        groupRef.current.style.opacity = '0';
      }
    }
  }, [position]);

  if (!selectInteractionContext) {
    return null;
  }

  return (
    <Container className={className} width={width} height={height}>
      <Group ref={groupRef}>
        {!!selectInteractionState && !!selectedMarkerDisplay && (
          <BottomMarker
            width={markerWidth}
            markerValueDisplay={selectedMarkerDisplay}
          />
        )}
      </Group>
    </Container>
  );
};

interface BottomMarkerProps {
  width: number;
  markerValueDisplay: React.ReactNode;
}

const BottomMarker: React.FC<BottomMarkerProps> = ({
  width,
  markerValueDisplay,
}) => {
  return (
    <BottomMarkerContainer width={width}>
      {markerValueDisplay}
    </BottomMarkerContainer>
  );
};

const Group = styled.div`
  position: absolute;
  height: 100%;
  left: 0px;
  top: 0px;

  will-change: transform, opacity;
`;

const Container = styled.div<{
  width: number;
  height: number;
}>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  position: relative;
  pointer-events: none;
`;

const BottomMarkerContainer = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  position: absolute;
  bottom: -30px;
  left: ${({ width }) => -((width + 20) / 2)}px;
  background-color: ${Color.grey900};
  padding: 2.5px 10px 2px 10px;
  height: 20px;
  border-radius: 12px;
  z-index: -1;
  color: ${Color.white};
`;
