import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import { Color, Size } from '../../theme/primitives';
import { Icon } from '../../atoms/Icon';
import { Button } from '../../atoms/Button';
import Text from '../../atoms/Text';

interface NotificationBannerProps {
  className?: string;
  iconSize?: string;
  description?: string;
  cancelButtonTitle?: string;
  submitButtonTitle: string;
  cancelButtonTestId?: string;
  submitButtonTestId?: string;
  onCancel?: () => void;
  onSubmit: () => void;
}

const NotificationBanner: React.FC<NotificationBannerProps> = ({
  className = '',
  iconSize = '30',
  description,
  cancelButtonTitle,
  submitButtonTitle,
  cancelButtonTestId,
  submitButtonTestId,
  onCancel,
  onSubmit,
}) => {
  return (
    <Container className={className}>
      <>
        <BannerIcon icon="lightningStrikeLight" iconSize={iconSize} />
        <Description variant="default" title={description}>
          {description}
        </Description>
      </>
      <ActionsContainer>
        {cancelButtonTitle && onCancel && (
          <CancelButton
            variant="link"
            title={cancelButtonTitle}
            data-testid={cancelButtonTestId}
            onClick={onCancel}
          >
            <ButtonText variant="default">{cancelButtonTitle}</ButtonText>
          </CancelButton>
        )}
        <SubmitButton
          variant="link"
          title={submitButtonTitle}
          data-testid={submitButtonTestId}
          onClick={onSubmit}
        >
          <ButtonText variant="default">{submitButtonTitle}</ButtonText>
          <ButtonIcon icon="arrowRightAlternate" iconSize="24" />
        </SubmitButton>
      </ActionsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Color.blue400};
  border: 1px solid ${Color.blue400};
  border-radius: 4px;
  margin-bottom: 32px;
  padding: 16px;

  @media (min-width: ${Size.mobile}) {
    flex-direction: row;
    justify-content: flex-start;
    padding: 16px 24px;
  }
`;

const overflowStyles = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const Description = styled(Text)`
  ${overflowStyles};
  margin: 8px 0 16px;
  color: ${Color.white};
  flex-grow: 1;
  text-align: center;

  @media (min-width: ${Size.mobile}) {
    margin: 0 0 0 16px;
    text-align: left;
  }
`;

const BannerIcon = styled(Icon)<{
  iconSize?: string;
}>`
  min-width: ${({ iconSize }) => `${iconSize}px`};
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const hoverStyles = css`
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
  color: ${Color.blue100};
`;

const ButtonText = styled(Text)`
  ${overflowStyles};
  font-weight: 700;
  color: ${Color.white};
`;

const CancelButton = styled(Button)`
  padding: 0;
  min-width: 100px;

  &:hover,
  &:focus {
    ${ButtonText} {
      ${hoverStyles};
    }
  }
`;

const ButtonIcon = styled(Icon)`
  color: ${Color.white};
`;

const SubmitButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0;
  cursor: pointer;
  min-width: 105px;

  &:hover,
  &:focus {
    ${ButtonIcon},
    ${ButtonText} {
      ${hoverStyles};
    }
  }
`;

export default memo(NotificationBanner);
