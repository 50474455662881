import React from 'react';
import styled from 'styled-components';

import { Color, Size } from '../../../theme/primitives';
import { SortDirection } from '../../../constants';
import { Icon } from '../../../atoms/Icon';

export interface SortIconProps {
  direction?: SortDirection;
}

export const SortIcon: React.FC<SortIconProps> = ({ direction }) => (
  <StyledIcon icon="sort" iconSize="12" direction={direction} />
);

const StyledIcon = styled(Icon)<{
  direction?: SortDirection;
}>`
  margin-left: ${Size.space100};
  path:last-of-type {
    stroke: ${Color.grey300};
  }
  path:first-of-type {
    stroke: ${Color.grey300};
  }

  ${({ direction }) => {
    switch (direction) {
      case SortDirection.ASC:
        return `
          path:first-of-type {
            stroke: ${Color.grey600};
          }
        `;
      case SortDirection.DESC:
        return `
          path:last-of-type {
            stroke: ${Color.grey600};
          }
        `;
      default:
        return `
        `;
    }
  }};
`;
