/* eslint-disable import/prefer-default-export */
import { useContext } from 'react';

import { ChartAreaContext } from '../../../charts/atoms/ChartAreaProvider/ChartAreaContext';
import { ColumnBounding } from '../types';
import { AXIS_X_HEIGHT, AXIS_Y_WIDTH } from '../constants';

import { useGroupedBarGraphContext } from './useGroupedBarGraphContext';

export const useColumn = () => {
  const {
    data,
    groupPadding,
    columnSeries,
    width: graphWidth,
    height: graphHeight,
  } = useGroupedBarGraphContext();
  const { y: chartAreaY } = useContext(ChartAreaContext);

  // total padding amount discounted from canvas width (note: last group has no padding)
  const totalPadding = (groupPadding || 0) * (data.length - 1);

  // amount of padding discounted by column
  const paddingDiscount = totalPadding / data.length / columnSeries.length;

  // column width: (canvas width ÷ (n of series × n of data points)) - padding discount
  const columnWidth =
    (graphWidth - AXIS_Y_WIDTH) / (columnSeries.length * data.length) -
    paddingDiscount;

  // group width: n of columns × (column width + group padding)
  const groupWidth = columnSeries.length * columnWidth + (groupPadding || 0);

  // x values for each group (exported in this hook to be used for interaction)
  const groupXs: number[] = [];

  // get x, y, width, height and color for each column for each group
  const groupDrawData: {
    bounding: ColumnBounding;
    color: string;
  }[][] = data.map((dataItem, groupIndex) => {
    // get x position of current group and push to groupXs
    const groupX = groupWidth * groupIndex;
    groupXs.push(groupX);

    // map each serie to an object containing column boundings + color
    const columnDrawData = columnSeries.map((serie, serieIndex) => {
      // initialize empty bounding object for current column
      const bounding: ColumnBounding = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      };

      bounding.x = groupX + columnWidth * serieIndex;

      // column height: (serie value - y min ÷ (y max - y min)) × (canvas height)
      // (that is, ratio of serie value/y range × canvas height)
      const columnHeight =
        (((dataItem as any)[serie.name] - chartAreaY.min) /
          (chartAreaY.max - chartAreaY.min)) *
        (graphHeight - AXIS_X_HEIGHT);

      // bounding y: canvas height - column height (note: y = 0 is canvas top)
      bounding.y = graphHeight - AXIS_X_HEIGHT - columnHeight;

      bounding.width = columnWidth;
      bounding.height = columnHeight;

      return { bounding, color: serie.color };
    });

    return columnDrawData;
  });

  return { groupDrawData, groupXs, groupWidth };
};
