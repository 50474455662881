import { useContext } from 'react';

import { range } from '../../../utils/mathUtils';
import { useInteraction } from '../../../atoms/GraphInteractionLayer/GraphInteractionContext';
import { ChartAreaContext } from '../../../charts/atoms/ChartAreaProvider/ChartAreaContext';
import { AXIS_Y_WIDTH } from '../constants';
import { Serie } from '../types';

import { useColumn } from './useColumn';
import { useBarGraphContext } from './BarGraphContext';

// eslint-disable-next-line import/prefer-default-export
export const useBarGraphInteraction = () => {
  const {
    width: graphWidth,
    graphId,
    xProperty,
    yProperty,
  } = useBarGraphContext();
  const { x: chartAreaX } = useContext(ChartAreaContext);
  const { columnDrawData } = useColumn();
  const [interactionState] = useInteraction();

  if (!interactionState) {
    return null;
  }

  const getHoveredColumnIndex = () =>
    columnDrawData.findIndex(drawDataItem => {
      const { bounding } = drawDataItem.column;
      const { x = 0 } = interactionState;

      const left = bounding.x;
      const right = bounding.x + bounding.width;
      const markerPositionX = range(
        x,
        chartAreaX.min,
        chartAreaX.max,
        0,
        graphWidth - AXIS_Y_WIDTH,
      );

      return markerPositionX > left && markerPositionX <= right;
    });

  const hoveredColumnIndex = getHoveredColumnIndex();

  const getHoveredColumnData = () =>
    (columnDrawData[hoveredColumnIndex] as any).column.series.map(
      (serie: Serie) => ({
        label: serie.property,
        value: serie.value,
      }),
    );

  if (
    hoveredColumnIndex < 0 ||
    interactionState.x < chartAreaX.min ||
    interactionState.x >= chartAreaX.max
  ) {
    return null;
  }

  return {
    [xProperty]: (columnDrawData[getHoveredColumnIndex()] as any)[xProperty],
    [yProperty]: (columnDrawData[getHoveredColumnIndex()] as any).column.total,
    seriesData: getHoveredColumnData(),
    isActive: graphId === interactionState.graphId,
  };
};
