import { Size } from '../../theme/primitives';

const theme = {
  sidebarNavItem: {
    shape: {
      padding: `${Size.space200} ${Size.space400}`,
      borderRadius: Size.radius100,
    },
  },
};

export default theme;
