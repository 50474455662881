import { setLogger } from './tracker';

const ANALYTICS_TRACKING_API = 'https://a2.hcaptcha.com/api/event';
const PLAUSIBLE_SCRIPT = 'https://newassets.hcaptcha.com/js/p.js';

// "initTracker" function inits Plausible only, since Posthog
// is already being initializaed in the official library "PostHogProvider"
// check -> "/site/src/legacy/index.tsx" under the
// line calling "<PostHogProvider client={posthogConfig}>".
// This function also sets the Logger instance
const initTracker = (isProduction?: boolean, logger?: any) => {
  try {
    setLogger(logger);

    const currentLocation = window.location.href;
    const isA11y = currentLocation.includes('accessibility');

    if (isProduction && !isA11y) {
      window.plausible =
        window.plausible ||
        function addPlausibleQueue(...args: any[]) {
          (window.plausible.q = window.plausible.q || []).push(args);
        };

      const attributes = {
        'data-domain': window.location.hostname,
        ...{ 'data-api': ANALYTICS_TRACKING_API },
      };

      const script = document.createElement('script');
      script.src = PLAUSIBLE_SCRIPT;
      script.async = true;

      Object.entries(attributes).forEach(([attributeName, attributeValue]) => {
        if (attributeName && attributeValue)
          script.setAttribute(attributeName, attributeValue);
      });

      document.body.appendChild(script);
    }
  } catch (error) {
    logger?.error(
      `[Analytics]: Couldn't inject the plausible script: ${error}`,
    );
  }
};

export default initTracker;
