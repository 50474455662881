import React from 'react';
import styled from 'styled-components';

import { Color } from '../../theme/primitives';
import { KeyCode } from '../../constants';
import Text from '../Text';

type ValueType = string | number;

export interface ChainOptionSelectProps {
  className?: string;
  items?: {
    label: string;
    value: ValueType;
  }[];
  selectedItemValue: ValueType;
  isItemAutoWidth?: boolean;
  onChange?: (id: ValueType) => void;
}

const ChainOptionSelect: React.FC<ChainOptionSelectProps> = ({
  className = '',
  items,
  selectedItemValue,
  isItemAutoWidth,
  onChange,
}) => {
  const handleKeyDown = (event: React.KeyboardEvent, id: string | number) => {
    if (event.key === KeyCode.ENTER) {
      onChange?.(id);
    }
  };

  return (
    <Container className={className} isEvenWidth={!isItemAutoWidth}>
      {items?.map(({ value, label }) => (
        <Option
          key={value}
          variant="default"
          title={label}
          isEvenWidth={!isItemAutoWidth}
          evenWidth={100 / items?.length}
          isSelected={selectedItemValue === value}
          tabIndex={0}
          onClick={() => onChange?.(value)}
          onKeyDown={event => handleKeyDown(event, value)}
        >
          {label}
        </Option>
      ))}
    </Container>
  );
};

const Container = styled.div<{
  isEvenWidth?: boolean;
}>`
  display: inline-flex;
  width: ${({ isEvenWidth }) => (isEvenWidth ? '100%' : 'auto')};
  flex-direction: row;
  background-color: ${Color.white};
  border: 1px solid ${Color.grey600};
  border-radius: 8px;
  padding: 4px;
  overflow-x: auto;
`;

const Option = styled(Text)<{
  isSelected?: boolean;
  isEvenWidth?: boolean;
  evenWidth?: number;
}>`
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
  font-weight: ${({ isSelected }) => (isSelected ? 500 : 400)};
  color: ${({ isSelected }) => (isSelected ? Color.white : Color.grey600)};
  background-color: ${({ isSelected }) =>
    isSelected ? Color.blue400 : Color.white};
  z-index: ${({ isSelected }) => (isSelected ? 1 : 0)};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 8px 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: ${({ isEvenWidth }) => (isEvenWidth ? 'center' : 'left')};
  max-width: ${({ isEvenWidth }) => (isEvenWidth ? '' : '150px')};
  width: ${({ isEvenWidth, evenWidth }) =>
    isEvenWidth ? `${evenWidth}%` : 'auto'};
  min-width: 70px;

  & + & {
    margin-left: 8px;
  }

  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? Color.blue400 : Color.blue200};
  }
`;

export default ChainOptionSelect;
