import { useContext } from 'react';

import { CoordinatesContext } from '../contexts/CanvasCoordinates';
import { Coordinates } from '../constants';

export const useCanvasCoordinates: () => [
  Coordinates | null,
  React.Dispatch<React.SetStateAction<Coordinates | null>>,
] = () => {
  const coordinatesContext = useContext(CoordinatesContext);

  if (coordinatesContext === null) {
    throw new Error(
      'useCanvasCoordinates must be wrapped under CoordinatesContext',
    );
  }

  return coordinatesContext;
};
