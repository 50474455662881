import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import styled from 'styled-components';

import { Color, Size } from '../../theme/primitives';
import { IconProp } from '../../atoms/Icon';
import Textarea from '../../atoms/Textarea';
import Text from '../../atoms/Text';
import { Tooltip } from '../../atoms/Tooltip';
import { InfoButton } from '../InfoButton';
import { OptionsListVariant } from '../OptionsList/theme';
import List from '../OptionsList';

import Tag from './components/Tag';
import ConditionControls from './components/ConditionControls';

const KeyCodes = {
  enter: 'Enter',
};
const delimiters = [KeyCodes.enter];

export interface TagInterface {
  name: string;
  label?: string;
  icon?: IconProp;
  isValid?: boolean;
  validationMessage?: string;
  operator?: string;
  value?: string;
  tag?: string;
  isValueRequired?: boolean;
  badge?: React.ReactNode;
}

export interface PredefinedOptionInterface {
  name: string;
  label?: string;
  icon?: IconProp;
  operators?: string[];
  values?: string[];
  isValueRequired?: boolean;
  badge?: React.ReactNode;
}

export interface OutputInterface {
  conditionControl?: string;
  tags: TagInterface[];
}

export interface TagInputProps {
  id?: string;
  inputHeight?: number;
  placeholder?: string;
  label?: string;
  tooltip?: string;
  selectedTags?: TagInterface[];
  predefinedOptions?: PredefinedOptionInterface[];
  conditionControls?: string[];
  isDisabled?: boolean;
  className?: string;
  width?: number;
  optionsListWidth?: number;
  onChange?: (data: OutputInterface) => void;
  isAlwaysEditView?: boolean;
  validator?: (value: string) => boolean;
  validationMessage?: string;
  isError?: boolean;
  errorMessage?: string;
  additionalInfoNode?: React.ReactNode;
  onBlur?: (data: OutputInterface) => void;
  tooltipText?: string;
  initialConditionControl?: string;
}

const DEFAULT_WIDTH = 300;

export const TagInput: React.FC<TagInputProps> = ({
  id = 'tag-input',
  inputHeight = 40,
  placeholder,
  label,
  tooltip,
  selectedTags,
  predefinedOptions = [],
  conditionControls = [],
  onChange,
  isDisabled,
  className = '',
  width,
  optionsListWidth,
  isAlwaysEditView,
  validator,
  validationMessage,
  isError,
  errorMessage,
  additionalInfoNode,
  onBlur,
  tooltipText = '',
  initialConditionControl,
}) => {
  const [outputTags, setOutputTags] = useState(selectedTags || []);
  const [newTag, setNewTag] = useState('');
  const [newTagInputHeight, setNewTagInputHeight] = useState(inputHeight);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedConditionControl, setSelectedConditionControl] = useState(
    initialConditionControl || conditionControls[0] || '',
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const actionsContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!initialConditionControl) {
      return;
    }

    setSelectedConditionControl(initialConditionControl);
  }, [initialConditionControl]);

  useEffect(() => {
    if (!selectedTags) {
      return;
    }

    setOutputTags(selectedTags);
  }, [selectedTags]);

  useLayoutEffect(() => {
    const autoExpandTextarea = () => {
      if (!inputRef.current) {
        return;
      }

      inputRef.current.style.height = `${inputHeight}px`;
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;

      if (newTagInputHeight !== inputRef.current.scrollHeight) {
        setNewTagInputHeight(inputRef.current.scrollHeight);
      }
    };

    autoExpandTextarea();
  }, [newTag, newTagInputHeight, inputHeight]);

  const escapeRegExp = (dataString = '') => {
    // $& means the whole matched string
    return dataString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const isTagMatch = useCallback(
    (inputTag: string, optionTag: TagInterface) => {
      const { label, operator = '', value = '' } = optionTag;
      let labelOperatorRegex = new RegExp(
        `^\\s*${escapeRegExp(label)}\\s*${escapeRegExp(operator)}\\s*`,
        'i',
      );
      const operatorValueRegex = new RegExp(
        `${escapeRegExp(operator)}\\s*${escapeRegExp(value)}\\s*$`,
      );

      if (value) {
        return (
          labelOperatorRegex.test(inputTag) && operatorValueRegex.test(inputTag)
        );
      }

      if (!operator) {
        labelOperatorRegex = new RegExp(
          `^\\s*${escapeRegExp(label)}\\s*$`,
          'i',
        );
      }

      return labelOperatorRegex.test(inputTag);
    },
    [],
  );

  const isTagDisplayed = useCallback(
    (tag = '') => outputTags.find(outputTag => isTagMatch(tag, outputTag)),
    [outputTags, isTagMatch],
  );

  const possibleOptions = useMemo(() => {
    return predefinedOptions.reduce(
      (
        resultOptions: TagInterface[],
        {
          name,
          label: tagLabel = '',
          icon,
          badge,
          operators = [],
          values = [],
          isValueRequired = true,
        },
      ) => {
        const label = tagLabel || name;

        if (!operators.length) {
          resultOptions.push({
            name,
            label,
            icon,
            badge,
            operator: '',
            value: '',
            tag: label,
            isValueRequired: false,
          });
        }

        operators.forEach(operator => {
          resultOptions.push({
            name,
            label,
            icon,
            badge,
            operator,
            value: '',
            tag: `${label} ${operator} `,
            isValueRequired: !operator.includes('exist'),
          });

          values.forEach(value => {
            resultOptions.push({
              name,
              label,
              icon,
              badge,
              operator,
              value,
              tag: `${label} ${operator} ${value}`,
              isValueRequired: !operator.includes('exist'),
            });
          });
        });

        return resultOptions;
      },
      [],
    );
  }, [predefinedOptions]);

  const getTagOption = useCallback(
    (tagToAdd: string) => {
      if (!possibleOptions.length && tagToAdd) {
        return {
          name: tagToAdd,
          value: tagToAdd,
          tag: tagToAdd,
        };
      }

      const matchedOptions = possibleOptions.filter(possibleOption =>
        isTagMatch(tagToAdd, possibleOption),
      );

      if (matchedOptions.length > 1) {
        return matchedOptions.reduce(
          (resultOption: TagInterface, matchedOption: TagInterface) => {
            const matchedOptionLength = Number(matchedOption?.tag?.length);
            const resultOptionLength = Number(resultOption?.tag?.length);

            if (matchedOptionLength > resultOptionLength) {
              return matchedOption;
            }

            return resultOption;
          },
        );
      }

      return matchedOptions[0];
    },
    [possibleOptions, isTagMatch],
  );

  const handleTagChange = useCallback(
    (tag?: string, callback?: Function) => {
      const tagToAdd = tag || newTag;

      const option = getTagOption(tagToAdd);

      if (!option || isTagDisplayed(tagToAdd)) {
        callback?.({
          conditionControl: selectedConditionControl,
          tags: outputTags,
        });

        return;
      }

      const [, value] = tagToAdd.split(
        new RegExp(
          `^\\s*${escapeRegExp(option.label)}\\s*${escapeRegExp(
            option.operator,
          )}\\s*`,
          'i',
        ),
      );
      const formattedValue = option.value?.trim() || value.trim();

      if (option.isValueRequired && !formattedValue) {
        callback?.({
          conditionControl: selectedConditionControl,
          tags: outputTags,
        });

        return;
      }

      const isValidOption = validator ? validator(formattedValue) : true;

      const output = getUniqueTags([
        ...outputTags,
        {
          ...option,
          tag: !predefinedOptions?.length
            ? option.tag
            : `${option.tag}${formattedValue}`,
          value: formattedValue,
          isValid: isValidOption,
        },
      ]);

      setNewTag('');
      setOutputTags(output);

      const tagOutput = {
        conditionControl: selectedConditionControl,
        tags: output,
      };

      onChange?.(tagOutput);
      callback?.(tagOutput);
    },
    [
      isTagDisplayed,
      getTagOption,
      validator,
      newTag,
      onChange,
      outputTags,
      selectedConditionControl,
      predefinedOptions?.length,
    ],
  );

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!containerRef.current) {
        return;
      }

      const isContainerClicked = containerRef.current.contains(
        event.target as Node,
      );
      const isClickedOutside =
        !isContainerClicked ||
        (isContainerClicked && event.target === actionsContainerRef.current);

      if (isClickedOutside) {
        if (isEditMode) {
          handleTagChange('', (output: OutputInterface) => {
            onBlur?.(output);
          });
        } else {
          handleTagChange();
        }

        setIsEditMode(false);
      }
    }

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [containerRef, handleTagChange, onBlur, isEditMode]);

  const getFormattedText = (text = '') => text.replace(/\s/g, '').toLowerCase();

  const isNewTagIncludedInPossibleOption = ({
    tag: possibleTag = '',
    label = '',
    operator = '',
    value,
  }: TagInterface) => {
    const formattedNewTag = getFormattedText(newTag);
    const isNewTagWithOperator = newTag.includes(operator);
    let isMatch = getFormattedText(label).includes(formattedNewTag);

    // there is no new tag or the possible option is without the operator
    //  => show the option without the value that contains the new tag (label covered)
    if (!newTag || !operator) {
      return isMatch && !value;
    }

    const [labelOperator] = possibleTag.replace(/\s/g, '').split(operator);
    isMatch = getFormattedText(labelOperator).includes(formattedNewTag);

    // the new tag is without the operator
    //   => show the option without the value that contains the new tag (label + operator covered)
    if (!isNewTagWithOperator) {
      return !newTag || (isMatch && !value);
    }

    isMatch = getFormattedText(possibleTag).includes(formattedNewTag);

    // the new tag is without the operator
    //   => show the option with the value that contains the new tag (label + operator + value covered)
    return isMatch && value;
  };

  const displayedOptions = possibleOptions
    .filter(
      possibleOption =>
        !isTagDisplayed(possibleOption.tag) &&
        isNewTagIncludedInPossibleOption(possibleOption),
    )
    .sort((o1, o2) => ((o1.label || '') < (o2.label || '') ? 1 : 0));

  const handleRemoveTag = (tagToRemove: string) => {
    const output = outputTags.filter(({ tag }) => tag !== tagToRemove);

    setOutputTags(output);

    if (onChange) {
      onChange({
        conditionControl: selectedConditionControl,
        tags: output,
      });
    }
  };

  const handleDisplayedTagClick = (tag: string) => {
    setNewTag(tag);
    handleRemoveTag(tag);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const { key } = event;

    if (delimiters.includes(key)) {
      event.preventDefault();

      handleTagChange(newTag);
    }
  };

  const getUniqueTags = (tagItems: TagInterface[] = []) =>
    Array.from(
      new Map(tagItems.map(tagItem => [tagItem.tag, tagItem])).values(),
    );

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewTag(event.target.value);
  };

  const handleConditionControlChange = (conditionControl = '') => {
    setSelectedConditionControl(conditionControl);

    if (onChange) {
      onChange({
        conditionControl,
        tags: outputTags,
      });
    }
  };

  const setEditMode = () => setIsEditMode(true);

  const handleContainerClick = (event: React.MouseEvent) => {
    if (isDisabled || event.target === actionsContainerRef.current) {
      return;
    }

    inputRef.current?.focus();
    setEditMode();
  };

  const handleOptionClick = (tag = '') => {
    const option = possibleOptions.find(
      ({ tag: possibleTag }) => possibleTag === tag,
    );

    if (!option) {
      return;
    }

    if (!option.isValueRequired || option.value) {
      handleTagChange(tag);

      return;
    }

    setNewTag(tag);
  };

  const containerEl = (
    <Container
      isError={isError}
      isDisabled={isDisabled}
      inputHeight={newTagInputHeight}
      isEditMode={isEditMode || isAlwaysEditView}
      width={width}
    >
      {isEditMode || isAlwaysEditView ? (
        <>
          {!!outputTags.length && (
            <TagsContainer>
              {outputTags?.map(
                ({
                  tag = '',
                  label = '',
                  operator = '',
                  value = '',
                  isValid = true,
                  validationMessage: tagValidationMessage,
                }) => (
                  <Tag
                    isValid={isValid}
                    key={`${tag}${label}`}
                    tag={tag}
                    label={label}
                    operator={operator}
                    value={value}
                    maxTagWidth={`calc(${width || DEFAULT_WIDTH}px - 95px)`}
                    onRemove={handleRemoveTag}
                    onClick={handleDisplayedTagClick}
                    validationMessage={
                      tagValidationMessage || validationMessage
                    }
                  />
                ),
              )}
            </TagsContainer>
          )}
          <AutocompleteContainer>
            <OptionsList
              variant={OptionsListVariant.BASE}
              width={optionsListWidth || width || DEFAULT_WIDTH}
              position="bottom"
              options={displayedOptions?.map(({ tag = '', icon, badge }) => ({
                name: tag,
                label: tag,
                value: tag,
                icon,
                badge,
              }))}
              additionalInfoNode={additionalInfoNode}
              open={!!displayedOptions.length}
              onChange={handleOptionClick}
            >
              {isEditMode && (
                <NewTagInput
                  rows={1}
                  placeholder={placeholder}
                  height={newTagInputHeight}
                  ref={inputRef}
                  autoFocus
                  disabled={isDisabled}
                  value={newTag}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                />
              )}
            </OptionsList>
          </AutocompleteContainer>
        </>
      ) : (
        <ReadableTagsContainer>
          {outputTags?.map(
            ({ tag = '', label = '', operator = '', value = '' }) => (
              <TagTextBlock key={`${tag}${label}`} variant="default">
                {label}
                <Operator>{operator}</Operator>
                {value}
              </TagTextBlock>
            ),
          )}
        </ReadableTagsContainer>
      )}
    </Container>
  );

  return (
    <TagInputContainer
      id={id}
      className={className}
      ref={containerRef}
      onClick={handleContainerClick}
    >
      {label || tooltip || !!conditionControls.length ? (
        <ActionsContainer ref={actionsContainerRef} width={width}>
          {label && (
            <Title>
              <Label>{label}</Label>
              {tooltip && (
                <TooltipWrapper>
                  <InfoButton text={tooltip} />
                </TooltipWrapper>
              )}
            </Title>
          )}
          {!!conditionControls.length && (
            <ConditionControls
              selectedConditionControl={selectedConditionControl}
              conditionControls={conditionControls}
              isEditMode={isEditMode}
              onChange={handleConditionControlChange}
              onViewModeConditionControlClick={setEditMode}
            />
          )}
        </ActionsContainer>
      ) : null}
      {tooltipText ? (
        <Tooltip text={tooltipText} maxWidth={240}>
          {containerEl}
        </Tooltip>
      ) : (
        containerEl
      )}
      {isError && !!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </TagInputContainer>
  );
};

const TagInputContainer = styled.div`
  display: inline-block;
`;

const ActionsContainer = styled.div<{
  width?: number;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  margin-bottom: 16px;
`;

const Title = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;

const TooltipWrapper = styled.div`
  margin-left: 10px;
`;

const Label = styled.label`
  display: inline-block;
  font-weight: 500;
  color: ${Color.grey600};
  font-size: ${Size.font200};
`;

const Container = styled.div<{
  isError?: boolean;
  isDisabled?: boolean;
  inputHeight?: number;
  width?: number;
  isEditMode?: boolean;
}>`
  min-height: ${({ inputHeight }) => `${inputHeight ? inputHeight + 2 : 42}px`};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  box-sizing: border-box;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  background-color: ${({ isEditMode }) =>
    isEditMode ? Color.white : Color.grey050};
  border-radius: 4px;
  border: 1px solid;
  border-color: ${({ isEditMode, isError }) => {
    if (isError) return `${Color.red400}!important`;

    return isEditMode ? Color.grey600 : Color.grey400;
  }};

  &:hover {
    border-color: ${Color.grey600};
  }
`;

const TagsContainer = styled.div`
  overflow: auto;
  padding: 8px;
  border-radius: 4px;
`;

const ReadableTagsContainer = styled.div`
  min-height: 40px;
  padding: 8px;
  box-sizing: border-box;
`;

const Operator = styled(Text)`
  font-weight: bold;
  margin: 0 5px;
`;

const TagTextBlock = styled(Text)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 4px;
  line-height: ${Size.font300};
  word-break: break-word;
`;

const AutocompleteContainer = styled.div`
  width: 100%;
  position: relative;
`;

const NewTagInput = styled(Textarea)<{
  height?: number;
}>`
  resize: none;
  width: 100%;
  height: ${({ height }) => `${height || 40}px`};
  border: none;
  outline: none;
  box-sizing: border-box;
  color: ${Color.grey600};
  padding: 10px 16px;
  line-height: ${Size.space400};
  background-color: transparent;
`;

const OptionsList = styled(List)`
  width: 100%;
  z-index: 10;
`;

const ErrorMessage = styled(Text)`
  color: ${Color.red400};
`;
