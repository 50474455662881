import styled from 'styled-components';

export const Name = styled.div`
  font-size: 10px;
  font-weight: 500;
  position: relative;
`;

export const Label = styled.div<{
  hideTicks?: boolean;
}>`
  font-size: 12px;
  font-weight: 500;
  position: absolute;

  ::after {
    display: ${({ hideTicks }) => (hideTicks ? 'none' : 'block')};
    content: '';
    background: ${({ theme }) => theme.axis.color.default.border};
    position: absolute;
  }
`;
