import { createContext } from 'react';

import { MouseEventContextState } from './contextMouseEventConstants';

type MouseEventContextValue = [
  MouseEventContextState | null,
  React.Dispatch<React.SetStateAction<MouseEventContextState | null>>,
];

export const MouseEventContext = createContext<MouseEventContextValue | null>(
  null,
);
