/* eslint-disable import/prefer-default-export */
import { useContext } from 'react';

import { range } from '../../../utils/mathUtils';
import { useInteraction } from '../../../atoms/GraphInteractionLayer/GraphInteractionContext';
import { ChartAreaContext } from '../../../charts/atoms/ChartAreaProvider/ChartAreaContext';
import { AXIS_Y_WIDTH } from '../constants';

import { useGroupedBarGraphContext } from './useGroupedBarGraphContext';
import { useColumn } from './useColumn';

export const useGroupedBarGraphInteraction = () => {
  const {
    data,
    width: graphWidth,
    columnSeries,
    xProperty,
    tooltipProperty,
    graphId,
  } = useGroupedBarGraphContext();
  const { x: chartAreaX } = useContext(ChartAreaContext);
  const { groupXs, groupWidth } = useColumn();
  const [interactionState] = useInteraction();

  if (!interactionState) {
    return null;
  }

  // get index of hovered group
  const getHoveredGroupIndex = () => {
    const { x: interactionX = 0 } = interactionState;

    // marker x: normalization of interactionX (from min/max of x axis to 0/canvas width)
    const markerPositionX = range(
      interactionX,
      chartAreaX.min,
      chartAreaX.max,
      0,
      graphWidth - AXIS_Y_WIDTH,
    );

    // find hovered group index in array containing all x values for groups
    return groupXs.findIndex(
      xValue =>
        markerPositionX >= xValue && markerPositionX <= xValue + groupWidth,
    );
  };

  const hoveredGroupIndex = getHoveredGroupIndex();

  // get hovered group data based on found index
  const getHoveredGroupData = () => {
    const groupData = data[hoveredGroupIndex];

    if (
      hoveredGroupIndex < 0 ||
      interactionState.x < chartAreaX.min ||
      interactionState.x >= chartAreaX.max
    ) {
      return null;
    }

    // traverse series to get series values for hovered group data
    const series = columnSeries.map(serie => ({
      label: serie.label,
      value: (groupData as any)[serie.name],
      color: serie.color,
    }));

    // return values for each serie along with group client score
    const groupWidgetData = {
      [xProperty]: (groupData as any)[xProperty] || 0,
      series,
    };

    return tooltipProperty
      ? {
          ...groupWidgetData,
          [tooltipProperty]: (groupData as any)[tooltipProperty] || 0,
        }
      : groupWidgetData;
  };

  return {
    // isActive is useful when multiple graphs share a single context
    isActive: interactionState?.graphId === graphId,
    widgetData: getHoveredGroupData(),
  };
};
