import React, { useCallback } from 'react';
import * as PIXI from 'pixi.js-legacy';
import * as ReactPIXI from '@inlet/react-pixi/legacy';

import { Column } from '../types';
import { Color } from '../../../theme/primitives';
import { AXIS_Y_OFFSET } from '../constants';

const { Graphics } = ReactPIXI;

export interface GraphColumnProps {
  drawData: Column;
  isHovered?: boolean;
}

export const GraphColumn: React.FC<GraphColumnProps> = ({
  drawData,
  isHovered,
}) => {
  const drawColumn = useCallback(
    graphics => {
      graphics.clear();

      const {
        x: rectX,
        y: rectY,
        width: rectWidth,
        height: rectHeight,
      } = drawData.bounding;

      graphics.beginFill(
        PIXI.utils.string2hex(!isHovered ? drawData.color : Color.grey300),
      );
      graphics.drawRect(
        rectX + AXIS_Y_OFFSET,
        rectY,
        -(AXIS_Y_OFFSET - rectWidth),
        rectHeight,
      );
      graphics.endFill();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [drawData],
  );

  return <Graphics draw={drawColumn} />;
};
