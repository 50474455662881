import React from 'react';
import styled from 'styled-components';

import Text from '../Text';

export type BreadCrumbType = {
  label: string | React.ReactNode;
  href: string;
};

export interface BreadCrumbsProps {
  items: BreadCrumbType[];
  separator?: React.ReactNode;
  className?: string;
  renderLink: ({
    href,
    children,
  }: {
    href: string;
    children: React.ReactNode;
  }) => JSX.Element;
}

export const BreadCrumbs: React.FC<BreadCrumbsProps> = ({
  items,
  separator,
  renderLink,
  className,
}) => {
  const separatorComponent = separator || (
    <Separator data-testid="breadcrumbs-separator">/</Separator>
  );
  return (
    <Container className={className}>
      {items.map((item, index) => (
        <React.Fragment key={item.href}>
          {index !== 0 ? separatorComponent : ''}
          {index === items.length - 1 ? (
            <Text variant="default">{item.label}</Text>
          ) : (
            renderLink({
              href: item.href,
              children: <Text variant="default">{item.label}</Text>,
            })
          )}
        </React.Fragment>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Separator = styled.div`
  margin: 0 5px;
`;
