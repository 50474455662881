import React from 'react';
import styled from 'styled-components';

import Text from '../Text';
import { Color } from '../../theme/primitives';
import { Icon } from '../Icon';

type ClassicRadioButtonProps = {
  className?: string;
  onChange: (value: string) => void;
  value: string;
  isChecked: boolean;
  label: React.ReactNode;
};

export const ClassicRadioButton: React.FC<ClassicRadioButtonProps> = ({
  className,
  onChange,
  value,
  isChecked,
  label,
}) => {
  return (
    <RadioButtonWrapper className={className} key={value}>
      <HiddenRadio
        type="radio"
        value={value}
        checked={isChecked}
        onChange={event => onChange(event.target.value)}
      />
      <Indicator isChecked={isChecked}>
        {isChecked ? <Icon icon="checkMark" iconSize="16" /> : null}
      </Indicator>
      <Label variant="default" isChecked={isChecked}>
        {label}
      </Label>
    </RadioButtonWrapper>
  );
};

const HiddenRadio = styled.input`
  display: none;
`;

const RadioButtonWrapper = styled.label`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
`;

const Label = styled(Text)<{ isChecked: boolean }>`
  color: ${Color.grey500};
  font-weight: ${({ isChecked }) => (isChecked ? '700' : '400')};
`;

const Indicator = styled.div<{ isChecked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid
    ${({ isChecked }) => (isChecked ? Color.blue400 : Color.grey400)};
  background-color: ${({ isChecked }) =>
    isChecked ? Color.blue400 : Color.transparent};
`;
