import { useContext } from 'react';

import { ChartAreaContext } from './ChartAreaContext';

export const useChartAreaContext = () => {
  const state = useContext(ChartAreaContext);

  if (!state) {
    return null;
  }

  return state;
};
