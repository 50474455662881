import React, { useMemo } from 'react';

import useId from '../../../utils/useId';
import { Line } from '../types';

export interface LineGraphState extends Required<LineGraphProviderProps> {
  /**
   * Graph ID
   */
  graphId: string;
}

type LineGraphProviderProps = {
  /**
   * Array of lines to display
   */
  lines: Line[];
  /**
   * Width of the container in px
   */
  width: number;
  /**
   * Height of the container in px
   */
  height: number;
};

const defaultState: LineGraphState = {
  graphId: '',
  lines: [],
  width: 1,
  height: 1,
};

const LineGraphContext = React.createContext<LineGraphState>(defaultState);

export const useLineGraphState = () => React.useContext(LineGraphContext);

export const LineGraphProvider: React.FC<LineGraphProviderProps> = ({
  lines,
  width,
  height,
  children,
}) => {
  const graphId = useId('line-graph');
  const state = useMemo(
    () => ({
      graphId,
      lines,
      width,
      height,
    }),
    [graphId, lines, width, height],
  );

  return (
    <LineGraphContext.Provider value={state}>
      {children}
    </LineGraphContext.Provider>
  );
};
