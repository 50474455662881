import { Size } from '../../theme/primitives';

const theme = {
  page: {
    shape: {
      padding: Size.space400,
    },
    layout: {
      gap: Size.space600,
    },
  },
};

export default theme;
