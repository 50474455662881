import React from 'react';
import {
  addMonths,
  startOfMonth,
  subMonths,
  format,
  isSameMonth,
} from 'date-fns';
import styled from 'styled-components';
import { enGB as locale } from 'date-fns/locale';

import { Color } from '../../theme/primitives';

const DatesNavigation = styled.div`
  align-items: center;
  color: #5c6f8a;
  display: flex;
  justify-content: space-between;
  position: relative;
  text-align: center;
  text-transform: capitalize;
`;

const DatesNavigationBtn = styled.a<{
  disable?: boolean;
  direction?: string;
}>`
  position: relative;
  background-color: #94a3b8;
  text-align: left;
  margin: 1.2em;
  transform: ${props =>
    `${
      props.direction === 'left'
        ? 'rotate(-30deg) skewX(-30deg) scale(1,.866)'
        : 'rotate(-90deg) skewX(-30deg) scale(1,.866)'
    }`};
  top: -2px;
  left: 0;
  cursor: ${({ disable }) => (disable ? 'default' : 'pointer')};
  pointer-events: ${({ disable }) => (disable ? 'none' : '')};

  &:hover {
    background: ${Color.grey600};
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: inherit;
  }

  &,
  &:before,
  &:after {
    width: 0.5rem;
    height: 0.5rem;
    border-top-right-radius: 30%;
  }

  &:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
      translate(0, -50%);
  }

  &:after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }
`;

const DatesNavigationCurrent = styled.span`
  flex-grow: 1;
  font-size: 14px;
  font-weight: 500;
`;

interface CalendarNavigationProps {
  locale: typeof locale;
  month: Date;
  minimumDate?: Date;
  onMonthChange?: (value: Date) => void;
  maximumDate?: Date;
}

const CalendarNavigation: React.FC<CalendarNavigationProps> = ({
  locale,
  month,
  minimumDate,
  onMonthChange,
  maximumDate,
}) => {
  const handlePrevious = (event: React.ChangeEvent<unknown>): void => {
    event.preventDefault();
    onMonthChange && onMonthChange(startOfMonth(subMonths(month, 1)));
  };

  const handleNext = (event: React.ChangeEvent<unknown>): void => {
    event.preventDefault();
    onMonthChange && onMonthChange(startOfMonth(addMonths(month, 1)));
  };

  return (
    <DatesNavigation>
      <DatesNavigationBtn
        direction="left"
        disable={minimumDate && isSameMonth(month, minimumDate)}
        onClick={handlePrevious}
        onTouchEnd={handlePrevious}
      />
      <DatesNavigationCurrent>
        {format(month, 'LLLL yyyy', { locale })}
      </DatesNavigationCurrent>
      <DatesNavigationBtn
        direction="right"
        disable={maximumDate && isSameMonth(month, maximumDate)}
        onClick={handleNext}
        onTouchEnd={handleNext}
      />
    </DatesNavigation>
  );
};

export default CalendarNavigation;
