import React, { cloneElement, KeyboardEvent, MouseEvent } from 'react';
import styled from 'styled-components';

import { Color } from '../../theme/primitives';
import Panel from '../Panel';
import Text from '../Text';
import { KeyCode } from '../../constants';
import { PillVariant } from '../Pill';

import { CardItemPill } from './components';

export interface CardItemProps {
  text: string;
  title: string;
  id?: string;
  label?: string;
  labelVariant?: PillVariant;
  avatar?: React.ReactNode;
  icon?: JSX.Element;
  ariaSelected?: boolean;
  ariaPosinset?: number;
  ariaSetsize?: number;
  onIconInteraction?: () => void;
  onIconFocus?: () => void;
  onIconBlur?: () => void;
  onSelect?: (event?: MouseEvent | KeyboardEvent) => void;
  className?: string;
}

export const CardItem: React.FC<CardItemProps> = ({
  id,
  title,
  label,
  labelVariant,
  text,
  avatar,
  icon,
  ariaSelected,
  ariaPosinset,
  ariaSetsize,
  onIconInteraction,
  onIconFocus,
  onIconBlur,
  onSelect,
  className,
}) => {
  const handleIconClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    onIconInteraction?.();

    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const handleIconKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();

    const targetElement = document?.querySelector?.(`#${id}`);

    if (event.key === KeyCode.ENTER && targetElement) {
      onIconInteraction?.();
      (targetElement as any).focus?.();
    }
  };

  const handleIconFocus = () => {
    onIconFocus?.();
  };

  const handleIconBlur = () => {
    onIconBlur?.();
  };

  const handleSelect = (event: MouseEvent | KeyboardEvent) => {
    if (
      event.type === 'click' ||
      (event as KeyboardEvent).key === KeyCode.ENTER
    ) {
      onSelect?.();
    }
  };

  return (
    <CardItemPanel
      className={className}
      tabIndex={0}
      role="option"
      aria-selected={ariaSelected}
      aria-posinset={ariaPosinset}
      aria-setsize={ariaSetsize}
      onClick={handleSelect}
      onKeyDown={handleSelect}
    >
      {avatar && <AvatarContainer>{avatar}</AvatarContainer>}
      <CardContent isAvatarRendered={Boolean(avatar)}>
        <CardTitle>{title}</CardTitle>
        <TopRightContainer>
          {label && <CardPill variant={labelVariant} text={label} />}
          {icon && (
            <IconWrapper
              role="button"
              onClick={handleIconClick}
              onKeyDown={handleIconKeyDown}
              onFocus={handleIconFocus}
              onBlur={handleIconBlur}
              tabIndex={0}
            >
              {cloneElement(icon, { id })}
            </IconWrapper>
          )}
        </TopRightContainer>
        <CardText>{text}</CardText>
      </CardContent>
    </CardItemPanel>
  );
};

const CardItemPanel = styled(Panel)`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 16px;

  padding: 16px;

  &:hover,
  &:focus {
    background-color: ${Color.grey100};
    border-color: ${Color.grey400};
    outline: none;
  }

  cursor: pointer;
`;

const AvatarContainer = styled.div`
  width: 32px;
  height: 32px;
`;

const CardContent = styled.div<{ isAvatarRendered: boolean }>`
  display: flex;
  flex-direction: column;

  position: relative;
  width: ${({ isAvatarRendered }) =>
    isAvatarRendered ? 'calc(100% - 32px)' : '100%'};
`;

const CardTitle = styled(Text)`
  font-weight: 700;
  text-transform: capitalize;

  max-width: calc(100% - 120px);
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TopRightContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  gap: 8px;
  max-width: 120px;
`;

const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  cursor: pointer;

  &:focus {
    outline: none;
    background-color: ${Color.grey100};
    border-radius: 50%;
    outline: 3px solid ${Color.grey100};
  }
`;

const CardText = styled(Text)`
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const CardPill = styled(CardItemPill)`
  max-width: 90px;
`;
