import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Panel from '../../atoms/Panel';
import { typography } from '../../theme/themeUtils';
import { InfoButton } from '../InfoButton';
import { Loader } from '../../atoms/Loader';

export interface WidgetContainerCommonProps {
  children: ReactNode;
  header: string | ReactNode;
  headerIndent?: number;
  tooltip?: string;
  tooltipDocsReference?: string;
  isLoading?: boolean;
  customLoadingIndicator?: ReactNode;
  onTooltipFocus?: () => void;
  onTooltipMouseOver?: () => void;
  className?: string;
}

export interface WidgetContainerProps extends WidgetContainerCommonProps {
  widgetUtility?: ReactNode;
}

export const WidgetContainer = ({
  children,
  header,
  headerIndent,
  widgetUtility,
  tooltip,
  tooltipDocsReference,
  isLoading,
  customLoadingIndicator,
  onTooltipFocus,
  onTooltipMouseOver,
  className,
}: WidgetContainerProps) => (
  <StyledPanel variant="percentageBarsWidget" className={className}>
    <WidgetInner>
      <WidgetHeader indent={headerIndent}>
        <HeaderContainer isUtilitySpecified={Boolean(widgetUtility)}>
          <Header>
            {header}
            {tooltip && (
              <InfoWrapper>
                <InfoButton
                  text={tooltip}
                  onFocus={onTooltipFocus}
                  onMouseOver={onTooltipMouseOver}
                  docsReference={tooltipDocsReference || undefined}
                />
              </InfoWrapper>
            )}
          </Header>
          {widgetUtility || null}
        </HeaderContainer>
      </WidgetHeader>
      {isLoading ? (
        <LoaderWrapper>
          {customLoadingIndicator || <Loader size="s" />}
        </LoaderWrapper>
      ) : (
        children
      )}
    </WidgetInner>
  </StyledPanel>
);

const Header = styled.div`
  display: flex;
  align-items: center;

  /* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
`;

const InfoWrapper = styled.div`
  margin-left: 10px;
`;

const HeaderContainer = styled.div<{ isUtilitySpecified?: boolean }>`
  display: grid;
  grid-template-columns: ${({ isUtilitySpecified }) =>
    isUtilitySpecified ? '2fr minmax(130px, 1fr);' : '1fr;'};
  grid-column-gap: 20px;
  align-items: center;
`;

const WidgetInner = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
`;

const StyledPanel = styled(Panel)`
  height: 100%;
  min-height: 100%;
`;

const WidgetHeader = styled.div`
  ${typography('h5')}
  color: ${({ theme }) => theme.linearProgress.color.default.filledBackground};
  margin-bottom: ${({ indent }: { indent?: number }) => `${indent || 20}px`};
`;

const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
