import React from 'react';
import styled from 'styled-components';

import { Icon } from '../../atoms/Icon';
import Text from '../../atoms/Text';
import { Size } from '../../theme/primitives';

export interface NotFoundScreenProps {
  title: string;
}

export const NotFoundStateLayout: React.FC<NotFoundScreenProps> = ({
  title,
}) => (
  <Container>
    <Icon icon="decode" iconSize="200" />
    <MessageText variant="h2">{title}</MessageText>
  </Container>
);

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MessageText = styled(Text)`
  margin-top: ${Size.space400};
`;
