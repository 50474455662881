import React from 'react';
import styled from 'styled-components';

import ChartAreaProvider from '../../charts/atoms/ChartAreaProvider';
import GraphInteractionLayer from '../../atoms/GraphInteractionLayer';
import AxisX from '../../charts/atoms/GraphAxis/AxisX';

import { BarGraphLegend } from './components/BarGraphLegend';
import { AXIS_X_HEIGHT, AXIS_Y_WIDTH, LEGEND_HEIGHT } from './constants';
import { BarGraphProvider } from './hooks/BarGraphContext';
import { BarGraphCanvas } from './components/BarGraphCanvas';
import AxisY from './components/AxisY';
import {
  BarGraphAxisConfigX,
  BarGraphAxisConfigY,
  LegendOption,
} from './types';

export interface BarGraphShapProps {
  data: object[];
  width: number;
  height: number;
  xAxis: BarGraphAxisConfigX;
  yAxis: BarGraphAxisConfigY;
  colors: string[];
  showLegend?: boolean;
  legendOptions?: LegendOption[];
  tooltip?: React.ReactNode;
  showMarker?: boolean;
  markerWidth?: number;
  markerValueDisplay?: React.ReactNode;
  className?: string;
}

export const BarGraphShap: React.FC<BarGraphShapProps> = ({
  data,
  width,
  height,
  xAxis,
  yAxis,
  colors,
  showLegend,
  legendOptions,
  tooltip,
  showMarker,
  markerWidth,
  markerValueDisplay,
  className,
}) => {
  return (
    <BarGraphContainer
      width={width}
      height={showLegend ? height + LEGEND_HEIGHT : height}
    >
      {showLegend && legendOptions && (
        <BarGraphLegend legendOptions={legendOptions} height={LEGEND_HEIGHT} />
      )}
      <BarGraphCanvasWrapper
        data={data}
        width={width}
        height={height}
        colors={colors}
        xAxis={xAxis}
        yAxis={yAxis}
        tooltip={tooltip}
        showMarker={showMarker}
        markerWidth={markerWidth}
        markerValueDisplay={markerValueDisplay}
        className={className}
      />
    </BarGraphContainer>
  );
};

const BarGraphCanvasWrapper: React.FC<{
  data: object[];
  columnStep?: number;
  columnPadding?: number;
  width: number;
  height: number;
  colors: string[];
  xAxis: BarGraphAxisConfigX;
  yAxis: BarGraphAxisConfigY;
  tooltip?: React.ReactNode;
  showMarker?: boolean;
  markerWidth?: number;
  markerValueDisplay?: React.ReactNode;
  className?: string;
}> = ({
  data,
  width,
  height,
  xAxis,
  yAxis,
  colors,
  tooltip,
  showMarker,
  markerWidth,
  markerValueDisplay,
  className,
}) => {
  return (
    <CanvasWrapper width={width} height={height}>
      <ChartAreaProvider x={xAxis.scale} y={yAxis.scale}>
        <BarGraphProvider
          data={data}
          width={width}
          height={height}
          xProperty={xAxis?.source?.property}
          columnPadding={yAxis?.source?.padding || 0}
          colors={colors}
        >
          <BarGraphCanvas
            width={width - AXIS_Y_WIDTH}
            height={height - AXIS_X_HEIGHT}
            className={className}
            showBackgroundLines
          />
          <BarGraphAxisX width={width - AXIS_Y_WIDTH} />
          <BarGraphAxisY
            marks={data?.map(dataItem => (dataItem as any)?.name || '') || []}
            height={height - AXIS_X_HEIGHT}
          />
          <BarGraphInteractionLayer
            width={width - AXIS_Y_WIDTH}
            height={height - AXIS_X_HEIGHT}
            tooltip={tooltip}
            showMarker={showMarker}
            markerWidth={markerWidth}
            markerValueDisplay={markerValueDisplay}
          />
        </BarGraphProvider>
      </ChartAreaProvider>
    </CanvasWrapper>
  );
};

const BarGraphContainer = styled.div<{
  width: number;
  height: number;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  margin-left: 100px;
`;

const CanvasWrapper = styled.div<{
  width: number;
  height: number;
}>`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const BarGraphAxisX = styled(AxisX)<{ width: number }>`
  width: ${props => props.width}px;
  height: ${AXIS_X_HEIGHT}px;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const BarGraphAxisY = styled(AxisY)<{ height: number }>`
  height: ${props => props.height}px;
  width: ${AXIS_Y_WIDTH}px;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const BarGraphInteractionLayer = styled(GraphInteractionLayer)`
  position: absolute;
  top: 0;
  right: 0;
`;
