import React from 'react';
import styled from 'styled-components';

import {
  useGroupedBarGraphContext,
  useGroupedBarGraphInteraction,
} from '../hooks';

interface GroupedBarGraphMarkerDisplayProps {
  formatValue?: (value: string | number) => string;
  className?: string;
}

export const GroupedBarGraphMarkerDisplay: React.FC<GroupedBarGraphMarkerDisplayProps> = ({
  formatValue,
  className,
}) => {
  const { xProperty } = useGroupedBarGraphContext();
  const graphInteraction = useGroupedBarGraphInteraction();

  if (!graphInteraction) {
    return null;
  }

  const { widgetData } = graphInteraction;

  if (!widgetData) {
    return null;
  }
  const { [xProperty]: valueToDisplay } = widgetData;

  return (
    <DisplayContainer className={className}>
      {formatValue ? formatValue(valueToDisplay) : valueToDisplay}
    </DisplayContainer>
  );
};

const DisplayContainer = styled.span`
  display: flex;
  padding: 0;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
`;
