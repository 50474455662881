import React, { useState, memo, useContext, useCallback } from 'react';
import * as PIXI from 'pixi.js-legacy';
import * as ReactPIXI from '@inlet/react-pixi/legacy';
import styled from 'styled-components';

import { useInteraction } from '../../../atoms/GraphInteractionLayer/GraphInteractionContext';
import { useBarGraphContext } from '../hooks/BarGraphContext';
import { useColumn } from '../hooks/useColumn';
import { ChartAreaContext } from '../../../charts/atoms/ChartAreaProvider/ChartAreaContext';
import { Color } from '../../../theme/primitives';
import { lerp, normalize } from '../../../utils/mathUtils';
import { useBarGraphInteraction } from '../hooks/useBarGraphInteraction';

import { GraphColumn } from './GraphColumn';

const { Container, Stage, Graphics } = ReactPIXI;

export interface BarGraphCanvasProps {
  width: number;
  height: number;
  className?: string;
  showBackgroundLines?: boolean;
}

export const BarGraphCanvasComponent: React.FC<BarGraphCanvasProps> = ({
  width,
  height,
  className,
  showBackgroundLines,
}) => {
  const [, setInteractionState] = useInteraction();
  const barGraphInteractionState = useBarGraphInteraction();
  const { data, graphId } = useBarGraphContext();
  const [isHovered, setIsHovered] = useState(false);
  const area = useContext(ChartAreaContext);

  const { columnDrawData } = useColumn();

  const drawBackgroundLines = useCallback(
    graphics => {
      graphics.clear();
      const { y } = area;

      y.scaleMarks.forEach(position => {
        const normalizedPosition = normalize(y.min, y.max, position);

        graphics.lineStyle({
          width: 1,
          color: PIXI.utils.string2hex(Color.grey100),
          alignment: 0.5,
        });
        graphics.moveTo(0, height - normalizedPosition * height);
        graphics.lineTo(width, height - normalizedPosition * height);
      });
    },
    [area, width, height],
  );

  const handleMouseMove = (event: any) => {
    return !isHovered
      ? null
      : (() => {
          const x = lerp(area.x.min, area.x.max, event.data.global.x / width);
          const y = lerp(area.y.max, area.y.min, event.data.global.y / height);

          setInteractionState({ x, y, graphId });
        })();
  };

  return (
    <BarGraphCanvasContainer>
      <Stage
        options={{
          backgroundAlpha: 0,
          autoDensity: true,
          antialias: true,
        }}
        width={width}
        height={height}
        className={className}
        raf={false}
        onMount={app => {
          // eslint-disable-next-line no-param-reassign
          app.renderer.plugins.interaction.moveWhenInside = true;
        }}
        onMouseOut={() => {
          setInteractionState(null);
          setIsHovered(false);
        }}
        onMouseOver={() => {
          setIsHovered(true);
        }}
        renderOnComponentChange
      >
        <Container
          interactive
          hitArea={new PIXI.Rectangle(0, 0, width, height)}
          mousemove={handleMouseMove}
          mouseout={() => {
            setInteractionState(null);
          }}
        >
          {showBackgroundLines && <Graphics draw={drawBackgroundLines} />}
          {data.map((_, index) => (
            <GraphColumn
              key={index}
              isHovered={barGraphInteractionState?.hoveredColumnIndex === index}
              drawData={columnDrawData[index]}
            />
          ))}
        </Container>
      </Stage>
    </BarGraphCanvasContainer>
  );
};

export const BarGraphCanvas = memo(BarGraphCanvasComponent);

const BarGraphCanvasContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
