import React from 'react';
import { components as ReactSelectComponents } from 'react-select';
import styled, { DefaultTheme } from 'styled-components';

import { typography, color, shape } from '../../theme/themeUtils';
import { Color } from '../../theme/primitives';
import { Icon } from '../Icon';
import { Select } from '../Select';

type Variant = keyof DefaultTheme['dropdown']['color'] &
  keyof DefaultTheme['dropdown']['shape'];

export type DropdownOptionType = {
  value: string | null;
  label: string | null;
};

export interface DropdownProps {
  variant?: Variant;
  className?: string;
  options: {
    label: string;
    value: any;
  }[];
  value?: {
    label: string;
    value: any;
  } | null;
  menuPlacement?: string;
  isInline?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  minHeight?: string;
  onChange: Function;
  placeholder?: string;
  // todo: consider to remove props above as unused
  color?: string;
  itemHeight?: number;
  isOpen?: boolean;
  itemWidth?: number;
}

const DropdownIndicator = (props: any) => {
  return (
    /* eslint-disable-next-line */
    <ReactSelectComponents.DropdownIndicator {...props}>
      <SelectIcon icon="arrowDown" iconSize="12" />
    </ReactSelectComponents.DropdownIndicator>
  );
};

export const Dropdown: React.FC<DropdownProps> = ({
  variant,
  className,
  options,
  value,
  menuPlacement = 'auto',
  isSearchable = false,
  isInline,
  isDisabled,
  minHeight,
  onChange,
  placeholder,
}) => (
  <ActionSelect
    className={className}
    variant={variant}
    components={{
      DropdownIndicator,
      IndicatorSeparator: () => null,
    }}
    menuPlacement={menuPlacement}
    isSearchable={isSearchable}
    options={options}
    value={value}
    isInline={isInline}
    minHeight={minHeight}
    onChange={onChange}
    placeholder={placeholder}
    isDisabled={isDisabled}
  />
);

const SelectIcon = styled(Icon)`
  fill: ${Color.grey400};
  margin-right: -4px;
  pointer-events: none;
`;

const ActionSelect = styled(Select)<{
  isInline?: boolean;
  variant?: Variant;
}>`
  min-width: ${({ isInline }) => (isInline ? '100px' : '150px')};
  width: ${({ isInline }) => (isInline ? '200px' : '100%')};

  .react-select {
    &__placeholder {
      margin: 0;
      color: ${({ theme, variant }) =>
        theme.dropdown.color[variant || 'default'].inactive.placeholderText};
    }

    &__control {
      box-sizing: border-box;
      height: 40px;
      padding: 0;

      ${({ theme, variant }) =>
        shape(theme.dropdown.shape[variant || 'default'])};
      ${({ theme, variant }) =>
        color(theme.dropdown.color[variant || 'default'].inactive)};

      ${SelectIcon} {
        fill: ${({ theme, variant }) =>
          theme.dropdown.color[variant || 'default'].inactive.border};
      }

      &.react-select__control--is-disabled,
      &.react-select__control--is-disabled:hover,
      &.react-select__control--is-disabled.react-select__control--is-focused {
        cursor: not-allowed;
        ${({ theme, variant }) =>
          color(theme.dropdown.color[variant || 'default'].disabled)};

        &:hover {
          border-color: ${({ theme, variant }) =>
            theme.dropdown.color[variant || 'default'].disabled.border};
        }

        &__value-container {
          cursor: not-allowed;
        }

        ${SelectIcon} {
          fill: ${({ theme, variant }) =>
            theme.dropdown.color[variant || 'default'].disabled.border};
        }
      }
    }

    &__control:hover,
    &__control--is-focused {
      outline: none;
      box-shadow: none;
      cursor: pointer;
    }

    &__control:hover {
      ${({ theme, variant }) =>
        color(theme.dropdown.color[variant || 'default'].hover)};

      ${SelectIcon} {
        fill: ${({ theme, variant }) =>
          theme.dropdown.color[variant || 'default'].hover.border};
      }
    }

    &__control--is-focused {
      ${({ theme, variant }) =>
        color(theme.dropdown.color[variant || 'default'].focus)};

      ${SelectIcon} {
        fill: ${({ theme, variant }) =>
          theme.dropdown.color[variant || 'default'].focus.border};
      }
    }

    &__control--menu-is-open {
      ${SelectIcon} {
        transform: rotate(180deg);
      }
    }

    &__menu-list {
      margin: 6px 2px;
      max-height: 200px;
      padding: 0px 2px;

      ::-webkit-scrollbar {
        width: 4px;
      }
      ::-webkit-scrollbar-track {
        background: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background: ${Color.grey400};
        border-radius: 8px;
      }
    }

    &__menu {
      border-radius: 8px;
      border: ${({ theme, variant }) =>
        `1px solid ${theme.dropdown.color[variant || 'default'].focus.border}`};
      box-shadow: ${({ theme, variant }) =>
        `0 0 2px ${theme.dropdown.color[variant || 'default'].focus.border}`};
      z-index: 3;
    }

    &__value-container,
    &__indicator {
      padding: 0;
    }

    &__single-value,
    &__option,
    &__option--selected {
      ${typography('default')};
      color: ${({ theme, variant }) =>
        theme.dropdown.color[variant || 'default'].focus.text};
    }

    &__option--is-focused {
      background-color: ${({ theme, variant }) =>
        theme.dropdown.color[variant || 'default'].focus.optionBackground};
    }

    &__option--is-selected {
      color: ${({ theme, variant }) =>
        theme.dropdown.color[variant || 'default'].selected.optionColor};
      background-color: ${({ theme, variant }) =>
        theme.dropdown.color[variant || 'default'].selected.optionBackground};
    }

    &__option {
      border-radius: 8px;
      cursor: pointer;
    }

    ${({ isInline }) =>
      isInline
        ? `
            &__control,
            &__control:hover,
            &__control--is-focused {
              border: none;
              background-color: ${Color.transparent};
            }

            &__value-container,
            &__indicator {
              padding: 0;
              text-align: right;
              justify-content: flex-end;
            }

            &__indicator {
              margin-left: 8px;
            }

            &__option {
              text-align: right;
            }
          `
        : ''};
  }
`;
