import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Color } from '../../theme/primitives';

type TableRowWrapperProps = {
  dataCy?: string;
  className?: string;
  children: ReactNode;
};

export const TableRowWrapper = ({
  dataCy,
  className,
  children,
}: TableRowWrapperProps) => (
  <Wrapper data-cy={dataCy || 'TableRowWrapper'} className={className}>
    {children}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  padding: 15px 20px;
  align-items: center;
  background-color: ${Color.grey050};
  border-bottom: 1px solid ${Color.grey400};

  @media (max-width: 720px) {
    max-width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px;
  }

  &:nth-of-type(odd) {
    background-color: ${Color.white};
  }

  :last-child {
    border: 0;
  }
`;

export default TableRowWrapper;
