import React from 'react';
import styled from 'styled-components';

import { Icon } from '../../atoms/Icon';
import { Color } from '../../theme/primitives';
import { Button } from '../../atoms/Button';

export interface ErrorStateLayoutProps {
  className?: string;
  onRetry: () => void;
  retryButtonText: string;
  title: string;
  message: React.ReactNode;
}

export const ErrorStateLayout: React.FC<ErrorStateLayoutProps> = ({
  className,
  onRetry,
  retryButtonText,
  title,
  message,
}) => (
  <Container className={className}>
    <IconWrapper>
      <Icon icon="close" iconSize="18" fill={Color.white} />
    </IconWrapper>
    <Title>{title}</Title>
    <Message>{message}</Message>
    <Button onClick={onRetry} variant="default">
      {retryButtonText}
    </Button>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 40px 0;
`;

const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #eb5757; // todo: revisit it when we have final color palette
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${Color.grey600};
  margin: 0;
  text-align: center;
`;

const Message = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
`;
