import React from 'react';
import styled from 'styled-components';

import { Icon } from '../../../../../atoms/Icon';
import { Color } from '../../../../../theme/primitives';
import {
  CLOSE_BUTTON_DIAMETER,
  CLOSE_BUTTON_PADDING,
} from '../../zoomConstants';

export const CloseButton: React.FC = () => (
  <Container>
    <Icon icon="close" fill={Color.white} iconSize="12" />
  </Container>
);

const Container = styled.div`
  width: ${CLOSE_BUTTON_DIAMETER}px;
  height: ${CLOSE_BUTTON_DIAMETER}px;

  border-radius: ${CLOSE_BUTTON_DIAMETER / 2}px;
  background: ${Color.grey900};

  position: absolute;
  top: ${CLOSE_BUTTON_PADDING}px;
  right: ${CLOSE_BUTTON_PADDING}px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
