import React from 'react';
import styled, { css } from 'styled-components';

import Text from '../Text';
import { IconPlaceholder } from '../Icon/index';

export interface SidebarNavItemProps {
  expanded: boolean;
  title: string;
  selected: boolean;
  href?: string;
  icon?: string; // TODO: use enum
  component?: React.ComponentType<any>;
}

export const SidebarNavItem: React.FC<SidebarNavItemProps> = ({
  selected,
  expanded,
  title,
  href,
  icon,
  component,
}) => {
  /* TODO: implement button layout with icon */
  return (
    <Container selected={selected}>
      <Link href={href} as={component}>
        <IconPlaceholder />
        <Label visible={expanded}>{title}</Label>
      </Link>
    </Container>
  );
};

const selectedState = css`
  background: ${({ theme }) => theme.navItem.color.background};

  ::after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    width: 4px;
    background: ${({ theme }) => theme.navItem.color.border};
  }
`;

const Container = styled.li<{ selected: boolean }>`
  flex: 1;
  margin: 0;
  position: relative;

  ${({ selected }) => selected && selectedState};
`;

const Link = styled.a`
  display: flex;
  align-items: center;

  padding: ${({ theme }) => theme.sidebarNavItem.shape.padding};
`;

const Label = styled(Text)<{
  visible: boolean;
}>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  margin-left: 20px;
`;
