import React from 'react';
import styled from 'styled-components';

import { Color } from '../../../../theme/primitives';
import { CROP_THRESHOLD, CropWidgetBoundValue } from '../zoomConstants';
import { getSortedBounds } from '../utils';

import { CloseButton } from './elements/ButtonClose';
import { ZoomButton } from './elements/ButtonZoom';
import { Handle } from './elements/InterfaceHandle';
import { ValueIndicator } from './elements/InterfaceValueIndicator';
import { WidgetEdge } from './constantsCrop';

export interface CropWidgetProps {
  graphHeight: number;
  bounds: CropWidgetBoundValue[] | null;
  markerWidth?: number;
  formatValueIndicator?: (displayValue: number) => string;
}

export const CropWidget: React.FC<CropWidgetProps> = ({
  graphHeight,
  bounds,
  formatValueIndicator,
  markerWidth,
}) => {
  if (!bounds) {
    return null;
  }

  const sortedBounds = getSortedBounds(bounds);
  if (!sortedBounds) {
    return null;
  }

  const {
    canvasValue: leftBound,
    dateValue: leftMarkerValue,
  } = sortedBounds[0];
  const {
    canvasValue: rightBound,
    dateValue: rightMarkerValue,
  } = sortedBounds[1];

  const widgetWidth = rightBound - leftBound;
  const areWidgetButtonsShown = widgetWidth >= CROP_THRESHOLD;

  return (
    <Container
      left={sortedBounds[0]?.canvasValue}
      right={sortedBounds[1]?.canvasValue}
    >
      <WidgetBox width={widgetWidth} height={graphHeight}>
        <WidgetBoxFill />
        <HandleLeft />
        <HandleRight />
        {areWidgetButtonsShown && (
          <>
            <ZoomButton />
            <CloseButton />
          </>
        )}
        <ValueIndicator
          value={leftMarkerValue as number}
          edge={WidgetEdge.LEFT}
          formatValueIndicator={formatValueIndicator}
          width={markerWidth}
        />
        <ValueIndicator
          value={rightMarkerValue as number}
          edge={WidgetEdge.RIGHT}
          formatValueIndicator={formatValueIndicator}
          width={markerWidth}
        />
      </WidgetBox>
    </Container>
  );
};

interface ContainerProps {
  left: number;
  right: number;
}

const Container = styled.div.attrs<ContainerProps>(({ left, right }): any => ({
  style: {
    left: `${left}px`,
    right: `${right}px`,
  },
}))<ContainerProps>`
  position: absolute;
`;

interface WidgetBoxProps {
  width: number;
  height: number;
}

const WidgetBox = styled.div.attrs<WidgetBoxProps>(({ width, height }) => ({
  style: {
    width: `${width}px`,
    height: `${height}px`,
  },
}))<WidgetBoxProps>`
  position: relative;

  border-left: 1px solid ${Color.grey900};
  border-right: 1px solid ${Color.grey900};
`;

const HandleLeft = styled(Handle)`
  left: -6px;
`;

const HandleRight = styled(Handle)`
  right: -6px;
`;

const WidgetBoxFill = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: ${Color.grey900};
  opacity: 0.4;
`;
