import { useStore, createStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { UserInfo } from '../types/user.type';

type State = {
  userInfo: UserInfo | null;
};

type Actions = {
  setUserInfo: (user: Partial<UserInfo>) => void;
  resetUserInfo: () => void;
};

const initialStates = {
  userInfo: {
    id: null,
    name: '',
    email: '',
  },
};

export const userStore = createStore(
  immer<State & Actions>(set => ({
    ...initialStates,

    setUserInfo: (user: Partial<UserInfo>) =>
      set(prevState => ({
        userInfo: { ...prevState.userInfo, ...user },
      })),

    resetUserInfo: () => set(() => ({ ...initialStates })),
  })),
);

export const useUserStore = <T = State & Actions>(
  selector: (state: State & Actions) => T = state => state as T,
): T => useStore(userStore, selector);
