import React, { useCallback } from 'react';
import * as PIXI from 'pixi.js-legacy';
import * as ReactPIXI from '@inlet/react-pixi';

import { ColumnBounding } from '../types';

const { Graphics } = ReactPIXI;

export interface ColumnProps {
  drawData: {
    bounding: ColumnBounding;
    color: string;
  };
}

export const Column: React.FC<ColumnProps> = ({ drawData }) => {
  const drawColumn = useCallback(
    graphics => {
      graphics.clear();

      const {
        x: rectX,
        y: rectY,
        width: rectWidth,
        height: rectHeight,
      } = drawData.bounding;

      graphics.beginFill(PIXI.utils.string2hex(drawData.color));
      graphics.drawRect(rectX, rectY, rectWidth, rectHeight);
      graphics.endFill();
    },
    [drawData],
  );

  return <Graphics draw={drawColumn} />;
};
