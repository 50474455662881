import React, { memo } from 'react';
import styled from 'styled-components';

import { OptionsListVariant } from '../theme';
import { IconProp } from '../../../atoms/Icon';

import CustomListOption from './CustomListOption';

interface ListOptionInterface {
  name?: string;
  value: string;
  label: string;
  icon?: IconProp;
  badge?: React.ReactNode;
}

interface ContainerProps {
  width?: number;
  height: number;
  y: number;
}

interface Props {
  options: ListOptionInterface[];
  listHeight?: number;
  height: number;
  width?: number;
  scroll: number;
  highlight?: string;
  selected?: string;
  itemHeight: number;
  variant?: OptionsListVariant;
  onChange: (value: string) => void;
  onHover: (actionData: { action: string; value: string }) => void;
}

const CustomList: React.FC<Props> = ({
  scroll,
  width,
  height,
  options,
  itemHeight,
  highlight,
  selected,
  variant,
  onChange,
  onHover,
}) => {
  const renderOptions = (): Array<React.ReactNode> => {
    return options.map(({ label = '', value = '', icon, badge }) => {
      const active = selected === value;
      const hover = highlight === value;

      return (
        <CustomListOption
          key={label}
          label={label}
          icon={icon}
          badge={badge}
          value={value}
          height={itemHeight}
          active={active}
          hover={hover}
          onClick={onChange}
          onHover={onHover}
        />
      );
    });
  };

  return (
    <Container width={width} height={height} y={scroll}>
      {renderOptions()}
    </Container>
  );
};

const Container = styled.div.attrs<ContainerProps>(({ width, height, y }) => ({
  style: {
    width: width ? `${width}px` : '100%',
    height: height && `${height}px`,
    transform: `translateY(${y}px)`,
  },
}))<ContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  will-change: transform;
`;

export default memo(CustomList);
