import React from 'react';
import styled from 'styled-components';

import { Color } from '../../theme/primitives';
import Panel from '../Panel';
import Text from '../Text';
import { Badge, BadgeType } from '../Badge';

interface ConfigPanelProps {
  className?: string;
  title?: string;
  description?: string | React.ReactNode;
  link?: {
    label: string;
    url: string;
  };
  children?: React.ReactNode;
  toggle?: React.ReactNode;
  isBeta?: boolean;
  isReadOnly?: boolean;
}

const ConfigPanel: React.FC<ConfigPanelProps> = ({
  className,
  title,
  description,
  link,
  children,
  toggle,
  isBeta = false,
  isReadOnly = false,
}) => {
  return (
    <Container className={className} isReadOnly={isReadOnly}>
      {title && (
        <PanelHeader isWithToggle={!!toggle}>
          <PanelTitle variant="default">
            {title}
            {link && link?.url && (
              <Link href={link.url} target="_blank" rel="noreferrer">
                {link.label}
              </Link>
            )}
            {isBeta && <BetaBadge type={BadgeType.BETA} title="Beta" />}
          </PanelTitle>
          {toggle && <ToggleWrapper>{toggle}</ToggleWrapper>}
          {description && (
            <PanelDescription variant="default">{description}</PanelDescription>
          )}
        </PanelHeader>
      )}
      {children}
    </Container>
  );
};

const Container = styled(Panel)<{ isReadOnly?: boolean }>`
  padding: 16px;
  border-radius: 8px;
  border-color: ${({ isReadOnly }) =>
    isReadOnly ? Color.grey200 : Color.grey300};
  background-color: ${({ isReadOnly }) =>
    isReadOnly ? Color.grey050 : Color.white};

  @media (min-width: 600px) {
    padding: 24px;
  }

  @media (min-width: 900px) {
    padding: 32px;
  }
`;

const PanelHeader = styled.div<{ isWithToggle: boolean }>`
  ${({ isWithToggle }) =>
    isWithToggle
      ? `
          width: 100%;
          display: grid;
          grid-template-areas:
            "title toggle"
            "description description";
          grid-template-columns: 1fr auto;
        `
      : ''}
  margin-bottom: 24px;

  @media (min-width: 900px) {
    margin-bottom: 32px;
  }
`;

const Link = styled.a`
  color: ${Color.blue400}!important;
  font-size: 14px;
  margin-left: 8px;
`;

const PanelTitle = styled(Text)`
  grid-area: title;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  display: inline-block;
`;

const PanelDescription = styled(Text)`
  grid-area: description;
  display: block;
  margin-top: 8px;
`;

const ToggleWrapper = styled.div`
  grid-area: toggle;
`;

const BetaBadge = styled(Badge)`
  margin-left: 8px;
`;

export default ConfigPanel;
