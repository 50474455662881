import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

import { colorStates, shape, typography } from '../../theme/themeUtils';
import { Color } from '../../theme/primitives';
import Text from '../Text';
import Input from '../Input';
import Panel from '../Panel';
import { Icon } from '../Icon';
import { Theme } from '../../styled';

const MultiSelectContext = React.createContext<MultiSelectProps | null>(null);

type Variant = 'siteKeySelect';

export interface MultiSelectProps<OptionType = MultiSelectDefaultOptionType> {
  className?: string;
  options: OptionType[];
  selected: OptionType[];
  onChange: (selected: OptionType[]) => void;
  filterOptions?: (option: OptionType, search: string) => boolean;

  OptionComponent?: MultiSelectOptionComponent<OptionType>;
  SelectedComponent?: MultiSelectSelectedComponent<OptionType>;

  clearLabel: string;
  selectAllLabel: string;

  inputPlaceholder?: string;
  error?: boolean;
  errorMessage?: string;
  variant?: Variant;
  noOptionsFoundText?: string;
  isAlwaysExpanded?: boolean;
  isDisabled?: boolean;
}

export const MultiSelect: React.FC<MultiSelectProps> = ({
  className,
  children,
  ...props
}) => {
  const [isLocalExpanded, setIsLocalExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { isAlwaysExpanded = false, isDisabled = false, errorMessage } = props;
  useEffect(() => {
    if (!isLocalExpanded && !isAlwaysExpanded) {
      return;
    }

    const eventHandler = (e: Event): void => {
      if (
        !containerRef.current ||
        containerRef.current.contains(e.target as Node)
      )
        return;
      setIsLocalExpanded(false);
    };

    document.addEventListener('mousedown', eventHandler);
    document.addEventListener('focusin', eventHandler);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('mousedown', eventHandler);
      document.removeEventListener('focusin', eventHandler);
    };
  }, [isLocalExpanded, isAlwaysExpanded]);

  const isExpanded = isLocalExpanded || isAlwaysExpanded;

  return (
    <MultiSelectContext.Provider value={props}>
      <Container ref={containerRef} className={className}>
        <Main
          isExpanded={isExpanded}
          setIsExpanded={setIsLocalExpanded}
          isDisabled={isDisabled}
          isAlwaysExpanded={isAlwaysExpanded}
        />
        {isExpanded && (
          <Options
            isAlwaysExpanded={isAlwaysExpanded}
            isDisabled={isDisabled}
          />
        )}
        {errorMessage && (
          <ErrorMessage variant="default">{errorMessage}</ErrorMessage>
        )}
      </Container>
    </MultiSelectContext.Provider>
  );
};

const Main: React.FC<{
  setIsExpanded: (isExpanded: boolean) => void;
  isExpanded: boolean;
  isDisabled: boolean;
  isAlwaysExpanded: boolean;
}> = ({ setIsExpanded, isExpanded, isDisabled, isAlwaysExpanded }) => {
  const {
    options,
    SelectedComponent: Selected = DefaultSelected,
    error,
    selected,
    variant,
  } = useContext(MultiSelectContext)!;

  return (
    <InputContainer
      isExpanded={isExpanded}
      variant={variant}
      error={error}
      tabIndex={0}
      onMouseDownCapture={e => {
        setIsExpanded(!isExpanded);
        e.stopPropagation();
      }}
      onKeyDown={({ key }) => {
        if (key === 'Enter' || key === ' ') {
          setIsExpanded(!isExpanded);
        }
      }}
      isDisabled={isDisabled}
      isAlwaysExpanded={isAlwaysExpanded}
    >
      <Selected options={options} selected={selected} />
      {!isDisabled && !isAlwaysExpanded && (
        <IconWrapper isExpanded={isExpanded}>
          <Icon icon="arrowDown" iconSize="12" />
        </IconWrapper>
      )}
    </InputContainer>
  );
};

const Options: React.FC<{ isAlwaysExpanded: boolean; isDisabled: boolean }> = ({
  isAlwaysExpanded,
  isDisabled,
}) => {
  const { inputPlaceholder, variant, error } = useContext(MultiSelectContext)!;
  const [search, setSearch] = useState('');

  return (
    <OptionsContainer>
      <OptionsPanel variant={variant} isError={error}>
        <OptionsContainerContents>
          <Input
            variant={variant}
            autoFocus={!isAlwaysExpanded}
            placeholder={inputPlaceholder}
            onChange={e => setSearch(e.target.value)}
          />
          {!isDisabled && <ActionsPanel />}
          <OptionsList search={search} isDisabled={isDisabled} />
        </OptionsContainerContents>
      </OptionsPanel>
    </OptionsContainer>
  );
};

const OptionsList: React.FC<{ search: string; isDisabled: boolean }> = ({
  search,
  isDisabled,
}) => {
  const {
    options,
    selected,
    onChange,
    OptionComponent: Option = DefaultOption,
    filterOptions = defaultSearch,
    noOptionsFoundText,
    variant,
  } = useContext(MultiSelectContext)!;

  const filteredOptions = options.filter(option =>
    filterOptions(option, search),
  );

  return (
    <OptionsListContainer>
      {filteredOptions.length ? (
        filteredOptions.map((option, index) => {
          const isSelected = selected.some(
            selectedOption => selectedOption.value === option.value,
          );
          const toggleSelect = () => {
            if (isDisabled) {
              return;
            }
            if (isSelected) {
              onChange(
                selected.filter(
                  selectedOption => selectedOption.value !== option.value,
                ),
              );
            } else {
              onChange([...selected, option]);
            }
          };

          return (
            <OptionContainer
              isSelected={isSelected}
              tabIndex={0}
              onMouseDownCapture={toggleSelect}
              onKeyDown={({ key }) => {
                if (key === 'Enter' || key === ' ') {
                  toggleSelect();
                }
              }}
              key={index}
              isDisabled={isDisabled}
            >
              <Option option={option} isSelected={isSelected} />
            </OptionContainer>
          );
        })
      ) : (
        <NoResultsFoundWrapper variant={variant}>
          {noOptionsFoundText || 'No options found'}
        </NoResultsFoundWrapper>
      )}
    </OptionsListContainer>
  );
};

const ActionsPanel: React.FC = () => {
  const { options, onChange, selectAllLabel, clearLabel, variant } = useContext(
    MultiSelectContext,
  )!;

  return (
    <ActionsPanelContainer>
      <ActionContainer onClick={() => onChange([...options])}>
        <SelectAllText multiSelectVariant={variant} variant="small">
          {selectAllLabel}
        </SelectAllText>
      </ActionContainer>
      <ActionContainer onClick={() => onChange([])}>
        <ClearAllText multiSelectVariant={variant} variant="small">
          {clearLabel}
        </ClearAllText>
      </ActionContainer>
    </ActionsPanelContainer>
  );
};

const defaultSearch = (
  option: MultiSelectDefaultOptionType,
  search: string,
): boolean => {
  return option.label.toLowerCase().includes(search.trim().toLowerCase());
};

const DefaultOption: MultiSelectOptionComponent = ({ option, isSelected }) => {
  return (
    <OptionExternalContainer>
      <OptionInternalContainer>
        <OptionLabel variant="small">{option.label || '<noname>'}</OptionLabel>
      </OptionInternalContainer>
      <SelectionState isSelected={isSelected} />
    </OptionExternalContainer>
  );
};

const DefaultSelected: MultiSelectSelectedComponent = ({
  options,
  selected,
}) => {
  return <>{`${selected.length} / ${options.length}`}</>;
};

export interface MultiSelectDefaultOptionType {
  label: string;
  value: string;
}

export type MultiSelectOptionComponent<
  OptionType = MultiSelectDefaultOptionType
> = React.FC<{
  option: OptionType;
  isSelected: boolean;
}>;

export type MultiSelectSelectedComponent<
  OptionType = MultiSelectDefaultOptionType
> = React.FC<{
  options: OptionType[];
  selected: OptionType[];
}>;

const Container = styled.div`
  position: relative;
`;

const OptionsListContainer = styled.div`
  overflow-y: auto;
`;

const OptionContainer = styled.div<{
  isSelected: boolean;
  isDisabled: boolean;
}>`
  ${({ theme }) => shape(theme.selectOption.shape)};

  cursor: ${({ isDisabled }) => (isDisabled ? 'inherit' : 'pointer')};

  &:hover {
    background: ${({ theme, isDisabled }) =>
      isDisabled ? 'inherit' : theme.selectOption.color.selected.background};
  }

  border-bottom: 1px solid
    ${({ theme }) => theme.selectOption.color.default.border};

  :last-child {
    border-bottom: none;
  }
`;

const OptionsContainer = styled.div`
  width: 100%;
  position: absolute;
  z-index: 999;
  max-height: 300px;
  display: flex;

  margin-top: ${({ theme }) => theme.select.layout.gap};
`;

const OptionsPanel = styled(Panel)<{
  isError?: boolean;
}>`
  flex: 1;
  border-radius: 8px;
  ${({ theme, isError }) =>
    isError && colorStates(theme.multiSelectInput.color.invalid)};
`;

const OptionsContainerContents = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ActionContainer = styled.div`
  ${({ theme }) => shape(theme.selectAction.shape)};

  cursor: pointer;
`;

const ActionsPanelContainer = styled.div`
  margin-top: ${({ theme }) => theme.select.layout.gap};
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border-bottom: 1px solid ${({ theme }) => theme.select.color.separator};
`;

const InputContainer = styled.div<{
  variant?: keyof DefaultTheme['multiSelectInput']['color'];
  error?: boolean;
  isExpanded?: boolean;
  isDisabled?: boolean;
  isAlwaysExpanded?: boolean;
}>`
  ${typography('default')};
  ${({ theme }) => shape(theme.multiSelectInput.shape.default)};
  ${({ theme, variant }) =>
    colorStates(theme.multiSelectInput.color[variant || 'default'])};
  ${({ theme, error }) => {
    return error && colorStates(theme.multiSelectInput.color.invalid);
  }};

  & svg {
    fill: ${({ theme, variant }) =>
      theme.multiSelectInput.color[variant || 'default'].inactive.border};
  }

  &:focus svg,
  &:hover svg {
    fill: currentColor;
  }

  ${({ isExpanded, theme, variant, error }) =>
    isExpanded &&
    !error &&
    css`
      border-color: ${theme.multiSelectInput.color[variant || 'default'].focus
        .border};
      color: ${theme.multiSelectInput.color[variant || 'default'].focus.text};
    `}

  cursor: ${({ isDisabled, isAlwaysExpanded }) =>
    isDisabled || isAlwaysExpanded ? 'inherit' : 'pointer'};
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  transform: ${({ isExpanded }: { isExpanded: boolean }) =>
    isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const NoResultsFoundWrapper = styled.div<{
  variant?: keyof DefaultTheme['multiSelectOption']['color'];
}>`
  padding: 35px 0;
  text-align: center;
  color: ${({ theme, variant }) =>
    theme.multiSelectOption.color[variant || 'default'].text};
`;

const SelectAllText = styled(Text)`
  :hover {
    text-decoration: underline;
  }
  color: ${({
    theme,
    multiSelectVariant,
  }: {
    theme: Theme;
    multiSelectVariant?: keyof DefaultTheme['multiSelectActions']['selectAll'];
  }) =>
    theme.multiSelectActions.selectAll[multiSelectVariant || 'default'].color};
`;

const ClearAllText = styled(Text)`
  :hover {
    text-decoration: underline;
  }
  color: ${({
    theme,
    multiSelectVariant,
  }: {
    theme: Theme;
    multiSelectVariant?: keyof DefaultTheme['multiSelectActions']['clearAll'];
  }) =>
    theme.multiSelectActions.clearAll[multiSelectVariant || 'default'].color};
`;

const SelectionState = ({ isSelected }: { isSelected: boolean }) => (
  <SelectionStateWrapper isSelected={isSelected}>
    <Icon
      icon="checkMark"
      iconSize="s"
      fill={isSelected ? 'white' : 'transparent'}
    />
  </SelectionStateWrapper>
);

const SelectionStateWrapper = styled.div`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected
        ? theme.siteKeysSelectOptionState.color.selected.border
        : theme.siteKeysSelectOptionState.color.default.border};
  background-color: ${({
    isSelected,
    theme,
  }: {
    isSelected: boolean;
    theme: Theme;
  }) =>
    isSelected
      ? theme.siteKeysSelectOptionState.color.selected.background
      : theme.siteKeysSelectOptionState.color.default.background};
`;

const OptionExternalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const OptionInternalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionLabel = styled(Text)`
  whitespace: nowrap;
  overflow: hidden;
  max-width: 290px;
  text-overflow: ellipsis;
  color: ${({ theme }: { theme: Theme }) =>
    theme.multiSelectOption.color.siteKeySelect.text};
`;

const ErrorMessage = styled(Text)`
  display: flex;
  margin: 4px 0 0;
  font-size: 10px;
  line-height: 14px;
  color: ${Color.red400};
`;
