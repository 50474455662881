import React, { useRef } from 'react';
import styled, { css } from 'styled-components';

// Constants
import { Color } from '../../theme/primitives';

const ThumbSize = '18px';
const TrackHeight = '5px';

const ThumbStyle = css`
  box-sizing: border-box;
  border: none;
  width: ${ThumbSize};
  height: ${ThumbSize};
  border-radius: 50%;
  background: white;
  border: 1px solid ${Color.grey600};
`;

const TrackStyle = css`
  box-sizing: border-box;
  border: none;
  height: ${TrackHeight};
  background: ${Color.grey600};
  border-radius: 10px;
`;

const Focus = css`
  box-shadow: 0 0 0 2px rgba(77, 225, 210, 0.7);
`;

const SliderInput = styled.input`
  height: 20px;
  width: 100%;
  outline: none;
  z-index: 4;
  appearance: none;
  background: transparent;
  cursor: pointer;
  box-shadow: auto;
  padding: 0;
  margin: 0;

  &,
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  &::-webkit-slider-thumb {
    margin-top: calc(0.5 * (${TrackHeight} - ${ThumbSize}));
    ${ThumbStyle};
  }

  &::-moz-range-thumb {
    ${ThumbStyle};
  }

  &::-ms-thumb {
    margin-top: 0;
    ${ThumbStyle};
  }

  &::-webkit-slider-runnable-track {
    ${TrackStyle};
  }

  &::-moz-range-track {
    ${TrackStyle};
  }

  &::-ms-track {
    ${TrackStyle};
  }

  &:focus {
    box-shadow: auto;
  }

  &:focus::-webkit-slider-thumb {
    ${Focus}
  }

  &:focus::-moz-range-thumb {
    ${Focus}
  }

  &:focus::-ms-thumb {
    ${Focus}
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export interface InputSliderProps {
  min?: number;
  max?: number;
  value?: number;
  step?: number;
  label?: string;
  onChange: (value: number) => void;
  onMouseUp?: (event: React.MouseEvent) => void;
  width?: number;
  className?: string;
  isDisabled?: boolean;
}

export const InputSlider: React.FC<InputSliderProps> = ({
  step,
  min = 0,
  max = 100,
  value,
  width = 200,
  onChange,
  onMouseUp,
  className,
  isDisabled,
}) => {
  const inputValue = value || min;
  const ref = useRef<HTMLInputElement | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const number = Number(event.target.value);

    if (onChange) {
      onChange(number);
    }
  };

  const handleMouseUp = (event: React.MouseEvent) => {
    ref.current?.blur();

    if (onMouseUp) {
      onMouseUp(event);
    }
  };

  return (
    <SliderInput
      type="range"
      min={min}
      max={max}
      step={step}
      value={inputValue?.toString()}
      ref={ref}
      disabled={isDisabled}
      onChange={handleChange}
      onMouseUp={handleMouseUp}
      className={className}
    />
  );
};
