import React from 'react';
import styled, { keyframes } from 'styled-components';

type Size = 'xxs' | 'xs' | 's' | 'm' | 'l' | number;

export interface LoaderProps {
  size?: 'xxs' | 'xs' | 's' | 'm' | 'l' | number;
  className?: string;
  dataTestId?: string;
}

const SIZE_MAP: Record<Size, string> = {
  xxs: '24px',
  xs: '32px',
  s: '48px',
  m: '64px',
  l: '96px',
};

const getSpinnerSize = (size: Size | number) =>
  SIZE_MAP[size] ? SIZE_MAP[size] : `${size}px`;

// eslint-disable-next-line import/prefer-default-export
export const Loader: React.FC<LoaderProps> = ({
  size,
  className = '',
  dataTestId,
}) => {
  return (
    <Container
      className={className}
      size={size || 'm'}
      data-testid={dataTestId}
    >
      <Spinner size={getSpinnerSize(size || 'm')} />
    </Container>
  );
};

const spin = () => keyframes`
  0% {
    transform: translate3d(-50%, -50%, 0) rotateZ(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotateZ(360deg);
  }
`;

const Container = styled.div<{ size: Size | number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${({ size }) =>
    size === 'xxs' || typeof size === 'number' ? size : '64px'};
  height: 100%;
  overflow: hidden;
`;

const Spinner = styled.div<{ size: string }>`
  position: relative;

  font-size: ${({ size }) => size};

  width: 1em;
  height: 1em;

  border-radius: 100%;

  border: 0.1em solid ${({ theme }) => theme.loader.color.background};
  box-sizing: border-box;

  ::after {
    content: '';
    display: block;
    box-sizing: border-box;

    border-radius: 100%;

    width: 1em;
    height: 1em;

    position: absolute;
    left: 50%;
    top: 50%;

    border: 0.1em solid transparent;
    border-top-color: ${({ theme }) => theme.loader.color.foreground};

    animation: ${spin} linear 1s 0s infinite forwards;
  }
`;
