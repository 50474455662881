import { Ref, MutableRefObject } from 'react';

export type RefType = Ref<HTMLElement>;

export const mergeRefs = (...refs: RefType[]) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return (inst: HTMLElement) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        (ref as MutableRefObject<HTMLElement>).current = inst;
      }
    }
  };
};
