/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';

export interface TableProps
  extends React.DetailedHTMLProps<
    React.TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
  > {}

export const Table = React.forwardRef<HTMLTableElement, TableProps>(
  ({ children, ...props }, ref) => {
    return (
      <StyledTable {...props} ref={ref}>
        {children}
      </StyledTable>
    );
  },
);

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-style: hidden;
  table-layout: fixed;
  td,
  th {
    padding: 0 15px;
  }
`;
