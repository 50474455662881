import { useContext } from 'react';

import {
  MouseEventContext,
  MouseEventContextState,
} from '../contexts/MouseEvent';

export const useMouseEvent: () => [
  MouseEventContextState | null,
  React.Dispatch<React.SetStateAction<MouseEventContextState | null>>,
] = () => {
  const mouseEventContext = useContext(MouseEventContext);

  if (mouseEventContext === null) {
    throw new Error(
      'useMouseEvent must be wrapped under MouseEventContextProvider',
    );
  }

  return mouseEventContext;
};
