import React, {
  memo,
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import styled from 'styled-components';

import { Color } from '../../theme/primitives';
import Text from '../../atoms/Text';
import Panel from '../../atoms/Panel';
import { Icon } from '../../atoms/Icon';
import { Loader } from '../../atoms/Loader';
import { Checkbox } from '../../atoms/Checkbox';
import { InfoButton } from '../../molecules/InfoButton';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '../../atoms/Table';
import { Paginator } from '../../molecules/Paginator';
import { KeyCode } from '../../constants';

import {
  SortDirection,
  ActionTableTheme,
  DEFAULT_ITEMS_PER_PAGE,
} from './constants';

interface SortDataInterface {
  activeColumnId?: string;
  direction?: SortDirection;
}

export interface RowItemInterface {
  id: string;
  columns: {
    id?: string;
    title?: string;
    value?: string | number | boolean;
    valueNode?: React.ReactNode;
    width?: string;
    onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void;
  }[];
}

export interface HeaderItemInterface {
  id?: string;
  title?: string;
  width?: string;
  tooltip?: string;
  tooltipDocsReference?: string;
  onClick?: (sort: SortDataInterface) => void;
}

export interface ActionTableProps {
  headers: HeaderItemInterface[];
  rowItems: RowItemInterface[];
  headerHeight?: string;
  rowHeight?: string;
  noItemsText?: string;
  isLoading?: boolean;
  isSelectable?: boolean;
  isSortable?: boolean;
  tableTheme?: ActionTableTheme;
  isRowRefreshEnabled?: boolean;
  isRowDeletionEnabled?: boolean;
  isActionVisible?: boolean;
  isPagination?: boolean;
  isItemsPerPageSelectorShown?: boolean;
  itemsPerPageDropdownPosition?: string;
  itemsPerPageOptions?: {
    value: any;
    label: string;
  }[];
  itemsPerPage?: number;
  onRowsRefresh?: Function;
  onRowsRemove?: Function;
  onRowClick?: Function;
  className?: string;
  dataTestId?: string;
  isServerSidePagination?: boolean;
  handlePageChange?: (page: number) => void;
  currentPage?: number;
  totalCount?: number;
  isHorizontalScrollEnabled?: boolean;
  highlightedRowId?: string | null;
  removeButtonTitle?: string;
  isServerSideSort?: boolean;
}

export type ActionTableRef = {
  changePage: (page: number) => void;
  getCurrentPage: () => number;
  getContainerNode: () => HTMLDivElement | null;
};

const ActionTable = forwardRef<ActionTableRef, ActionTableProps>(
  (
    {
      headers = [],
      rowItems = [],
      headerHeight,
      rowHeight,
      noItemsText = '',
      isLoading,
      isSelectable,
      isSortable,
      isPagination,
      isItemsPerPageSelectorShown,
      itemsPerPageDropdownPosition,
      itemsPerPageOptions,
      itemsPerPage = 10,
      tableTheme = ActionTableTheme.PRIMARY,
      isRowRefreshEnabled,
      isRowDeletionEnabled,
      isActionVisible,
      onRowsRefresh,
      onRowsRemove,
      onRowClick,
      className,
      dataTestId,
      handlePageChange,
      currentPage = 1,
      totalCount = 0,
      isServerSidePagination,
      isHorizontalScrollEnabled,
      highlightedRowId,
      removeButtonTitle,
      isServerSideSort,
    },
    ref,
  ) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const areAllRowsSelected = selectedRows.length === rowItems.length;
    const [sortData, setSortData] = useState<SortDataInterface>({
      direction: SortDirection.NONE,
    });
    const [sortedRows, setSortedRows] = useState([...rowItems]);
    const [displayedRows, setDisplayedRows] = useState(
      isPagination && !isServerSidePagination
        ? rowItems.slice(0, itemsPerPage)
        : [...rowItems],
    );
    const [selectedPage, setSelectedPage] = useState(1);
    const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(
      itemsPerPage || itemsPerPageOptions?.[0]?.value || DEFAULT_ITEMS_PER_PAGE,
    );

    const getActiveColumnValue = (
      row: RowItemInterface,
      activeColumnId: string,
    ) => {
      const activeColumnValue = row.columns.find(
        column => column.id === activeColumnId,
      )?.value;

      if (typeof activeColumnValue === 'string') {
        return activeColumnValue.toLowerCase();
      }

      return activeColumnValue;
    };

    const getRows = useCallback(
      (rows, isSortable, { activeColumnId, direction }, isServerSideSort) => {
        if (
          !isSortable ||
          !direction ||
          direction === SortDirection.NONE ||
          isServerSideSort
        ) {
          return rows;
        }

        return rows.sort(
          (prevRow: RowItemInterface, nextRow: RowItemInterface) => {
            const prevValue = getActiveColumnValue(
              direction === SortDirection.ASC ? prevRow : nextRow,
              activeColumnId,
            );
            const nextValue = getActiveColumnValue(
              direction === SortDirection.ASC ? nextRow : prevRow,
              activeColumnId,
            );

            // Ensure both values are of the same type - (especially for numbers)
            const isNumberPrev = !isNaN(Number(prevValue));
            const isNumberNext = !isNaN(Number(nextValue));

            if (isNumberPrev && isNumberNext) {
              return Number(prevValue) - Number(nextValue);
            }

            if (prevValue === nextValue || !prevValue || !nextValue) {
              return 0;
            }

            return prevValue > nextValue ? 1 : -1;
          },
        );
      },
      [],
    );

    useEffect(() => {
      let rowsToDisplay = [...rowItems];

      rowsToDisplay = getRows(
        rowsToDisplay,
        isSortable,
        sortData,
        isServerSideSort,
      );

      if (!isPagination) {
        setDisplayedRows(rowsToDisplay);
      } else {
        setSortedRows(rowsToDisplay);
      }
  }, [rowItems, sortData, isServerSideSort]); // eslint-disable-line

    useEffect(() => {
      const rowIds = rowItems.map(rowItem => rowItem.id);

      setSelectedRows(selectedRowIds =>
        selectedRowIds.filter(selectedRowId => rowIds.includes(selectedRowId)),
      );
    }, [rowItems]);

    useEffect(() => {
      if (!isPagination) {
        return;
      }

      const rowsToDisplay = sortedRows.slice(
        (selectedPage - 1) * selectedItemsPerPage,
        selectedItemsPerPage + (selectedPage - 1) * selectedItemsPerPage,
      );

      setDisplayedRows(rowsToDisplay);
    }, [isPagination, selectedPage, selectedItemsPerPage, sortedRows]);

    useEffect(() => {
      if (highlightedRowId === undefined || highlightedRowId === null) {
        return;
      }

      const itemIndexToHighlight = sortedRows.findIndex(
        row => row.id === highlightedRowId,
      );

      if (itemIndexToHighlight === -1) {
        return;
      }

      const pageNumberToGo =
        selectedItemsPerPage > itemIndexToHighlight
          ? 1
          : Math.floor(itemIndexToHighlight / selectedItemsPerPage) + 1;

      if (pageNumberToGo === selectedPage) {
        return;
      }

      setSelectedPage(pageNumberToGo);
  }, [highlightedRowId, sortedRows, selectedItemsPerPage]); // eslint-disable-line

    const handleRowSelection = (id: string) => {
      setSelectedRows(selectedRowIds => {
        if (selectedRows.includes(id)) {
          return selectedRows.filter(rowId => rowId !== id);
        }

        return [...selectedRowIds, id];
      });
    };

    const handleGeneralRowSelection = () => {
      if (selectedRows.length) {
        setSelectedRows([]);

        return;
      }

      setSelectedRows(rowItems.map(ip => ip.id));
    };

    const handleRemove = (rowIds: string[]) => {
      if (onRowsRemove) {
        onRowsRemove(rowIds);
      }
    };

    const handleRefresh = (rowIds: string[]) => {
      if (onRowsRefresh) {
        onRowsRefresh(rowIds);
      }
    };

    const handleRowClick = (
      event: React.MouseEvent<HTMLElement>,
      row: RowItemInterface,
    ) => {
      onRowClick?.(event, row);
    };

    const handleRowKeyPress = (
      event: React.KeyboardEvent<HTMLElement>,
      row: RowItemInterface,
    ) => {
      if (!onRowClick) {
        return;
      }

      const { key } = event;

      if (key === KeyCode.ENTER) {
        event.preventDefault();
        onRowClick?.(event, row);
      }
    };

    const getSortDirection = (activeColumnId: string) => {
      const isColumnDifferent = activeColumnId !== sortData.activeColumnId;

      let direction = isColumnDifferent
        ? SortDirection.ASC
        : SortDirection.NONE;

      if (!isColumnDifferent) {
        switch (sortData.direction) {
          case SortDirection.NONE:
            direction = SortDirection.ASC;
            break;
          case SortDirection.ASC:
            direction = SortDirection.DESC;
            break;
          case SortDirection.DESC:
            direction = SortDirection.NONE;
            break;
          default:
            direction = SortDirection.NONE;
        }
      }

      return direction;
    };

    const handleSort = (
      activeColumnId: string,
      onHeaderClickHandler?: (sortData: SortDataInterface) => void,
    ) => {
      if (!isSortable && !isServerSideSort) {
        return;
      }

      if (isPagination) {
        setSelectedPage(1);
      }

      const newSortData = {
        activeColumnId,
        direction: getSortDirection(activeColumnId),
      };

      setSortData(newSortData);
      onHeaderClickHandler?.(newSortData);
    };

    const isSortIconVisible = (columnId: string, sortData: SortDataInterface) =>
      columnId === sortData.activeColumnId &&
      sortData.direction !== SortDirection.NONE;

    const handleSelectPage = useCallback(
      (page: number) => {
        if (!handlePageChange) return;
        handlePageChange(page);
      },
      [handlePageChange],
    );

    const isNewPageLoaded = !(isLoading && isServerSidePagination);

    const isNoData = !displayedRows.length && !isLoading;

    const isClientSidePagination = isPagination && !isServerSidePagination;

    useImperativeHandle(
      // todo: replace props passing by ref everywhere it's needed (including tables with server side pagination)
      ref,
      () => ({
        changePage: (page: number) => {
          setSelectedPage(page);
        },
        getCurrentPage: () => {
          return currentPage;
        },
        getContainerNode: () => {
          return containerRef.current;
        },
      }),
      [currentPage],
    );

    return (
      <Container className={className} ref={containerRef}>
        <TablePanel
          variant="collapsed"
          className="innerPanel"
          isHorizontalScrollEnabled={isHorizontalScrollEnabled}
          tableTheme={tableTheme}
        >
          {!isLoading && (
            <Table data-testid={dataTestId}>
              <TableHead>
                <TableHeadRow tableTheme={tableTheme}>
                  {isSelectable && (
                    <TableHeading width="80" height={headerHeight}>
                      <Checkbox
                        onChange={() => handleGeneralRowSelection()}
                        value={!!selectedRows.length}
                        size="14px"
                        iconSize={areAllRowsSelected ? '24' : '14'}
                        iconType={areAllRowsSelected ? 'checkMark' : 'dash'}
                        isDisabled={!displayedRows.length}
                        isRounded
                      />
                    </TableHeading>
                  )}
                  {!!headers.length &&
                    headers.map(
                      ({
                        id = '',
                        title = '',
                        width,
                        tooltip = '',
                        tooltipDocsReference = '',
                        onClick: onHeaderClickHandler,
                      }) => (
                        <TableHeading
                          key={id || title}
                          id={id}
                          title={title}
                          data-columnid={id}
                          width={width}
                          height={headerHeight}
                          isSortable={!!onHeaderClickHandler || isSortable}
                          onClick={() => handleSort(id, onHeaderClickHandler)}
                        >
                          {title && (
                            <TableHeadingContent>
                              {(tooltip && (
                                <Flex>
                                  <HeaderText>{title}</HeaderText>
                                  <TooltipWrapper>
                                    <InfoButton
                                      text={tooltip}
                                      docsReference={
                                        tooltipDocsReference || undefined
                                      }
                                    />
                                  </TooltipWrapper>
                                </Flex>
                              )) ||
                                title}

                              {(isSortable || onHeaderClickHandler) && (
                                <>
                                  <SortIconRowHover
                                    icon="sort"
                                    iconSize="12"
                                    direction={
                                      id === sortData.activeColumnId
                                        ? sortData.direction
                                        : SortDirection.NONE
                                    }
                                  />
                                  <SortIconNoHover
                                    icon="sort"
                                    iconSize="12"
                                    isVisible={isSortIconVisible(id, sortData)}
                                    direction={
                                      id === sortData.activeColumnId
                                        ? sortData.direction
                                        : SortDirection.NONE
                                    }
                                  />
                                </>
                              )}
                            </TableHeadingContent>
                          )}
                        </TableHeading>
                      ),
                    )}
                  {isRowRefreshEnabled && (
                    <TableHeading width="50" height={headerHeight}>
                      {!!selectedRows.length && (
                        <StyledIcon
                          isVisible
                          icon="refresh"
                          iconSize="18"
                          onClick={() => handleRefresh(selectedRows)}
                        />
                      )}
                    </TableHeading>
                  )}
                  {isRowDeletionEnabled && (
                    <TableHeading width="90" height={headerHeight}>
                      {!!selectedRows.length && (
                        <IconContainer
                          title={removeButtonTitle}
                          onClick={() => handleRemove(selectedRows)}
                        >
                          <StyledIcon isVisible icon="close" iconSize="12" />
                        </IconContainer>
                      )}
                    </TableHeading>
                  )}
                </TableHeadRow>
              </TableHead>
              {!!displayedRows.length && isNewPageLoaded && (
                <TableBody>
                  {displayedRows.map(({ id, columns = [] }) => (
                    <TableDataRow
                      key={id}
                      tableTheme={tableTheme}
                      isRowClickEnabled={onRowClick}
                      tabIndex={onRowClick ? 0 : -1}
                      onClick={event => handleRowClick(event, { id, columns })}
                      onKeyPress={event =>
                        handleRowKeyPress(event, { id, columns })
                      }
                      isHighlighted={highlightedRowId === id}
                    >
                      {isSelectable && (
                        <TableData
                          width="80"
                          height={rowHeight}
                          tableTheme={tableTheme}
                        >
                          <Checkbox
                            onChange={() => handleRowSelection(id)}
                            value={selectedRows.includes(id)}
                            size="14px"
                            isRounded
                          />
                        </TableData>
                      )}
                      {!!columns?.length &&
                        columns.map(
                          ({
                            id: columnId,
                            valueNode,
                            title = '',
                            value = '',
                            width,
                            onClick,
                          }) => (
                            <TableData
                              key={columnId || `${id}${value}`}
                              id={columnId}
                              width={width}
                              title={title || String(value)}
                              height={rowHeight}
                              data-columnid={columnId}
                              tableTheme={tableTheme}
                              onClick={event => onClick?.(event)}
                              onKeyPress={event => onClick?.(event)}
                            >
                              {valueNode || value}
                            </TableData>
                          ),
                        )}
                      {isRowRefreshEnabled && (
                        <TableData
                          width="50"
                          height={rowHeight}
                          tableTheme={tableTheme}
                        >
                          <StyledIcon
                            isVisible={
                              isActionVisible || selectedRows.includes(id)
                            }
                            icon="refresh"
                            iconSize="18"
                            onClick={() => handleRefresh([id])}
                          />
                        </TableData>
                      )}
                      {isRowDeletionEnabled && (
                        <TableData
                          width="90"
                          height={rowHeight}
                          tableTheme={tableTheme}
                        >
                          <IconContainer
                            title={removeButtonTitle}
                            isVisible={
                              isActionVisible || selectedRows.includes(id)
                            }
                            onClick={() => handleRemove([id])}
                          >
                            <StyledIcon
                              isVisible={
                                isActionVisible || selectedRows.includes(id)
                              }
                              icon="close"
                              iconSize="12"
                            />
                          </IconContainer>
                        </TableData>
                      )}
                    </TableDataRow>
                  ))}
                </TableBody>
              )}
            </Table>
          )}
          {isNoData && (
            <NoItemsTableData
              variant="default"
              height={rowHeight}
              tableTheme={tableTheme}
            >
              {noItemsText}
            </NoItemsTableData>
          )}
          {isLoading && (
            <NoItemsTableData
              variant="default"
              height={rowHeight}
              tableTheme={tableTheme}
            >
              <Loader size="xs" />
            </NoItemsTableData>
          )}
        </TablePanel>
        {isClientSidePagination && (
          <Pagination
            selectedPage={selectedPage}
            itemsTotal={sortedRows?.length}
            itemsPerPageDropdownPosition={itemsPerPageDropdownPosition}
            isItemsPerPageSelectorShown={isItemsPerPageSelectorShown}
            itemsPerPageOptions={itemsPerPageOptions}
            itemsPerPage={itemsPerPage}
            onChange={setSelectedPage}
            onItemsPerPageChange={setSelectedItemsPerPage}
          />
        )}
        {isServerSidePagination && (
          <Pagination
            selectedPage={currentPage}
            itemsTotal={totalCount}
            itemsPerPageDropdownPosition={itemsPerPageDropdownPosition}
            isItemsPerPageSelectorShown={isItemsPerPageSelectorShown}
            itemsPerPageOptions={itemsPerPageOptions}
            itemsPerPage={itemsPerPage}
            onChange={handleSelectPage}
            onItemsPerPageChange={setSelectedItemsPerPage}
          />
        )}
      </Container>
    );
  },
);

const Container = styled.div``;

const Pagination = styled(Paginator)`
  margin-top: 48px;
`;

const SortIcon = styled(Icon)`
  margin-left: 4px;
  color: ${Color.grey600};
  path:last-of-type {
    stroke: ${Color.grey300};
  }
  path:first-of-type {
    stroke: ${Color.grey300};
  }
`;

const SortIconRowHover = styled(SortIcon)<{
  direction?: SortDirection;
}>`
  display: none;
  ${({ direction }) => {
    switch (direction) {
      case SortDirection.ASC:
        return `
          path:first-of-type {
            stroke: ${Color.grey600};
          }
        `;
      case SortDirection.DESC:
        return `
          path:last-of-type {
            stroke: ${Color.grey600};
          }
        `;
      default:
        return `
        `;
    }
  }};
`;

const SortIconNoHover = styled(SortIcon)<{
  isVisible?: boolean;
  direction?: SortDirection;
}>`
  display: ${({ isVisible }) => (isVisible ? 'inline-block' : 'none')};
  ${({ direction }) => {
    switch (direction) {
      case SortDirection.ASC:
        return `
          path:first-of-type {
            stroke: ${Color.grey600};
          }

          path:last-of-type {
            stroke: ${Color.grey300};
          }
        `;
      case SortDirection.DESC:
        return `
          path:first-of-type {
            stroke: ${Color.grey300};
          }

          path:last-of-type {
            stroke: ${Color.grey600};
          }
        `;
      default:
        return `
        `;
    }
  }};
`;

const IconContainer = styled.div<{
  isVisible?: boolean;
}>`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

const StyledIcon = styled(Icon)<{
  isVisible?: boolean;
}>`
  cursor: pointer;
  padding: 3px;
  fill: ${Color.grey400};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};

  &:hover {
    border-radius: 50%;
    background-color: ${Color.grey100};
  }
`;

const TablePanel = styled(Panel)<{
  isHorizontalScrollEnabled?: boolean;
  tableTheme: ActionTableTheme;
}>`
  border-radius: 8px;
  border-color: ${Color.grey400};
  overflow: hidden;
  ${({ isHorizontalScrollEnabled }) =>
    isHorizontalScrollEnabled ? 'overflow-x: auto' : ''}
`;

const TableDataRow = styled(TableRow)<{
  tableTheme?: ActionTableTheme;
  height?: string;
  isRowClickEnabled?: any;
  isHighlighted?: boolean;
}>`
  background-color: ${({ tableTheme }) =>
    tableTheme === ActionTableTheme.DISABLED ? Color.grey050 : Color.white};

  &:nth-child(even) {
    background-color: ${({ tableTheme }) =>
      tableTheme === ActionTableTheme.SECONDARY ? Color.white : Color.grey050};
  }

  &:hover {
    ${({ isRowClickEnabled, tableTheme }) => {
      if (isRowClickEnabled) {
        return `
        background-color: ${Color.grey100};
        cursor: pointer;
        `;
      }
      if (tableTheme === ActionTableTheme.DISABLED) {
        return 'cursor: not-allowed;';
      }
      return '';
    }}

    ${StyledIcon} {
      visibility: visible;
    }
  }

  ${({ isHighlighted }) =>
    isHighlighted &&
    `
    background-color: ${Color.grey200} !important;
  `}
`;

const TableHeadRow = styled(TableDataRow)<{
  tableTheme: ActionTableTheme;
}>`
  background-color: ${({ tableTheme }) =>
    tableTheme === ActionTableTheme.PRIMARY ? Color.white : Color.grey100};

  &:hover {
    ${SortIconRowHover} {
      display: inline-block;
    }
    ${SortIconNoHover} {
      display: none;
    }
  }
`;

const TableData = styled(TableCell)<{
  width?: string;
  height?: string;
  title?: any;
  tableTheme?: ActionTableTheme;
}>`
  border-left: none;
  border-right: none;
  border-radius: 0px;
  padding: 0 30px;
  height: ${({ height }) => height || '50px'};
  border: ${({ tableTheme }) =>
    tableTheme === ActionTableTheme.PRIMARY ? 'none' : 'inherit'};
`;

const TableHeading = styled(TableData)<{
  height?: string;
  isSortable?: boolean;
}>`
  height: ${({ height }) => height || '70px'};
  cursor: ${({ isSortable }) => (isSortable ? 'pointer' : 'default')};

  &,
  &[scope='col'] {
    border-bottom: 1px solid ${Color.grey400};
  }
`;

const TableHeadingContent = styled.div`
  display: inline-flex;
  align-items: center;
`;

const NoItemsTableData = styled(Text)<{
  height?: string;
  tableTheme: ActionTableTheme;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${Color.grey400};
  padding: 0 30px;
  height: ${({ height }) => height || '50px'};
`;

const TooltipWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
`;

const Flex = styled.div`
  display: flex;
`;

const HeaderText = styled.div`
  display: flex;
  align-items: center;
`;

export default memo(ActionTable);
