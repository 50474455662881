import React, { Ref, useContext } from 'react';
import { eachDayOfInterval, isSameMonth, lightFormat } from 'date-fns';
import styled from 'styled-components';
import { enGB } from 'date-fns/locale';

import CalendarDay from '../CalendarDay';
import {
  DayCellStateModifiers,
  DayCellState,
} from '../../atoms/DateRangePicker/utils';
import CalendarContext from '../../atoms/Calendar/CalendarContext';

import useGrid from './utils/useGrid';
import { OriginDirection } from './constants';

const DatesGrid = styled.div<{ cellHeight: number }>`
  box-sizing: content-box;
  overflow: hidden;
  position: relative;
  user-select: none;
  height: ${({ cellHeight }) => `${cellHeight * 6}px`};
`;

const DatesGridWrapper = styled.div<{
  offset: number;
  transitionDuration: number;
  transition: boolean;
  directionBottom: boolean;
  directionTop: boolean;
}>`
  backface-visibility: hidden;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  right: 0;
  top: ${({ directionBottom }) => `${directionBottom ? 'auto' : '0'}`};
  bottom: ${({ directionBottom }) => `${directionBottom ? '0' : 'auto'}`};
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
  transform: ${({ offset }) =>
    `${
      offset
        ? `translate3d(0px, ${offset}px, 0px)`
        : `translate3d(0px, 0px, 0px)`
    }`};
  transition-duration: ${({ transitionDuration }) => `${transitionDuration}ms`};
  transition-property: ${({ transition }) =>
    `${transition ? 'transform' : 'none'}`};
`;

const computeModifiers = (
  modifiers: DayCellStateModifiers,
  date: Date,
): DayCellState => {
  const computedModifiers: DayCellState = {};

  Object.keys(modifiers).forEach((key: string) => {
    const prop = modifiers[key as keyof DayCellStateModifiers];

    if (prop) {
      computedModifiers[key as keyof DayCellStateModifiers] = prop(date);
    }
  });

  return computedModifiers;
};

interface CalendarGridProps {
  month: Date;
  modifiers: DayCellStateModifiers;
  onDayHover?: (value: Date | null) => void;
  onDayClick?: (value: Date) => void;
  transitionDuration: number;
}

const CalendarGrid: React.FC<CalendarGridProps> = ({
  month,
  modifiers,
  onDayHover,
  onDayClick,
  transitionDuration,
}) => {
  const { locale = enGB, translations } = useContext(CalendarContext);

  const grid = useGrid({
    locale,
    month,
    transitionDuration,
  });
  const {
    startDate,
    endDate,
    cellHeight,
    containerElementRef,
    isWide,
    offset,
    origin,
    transition,
  } = grid;

  const days = eachDayOfInterval({
    start: startDate,
    end: endDate,
  }).map(date => (
    <CalendarDay
      date={date}
      height={cellHeight}
      key={lightFormat(date, 'yyyy-MM-dd')}
      locale={locale}
      validationMessage={translations.dateOutsideRange}
      modifiers={{
        ...computeModifiers(modifiers, date),
        outside: !isSameMonth(date, month),
        wide: isWide,
      }}
      onHover={onDayHover}
      onClick={onDayClick}
    />
  ));

  return (
    <DatesGrid cellHeight={cellHeight!}>
      <DatesGridWrapper
        directionBottom={origin === OriginDirection.ORIGIN_BOTTOM}
        directionTop={origin === OriginDirection.ORIGIN_TOP}
        transition={transition}
        offset={offset}
        transitionDuration={transitionDuration}
        ref={containerElementRef as Ref<HTMLDivElement>}
      >
        {days}
      </DatesGridWrapper>
    </DatesGrid>
  );
};

export default CalendarGrid;
