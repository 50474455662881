import React, { useState } from 'react';
import { endOfDay, isAfter, isBefore, isSameDay, startOfDay } from 'date-fns';

import { FocusState } from '../../organisms/DateRangePickerCalendar/constants';
import Calendar from '../Calendar';

import useControllableState from './helpers/useControllableState';

interface DateRangePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  focus: FocusState;
  onStartDateChange: (value: Date | null) => void;
  onEndDateChange: (value: Date | null) => void;
  onFocusChange: (value: FocusState) => void;
  minimumLength: number;
  maximumLength: number;
  weekdayFormat: string;
  month: Date;
  onMonthChange?: (value: Date) => void;
  minimumDate: Date;
  maximumDate: Date;
  onApply: (start: Date, end: Date, granularity: number | null) => void;
  currentFormat: '12' | '24';
  setCurrentFormat: (value: '12' | '24') => void;
  granularityItems: number[];
  granularity: number;
  onGranularityChange: (value: number | null) => void;
  defaultTimePeriod: number;
  timePeriodState: number | null;
  setTimePeriodState: (value: number | null) => void;
  defaultGranularity: number;
  showGranularity?: boolean;
  className?: string;
}

/**
 * The container in which the calendar is located
 * @param startDate - selected start date in calendar, if no date is selected, then - undefined
 * @param endDate - selected end date in calendar, if no date is selected, then - undefined
 * @param focus - value, returns a string indicating what the user currently selects: a start or end date
 * @param receivedMonth - default month
 * @param onStartDateChange - method for changing the start date
 * @param onEndDateChange - method for changing the end date
 * @param onFocusChange - method for changing the focus
 * @param onMonthChange - method for changing a month
 * @param minimumDate - minimum date that user selected
 * @param maximumDate - maximum date that user selected
 * @param minimumLength - minimum date that user can select
 * @param maximumLength - maximum date that user can select
 * @param weekdayFormat - the format for displaying the days of the week in the calendar
 * @param onChange - change the date date on the calendar component
 * @param showGranularity - show granularity options
 * @constructor
 */
const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  focus,
  month: receivedMonth,
  onStartDateChange,
  onEndDateChange,
  onFocusChange,
  onMonthChange,
  minimumDate,
  maximumDate,
  minimumLength,
  maximumLength,
  weekdayFormat,
  onApply,
  currentFormat,
  setCurrentFormat,
  defaultGranularity,
  granularityItems,
  granularity,
  defaultTimePeriod,
  onGranularityChange,
  timePeriodState,
  setTimePeriodState,
  showGranularity = true,
  className,
}) => {
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);
  const [month, setMonth] = useControllableState(receivedMonth, onMonthChange);
  let displayedStartDate: Date | null = null;
  let displayedEndDate: Date | null = null;

  if (focus === FocusState.START_DATE) {
    const isReverseSelectionStarted =
      hoveredDate && startDate && isBefore(hoveredDate, startDate);
    if (isReverseSelectionStarted) {
      displayedEndDate = startDate;
      displayedStartDate = hoveredDate;
    } else {
      displayedStartDate = startDate;
      displayedEndDate = hoveredDate;
    }
  } else if (focus === FocusState.END_DATE) {
    const isReverseSelectionEnded =
      endDate && startDate && isBefore(endDate, startDate);
    if (isReverseSelectionEnded) {
      displayedStartDate = endDate;
      displayedEndDate = startDate;
    } else {
      displayedStartDate = startDate;
      displayedEndDate = endDate;
    }
  } else {
    // focus === FocusState.NONE
  }

  const isStartDate = (date: Date): boolean =>
    !!displayedStartDate && isSameDay(date, displayedStartDate);

  const isMiddleDate = (date: Date): boolean => {
    if (!displayedStartDate || !displayedEndDate) return false;
    const isReverseSelection = isBefore(displayedEndDate, displayedStartDate);
    if (isReverseSelection) {
      return (
        isBefore(date, displayedStartDate) && isAfter(date, displayedEndDate)
      );
    }
    return (
      isBefore(date, displayedEndDate) && isAfter(date, displayedStartDate)
    );
  };

  const isEndDate = (date: Date): boolean =>
    !!displayedEndDate && isSameDay(date, displayedEndDate);

  const isDisabled = (date: Date): boolean =>
    (!!minimumDate && isBefore(startOfDay(date), startOfDay(minimumDate))) ||
    (!!maximumDate && isAfter(endOfDay(date), endOfDay(maximumDate)));

  const handleSelectDate = (date: Date): void => {
    if (focus === FocusState.NONE) {
      onStartDateChange(date);
      onFocusChange(FocusState.START_DATE);
    } else if (focus === FocusState.START_DATE) {
      const isReverseSelectionStarted =
        hoveredDate && startDate && isBefore(hoveredDate, startDate);
      if (isReverseSelectionStarted) {
        onEndDateChange(startDate);
        onStartDateChange(endOfDay(date));
      } else {
        onEndDateChange(endOfDay(date));
      }
      onFocusChange(FocusState.END_DATE);
    } else if (focus === FocusState.END_DATE) {
      onStartDateChange(date);
      onEndDateChange(null);
      onFocusChange(FocusState.START_DATE);
    }
  };

  return (
    <Calendar
      currentDate={month}
      startDate={startDate}
      endDate={endDate}
      focus={focus}
      onMonthChange={setMonth}
      onDayHover={setHoveredDate}
      onDayClick={handleSelectDate}
      minimumDate={minimumDate}
      maximumDate={maximumDate}
      modifiers={{
        selectedStart: isStartDate,
        selectedMiddle: isMiddleDate,
        selectedEnd: isEndDate,
        disabled: isDisabled,
      }}
      weekdayFormat={weekdayFormat}
      onStartDateChange={onStartDateChange}
      onEndDateChange={onEndDateChange}
      onFocusChange={onFocusChange}
      onApply={onApply}
      currentFormat={currentFormat}
      setCurrentFormat={setCurrentFormat}
      granularityItems={granularityItems}
      granularity={granularity}
      onGranularityChange={onGranularityChange}
      defaultTimePeriod={defaultTimePeriod}
      timePeriodState={timePeriodState}
      setTimePeriodState={setTimePeriodState}
      defaultGranularity={defaultGranularity}
      showGranularity={showGranularity}
      className={className}
    />
  );
};

export default DateRangePicker;
