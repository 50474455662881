import { Color } from '../../theme/primitives';

const theme = {
  cardsDropdown: {
    color: {
      default: {
        inactive: {
          background: Color.white,
          text: Color.grey800,
          border: Color.grey400,
          placeholderText: Color.grey400,
        },
        hover: {
          background: Color.white,
          text: Color.grey800,
          border: Color.grey800,
        },
        focus: {
          background: Color.white,
          text: Color.grey800,
          border: Color.grey800,
        },
        error: {
          background: Color.white,
          text: Color.grey800,
          border: Color.red400,
        },
      },
      lighter: {
        inactive: {
          background: Color.white,
          text: Color.grey600,
          border: Color.grey400,
          placeholderText: Color.grey600,
        },
        hover: {
          background: Color.white,
          text: Color.grey600,
          border: Color.grey600,
        },
        focus: {
          background: Color.white,
          text: Color.grey600,
          border: Color.grey600,
        },
        error: {
          background: Color.white,
          text: Color.grey600,
          border: Color.red400,
        },
      },
    },
    shape: {
      default: {
        padding: '8px 16px',
        borderRadius: '4px',
        borderStyle: 'solid',
        borderWidth: '1px',
      },
      lighter: {
        padding: '8px 16px',
        borderRadius: '4px',
        borderStyle: 'solid',
        borderWidth: '1px',
      },
    },
  },
};

export default theme;
