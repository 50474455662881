import React from 'react';

import { Pill, PillVariant } from '../../Pill';

export interface CardItemPillProps {
  variant?: PillVariant;
  text: string;
  className?: string;
}

export const CardItemPill: React.FC<CardItemPillProps> = ({
  variant,
  text,
  className,
}) => (
  <Pill
    variant={variant || 'blue'}
    text={text}
    title={text}
    className={className}
  />
);
