import React from 'react';
import styled from 'styled-components';

import { useSelectInteraction } from '../context/ContextSelectInteraction';
import { useBarGraphContext } from '../../../../organisms/BarGraph/hooks/BarGraphContext';

export interface MarkerValueDisplayProps {
  className?: string;
  formatValue?: (data: any) => string | number;
}

export const SelectedValueDisplay: React.FC<MarkerValueDisplayProps> = ({
  className,
  formatValue,
}) => {
  const selectInteractionContext = useSelectInteraction();
  const { xProperty } = useBarGraphContext();
  const [selectInteractionState] = selectInteractionContext || [];

  if (!selectInteractionContext) {
    return null;
  }

  const { data } = selectInteractionState || {};
  const displayedData: any = data || {};

  return (
    <MarkerContent className={className}>
      {formatValue ? formatValue(displayedData) : displayedData[xProperty]}
    </MarkerContent>
  );
};

const MarkerContent = styled.span`
  display: flex;
  padding: 0;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
`;
