import { Size } from '../../theme/primitives';

const theme = {
  sidebar: {
    layout: {
      gap: Size.space400,
    },
  },
};

export default theme;
