import React from 'react';

import { AxisConfig, ChartAreaContext } from './ChartAreaContext';
import useAxisState from './useAxisState';

export interface ChartAreaProviderProps {
  x: AxisConfig;
  y: AxisConfig;
}

const MAX_MARKS_ON_X_AXIS = 8;
const MAX_MARKS_ON_Y_AXIS = 10;

const ChartAreaProvider: React.FC<ChartAreaProviderProps> = ({
  x,
  y,
  children,
}) => {
  const xAxis = useAxisState(x, MAX_MARKS_ON_X_AXIS);
  const yAxis = useAxisState(y, MAX_MARKS_ON_Y_AXIS);
  return (
    <ChartAreaContext.Provider
      value={{
        x: xAxis,
        y: yAxis,
      }}
    >
      {children}
    </ChartAreaContext.Provider>
  );
};

export default ChartAreaProvider;
