let UserAgent: string | null = null;

/**
 * @name Browser#detect
 * @desc Detection function used to parse user agent against an array of strings.
 * @function
 * @param {Array.<string>} items - optional strings to test for in user agent
 * @param {boolean} [either=false] - if the user agent requires all items to pass
 * @returns {boolean}
 */

export function detect(items: Array<string>, either?: boolean) {
  if (!UserAgent) {
    UserAgent = navigator.userAgent.toLowerCase();
  }

  const found = items.filter(item =>
    UserAgent ? UserAgent.indexOf(item.toLowerCase()) >= 0 : false,
  );

  return found.length === items.length || (either && found.length > 0);
}

export const type = (() => {
  if (!UserAgent) {
    UserAgent = navigator.userAgent.toLowerCase();
  }

  if (detect(['edge'])) {
    return 'edge';
  }
  if (detect(['opr'])) {
    return 'opera';
  }
  if (detect(['msie'])) {
    return 'ie';
  }
  if (detect(['trident', 'rv:'])) {
    return 'ie';
  }
  if (detect(['chrome', 'crios'], true)) {
    return 'chrome';
  }
  if (detect(['safari'])) {
    return 'safari';
  }
  if (detect(['firefox'])) {
    return 'firefox';
  }
  return 'unknown';
})();

export const version = (() => {
  if (!UserAgent) {
    UserAgent = navigator.userAgent.toLowerCase();
  }

  try {
    if (type === 'edge') {
      return Number(UserAgent.split('edge/')[1].split('.')[0]);
    }

    if (type === 'opera') {
      return Number(UserAgent.split('opr/')[1].split('.')[0]);
    }

    if (type === 'ie') {
      if (UserAgent.indexOf('msie') >= 0) {
        return Number(UserAgent.split('msie ')[1].split('.')[0]);
      }

      if (UserAgent.indexOf('rv:') >= 0) {
        return Number(UserAgent.split('rv:')[1].split('.')[0]);
      }
    }

    if (type === 'firefox') {
      return Number(UserAgent.split('firefox/')[1].split('.')[0]);
    }

    if (type === 'safari') {
      return Number(
        UserAgent.split('version/')[1]
          .split('.')[0]
          .split('.')[0],
      );
    }

    if (type === 'chrome') {
      if (detect(['crios']) && UserAgent) {
        return Number(UserAgent.split('crios/')[1].split('.')[0]);
      }

      return UserAgent
        ? Number(UserAgent.split('chrome/')[1].split('.')[0])
        : -1;
    }
  } catch (e) {
    return -1;
  }
  return -1;
})();

export const isMobile =
  !!('ontouchstart' in window || 'onpointerdown' in window) &&
  detect(
    ['ios', 'iphone', 'ipad', 'windows phone', 'android', 'blackberry'],
    true,
  );

const browserUtil = { version, type, detect, isMobile };

export default browserUtil;
