import { Color, Size } from '../../theme/primitives';

const theme = {
  panel: {
    color: {
      default: {
        background: Color.white,
        border: Color.grey400,
      },
      legacy: {
        border: Color.grey300,
        background: Color.white,
      },
      small: {
        background: Color.white,
        border: Color.grey400,
      },
      collapsed: {
        background: Color.white,
        border: Color.grey100,
      },
      focus: {
        background: Color.white,
        border: Color.grey600,
      },
      siteKeySelect: {
        background: Color.white,
        border: Color.grey500,
      },
      percentageBarsWidget: {
        background: Color.white,
        border: Color.grey400,
      },
    },
    shape: {
      default: {
        padding: Size.space600,
        borderRadius: Size.radius300,
        borderStyle: 'solid',
        borderWidth: '1px',
      },
      percentageBarsWidget: {
        padding: Size.space400,
        borderRadius: Size.radius200,
        borderStyle: 'solid',
        borderWidth: '1px',
      },
      small: {
        padding: Size.space300,
        borderRadius: Size.radius200,
        borderStyle: 'solid',
        borderWidth: '1px',
      },
      collapsed: {
        padding: Size.space0,
        borderRadius: Size.radius200,
        borderStyle: 'solid',
        borderWidth: '1px',
      },
      legacy: {
        padding: Size.space800,
        borderRadius: Size.radius200,
        borderStyle: 'solid',
        borderWidth: '1px',
      },
      siteKeySelect: {
        padding: Size.space300,
        borderRadius: Size.radius200,
        borderStyle: 'solid',
        borderWidth: '1px',
      },
    },
  },
};

export default theme;
