import styled from 'styled-components';
import React from 'react';

import { useExpansionPanelState } from './ExpansionPanelContext';

type ExpansionPanelSummaryProps = {
  children: React.ReactNode | React.ReactNode[];
};
export const ExpansionPanelSummary: React.FC<ExpansionPanelSummaryProps> = ({
  children,
}) => {
  const expansionPanelState = useExpansionPanelState();

  if (expansionPanelState === null) {
    throw new Error(
      `ExpansionPanel context not found.
       ExpansionPanelProvider is not placed in the JSX declaration`,
    );
  }

  return <SummaryContentWrapper>{children}</SummaryContentWrapper>;
};

const SummaryContentWrapper = styled.div`
  width: 100%;
`;
