import styled from 'styled-components';

const List = styled.ul<{ inline?: boolean }>`
  display: ${({ inline }) => inline && 'flex'};
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin-right: ${({ inline }) => inline && '10px'};
  }
`;

export default List;
