import { Color, Size } from '../../theme/primitives';

const theme = {
  linearProgress: {
    shape: {
      default: {
        borderRadius: Size.radius100,
        height: Size.space300,
      },
    },
    color: {
      default: {
        background: Color.grey100,
        filledBackground: Color.grey500,
      },
    },
  },
};

export default theme;
