import { makeAutoObservable } from 'mobx';

import Data from 'shared/data/Data';

export default class UserStore {
  data: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  get role() {
    const {
      is_enterprise_publisher = false,
      is_pro_publisher = false,
      is_accessibility = false,
      is_staff = false,
      is_support = false,
      is_requester = false,
    } = this.data;

    if (is_staff) return 'staff';
    if (is_pro_publisher) return 'pro';
    if (is_enterprise_publisher) return 'enterprise';
    if (is_support) return 'support';
    if (is_requester) return 'requester';
    if (is_accessibility) return 'accessibility';
    return 'webmaster';
  }

  setData = (data: any) => {
    this.data = data;
    this.data.features = { ...Data.featureToggle, ...this.data.features };
  };

  addAuthorizedAccount = (newAcc: {
    email: string;
    role: string;
    auth_method: boolean;
    login_passkey: boolean;
    sso: string;
    two_fa: boolean;
  }) => {
    const { authorized_accounts = [] } = this.data;

    this.data = {
      ...this.data,
      authorized_accounts: [newAcc, ...authorized_accounts],
    };
  };

  removeAuthorizedAccount = (email: string) => {
    const { authorized_accounts = [] } = this.data;
    this.data = {
      ...this.data,
      authorized_accounts: authorized_accounts.filter(
        user => user.email !== email,
      ),
    };
  };

  setAuthorizedAccounts = (authorized_accounts: Array<any>) => {
    this.data = { ...this.data, authorized_accounts };
  };

  getAvailableAccounts = () => {
    const { available_accounts = [] } = this.data;
    return available_accounts;
  };

  getAccounts = () => {
    const availableAccounts = this.getAvailableAccounts();
    const currentUserEmail = this.getEmail();

    if (!availableAccounts) {
      return [];
    }

    const accounts = [...availableAccounts]
      .map(account => {
        return { email: account.email, id: account.id };
      })
      .filter(account => account.email !== currentUserEmail);

    return accounts;
  };

  setAvailableAccounts = (available_accounts: Array<any>) => {
    this.data = { ...this.data, available_accounts };
  };

  getAuthorizedAccounts = () => {
    const { authorized_accounts = [] } = this.data;
    return authorized_accounts;
  };

  getLoggedInFromAnotherAccountEmail = () => {
    const { logged_in_from_another_account = undefined } = this.data;
    return logged_in_from_another_account;
  };

  getEmail = () => {
    const { email = undefined } = this.data;
    return email;
  };

  getFeatures = () => {
    const { features = {} } = this.data;
    return features;
  };

  getTrafficQualityMsg = () => {
    const { avg_traffic_quality_msg = '' } = this.data;
    return avg_traffic_quality_msg;
  };

  getPayoutEmail = () => {
    const { payout_email = '' } = this.data;
    return payout_email;
  };

  getPayoutScheduled = () => {
    const { payout_scheduled = false } = this.data;
    return payout_scheduled;
  };

  setPayoutScheduled = (value: boolean) => {
    this.data = { ...this.data, payout_scheduled: value };
  };

  updatePayoutEmail = (email: string) => {
    this.data = { ...this.data, payout_email: email };
  };

  getReferralCodeUrl = () => {
    const { referral_code_url = '' } = this.data;
    return referral_code_url;
  };

  getSignUpsCount = () => {
    const { referred_signups_count = 0 } = this.data;
    return referred_signups_count;
  };

  getApiKey = () => {
    const { api_key = '' } = this.data;
    return api_key;
  };

  getSigningKey = () => {
    const { signing_key = '' } = this.data;
    return signing_key;
  };

  setApiKey = (newApiKey: string) => {
    this.data = { ...this.data, api_key: newApiKey };
  };

  setSigningKey = (newSigningKey: string) => {
    this.data = { ...this.data, signing_key: newSigningKey };
  };

  getSecret = () => {
    const { secret = '' } = this.data;
    return secret;
  };

  setSecret = (secret: string) => {
    this.data = { ...this.data, secret };
  };

  getAccountESecret = () => {
    const { account_es_key = '' } = this.data;
    return account_es_key;
  };

  setAccountESecret = (account_es_key: string) => {
    this.data = { ...this.data, account_es_key };
  };

  setAvailableSecretRotations = (rotationsLeft: number) => {
    this.data = { ...this.data, secret_updates_left: rotationsLeft };
  };

  getAvailableSecretRotations = () => {
    const { secret_updates_left = 3 } = this.data;
    return secret_updates_left;
  };

  getInitialInterests = () => {
    const { initial_interests = [] } = this.data;
    return initial_interests;
  };

  getThreatReports = () => {
    const { threat_reports = [] } = this.data;
    return threat_reports;
  };

  getOrganizations = () => {
    const { organizations = [] } = this.data;
    return organizations;
  };

  setOrganizations = (organizations: string[]) => {
    this.data = { ...this.data, organizations };
  };

  getData = () => {
    return this.data;
  };

  getWebhookNotificationsEnabled = () => {
    const { webhook_notifications_enabled = false } = this.data;
    return webhook_notifications_enabled;
  };

  setWebhookNotificationsEnabled = (value: boolean) => {
    this.data = { ...this.data, webhook_notifications_enabled: value };
  };

  getAvailableThreatFactors = () => {
    const { available_threat_factors = [] } = this.data;
    return available_threat_factors;
  };

  setOnBoardingEnabled = (value: boolean) => {
    this.data = { ...this.data, onboarding_enabled: value };
  };

  getOnboaringEnabled = () => {
    const { onboarding_enabled = true } = this.data;
    return onboarding_enabled;
  };

  getCustomThreatModels = () => {
    return this.data.custom_threat_models;
  };

  getBillingDomainWhitelisted = () => {
    const { billing_domain_whitelist = false } = this.data;
    return billing_domain_whitelist;
  };

  isRequester = () => {
    const { is_requester: isRequester = false } = this.data;

    return isRequester;
  };

  isPublisher = () => {
    const { is_webmaster: isWebmaster = false } = this.data;

    return isWebmaster;
  };

  isProPublisher = () => {
    const { is_pro_publisher: isProPublisher = false } = this.data;

    return isProPublisher;
  };

  isEnterprisePublisher = () => {
    const {
      is_enterprise_publisher: isEnterprisePublisher = false,
    } = this.data;

    return isEnterprisePublisher;
  };

  isStaff = () => {
    const { is_staff: isStaff = false } = this.data;

    return isStaff;
  };

  isSupport = () => {
    const { is_support: isSupport = false } = this.data;

    return isSupport;
  };

  isLabeler = () => {
    const { is_labeler_not_existing: isLabeler = false } = this.data;

    return isLabeler;
  };

  setRuleReviewWebhooks = (webhooks: any) => {
    this.data = { ...this.data, rule_review_webhooks: webhooks };
  };

  setRuleReviewEnabled = (value: boolean) => {
    this.data = { ...this.data, rule_review_enabled: value };
  };

  getRuleReviewEnabled = () => {
    const { rule_review_enabled = false } = this.data;
    return rule_review_enabled;
  };

  setRetroTaggingWebhooksEnabled = (value: boolean) => {
    this.data = { ...this.data, retro_tagging_webhooks_enabled: value };
  };

  getRetroTaggingWebhooksEnabled = () => {
    const { retro_tagging_webhooks_enabled = false } = this.data;
    return retro_tagging_webhooks_enabled;
  };
}
