import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { isValid } from 'date-fns';

import { isEnterPressed } from '../../utils';

import { formatDate, formatTime } from './utils';

export interface DateInputProp {
  label?: string;
  isActive?: boolean;
  width: number;
  height?: number;
  setIsExpanded?: (value: boolean) => void;
  className?: string;
  dateTime: Date | null;
  timeFormat: string;
  onChange?: (datetime: Date) => void;
  validationError?: string;
  onValidate?: (datetime: Date) => void;
}

export const DateInput: React.FC<DateInputProp> = ({
  label,
  isActive,
  width,
  height = 30,
  setIsExpanded,
  className,
  dateTime,
  timeFormat,
  onChange,
  validationError,
  onValidate,
}) => {
  const [inputDate, setInputDate] = useState(formatDate(dateTime));
  const [inputTime, setInputTime] = useState(formatTime(dateTime, timeFormat));

  useEffect(() => {
    if (!onValidate) return;

    const dateToValidate = new Date(`${inputDate} ${inputTime}`);

    if (!isValid(dateToValidate)) return;

    onValidate(dateToValidate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDate, inputTime]);

  useEffect(() => {
    setInputDate(formatDate(dateTime));
    setInputTime(formatTime(dateTime, timeFormat));
  }, [dateTime, timeFormat]);

  const handleDateTimeSelect = () => {
    if (validationError) return;

    onChange?.(new Date(`${inputDate} ${inputTime}`));
  };

  const handleInputKeyPress = (event: React.KeyboardEvent) => {
    if (validationError) return;

    if (isEnterPressed(event.key)) {
      handleDateTimeSelect();
    }
  };

  return (
    <DateInputWrapper width={width} data-error={!!validationError}>
      <DateInputContainer
        height={height}
        className={className}
        onClick={() => setIsExpanded && setIsExpanded(true)}
      >
        {label && (
          <DateInputLabel isActive={isActive} id="date-input">
            {label}
          </DateInputLabel>
        )}
        <DateInputTxt
          aria-labelledby="date-input"
          type="text"
          value={inputDate}
          disabled={!onChange}
          onChange={event => setInputDate?.(event.target.value)}
          onKeyPress={handleInputKeyPress}
          onBlur={handleDateTimeSelect}
        />
        <DateInputTxt
          aria-labelledby="time-input"
          type="text"
          value={inputTime}
          disabled={!onChange}
          onChange={event => setInputTime?.(event.target.value)}
          onKeyPress={handleInputKeyPress}
          onBlur={handleDateTimeSelect}
        />
      </DateInputContainer>
      {validationError && <ErrorMessage>{validationError}</ErrorMessage>}
    </DateInputWrapper>
  );
};

const DateInputContainer = styled.div<{
  height: number;
}>`
  border: 1px solid;
  display: flex;
  align-items: center;
  border-color: ${({ theme }) => theme.global.color.text};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.panel.color.default.background};
  justify-content: space-evenly;

  height: ${({ height }) => height}px;
`;

const DateInputWrapper = styled.div<{
  width: number;
}>`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  width: ${({ width }) => width}px;

  &[data-error='true'] {
    margin-bottom: 2px;

    ${DateInputContainer} {
      border-color: ${({ theme }) => theme.formFieldError.color.text};
    }
  }
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  color: ${({ theme }) => theme.formFieldError.color.text};
`;

const DateInputLabel = styled.label<{
  isActive?: boolean;
}>`
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-right: 1px solid;
  border-color: ${({ theme }) => theme.global.color.text};
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: ${({ theme, isActive }) =>
    isActive && theme.global.color.background};
  transition: background-color 200ms linear;
`;

const DateInputTxt = styled.input`
  width: 100%;
  padding: 0;
  text-align: center;
  border: none;
  font-size: 12px;
  color: ${({ theme }) => theme.global.color.text};
  background-color: ${({ theme }) => theme.panel.color.default.background};

  &:first-of-type {
    border-right: 1px solid;
    border-color: ${({ theme }) => theme.global.color.background};
  }

  :disabled {
    cursor: inherit;
  }

  &:focus-visible {
    outline: none;
  }
`;
