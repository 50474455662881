import React from 'react';
import styled from 'styled-components';

import { BarGraphLegend } from '../../atoms/BarGraphLegend';
import { logarithmicToLinear } from '../../utils/mathUtils';

import CanvasWrapper from './components/CanvasWrapper';
import { GroupedBarGraphAxisX, GroupedBarGraphAxisY } from './types';
import { LEGEND_HEIGHT } from './constants';

export interface GroupedBarGraphProps {
  data: object[];
  width: number;
  height: number;
  xAxis: GroupedBarGraphAxisX;
  yAxis: GroupedBarGraphAxisY;
  showLegend?: boolean;
  tooltip?: React.ReactNode;
  tooltipProperty?: string;
  tooltipLabel?: string;
  useLogScaleY?: boolean;
  showMarker?: boolean;
  markerWidth?: number;
  markerValueDisplay?: React.ReactNode;
  className?: string;
}

export const GroupedBarGraph: React.FC<GroupedBarGraphProps> = ({
  data,
  width,
  height,
  xAxis,
  yAxis,
  showLegend,
  tooltip,
  tooltipProperty,
  tooltipLabel,
  showMarker,
  markerWidth,
  markerValueDisplay,
  useLogScaleY = true, // DO NOT SET TO FALSE causes app to freeze; investigation needed
  className,
}) => {
  const { property } = xAxis.source;

  const sortedData = data.sort((a, b) =>
    (a as any)[property] > (b as any)[property] ? 1 : -1,
  );

  const seriesNames = yAxis.source.series.map(serie => serie.name);

  const dataToGraph = useLogScaleY
    ? sortedData.map(item => {
        const itemKeys = Object.keys(item);
        const transformedItem = { ...item };

        itemKeys.forEach(key => {
          if (seriesNames.includes(key)) {
            (transformedItem as any)[key] = logarithmicToLinear(
              (item as any)[key],
              yAxis.scale.min,
              yAxis.scale.max,
            );
          }
        });

        return transformedItem;
      })
    : sortedData;

  const yAxisScale = useLogScaleY
    ? { ...yAxis.scale, min: 0, max: 1 }
    : yAxis.scale;

  return (
    <Container
      width={width}
      height={showLegend ? height + LEGEND_HEIGHT : height}
      className={className}
    >
      {showLegend && (
        <BarGraphLegend
          columnSeries={yAxis.source.series}
          height={LEGEND_HEIGHT}
        />
      )}
      <CanvasWrapper
        data={dataToGraph}
        width={width}
        height={height}
        xAxis={xAxis}
        yAxis={{ ...yAxis, scale: yAxisScale }}
        tooltip={tooltip}
        tooltipProperty={tooltipProperty}
        tooltipLabel={tooltipLabel}
        showMarker={showMarker}
        markerWidth={markerWidth}
        markerValueDisplay={markerValueDisplay}
      />
    </Container>
  );
};

const Container = styled.div<{ width: number; height: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;
