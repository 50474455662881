import { lerp } from '../../../../utils/mathUtils';
import {
  CropWidgetBoundValue,
  CLOSE_BUTTON_PADDING,
  CLOSE_BUTTON_DIAMETER,
  HANDLE_BOUND_EDGE_X,
  ZOOM_BUTTON_DIAMETER,
} from '../zoomConstants';

export const getBoundValue = (
  xCoord: number,
  min: number,
  max: number,
  width: number,
) =>
  ({
    canvasValue: xCoord,
    dateValue: lerp(min, max, xCoord / width),
  } as CropWidgetBoundValue);

export const getSortedBounds = (bounds: CropWidgetBoundValue[]) =>
  // bounds can be empty array
  bounds.length
    ? bounds.sort(({ canvasValue: a }, { canvasValue: b }) => a - b)
    : null;

export const getBoundsRange = (leftBound?: number, rightBound?: number) => {
  if (typeof leftBound !== 'number' || typeof rightBound !== 'number') {
    return 0;
  }

  return rightBound - leftBound;
};

export const getIsWithinHandleBoundsX = (x: number, widgetBound: number) => {
  const leftHandleBound = widgetBound - HANDLE_BOUND_EDGE_X;
  const rightHandleBound = widgetBound + HANDLE_BOUND_EDGE_X;

  const isHandleClick = x >= leftHandleBound && x <= rightHandleBound;

  return isHandleClick;
};

export const getWidgetCenterX = (leftBound: number, rightBound: number) =>
  leftBound + (rightBound - leftBound) / 2;

export const getIsWithinCloseButtonX = (
  x: number,
  widgetRightBoundX: number,
) => {
  const closeButtonRightBound = widgetRightBoundX - CLOSE_BUTTON_PADDING;
  const closeButtonLeftBound = closeButtonRightBound - CLOSE_BUTTON_DIAMETER;

  return x >= closeButtonLeftBound && x <= closeButtonRightBound;
};

export const getIsWithinCloseButtonY = (y: number) => {
  const closeButtonUpperBound = CLOSE_BUTTON_PADDING;
  const closeButtoLowerBound = closeButtonUpperBound + CLOSE_BUTTON_DIAMETER;

  return y >= closeButtonUpperBound && y <= closeButtoLowerBound;
};

export const getIsWithinZoomButtonX = (x: number, widgetCenterX: number) => {
  const zoomButtonRadius = ZOOM_BUTTON_DIAMETER / 2;

  return (
    x >= widgetCenterX - zoomButtonRadius &&
    x <= widgetCenterX + zoomButtonRadius
  );
};

export const getIsWithinZoomButtonY = (y: number, widgetCenterY: number) => {
  // this assumes zoom button has a translateY of -25%
  // (i.e. its top bound is moved 1/4th its dimension upwards from centerY)
  const zoomButtonHalfRadius = ZOOM_BUTTON_DIAMETER / 4;

  return (
    y >= widgetCenterY - zoomButtonHalfRadius &&
    y <= widgetCenterY + ZOOM_BUTTON_DIAMETER - zoomButtonHalfRadius
  );
};
