import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components';

import { Color } from '../../theme/primitives';
import { Icon } from '../Icon';

enum ToastType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

const ToastContext = createContext({
  [ToastType.SUCCESS]: (content: string) => {},
  [ToastType.INFO]: (content: string) => {},
  [ToastType.WARNING]: (content: string) => {},
  [ToastType.ERROR]: (
    content: string,
    renderContent?: () => React.ReactNode,
  ) => {},
});

const useToast = () =>
  useContext<{
    success: (content: string) => void;
    info: (content: string) => void;
    warning: (content: string) => void;
    error: (content: string, renderContent?: () => React.ReactNode) => void;
  }>(ToastContext);

const useTimeout = (callback: any, delay: number) => {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.=
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the timeout
  useEffect(() => {
    // Don't schedule if no delay is specified
    if (delay === null) return undefined;

    const id = setTimeout(() => savedCallback.current(), delay);

    return () => clearTimeout(id);
  }, [delay]);
};

const Toast = (props: {
  onclose: React.MouseEventHandler<HTMLButtonElement> | undefined;
  type: ToastType;
  children: string;
  renderContent?: () => React.ReactNode;
}) => {
  useTimeout(props.onclose, 10000);

  const getIconType = (type: string) => {
    if (type === ToastType.ERROR) {
      return 'closeAlt';
    }
    if (type === ToastType.SUCCESS) {
      return 'checkMark';
    }

    if (type === ToastType.WARNING) {
      return 'warningCircle';
    }

    return 'infoAlt';
  };

  return (
    <ToastContainer>
      <TextContainer>
        <IconContainer type={props.type}>
          <Icon
            icon={getIconType(props.type)}
            iconSize="18"
            fill={Color.white}
          />
        </IconContainer>
        <ToastText>{props.children}</ToastText>
      </TextContainer>
      <ActionsContainer>
        {props.renderContent ? props.renderContent() : null}
        <ToastCloseBtn onClick={props.onclose}>&#215;</ToastCloseBtn>
      </ActionsContainer>
    </ToastContainer>
  );
};

function generateUEID() {
  let first = (Math.random() * 46656 || 0).toString(36);
  let second = (Math.random() * 46656 || 0).toString(36);
  first = `000${first}`.slice(-3);
  second = `000${second}`.slice(-3);

  return first + second;
}

const ToastProvider = (props: { children: any }) => {
  const [toasts, setToasts] = useState<any>([]);
  const success = (content: string) =>
    setToasts((currentToasts: any) => [
      { id: generateUEID(), content, type: ToastType.SUCCESS },
      ...currentToasts,
    ]);
  const info = (content: string) =>
    setToasts((currentToasts: any) => [
      { id: generateUEID(), content, type: ToastType.INFO },
      ...currentToasts,
    ]);

  const warning = (content: string) =>
    setToasts((currentToasts: any) => [
      { id: generateUEID(), content, type: ToastType.WARNING },
      ...currentToasts,
    ]);
  const error = (content: string, renderContent?: () => React.ReactNode) =>
    setToasts((currentToasts: any) => [
      { id: generateUEID(), content, type: ToastType.ERROR, renderContent },
      ...currentToasts,
    ]);
  const close = (id: any) =>
    setToasts((currentToasts: any) =>
      currentToasts.filter((toast: any) => toast.id !== id),
    );
  const contextValue = useMemo(() => ({ success, info, error, warning }), []);

  return (
    <ToastContext.Provider value={contextValue}>
      {props.children}

      {createPortal(
        <ToastsWrapper>
          {toasts.map((toast: any) => (
            <Toast
              key={toast.id}
              onclose={() => close(toast.id)}
              type={toast.type}
              renderContent={toast.renderContent}
            >
              {toast.content}
            </Toast>
          ))}
        </ToastsWrapper>,
        document.body,
      )}
    </ToastContext.Provider>
  );
};

const slideIn = keyframes`
  from {
    transform: translateY(-100%);
  }
  50%  {
    transform: translateY(10%);
  }
  to {
    transform: translateY(0);
  }
`;

const ToastsWrapper = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  transform: translateX(calc(50vw - 50%));
  padding: 0 10px;
  z-index: 999999999;
`;

const ToastContainer = styled.div`
  border: 2px solid transparent;
  background-color: ${Color.grey600};
  border-radius: 4px;
  min-height: 56px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.16);
  margin-top: 10px;
  display: flex;
  position: relative;
  cursor: pointer;
  animation: ${slideIn} 0.7s ease-in-out;
  box-sizing: border-box;
`;

const TextContainer = styled.div`
  align-items: center;
  display: flex;
`;

const IconContainer = styled.div<{
  type: ToastType;
}>`
  margin-left: 15px;
  background-color: ${props => {
    if (props.type === ToastType.ERROR) {
      return Color.red400;
    }
    if (props.type === ToastType.SUCCESS) {
      return Color.teal500;
    }
    return Color.blue400;
  }};
  border-radius: 100%;
  padding: 1px;
`;

const ToastText = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  min-width: 175px;
  color: ${Color.white};
  padding: 5px 20px 5px 15px;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ToastCloseBtn = styled.button`
  border: none;
  background-color: transparent;
  font-size: 26px;
  cursor: pointer;
  margin-right: 15px;
  padding: 0px;
  height: 100%;
  color: ${Color.grey400};

  &:hover {
    color: ${Color.white};
  }
`;

export { ToastProvider, useToast };
