import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { DefaultTheme } from 'styled-components';

import { Icon, ICONS_MAP } from '../../atoms/Icon';
import { shape } from '../../theme/themeUtils';

import {
  ActionMenuItem,
  wrapperStyles,
  ActionMenuItemsGroup,
} from './components';

type Variant = keyof DefaultTheme['actionMenu']['color'] &
  keyof DefaultTheme['actionMenu']['shape'] &
  keyof DefaultTheme['actionMenuItem']['color'] &
  keyof DefaultTheme['actionMenuItem']['shape'];

type MenuItemType = {
  label: string;
  icon: keyof typeof ICONS_MAP;
  onClick: () => void;
  isDisabled?: boolean;
};

export type MenuItems = MenuItemType[];

export type GroupedMenuItems = MenuItems[];

export enum ActionMenuPosition {
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
}

export interface ActionMenuProps {
  className?: string;
  items?: MenuItems | GroupedMenuItems;
  children?: React.ReactNode | React.ReactNode[];
  menuLabel?: React.ReactNode;
  variant?: Variant;
  position?: ActionMenuPosition;
  dataTestId?: string;
  onToggle?: () => void;
}

const isGroupedItems = (
  items: MenuItems | GroupedMenuItems,
): items is GroupedMenuItems => Array.isArray(items[0]);

export const ActionMenu: React.FC<ActionMenuProps> = ({
  className,
  items,
  menuLabel,
  variant,
  children,
  position = ActionMenuPosition.BOTTOM_LEFT,
  dataTestId = 'action-menu-label',
  onToggle,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const requestAnimationFrameRef = useRef<number | null>(null);
  const [menuPositionOffset, setMenuPositionOffset] = useState({ x: 0, y: 0 });

  const setMenuPositionOnThePage = useCallback(() => {
    if (requestAnimationFrameRef.current) {
      cancelAnimationFrame(requestAnimationFrameRef.current);
    }

    requestAnimationFrameRef.current = requestAnimationFrame(() => {
      if (!menuRef.current || !containerRef.current) {
        return;
      }

      const menuRect = menuRef.current.getBoundingClientRect();
      const menuLabelParameters = containerRef.current?.getBoundingClientRect();
      const height = menuRect.height + menuLabelParameters.height;
      let topPosition = menuLabelParameters.y;

      if (
        position === ActionMenuPosition.BOTTOM_LEFT ||
        position === ActionMenuPosition.BOTTOM_RIGHT
      ) {
        topPosition =
          menuLabelParameters.y > window.innerHeight - height
            ? topPosition - height
            : topPosition;
      }

      menuRef.current.style.left = `${menuLabelParameters.x}px`;
      menuRef.current.style.top = `${topPosition}px`;

      setMenuPositionOffset({
        x: menuLabelParameters.width,
        y: menuLabelParameters.height,
      });
    });
  }, [position]);

  useEffect(() => {
    if (isOpen) {
      setMenuPositionOnThePage();
    }
  }, [isOpen, setMenuPositionOnThePage]);

  useEffect(() => {
    window.addEventListener('resize', setMenuPositionOnThePage);
    window.addEventListener('scroll', setMenuPositionOnThePage);

    return () => {
      window.removeEventListener('resize', setMenuPositionOnThePage);
      window.removeEventListener('scroll', setMenuPositionOnThePage);
    };
  }, [setMenuPositionOnThePage]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!containerRef.current) {
        return;
      }

      const isContainerClicked = containerRef.current.contains(
        event.target as Node,
      );
      const isClickedOutside = !isContainerClicked;

      if (isClickedOutside) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef]);

  const handleMenuToggle = useCallback(
    (
      event:
        | React.MouseEvent<HTMLDivElement>
        | React.KeyboardEvent<HTMLDivElement>,
    ) => {
      event.stopPropagation();

      onToggle?.();
      setIsOpen(isOpen => !isOpen);
    },
    [onToggle],
  );

  const handItemClick = (itemCallback: () => void) => () => {
    itemCallback();
    setIsOpen(false);
  };

  const renderGroupedItems = (itemsToRender: GroupedMenuItems) =>
    itemsToRender.map((sectionItems, sectionIndex) => (
      <ActionMenuItemsGroup key={sectionIndex} variant={variant}>
        {sectionItems.map(item => (
          <ActionMenuItem
            key={item.label}
            variant={variant}
            onClick={handItemClick(item.onClick)}
            onKeyPress={handItemClick(item.onClick)}
            label={item.label}
            icon={item.icon}
            isDisabled={item.isDisabled}
          />
        ))}
      </ActionMenuItemsGroup>
    ));

  const renderFlatStructureItems = (itemsToRender: MenuItems) =>
    itemsToRender.map(item => (
      <ActionMenuItem
        variant={variant}
        key={item.label}
        onClick={handItemClick(item.onClick)}
        onKeyPress={handItemClick(item.onClick)}
        label={item.label}
        icon={item.icon}
        isDisabled={item.isDisabled}
      />
    ));

  const renderItems = (items: MenuItems | GroupedMenuItems) =>
    isGroupedItems(items)
      ? renderGroupedItems(items)
      : renderFlatStructureItems(items);

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...child.props,
        onClick: handItemClick(child.props.onClick),
      });
    }
    return child;
  });

  return (
    <MenuWrapper className={className} ref={containerRef}>
      <MenuLabel
        onClick={handleMenuToggle}
        onKeyPress={handleMenuToggle}
        data-testid={dataTestId}
        tabIndex={0}
      >
        {menuLabel || <ActionMenuDefaultLabel icon="dotsVertical" />}
      </MenuLabel>
      {isOpen && (
        <ItemsContainer
          variant={variant}
          position={position}
          ref={menuRef}
          xOffset={menuPositionOffset.x}
          yOffset={menuPositionOffset.y}
          className="itemsContainer"
        >
          {items?.length ? renderItems(items) : childrenWithProps}
        </ItemsContainer>
      )}
    </MenuWrapper>
  );
};

const MenuWrapper = styled.div`
  position: relative;
`;

const MenuLabel = styled.div`
  cursor: pointer;
`;

const ActionMenuDefaultLabel = styled(Icon)<{ variant?: Variant }>`
  color: ${({ theme, variant }) =>
    theme.actionMenu.color[variant || 'default'].text};
`;

const ItemsContainer = styled.div<{
  variant?: Variant;
  position: ActionMenuPosition;
  xOffset: number;
  yOffset: number;
}>`
  ${({ position, xOffset, yOffset }) => {
    switch (position) {
      case ActionMenuPosition.TOP_LEFT:
        return `
          transform: translate(calc(-100% + ${xOffset}px), calc(-100% - 8px));
      `;
      case ActionMenuPosition.TOP_RIGHT:
        return `
        transform: translate(0, calc(-100% - 8px));
      `;
      case ActionMenuPosition.BOTTOM_RIGHT:
        return `
        transform: translate(0, calc(${yOffset}px + 8px));
      `;
      default:
        return `
        transform: translate(calc(-100% + ${xOffset}px), calc(${yOffset}px + 8px));
      `;
    }
  }}

  position: fixed;
  min-width: 200px;
  ${({ theme, variant }) =>
    shape(theme.actionMenu.shape[variant || 'default'])};
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.16);
  ${wrapperStyles};
  background-color: ${({ variant, theme }) =>
    theme.actionMenu.color[variant || 'default'].background};
  z-index: 10000;
`;
