import React, { ReactNode, useEffect, useRef, useState } from 'react';
import * as ReactDOM from 'react-dom';
import styled, { DefaultTheme } from 'styled-components';

import { mergeRefs } from '../../utils/mergeRefs';
import Text from '../Text';
import { Icon } from '../Icon';
import { Color } from '../../theme/primitives';
import { typography } from '../../theme/themeUtils';

const { body } = document;

export enum TooltipPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

enum ArrowTooltipPosition {
  MIDDLE = 'top',
  LEFT = 'left',
  RIGHT = 'right',
}

export interface TooltipProps {
  className?: string;
  text?: string;
  docsReference?: string;
  content?: React.ReactNode;
  children: any; // todo: find better solution
  variant?: keyof DefaultTheme['tooltip'];
  width?: number;
  maxWidth?: number;

  // @ts-ignore todo: fix types
  position?: TooltipPosition | string;
  show?: boolean;
  controlled?: boolean;
}

export const Tooltip = ({
  content,
  text,
  docsReference,
  children,
  variant,
  width,
  maxWidth,
  className,
  position = TooltipPosition.TOP,
  show = false,
  controlled = false,
}: TooltipProps) => {
  const [isVisible, setVisibility] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const elementRef = useRef<HTMLDivElement>(null);
  const hideTimeoutRef = useRef<any | null>(null);
  const isTooltipHoveredRef = useRef(false);

  const [arrowPosition, setArrowPosition] = useState(
    ArrowTooltipPosition.MIDDLE,
  );
  const [tooltipPosition, setTooltipPosition] = useState(position);

  useEffect(() => {
    if (show && !isVisible) {
      showTooltip(show);
    } else if (!show && isVisible) {
      showTooltip(show);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (!elementRef.current) {
      return;
    }
    document.addEventListener('scroll', handleTooltipPosition, true);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('scroll', handleTooltipPosition, true);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleTooltipMove = (event: any) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        isTooltipHoveredRef.current = false;
      } else if (
        tooltipRef.current &&
        tooltipRef.current.contains(event.target)
      ) {
        isTooltipHoveredRef.current = true;
      }
    };

    document.addEventListener('mousemove', handleTooltipMove, true);
    return () => {
      document.removeEventListener('mousemove', handleTooltipMove, true);
    };
  }, []);

  const closeTooltip = () => {
    showTooltip(false);
    isTooltipHoveredRef.current = false;
    clearHideTimeout();
  };

  const clearHideTimeout = () => {
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
      hideTimeoutRef.current = null;
    }
  };

  useEffect(() => {
    return () => {
      clearHideTimeout();
    };
  }, []);

  const handleArrowPosition = () => {
    if (!tooltipRef.current || !elementRef.current) {
      return;
    }

    const elementRect = elementRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();

    if (elementRect.x < tooltipRect.width / 2) {
      tooltipRef.current.style.left = `${elementRect.x}px`;

      setArrowPosition(ArrowTooltipPosition.LEFT);
    } else if (elementRect.x > window.innerWidth - tooltipRect.width / 2) {
      tooltipRef.current.style.left = `${elementRect.x +
        elementRect.width -
        tooltipRect.width}px`;

      setArrowPosition(ArrowTooltipPosition.RIGHT);
    } else {
      tooltipRef.current.style.left = `${elementRect.x -
        tooltipRect.width / 2 +
        elementRect.width / 2}px`;

      setArrowPosition(ArrowTooltipPosition.MIDDLE);
    }
  };

  const handleTooltipPositionTop = () => {
    if (!tooltipRef.current || !elementRef.current) {
      return;
    }

    const elementRect = elementRef.current.getBoundingClientRect();

    tooltipRef.current.style.top = `${elementRect.y}px`;

    setTooltipPosition(TooltipPosition.TOP);
  };

  const handleTooltipPositionBottom = () => {
    if (!tooltipRef.current || !elementRef.current) {
      return;
    }

    const elementRect = elementRef.current.getBoundingClientRect();

    tooltipRef.current.style.top = `${elementRect.y + elementRect.height}px`;

    setTooltipPosition(TooltipPosition.BOTTOM);
  };

  const handleTooltipPosition = () => {
    if (!tooltipRef.current || !elementRef.current) {
      return;
    }

    const elementRect = elementRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();

    if (elementRect.y > window.innerHeight - tooltipRect.height) {
      handleTooltipPositionTop();
    } else if (elementRect.y < tooltipRect.height) {
      handleTooltipPositionBottom();
    } else {
      if (position === TooltipPosition.TOP) {
        handleTooltipPositionTop();
      }

      if (position === TooltipPosition.BOTTOM) {
        handleTooltipPositionBottom();
      }
    }

    handleArrowPosition();
  };

  const showTooltip = (show = true) => {
    clearHideTimeout();

    setVisibility(show);
    requestAnimationFrame(handleTooltipPosition);
  };

  // InfoButton: mouseOver
  const onMouseOver = (event: MouseEvent) => {
    if (controlled) return;

    showTooltip();
  };

  // InfoButton: mouseOut
  const onMouseOut = (event: MouseEvent) => {
    if (controlled) return;

    clearHideTimeout();

    if (isTooltipHoveredRef.current) return;

    hideTimeoutRef.current = setTimeout(() => {
      if (!isTooltipHoveredRef.current && !controlled) {
        setVisibility(false);
      }
    }, 250);
  };

  const onMouseDown = (event: MouseEvent) => {
    if (!controlled) event.preventDefault();
  };

  const onFocusIn = (event: MouseEvent) => {
    showTooltip();
  };

  const onFocusOut = (event: MouseEvent) => {
    setVisibility(false);
  };

  const openDoc = (docsReference: string) => {
    window.open(docsReference, '_blank');
  };

  const child =
    typeof children === 'string' ? (
      <span>{children}</span>
    ) : (
      React.Children.only(children)
    );
  if (!child) return null;

  return (
    <>
      <TooltipPortal isVisible={controlled ? show : isVisible}>
        <TooltipContainer
          ref={tooltipRef}
          variant={variant}
          width={width}
          maxWidth={maxWidth}
          className={className}
          position={tooltipPosition}
          onMouseLeave={closeTooltip}
        >
          <ContentContainer variant={variant}>
            {content}
            {text && <TooltipText>{text}</TooltipText>}
            {docsReference && (
              <DocsText onClick={() => openDoc(docsReference)}>
                <Text>Learn more</Text>
                <Icon icon="arrowUpRight" iconSize="16" />
              </DocsText>
            )}
          </ContentContainer>
          <TooltipArrow
            position={arrowPosition}
            tooltipPosition={tooltipPosition}
            variant={variant}
          >
            <Icon icon="tooltipArrow" iconSize="15" />
          </TooltipArrow>
        </TooltipContainer>
      </TooltipPortal>

      {React.cloneElement(child, {
        ...child.props,
        ref: mergeRefs(child.ref, elementRef),
        ...{
          onMouseOver: child.props.onMouseOver
            ? (event: MouseEvent) => {
                child.props.onMouseOver(event);
                onMouseOver(event);
              }
            : onMouseOver,
        },
        ...{
          onMouseOut: child.props.onMouseOut
            ? (event: MouseEvent) => {
                child.props.onMouseOut(event);
                onMouseOut(event);
              }
            : onMouseOut,
        },
        ...{ onMouseDown },
        ...{
          onFocus: child.props.onMouseOver
            ? (event: MouseEvent) => {
                child.props.onFocus(event);
                onFocusIn(event);
              }
            : onFocusIn,
        },
        ...{
          onBlur: child.props.onMouseOut
            ? (event: MouseEvent) => {
                child.props.onBlur(event);
                onFocusOut(event);
              }
            : onFocusOut,
        },
      })}
    </>
  );
};

const TooltipPortal = ({
  children,
  isVisible,
}: {
  children: ReactNode;
  isVisible: boolean;
}) => {
  return isVisible ? ReactDOM.createPortal(children, body) : null;
};

const TooltipText = styled.div`
  text-align: left;
  font-weight: 400;
`;

const DocsText = styled.div`
  text-align: left;
  font-weight: 400;
  color: ${Color.blue300};
  display: flex;
  max-width: 85px;

  &:hover {
    cursor: pointer;
    color: ${Color.blue200};
  }
`;

const TooltipContainer = styled.div<{
  variant?: keyof DefaultTheme['tooltip'];
  width?: number;
  maxWidth?: number;
  position: string;
}>`
  ${typography('small')};
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to: {
      opacity: 1;
    }
  }

  animation: fadeIn 0.3s forwards;
  min-width: ${({ width }) => (width ? `${width}px` : 'auto')};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'auto')};
  box-sizing: border-box;
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.1);
  border: ${({ theme, variant }) =>
    `${theme.tooltip[variant || 'default'].borderWidth} solid ${
      theme.tooltip[variant || 'default'].borderColor
    }`};
  border-radius: 8px;
  background-color: ${({ theme, variant }) =>
    theme.tooltip[variant || 'default'].background};
  position: fixed;
  pointer-events: auto;
  z-index: 100000;
  padding: 0;

  ${({ position }) => {
    switch (position) {
      case TooltipPosition.BOTTOM:
        return `transform: translate(0, 12px);`;
      case TooltipPosition.TOP:
      default:
        return `transform: translate(0, calc(-100% - 12px))`;
    }
  }};
`;

const ContentContainer = styled.div<{
  variant?: keyof DefaultTheme['tooltip'];
}>`
  position: relative;
  border-radius: 4px;
  pointer-events: auto;
  color: ${({ theme, variant }) => theme.tooltip[variant || 'default'].text};
  padding: ${({ theme, variant }) =>
    theme.tooltip[variant || 'default'].padding};
`;

const TooltipArrow = styled.div<{
  position: string;
  tooltipPosition: string;
  variant?: keyof DefaultTheme['tooltip'];
}>`
  position: absolute;

  & svg {
    height: 12.5px;

    rect {
      fill: ${({ theme, variant }) =>
        theme.tooltip[variant || 'default'].background};
    }

    g {
      path:nth-child(1) {
        fill: ${({ theme, variant }) =>
          theme.tooltip[variant || 'default'].background};
      }

      path:nth-child(2) {
        stroke: ${({ theme, variant }) =>
          theme.tooltip[variant || 'default'].borderColor};
      }
    }
  }

  ${({ tooltipPosition, theme, variant }) => {
    const basePercentage = theme.tooltip[variant || 'default'].arrowHasStroke
      ? 'calc(100% - 1px)'
      : 'calc(100% - 5px)';

    switch (tooltipPosition) {
      case TooltipPosition.BOTTOM:
        return `
          transform: translateX(-50%) rotate(180deg);
          top: unset;
          bottom: ${basePercentage};
        `;
      case TooltipPosition.TOP:
      default:
        return `
          position: absolute;
          top: ${basePercentage};
          transform: translateX(-50%);
        `;
    }
  }};

  ${({ position }) => {
    switch (position) {
      case 'left':
        return `
          left: 16px;
          right: unset;
        `;
      case 'right':
        return `
          right: 8px;
          left: unset;
        `;
      case 'middle':
      default:
        return `
          left: 50%;
          right: unset;
        `;
    }
  }};
`;
