/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';

import { Color, Size } from '../../theme/primitives';
import { color, typography } from '../../theme/themeUtils';
import useId from '../../utils/useId';

export interface FormFieldProps {
  render(id: string): React.ReactNode;
  label?: React.ReactNode;
  description?: React.ReactNode;
  details?: string;
  error?: string;
  className?: string;
}

export const FormField: React.FC<FormFieldProps> = ({
  label,
  description,
  details,
  error,
  render,
  className,
}) => {
  const id = useId('FormField');

  return (
    <Container className={className}>
      {label && (
        <Label htmlFor={id} error={!!error} className="field-label">
          {label}
          {details && <span> – {details}</span>}
        </Label>
      )}
      {description && (
        <Description className="field-description">{description}</Description>
      )}
      {render(id)}
      {error && <Error>{error}</Error>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
`;

const Label = styled.label<{ error: boolean }>`
  ${typography('default')};
  ${({ theme, error }) =>
    color(theme.formFieldLabel.color[error ? 'invalid' : 'default'])};
  margin-bottom: ${({ theme }) => theme.formFieldLabel.layout.gap};
`;

const Description = styled.div`
  width: 100%;
  user-select: none;
  letter-spacing: 0.25px;
  line-height: 10px;

  font-size: 12px;
  font-weight: 400;

  color: ${Color.grey500};

  margin-bottom: ${Size.space200};
`;

const Error = styled.span`
  ${typography('small')};
  ${({ theme }) => color(theme.formFieldError.color)};

  font-weight: 700;
  margin-top: ${({ theme }) => theme.formFieldError.layout.gap};
`;
