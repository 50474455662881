import React from 'react';

import { HeatMapDataConfig } from '../types';

import HeatMapDataContext from './HeatMapDataContext';
import useCellsState from './useHeatMapState';

interface HeatMapDataProps {
  dataConfig: HeatMapDataConfig;
  palette: string[];
}

const HeatMapStateProvider: React.FC<HeatMapDataProps> = ({
  dataConfig,
  palette,
  children,
}) => {
  const state = useCellsState(dataConfig, palette);

  return (
    <HeatMapDataContext.Provider value={state}>
      {children}
    </HeatMapDataContext.Provider>
  );
};

export default HeatMapStateProvider;
