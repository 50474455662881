import { Size } from '../../theme/primitives';

const theme = {
  card: {
    layout: {
      gap: Size.space300,
    },
  },
  cardHeader: {
    shape: {
      padding: Size.space200,
    },
    layout: {
      gap: Size.space500,
    },
  },
};

export default theme;
