/* eslint-disable no-underscore-dangle */
import React, { ReactNode, forwardRef } from 'react';
import styled from 'styled-components';

import Panel from '../../atoms/Panel';
import { addPropsToChildren } from '../../utils/propsUtils';
import { AdvancedOptionsList } from '../AdvancedOptionsList';
import { AdvancedSelectionInput } from '../AdvancedSelectionInput';

interface AdvancedOptionProps {
  children: ReactNode;
  width?: string;
  margin?: string;
  onChange?: (value: string) => void;
  onSearch?: (value: string) => void;
}

export const AdvancedSelection = forwardRef<
  HTMLDivElement,
  AdvancedOptionProps
>(({ children, width, margin, onChange, onSearch }, ref) => {
  return (
    <OptionsContainer width={width} ref={ref} margin={margin}>
      <OptionsPanel>
        <OptionsContainerContents>
          {addPropsToChildren(children, [
            {
              type: AdvancedOptionsList.defaultProps?.__type,
              events: {
                onClick: onChange && ((value: string) => onChange(value)),
              },
            },
            {
              type: AdvancedSelectionInput.defaultProps?.__type,
              events: {
                onChange: onSearch && ((value: string) => onSearch(value)),
              },
            },
          ])}
        </OptionsContainerContents>
      </OptionsPanel>
    </OptionsContainer>
  );
});

const OptionsContainer = styled.div<{
  width?: string;
  margin?: string;
}>`
  width: ${({ width }) => width || '100%'};
  margin: ${({ margin }) => margin || '4px 0 0 0'};
  position: absolute;
  z-index: 999;
  max-height: 300px;
  display: flex;
  margin-top: 4px;
`;

const OptionsPanel = styled(Panel).attrs((props: { variant?: string }) => ({
  variant: props.variant || 'small',
}))`
  flex: 1;
  border-radius: 8px;
`;

const OptionsContainerContents = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
