import { Color, Size } from '../../theme/primitives';

const theme = {
  multiSelectInput: {
    shape: {
      default: {
        padding: `${Size.space200} ${Size.space200}`,
        borderRadius: Size.radius200,
        borderStyle: 'solid',
        borderWidth: '1px',
      },
    },
    color: {
      default: {
        inactive: {
          text: Color.grey600,
          background: Color.white,
          border: Color.grey400,
        },
        hover: {
          text: Color.grey600,
          border: Color.grey600,
        },
        focus: {
          text: Color.grey600,
          border: Color.grey600,
        },
        placeholder: {
          text: Color.grey600,
        },
        disabled: {
          text: Color.grey600,
          background: Color.grey100,
          border: Color.grey100,
        },
      },
      invalid: {
        inactive: {
          text: Color.grey600,
          background: Color.white,
          border: Color.red400,
        },
        hover: {
          text: Color.grey600,
          border: Color.red400,
        },
        focus: {
          text: Color.grey600,
          border: Color.red400,
        },
        placeholder: {
          text: Color.grey600,
        },
        disabled: {
          text: Color.grey600,
          background: Color.grey100,
          border: Color.grey100,
        },
      },
      siteKeySelect: {
        inactive: {
          text: Color.grey600,
          background: Color.white,
          border: Color.grey400,
        },
        hover: {
          text: Color.grey600,
          border: Color.grey600,
        },
        focus: {
          text: Color.grey600,
          border: Color.grey600,
        },
        placeholder: {
          text: Color.grey600,
        },
        disabled: {
          text: Color.grey600,
          background: Color.grey100,
          border: Color.grey100,
        },
      },
    },
  },
  select: {
    layout: {
      gap: Size.space200,
    },
    color: {
      separator: Color.grey300,
    },
  },
  selectAction: {
    shape: {
      padding: `${Size.space100} ${Size.space200}`,
    },
  },
  selectOption: {
    shape: {
      padding: Size.space300,
    },
    color: {
      default: {
        border: Color.grey100,
      },
      selected: {
        border: Color.grey100,
        background: Color.grey100,
      },
      hovered: {
        border: Color.grey100,
        background: Color.grey100,
      },
    },
  },
  multiSelectOption: {
    color: {
      default: {
        text: Color.grey500,
      },
      siteKeySelect: {
        text: Color.grey600,
      },
    },
  },
  multiSelectActions: {
    selectAll: {
      default: {
        color: Color.grey500,
      },
      siteKeySelect: {
        color: Color.grey600,
      },
    },
    clearAll: {
      default: {
        color: Color.grey500,
      },
      siteKeySelect: {
        color: Color.red400,
      },
    },
  },
};

export default theme;
