import styled from 'styled-components';

const Form = styled.form`
  & > * {
    margin-bottom: ${({ theme }) => theme.form.layout.gap};

    :last-child {
      margin-bottom: 0;
    }
  }
`;

export default Form;
