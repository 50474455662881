/* eslint-disable import/prefer-default-export */

export enum KeyCode {
  ENTER = 'Enter',
  ESC = 'Escape',
  TAB = 'Tab',
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none',
}

export enum MouseEventType {
  MOVE = 'move',
  UP = 'up',
  DOWN = 'down',
}

export enum Cursor {
  DEFAULT = 'default',
  COL_RESIZE = 'col-resize',
  POINTER = 'pointer',
  GRAB = 'grab',
  GRABBING = 'grabbing',
}

export type Coordinates = {
  x: number;
  y: number;
};
