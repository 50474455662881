import {
  css,
  DefaultTheme,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components';
import { Property } from 'csstype';

export function typography(variant: keyof DefaultTheme['typography']) {
  return ({ theme }: ThemeProps<DefaultTheme>): FlattenSimpleInterpolation =>
    css(theme.typography[variant]);
}

interface ColorConfig {
  text?: Property.Color;
  background?: Property.BackgroundColor;
  border?: Property.BorderColor;
}

export function color({
  text: color,
  background: backgroundColor,
  border: borderColor,
}: ColorConfig = {}) {
  return css({
    color,
    backgroundColor,
    borderColor,
  });
}

export function shape(shapeConfig: {
  borderRadius?: Property.BorderRadius;
  borderWidth?: Property.BorderWidth;
  borderStyle?: Property.BorderStyle;
  padding?: Property.Padding;
  width?: Property.Width;
  height?: Property.Height;
}) {
  return css(shapeConfig);
}

export function colorStates(
  states: Partial<
    Record<
      'inactive' | 'focus' | 'hover' | 'active' | 'placeholder' | 'disabled',
      ColorConfig
    >
  >,
) {
  return css`
    ${color(states.inactive)};

    :hover {
      ${color(states.hover)};
    }

    :focus {
      ${color(states.focus)};
    }

    :active {
      ${color(states.active)};
    }

    :disabled {
      ${color(states.disabled)};
    }

    ::placeholder {
      ${color(states.placeholder)};
    }
  `;
}
