import React, {
  ReactNode,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import styled, { css } from 'styled-components';

import { ArrowPagination } from '../../molecules/ArrowPagination';
import { typography } from '../../theme/themeUtils';
import { Color } from '../../theme/primitives';
import { WidgetContainerWithFilter } from '../../molecules/WidgetContainerWithFilter';
import { WidgetContainer } from '../../molecules/WidgetContainer';
import { WithFilterProps } from '../../molecules/WidgetContainerWithFilter/WidgetFilter';
import debounce from '../../utils/debounce';

type ListItemType = {
  label: string;
  value: string | number;
};

export interface ListWidgetProps {
  items: ListItemType[];
  header: string;
  tooltip?: string;
  tooltipDocsReference?: string;
  isLoading?: boolean;
  customLoadingIndicator?: ReactNode;
  renderHoveredRow?: (item: ListItemType) => React.ReactNode | null;
}

const RESIZE_DELAY = 500;

const useCheckIfContentFitsIntoTheLabel = (label: string) => {
  const [isContentFit, setIsContentFitState] = useState(true);
  const labelRef = useRef<HTMLDivElement>(null);
  const checkIfContentFitsIntoTheLabel = useCallback(() => {
    if (!labelRef.current) return;
    const { current: labelElement } = labelRef;
    if (
      labelElement.clientHeight < labelElement.scrollHeight ||
      labelElement.clientWidth < labelElement.scrollWidth
    ) {
      setIsContentFitState(false);
      return;
    }
    setIsContentFitState(true);
  }, [labelRef]);
  const debouncedCheck = debounce(checkIfContentFitsIntoTheLabel, RESIZE_DELAY);
  useEffect(() => {
    checkIfContentFitsIntoTheLabel();
    window.addEventListener('resize', debouncedCheck);
    return () => {
      window.removeEventListener('resize', debouncedCheck);
    };
  }, [label, checkIfContentFitsIntoTheLabel, debouncedCheck]);
  return { isContentFit, labelRef };
};

const Row = ({
  index,
  label,
  value,
}: ListItemType & {
  index: number;
}) => {
  const { isContentFit, labelRef } = useCheckIfContentFitsIntoTheLabel(label);
  return (
    <ListItemWithHoverEffect index={index} isContentFit={isContentFit}>
      <Label ref={labelRef}>{label}</Label>
      <Value>{value}</Value>
      <ItemBackground />
    </ListItemWithHoverEffect>
  );
};

const WidgetBody = ({
  items,
  renderHoveredRow,
}: {
  items: ListItemType[];
  renderHoveredRow?: (item: ListItemType) => React.ReactNode | null;
}) => {
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };
  const handleMouseEnter = (index: number) => () => {
    setHoveredRowIndex(index);
  };

  return (
    <ArrowPagination items={items}>
      {pageItems =>
        pageItems.map(({ label, value }, index) => {
          const isHovered = index === hoveredRowIndex;
          return renderHoveredRow ? (
            <PureListItem
              isHovered={isHovered}
              onMouseEnter={handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              {isHovered && renderHoveredRow ? (
                renderHoveredRow({ label, value })
              ) : (
                <>
                  <Label>{label}</Label>
                  <Value>{value}</Value>
                </>
              )}
            </PureListItem>
          ) : (
            <Row key={index} index={index} label={label} value={value} />
          );
        })
      }
    </ArrowPagination>
  );
};

export const ListWidgetWithFilter = ({
  items,
  header,
  filterOptions,
  selectedFilter,
  onFilterOptionChange,
  tooltip,
  isLoading,
  customLoadingIndicator,
  renderHoveredRow,
}: WithFilterProps<ListWidgetProps>) => {
  return (
    <WidgetContainerWithFilter
      header={header}
      onFilterOptionChange={onFilterOptionChange}
      filterOptions={filterOptions}
      selectedFilter={selectedFilter}
      headerIndent={15}
      tooltip={tooltip}
      isLoading={isLoading}
      customLoadingIndicator={customLoadingIndicator}
    >
      <WidgetBody items={items} renderHoveredRow={renderHoveredRow} />
    </WidgetContainerWithFilter>
  );
};

export const ListWidget = ({
  items,
  header,
  tooltip,
  tooltipDocsReference,
  isLoading,
  customLoadingIndicator,
  renderHoveredRow,
}: ListWidgetProps) => {
  return (
    <WidgetContainer
      header={header}
      headerIndent={15}
      tooltip={tooltip}
      tooltipDocsReference={tooltipDocsReference}
      isLoading={isLoading}
      customLoadingIndicator={customLoadingIndicator}
    >
      <WidgetBody items={items} renderHoveredRow={renderHoveredRow} />
    </WidgetContainer>
  );
};

const Label = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const Value = styled.span`
  word-break: break-all;
  white-space: normal;
  text-align: right;
`;

const ItemBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: ${Color.grey500};
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
  transform-origin: 50% 50%;
  transform: translateY(-10px) scaleY(0.875) scaleX(0.99);
  transition: transform 0.35s cubic-bezier(0.5, 0, 0.75, 0);
`;

const PureListItem = styled.div<{ isHovered: boolean }>`
  width: 100%;
  ${typography('small')};
  color: ${Color.grey500};
  display: grid;
  grid-template-columns: ${({ isHovered }) => (isHovered ? '1fr' : '1fr 75px')};
  grid-column-gap: 15px;
  justify-content: space-between;
  align-items: center;
  padding: 7.5px 10px;
  box-sizing: border-box;
  max-width: 100%;
  overflow: ${({ isHovered }) => (isHovered ? 'visible' : 'hidden')};
  white-space: ${({ isHovered }) => (isHovered ? 'normal' : 'nowrap')};
  text-overflow: ellipsis;
  cursor: auto;
  background-color: ${Color.white};

  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
`;

const ListItemWithHoverEffect = styled.div<{
  index: number;
  isContentFit: boolean;
}>`
  width: 100%;
  ${typography('small')};
  color: ${Color.grey500};
  display: grid;
  grid-template-columns: 1fr 75px;
  grid-column-gap: 15px;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: 7.5px 10px;
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: ${({ isContentFit }) => (isContentFit ? 'auto' : 'pointer')};
  background-color: ${Color.white};
  position: absolute;
  top: ${props => props.index * 30}px;

  &:nth-child(odd) {
    background-color: ${Color.grey050};
  }
  ${({ isContentFit }) =>
    !isContentFit &&
    css`
      &:hover {
        & ${Label} {
          overflow: visible;
          white-space: normal;
        }
        overflow: visible;
        position: absolute;
        height: auto;
        z-index: 2;
        background-color: transparent;
        color: #fff;
        align-items: flex-start;
        & * {
          z-index: 2;
        }
        & ${Value} {
          opacity: 1;
        }
        & ${ItemBackground} {
          visibility: visible;
          opacity: 1;
          z-index: 1;
          height: calc(100% + 20px);
          transform: translateY(-10px) scaleY(1) scaleX(1);
          transition: transform 0.35s cubic-bezier(0.33, 1, 0.68, 1);
          border-radius: 4px;
        }
      }
    `}
`;
