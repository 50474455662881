import styled from 'styled-components';
import React from 'react';

import { useExpansionPanelState } from './ExpansionPanelContext';

type ExpansionPanelDetailsProps = {
  children: React.ReactNode | React.ReactNode[];
};
export const ExpansionPanelDetails: React.FC<ExpansionPanelDetailsProps> = ({
  children,
}) => {
  const expansionPanelState = useExpansionPanelState();

  if (expansionPanelState === null) {
    throw new Error(
      `ExpansionPanel context not found.
       ExpansionPanelProvider is not placed in the JSX declaration`,
    );
  }

  const [isExpanded] = expansionPanelState;

  return isExpanded ? (
    <DetailsContentWrapper>{children}</DetailsContentWrapper>
  ) : null;
};

const DetailsContentWrapper = styled.div`
  width: 100%;
`;
