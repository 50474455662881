import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Icon } from '../../atoms/Icon';
import { Color } from '../../theme/primitives';

export interface ArrowPaginationProps<ItemType> {
  children: (pageItems: Array<ItemType>) => ReactNode;
  items: Array<ItemType>;
  itemsPerPage?: number;
}

export const ArrowPagination = <ItemType,>({
  children,
  items,
  itemsPerPage = 5,
}: ArrowPaginationProps<ItemType>) => {
  const [pageItems, setPageItems] = useState<ItemType[]>([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const itemsToDisplay = items.slice(
      (page - 1) * itemsPerPage,
      itemsPerPage + (page - 1) * itemsPerPage,
    );
    setPageItems(itemsToDisplay);
  }, [page, itemsPerPage, items]);

  const isFirstPage = page === 1;
  const isLastPage = page * itemsPerPage >= items.length;

  return (
    <Container>
      <ContentWrapper>{children && children(pageItems)}</ContentWrapper>
      {items.length > itemsPerPage && (
        <ButtonsContainer>
          <ButtonWrapper
            data-testid="prev-page-arrow"
            isDisabled={isFirstPage}
            onClick={() => (isFirstPage ? undefined : setPage(page - 1))}
          >
            <PrevPageButton icon="arrowDown" iconSize="13" />
          </ButtonWrapper>
          <ButtonWrapper
            data-testid="next-page-arrow"
            isDisabled={isLastPage}
            onClick={() => (isLastPage ? undefined : setPage(page + 1))}
          >
            <NextPageButton icon="arrowDown" iconSize="13" />
          </ButtonWrapper>
        </ButtonsContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 1fr 13px;
  grid-row-gap: 10px;
  position: relative;
`;

const ButtonsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.div<{ isDisabled: boolean }>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  & > svg {
    display: block;
    fill: ${({ isDisabled }) => (isDisabled ? Color.grey200 : Color.grey600)};
  }
  &:first-child {
    margin-right: 30px;
  }
`;

const PrevPageButton = styled(Icon)`
  transform: rotate(90deg);
`;

const NextPageButton = styled(Icon)`
  transform: rotate(-90deg);
`;

const ContentWrapper = styled.div`
  width: 100%;
`;
