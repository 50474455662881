import React, { createContext, useContext, useMemo } from 'react';

import useId from '../../../utils/useId';
import { SerieSetting } from '../types';

type BarGraphProviderProps = {
  // Raw data passed to the graph
  data: object[];
  // Width of the graph container (in px)
  width: number;
  // Height of the graph container (in px)
  height: number;
  // Interval between columns
  xProperty: string;
  // Data feature used as reference for the Y axis
  yProperty: string;
  // Label for y axis property
  yLabel?: string;
  // x-axis interval between columns
  columnStep?: number;
  // Amount of right padding for each column (in px)
  columnPadding?: number;
  // labels and colors for each of the column series
  columnSeriesSettings: SerieSetting[] | never[];
};

export interface BarGraphState extends BarGraphProviderProps {
  graphId: string;
}

const defaultState: BarGraphState = {
  data: [],
  graphId: '',
  columnStep: 1,
  columnPadding: 0,
  xProperty: '',
  yProperty: '',
  yLabel: '',
  columnSeriesSettings: [],
  width: 1,
  height: 1,
};

const BarGraphContext = createContext<BarGraphState>(defaultState);
export const useBarGraphContext = () => useContext(BarGraphContext);

export const BarGraphProvider: React.FC<BarGraphProviderProps> = ({
  data,
  width,
  height,
  xProperty,
  yProperty,
  yLabel,
  columnStep,
  columnPadding,
  columnSeriesSettings,
  children,
}) => {
  const graphId = useId('bar-graph');

  const state = useMemo(() => {
    return {
      data,
      columnStep,
      columnPadding,
      xProperty,
      yProperty,
      yLabel,
      columnSeriesSettings,
      width,
      height,
      graphId,
    };
  }, [
    data,
    columnStep,
    columnPadding,
    xProperty,
    yProperty,
    yLabel,
    columnSeriesSettings,
    width,
    height,
    graphId,
  ]);

  return (
    <BarGraphContext.Provider value={state}>
      {children}
    </BarGraphContext.Provider>
  );
};
