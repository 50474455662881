import * as PIXI from 'pixi.js-legacy';
import React, { useCallback, useContext } from 'react';
import * as ReactPIXI from '@inlet/react-pixi/legacy';

import { range } from '../../utils/mathUtils';
import { Color } from '../../theme/primitives';

import { ChartAreaContext } from './ChartAreaProvider/ChartAreaContext';

const { Graphics } = ReactPIXI;

const AxisYGuideLines: React.FC<{
  width: number;
  height: number;
}> = ({ width, height }) => {
  const {
    y: { min, max, scaleMarks },
  } = useContext(ChartAreaContext);

  const drawGuides = useCallback(
    (graphics: PIXI.Graphics) => {
      graphics.clear();

      graphics.lineStyle({
        color: PIXI.utils.string2hex(Color.grey100),
        width: 1,
        cap: PIXI.LINE_CAP.BUTT,
        join: PIXI.LINE_JOIN.ROUND,
      });

      scaleMarks.forEach(value => {
        const yPos = range(value, min, max, height, 0);

        graphics.moveTo(0, yPos);
        graphics.lineTo(width, yPos);
      });
    },
    [scaleMarks, min, max, width, height],
  );

  return <Graphics draw={drawGuides} />;
};

export default AxisYGuideLines;
