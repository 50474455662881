import React from 'react';
import styled from 'styled-components';

import { Color } from '../../theme/primitives';
import { KeyCode } from '../../constants';

type LabelPosition = 'right' | 'left';

export interface ToggleProps {
  isOn: boolean;
  disabled?: boolean;
  onChange: (event: React.MouseEvent | React.KeyboardEvent) => void;
  labelId: string;
  labelText: string;
  labelPosition?: LabelPosition;
  className?: string;
  link?: string;
  linkText?: string;
}

export const ToggleSwitch: React.FC<ToggleProps> = ({
  isOn = false,
  disabled = false,
  onChange,
  labelText,
  labelId,
  labelPosition = 'right',
  className,
  link,
  linkText,
}) => {
  const handleClick = (event: React.MouseEvent) => {
    if (disabled) {
      return;
    }

    onChange(event);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (disabled) {
      return;
    }

    if (event.key === KeyCode.ENTER) {
      onChange(event);
    }
  };

  return (
    <Container className={className}>
      <ToggleContainer
        role="checkbox"
        checked={isOn}
        tabIndex={0}
        aria-checked={isOn}
        aria-labelledby={labelId}
        disabled={disabled}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        <ToggleButton disabled={disabled} isOn={isOn} />
      </ToggleContainer>
      <LabelContainer position={labelPosition}>
        <StyledLabel id={labelId}>{labelText}</StyledLabel>
        {link && (
          <ALink href={link} target="_blank" rel="noreferrer">
            {linkText}
          </ALink>
        )}
      </LabelContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ToggleContainer = styled.span<{
  checked: boolean;
  disabled: boolean;
}>`
  display: flex;
  align-items: center;
  position: relative;
  width: 40px;
  height: 24px;
  border-radius: 20px;
  background-color: ${props =>
    props.disabled
      ? Color.grey100
      : props.checked
      ? Color.blue400
      : Color.grey400};
  transition: .15s;
  cursor: pointer;

  &:focus {
    box-shadow: box-shadow: 0px 0px 0px 2px rgba(77, 225, 210, 0.7);
  }

  &:hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    background-color: ${props =>
      props.disabled
        ? Color.grey100
        : props.checked
        ? Color.blue500
        : Color.grey600};
  }
`;

const ToggleButton = styled.span<{ isOn: boolean; disabled: boolean }>`
  width: 14px;
  height: 14px;
  background-color: ${props => (props.disabled ? Color.grey200 : Color.white)};
  border-radius: 50%;
  position: absolute;
  transition: inherit;
  pointer-events: none;
  left: ${props => (!props.isOn ? '4px' : 'calc(100% - 18px)')};
`;

const StyledLabel = styled.label`
  display: inline;
`;

const ALink = styled.a`
  color: ${Color.blue400}!important;
`;

const LabelContainer = styled.div<{
  position?: LabelPosition;
}>`
  display: flex;
  white-space: pre;
  order: ${({ position }) => (position === 'left' ? -1 : 0)};
`;
