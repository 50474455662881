import { useMemo } from 'react';

import { Axis, AxisConfig } from './ChartAreaContext';

export default function useAxisState(
  axisConfig: AxisConfig,
  maxMarks: number,
): Axis {
  return useMemo(() => {
    const scaleMarks = buildScale(maxMarks, axisConfig);

    return {
      min: axisConfig.min,
      max: axisConfig.max,
      step: axisConfig.step,
      scaleMarks,
      format: axisConfig.format,
      name: axisConfig.name,
    };
  }, [axisConfig, maxMarks]);
}

function calculateStep(
  range: number,
  maxItems: number,
  availableSteps: number[],
) {
  const rawStep = range / maxItems;
  const closestStep =
    availableSteps.find(value => value > rawStep) ||
    availableSteps[availableSteps.length - 1] ||
    rawStep;

  return closestStep;
}

export function buildScale(
  maxMarks: number,
  { min, max, scaleDivisors = [], scaleOffset = 0 }: AxisConfig,
): number[] {
  const step = calculateStep(max - min, maxMarks, scaleDivisors);

  const offset = (step + (scaleOffset % step)) % step;

  let start = Math.floor(min / step) * step + offset;

  if (start < min) {
    start += step;
  }

  return Array.from({
    length: Math.floor((max - start) / step) + 1,
  }).map((_, index) => start + index * step);
}
