import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { Button } from '../../atoms/Button';
import { Icon } from '../../atoms/Icon';
import { Color } from '../../theme/primitives';

interface AdvancedSelectionSwitchProps {
  label: string;
  width?: string;
  isExpanded: boolean;
  isDisabled: boolean;
  onClick: () => void;
}

export const AdvancedSelectionSwitch = forwardRef<
  HTMLButtonElement,
  AdvancedSelectionSwitchProps
>(({ label, width, isExpanded, isDisabled, onClick }, ref) => {
  return (
    <AddButton
      onClick={onClick}
      variant="simple"
      disabled={isDisabled}
      ref={ref}
      width={width}
    >
      {label}
      <CloseIcon icon="dropDownArrowUp" iconSize="14" isExpanded={isExpanded} />

      <AddIcon
        icon="dropDownArrowDown"
        iconSize="14"
        fill={Color.grey600}
        isExpanded={isExpanded}
      />
    </AddButton>
  );
});

const AddButton = styled(Button)<{
  width?: string;
}>`
  display: flex;
  border-radius: 8px;
  gap: 10px;
  align-items: center;
  ${({ width }) => width && `width: ${width};`}
`;

const CloseIcon = styled(Icon)<{
  isExpanded: boolean;
}>`
  opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};
  transition: opacity 150ms;
  margin: 2px 0 0 0;
`;

const AddIcon = styled(Icon)<{
  isExpanded: boolean;
}>`
  display: ${({ isExpanded }) => (!isExpanded ? 'flex' : 'none')};
  transition: all 150ms;
  margin: 2px 0 0 0;
`;
