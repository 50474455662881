import React from 'react';
import styled from 'styled-components';

import { useCheckpointsContext } from '../hooks';

import { CheckpointMarker } from './CheckpointMarker';

export interface GraphCheckpointsProps {
  width: number;
  height: number;
  className?: string;
}

export const GraphCheckpoints: React.FC<GraphCheckpointsProps> = ({
  width,
  height,
  className,
}) => {
  const { checkpoints } = useCheckpointsContext();

  if (!checkpoints.length) {
    return null;
  }

  return (
    <Container width={width} height={height} className={className}>
      <Wrapper>
        {checkpoints.map(({ label, id, x, isRequested }) => {
          return (
            <CheckpointMarker
              key={x}
              xPosition={x}
              label={label}
              id={id}
              isRequested={isRequested}
            />
          );
        })}
      </Wrapper>
    </Container>
  );
};

const Container = styled.div<{ width: number; height: number }>`
  position: absolute;
  top: 0;
  right: 0;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
