import React from 'react';
import styled, { keyframes } from 'styled-components';

import { Color } from '../../theme/primitives';
import { Button } from '../../atoms/Button';
import Text from '../../atoms/Text';

interface InfoPopupProps {
  className?: string;
  imageLink?: string;
  description: string;
  submitButtonTitle: string;
  submitButtonTestId?: string;
  onSubmit: () => void;
}

const InfoPopup: React.FC<InfoPopupProps> = ({
  className = '',
  imageLink,
  description,
  submitButtonTitle,
  submitButtonTestId,
  onSubmit,
}) => {
  return (
    <Container className={className}>
      {imageLink && (
        <ImageContainer>
          <Image src={imageLink} alt="" width={94} height={150} />
        </ImageContainer>
      )}
      <InfoContainer>
        <InfoText variant="default">{description}</InfoText>
        <InfoButton
          variant="link"
          data-testid={submitButtonTestId}
          onClick={onSubmit}
        >
          {submitButtonTitle}
        </InfoButton>
      </InfoContainer>
    </Container>
  );
};

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  50%  {
    transform: translateY(-10%);
  }
  to {
    transform: translateY(0);
  }
`;

const Container = styled.div`
  animation: ${slideIn} 0.7s ease-in-out;
  position: fixed;
  z-index: 10;
  bottom: 16px;
  right: 16px;
  max-width: calc(100% - 32px);
  min-height: 166px;
  border: 1px solid ${Color.grey200};
  background-color: ${Color.white};
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  display: flex;

  @media (min-width: 500px) {
    max-width: 400px;
  }
`;

const ImageContainer = styled.div`
  padding: 8px 0 8px 8px;
`;

const Image = styled.img`
  height: 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const InfoText = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const InfoButton = styled(Button)`
  margin-top: 16px;
  align-self: flex-end;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  padding: 0;
`;

export default InfoPopup;
