import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Color, Size } from '../../theme/primitives';
import { Pagination } from '../../molecules/Pagination';

export interface TableWithPaginationProps {
  children: ReactNode;
  paginator?: boolean;
  activePage?: number;
  itemsPerPage?: number;
  totalItems?: number;
  pageRange?: number;
  onPageChange?: (pageNumber: number) => void;
  renderRows?: (data: any[]) => JSX.Element;
  rowsData?: any[];
}

const getDataForPage = ({
  data,
  page = 1,
  itemsPerPage,
}: {
  data: any[];
  page: number;
  itemsPerPage: number;
}): any[] =>
  data.slice(
    (page - 1) * itemsPerPage,
    itemsPerPage + (page - 1) * itemsPerPage,
  );

export const TableWithPagination: React.FC<TableWithPaginationProps> = ({
  children,
  paginator,
  activePage,
  onPageChange,
  itemsPerPage,
  totalItems,
  pageRange,
  renderRows,
  rowsData = [],
}: TableWithPaginationProps) => {
  const renderPaginator = (): boolean => {
    if (paginator && totalItems && itemsPerPage) {
      return totalItems > itemsPerPage;
    }
    return false;
  };

  const shouldRenderPaginatedData = paginator && rowsData && renderRows;
  const paginatedDataForPage = shouldRenderPaginatedData
    ? getDataForPage({
        data: rowsData!,
        itemsPerPage: itemsPerPage!,
        page: activePage!,
      })
    : [];

  const handlePageChange = (pageNumber: number): void => {
    if (onPageChange) {
      onPageChange(pageNumber);
    }
  };

  return (
    <TableDiv>
      {children}
      {shouldRenderPaginatedData && renderRows!(paginatedDataForPage)}
      {renderPaginator() && (
        <PaginationContainer>
          <Pagination
            total={pageRange as number}
            current={activePage as number}
            onChange={handlePageChange}
          />
        </PaginationContainer>
      )}
    </TableDiv>
  );
};

TableWithPagination.defaultProps = {
  paginator: false,
  activePage: 1,
  itemsPerPage: 15,
  totalItems: 10,
  pageRange: 5,
  onPageChange: undefined,
};

const TableDiv = styled.div`
  display: block;
  position: relative;
  width: 1100px;
  max-width: 100%;
  color: ${Color.black};
  border: 1px solid ${Color.grey400};
  border-radius: 4px;
  overflow: hidden;

  @media (max-width: ${Size.mobile}) {
    width: 100%;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
