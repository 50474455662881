import React from 'react';
import styled from 'styled-components';

import GraphInteractionLayer from '../../atoms/GraphInteractionLayer';
import { ZoomInteractionLayer } from '../../charts/atoms/graph-zoom';
import { LineChartLegend } from '../../atoms/LineChartLegend';
import AxisX from '../../charts/atoms/GraphAxis/AxisX';
import AxisY from '../../charts/atoms/GraphAxis/AxisY';
import ChartAreaProvider from '../../charts/atoms/ChartAreaProvider';
import { AxisConfig } from '../../charts/atoms/ChartAreaProvider/ChartAreaContext';
import { logarithmicToLinear } from '../../utils/mathUtils';
import {
  CheckpointsConfigValue,
  GraphCheckpointsProvider,
  GraphCheckpoints,
} from '../../charts/atoms/GraphCheckpoints';
import { MouseEventContextProvider } from '../../contexts/MouseEvent';
import { CoordinatesContextProvider } from '../../contexts/CanvasCoordinates';

import { AXIS_X_HEIGHT, AXIS_Y_WIDTH, LEGEND_HEIGHT } from './constants';
import { LineGraphCanvas } from './components/LineGraphCanvas';
import { LineGraphProvider } from './hooks/LineGraphContext';
import { Line } from './types';
import ThresholdLine from './ThresholdLine';

export interface LineGraphProps {
  /**
   * Array of lines to display
   */
  lines: Line[];
  /**
   * Width of the container in px
   */
  width: number;
  /**
   * Height of the container in px
   */
  height: number;
  x: AxisConfig;
  y: AxisConfig;
  /**
   * Tooltip node
   */
  tooltip?: React.ReactNode;
  /**
   * Shows a legend for the graph
   */
  showLegend?: boolean;
  /**
   * Use logarithmic scaling for plotting y values
   */
  useLogScaleY?: boolean;
  /**
   * Amount of elements of the y log scale
   */
  logTicksAmountY?: number;
  /**
   * Sets value display node to show
   */
  showMarker?: boolean;
  /**
   * Width of the value display marker
   */
  markerWidth?: number;
  /**
   * Value display node (shows on top of x axis along with the interactive marker)
   */
  markerValueDisplay?: React.ReactNode;
  /**
   * Custom class name for the container
   */
  className?: string;
  /**
   * If compact, remove axis and tooltip
   */
  layout?: string;
  /**
   * Model rollback checkpoint values (if any)
   */
  checkpoints?: CheckpointsConfigValue;
  /**
   * Controls display of model checkpoint markers
   */
  areCheckpointsShown?: boolean;
  /**
   * Function invoked on checkpoint marker tip click
   */
  onCheckpointMarkerClick?: (id: string) => void;
  /**
   * Label of the action to appear on marker tip hover
   */
  checkpointClickActionLabel?: string;
  /**
   * Name of icon to be rendered on checkpoint marker tip
   */
  checkpointMarkerIcon?: string;
  /**
   * Callback triggered on clicking the zoom-in button (if zoom widget is setup)
   */
  onZoomIn?: (newMinX: number, newMaxX: number) => void;
  /**
   * Formats what's displayed in value indicators (e.g. in zoom crop widget)
   */
  formatValueIndicator?: (displayValue: number) => string;

  /**
   * Provides thresholdBar that will be changing Threshold value.
   */
  threshold?: number;
  thresholdTooltip?: string;
  onThresholdChange?: (value: number) => void;
}

export const LineGraph: React.FC<LineGraphProps> = ({
  lines,
  width,
  height,
  x,
  y,
  tooltip,
  showLegend,
  useLogScaleY,
  showMarker,
  markerWidth,
  markerValueDisplay,
  className,
  layout,
  checkpoints,
  areCheckpointsShown,
  onCheckpointMarkerClick,
  checkpointClickActionLabel,
  checkpointMarkerIcon,
  onZoomIn,
  formatValueIndicator,
  threshold,
  thresholdTooltip,
  onThresholdChange,
}) => {
  const ySettings = useLogScaleY
    ? {
        ...y,
        min: 0,
        max: 1,
      }
    : y;
  const lineValues = useLogScaleY
    ? lines.map(line => {
        const logScaledPoints = line.points.map(point => ({
          ...point,
          y: logarithmicToLinear(point.y, y.min, y.max),
        }));
        return { ...line, points: logScaledPoints };
      })
    : lines;
  return (
    <ChartAreaProvider x={x} y={ySettings}>
      <LineGraphProvider lines={lineValues} width={width} height={height}>
        <LineGraphContainer width={width} height={height + LEGEND_HEIGHT}>
          {showLegend && (
            <LineChartLegend lines={lines} height={LEGEND_HEIGHT} />
          )}
          <LineGraphInternal
            width={width}
            height={height}
            tooltip={tooltip}
            showMarker={showMarker}
            markerWidth={markerWidth}
            markerValueDisplay={markerValueDisplay}
            className={className}
            layout={layout}
            checkpoints={checkpoints}
            areCheckpointsShown={areCheckpointsShown}
            checkpointClickActionLabel={checkpointClickActionLabel}
            onCheckpointMarkerClick={onCheckpointMarkerClick}
            checkpointMarkerIcon={checkpointMarkerIcon}
            onZoomIn={onZoomIn}
            formatValueIndicator={formatValueIndicator}
            threshold={threshold}
            thresholdTooltip={thresholdTooltip}
            onThresholdChange={onThresholdChange}
            maxYValue={ySettings.max}
          />
        </LineGraphContainer>
      </LineGraphProvider>
    </ChartAreaProvider>
  );
};

const LineGraphInternal: React.FC<{
  height: number;
  width: number;
  tooltip?: React.ReactNode;
  showMarker?: boolean;
  markerWidth?: number;
  markerValueDisplay?: React.ReactNode;
  className?: string;
  layout?: string;
  checkpoints?: CheckpointsConfigValue;
  areCheckpointsShown?: boolean;
  checkpointClickActionLabel?: string;
  onCheckpointMarkerClick?: (id: string) => void;
  checkpointMarkerIcon?: string;
  onZoomIn?: (newMinX: number, newMaxX: number) => void;
  formatValueIndicator?: (displayValue: number) => string;
  threshold?: number;
  thresholdTooltip?: string;
  onThresholdChange?: (value: number) => void;
  maxYValue: number;
}> = ({
  width,
  height,
  tooltip,
  showMarker,
  markerWidth,
  markerValueDisplay,
  className,
  layout,
  checkpoints,
  areCheckpointsShown,
  checkpointClickActionLabel,
  onCheckpointMarkerClick,
  checkpointMarkerIcon,
  onZoomIn,
  formatValueIndicator,
  threshold,
  thresholdTooltip,
  onThresholdChange,
  maxYValue,
}) => {
  const isFull = layout !== 'compact';
  const stageWidth = width - (isFull ? AXIS_Y_WIDTH : 0);
  const stageHeight = height - (isFull ? AXIS_X_HEIGHT : 0);
  return (
    <LineGraphInternalContainer
      className={className}
      width={width}
      height={height}
    >
      <MouseEventContextProvider>
        <CoordinatesContextProvider>
          <StyledCanvas
            height={stageHeight}
            width={stageWidth}
            threshold={threshold}
          />
          {areCheckpointsShown && (
            <GraphCheckpointsProvider
              config={{
                width: stageWidth,
                checkpoints: checkpoints || {},
                checkpointClickActionLabel,
                onMarkerTipClick: onCheckpointMarkerClick,
                checkpointMarkerIcon,
              }}
            >
              <GraphCheckpoints width={stageWidth} height={stageHeight} />
            </GraphCheckpointsProvider>
          )}
          {isFull && (
            <>
              <StyledAxisY height={height - AXIS_X_HEIGHT} />
              <StyledAxisX width={width - AXIS_Y_WIDTH} />
              <ZoomInteractionLayer
                width={stageWidth}
                height={stageHeight}
                markerWidth={markerWidth}
                onZoomIn={onZoomIn}
                formatValueIndicator={formatValueIndicator}
              />
              <StyledInteractionLayer
                width={stageWidth}
                height={stageHeight}
                tooltip={tooltip}
                showMarker={showMarker}
                markerWidth={markerWidth}
                markerValueDisplay={markerValueDisplay}
              />
            </>
          )}
          {typeof threshold === 'number' && (
            <ThresholdLine
              onThresholdChange={onThresholdChange}
              maxY={stageHeight}
              maxX={stageWidth + AXIS_Y_WIDTH}
              maxYValue={maxYValue}
              threshold={threshold}
              thresholdTooltip={thresholdTooltip}
            />
          )}
        </CoordinatesContextProvider>
      </MouseEventContextProvider>
    </LineGraphInternalContainer>
  );
};

const StyledCanvas = styled(LineGraphCanvas)`
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledInteractionLayer = styled(GraphInteractionLayer)`
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledAxisX = styled(AxisX)<{ width: number }>`
  width: ${props => props.width}px;
  height: ${AXIS_X_HEIGHT}px;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const StyledAxisY = styled(AxisY)<{ height: number }>`
  height: ${props => props.height}px;
  width: ${AXIS_Y_WIDTH}px;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column-reverse;
`;

const LineGraphInternalContainer = styled.div<{
  width: number;
  height: number;
}>`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const LineGraphContainer = styled.div<{
  width: number;
  height: number;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;
