import { useCallback, useContext, useMemo } from 'react';

import { range } from '../../../utils/mathUtils';
import { useInteraction } from '../../../atoms/GraphInteractionLayer/GraphInteractionContext';
import { ChartAreaContext } from '../../../charts/atoms/ChartAreaProvider/ChartAreaContext';
import { AXIS_X_HEIGHT } from '../constants';

import { useColumn } from './useColumn';
import { useBarGraphContext } from './BarGraphContext';

// eslint-disable-next-line import/prefer-default-export
export const useBarGraphInteraction = () => {
  const {
    height: graphHeight,
    graphId,
    xProperty,
    columnPadding,
  } = useBarGraphContext();
  const { y: chartAreaY } = useContext(ChartAreaContext);
  const { columnDrawData } = useColumn();
  const [interactionState] = useInteraction();
  const sortedColumnData = useMemo(() => columnDrawData.slice().reverse(), [
    columnDrawData,
  ]);

  const getHoveredColumnIndex = useCallback(
    () =>
      sortedColumnData.findIndex(drawDataItem => {
        const { bounding } = drawDataItem;
        const { y = 0 } = interactionState as any;

        const top = bounding.y;
        const bottom = bounding.y + bounding.height + (columnPadding || 0);
        const markerPositionY = range(
          y,
          chartAreaY.min,
          chartAreaY.max,
          0,
          graphHeight - AXIS_X_HEIGHT,
        );

        return markerPositionY > top && markerPositionY <= bottom;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [interactionState],
  );

  if (!interactionState) {
    return null;
  }

  const getHoveredColumnData = () => {
    return columnDrawData.find(
      (serie: any, index: number) =>
        index === getHoveredColumnIndex() && {
          label: serie.label,
          feature_value: serie.feature_value,
          value: serie.bounding?.y,
          color: serie.color,
        },
    );
  };

  if (
    getHoveredColumnIndex() < 0 ||
    interactionState.y < chartAreaY.min ||
    interactionState.y >= chartAreaY.max
  ) {
    return null;
  }

  return {
    [xProperty]: (columnDrawData[getHoveredColumnIndex()] as any).x,
    columnData: getHoveredColumnData(),
    isActive: graphId === interactionState.graphId,
    hoveredColumnIndex: getHoveredColumnIndex(),
  };
};
