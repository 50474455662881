import React from 'react';
import styled from 'styled-components';

import Panel from '../../atoms/Panel';
import { Icon } from '../../atoms/Icon';
import { Color } from '../../theme/primitives';

import {
  ExpansionPanelProvider,
  useExpansionPanelState,
} from './ExpansionPanelContext';

type ExpandablePanelProps = {
  children: React.ReactNode | React.ReactNode[];
  renderToggleButtonText: (state: boolean) => React.ReactNode;
  togglePosition?: 'top' | 'bottom';
  hasChevron?: boolean;
};

export const ExpansionPanel: React.FC<ExpandablePanelProps> = ({
  children,
  renderToggleButtonText,
  togglePosition = 'bottom',
  hasChevron = false,
}) => (
  <ExpansionPanelProvider>
    <ExpansionPanelComponent
      renderToggleButtonText={renderToggleButtonText}
      togglePosition={togglePosition}
      hasChevron={hasChevron}
    >
      {children}
    </ExpansionPanelComponent>
  </ExpansionPanelProvider>
);

const ExpansionPanelComponent: React.FC<ExpandablePanelProps> = ({
  children,
  renderToggleButtonText,
  togglePosition,
  hasChevron,
}) => {
  const expansionPanelState = useExpansionPanelState();

  if (!expansionPanelState) {
    return null;
  }

  const [isExpanded, setIsExpanded] = expansionPanelState;

  const handleToggleState = () => {
    const nextState = !isExpanded;
    setIsExpanded(nextState);
  };

  return (
    <StyledPanel>
      {togglePosition === 'top' && (
        <ToggleStateButton onClick={handleToggleState} topRight>
          {renderToggleButtonText(isExpanded)}
          {hasChevron && (
            <Icon
              icon={isExpanded ? 'chevronUp' : 'chevronDown'}
              iconSize="18"
            />
          )}
        </ToggleStateButton>
      )}
      {children}
      {togglePosition === 'bottom' && (
        <ToggleStateButton onClick={handleToggleState}>
          {renderToggleButtonText(isExpanded)}
          {hasChevron && (
            <Icon
              icon={isExpanded ? 'chevronUp' : 'chevronDown'}
              iconSize="18"
            />
          )}
        </ToggleStateButton>
      )}
    </StyledPanel>
  );
};

const StyledPanel = styled(Panel)`
  padding: 24px;
  border-radius: 8px;
  border-color: ${Color.grey200};
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ToggleStateButton = styled.button<{ topRight?: boolean }>`
  background-color: transparent;
  border: none;
  color: ${Color.blue400};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  cursor: pointer;
  gap: 4px;
  display: flex;
  align-items: center;

  ${({ topRight }) =>
    topRight
      ? `
    position: absolute;
    top: 16px;
    right: 16px;
  `
      : `
    align-self: flex-end;
    margin-top: 16px;
  `}
`;
