import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { isEnterPressed } from '../../utils';
import { Icon } from '../Icon';

const ArrowDownIconWrapper = styled.div`
  display: flex;
  align-items: center;
  transform: ${({ isToggled }: { isToggled: boolean }) =>
    isToggled ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const ArrowRightIconWrapper = styled.div`
  position: relative;
  display: flex;
  top: 1px;
`;

const InputContainer = styled.div<{
  width?: number;
  height: number;
  isToggled: boolean;
}>`
  padding: 0 16px;
  box-sizing: border-box;
  border: 1px solid;
  display: flex;
  align-items: center;
  border-color: ${({ theme, isToggled }) =>
    theme.panel.color[isToggled ? 'focus' : 'default'].border};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => height}px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.panel.color.default.background};
  justify-content: space-between;
  cursor: pointer;

  & svg {
    fill: ${({ theme, isToggled }) =>
      theme.panel.color[isToggled ? 'focus' : 'default'].border};
  }

  &:hover,
  &:focus svg,
  &:hover svg {
    border-color: ${({ theme }) => theme.panel.color.focus.border};
    fill: ${({ theme }) => theme.panel.color.focus.border};
  }
`;

const InputTxt = styled.input`
  width: 125px;
  padding: 0;
  text-align: center;
  border: none;
  font-size: 14px;
  color: ${({ theme }) => theme.global.color.text};
  background-color: ${({ theme }) => theme.panel.color.default.background};
  pointer-events: none;

  :disabled {
    cursor: inherit;
  }
`;

const InnerContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 16px;
`;

export interface DateRangeInputProp {
  label?: string;
  date: string;
  time: string;
  isActive?: boolean;
  width?: number;
  height?: number;
  isExpanded?: boolean;
  setIsExpanded?: (value: boolean) => void;
  className?: string;
}

export const DateRangeInput: React.FC<DateRangeInputProp> = ({
  label,
  date,
  time,
  isActive,
  width,
  height = 40,
  isExpanded = false,
  setIsExpanded,
  className,
}) => {
  const [isToggled, setToggle] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setToggle(isExpanded);
  }, [isExpanded]);

  const onClick = () => {
    setToggle(!isToggled);
    setIsExpanded && setIsExpanded(!isToggled);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (isEnterPressed(event.key)) {
      onClick();
    }
  };

  return (
    <InputContainer
      ref={ref}
      width={width}
      height={height}
      className={className}
      isToggled={isToggled}
      tabIndex={0}
      onClick={onClick}
      onKeyPress={handleKeyPress}
    >
      <InnerContainer>
        <InputTxt
          aria-labelledby="date-input"
          type="text"
          value={date}
          disabled
        />
        <ArrowRightIconWrapper>
          <Icon icon="arrowRight" iconSize="15" />
        </ArrowRightIconWrapper>
        <InputTxt
          aria-labelledby="time-input"
          type="text"
          value={time}
          disabled
        />
      </InnerContainer>
      <ArrowDownIconWrapper isToggled={isToggled}>
        <Icon icon="arrowDown" iconSize="12" />
      </ArrowDownIconWrapper>
    </InputContainer>
  );
};
