import React from 'react';
import styled from 'styled-components';

import { Color } from '../../../../../theme/primitives';
import Text from '../../../../../atoms/Text';

interface HandleProps {
  className?: string;
  isDragging?: boolean;
}

export const Handle: React.FC<HandleProps> = ({ className }) => (
  <Container className={className}>
    <Ornament />
  </Container>
);

export const HorizontalHandle: React.FC<HandleProps & {
  style?: React.CSSProperties;
  thresholdTooltip?: string;
}> = ({ className, style, isDragging, thresholdTooltip }) => {
  return (
    <HorizontalContainer className={className} style={style}>
      <HorizontalOrnament isDragging={!!isDragging} />
      <ThresholdLabel>
        <Text>{thresholdTooltip || 'Threshold'}</Text>
      </ThresholdLabel>
    </HorizontalContainer>
  );
};
const Container = styled.div`
  position: absolute;
  top: 50%;

  width: 12px;
  height: 24px;
  border-radius: 2px;
  background-color: ${Color.grey900};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Ornament = styled.div`
  width: 2px;
  height: 16px;
  border-radius: 1px;
  background-color: ${Color.white};
`;

const ThresholdLabel = styled.div`
  display: none;
  position: absolute;
  max-width: 300px;
  text-align: center;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${Color.grey700};
  color: ${Color.white};
  transform: translate(-50%, -100%);
  left: 50%;
  top: -12px;
`;

const HorizontalContainer = styled.div`
  position: absolute;
  top: 50%;

  width: 24px;
  height: 12px;
  border-radius: 2px;
  background-color: ${Color.grey700};

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    ${ThresholdLabel} {
      display: inline-block;
    }
  }
`;

interface HorizontalOrnamentProps {
  isDragging: boolean;
}

const HorizontalOrnament = styled.div<HorizontalOrnamentProps>`
  width: 16px;
  height: 2px;
  cursor: grab;
  border-radius: 1px;
  background-color: ${props => (props.isDragging ? '#666' : Color.white)};
  transition: background-color 0.3s ease;
  &:active {
    cursor: grabbing;
  }
  &:hover {
    background-color: #ccc;
  }
`;
