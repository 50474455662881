import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: process.env.AUTH_TOKEN_SENTRY,

  beforeBreadcrumb(breadcrumb) {
    return breadcrumb;
  },

  release: process.env.APP_VERSION,
  environment: process.env.APP_SENTRY_ENVIRONMENT,
  sampleRate: 0.2,
  // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  // if uncommented, this will pop up a dialog every time theres an error and ask the user for what happened
  // see more docs here:
  // https://docs.sentry.io/enriching-error-data/user-feedback/?platform=browser
  // beforeSend(event, hint) {
  //   // Check if it is an exception, and if so, show the report dialog
  //   if (event.exception) {
  //     Sentry.showReportDialog({ eventId: event.event_id });
  //   }
  //   return event;
  // }
});
