export const APP = {
  env: process.env.APP_ENV,
  endpoint: process.env.API_ACCOUNTS,
  stats_endpoint: process.env.API_STATS,
  captcha_sitekey: process.env.SITEKEY_AUTH,
  passive_captcha_sitekey: process.env.SITEKEY_A11Y,
  fraud_billing_captcha_sitekey: process.env.SITEKEY_FRAUD,
  captcha_endpoint: process.env.API_HCAPTCHA,
  accounts_captcha_endpoint: process.env.API_ACCOUNTS,
  docs_url: process.env.COMPANY_DOCS,
} as Record<string, string>;

export const hCaptcha = {
  sitekey: APP.captcha_sitekey,
  passiveSitekey: APP.passive_captcha_sitekey,
  fraudBillingCaptchaSitekey: APP.fraud_billing_captcha_sitekey,
  endpoint: APP.captcha_endpoint,
};

export const AnnotationTypes = [
  {
    value: 'boundingBox',
    label: 'Bounding box',
  },
  {
    value: 'categorization',
    label: 'Categorization',
  },
  {
    value: 'comparison',
    label: 'Comparison',
  },
  {
    value: 'landmark',
    label: 'Landmark',
  },
  {
    value: 'polygon3',
    label: 'Polygon 3 points',
  },
  {
    value: 'polygon4',
    label: 'Polygon 4 points',
  },
  {
    value: 'polygon5',
    label: 'Polygon 5 points',
  },
  {
    value: 'polygon6',
    label: 'Polygon 6 points',
  },
];

export const IS_FOUNDATION = process.env.COMPANY_LOGO === 'foundation';
export const BRANDING = process.env.COMPANY_LOGO;
export const COMPANY = process.env.COMPANY_NAME;
export const TEXT_BRANDING = process.env.COMPANY_BRAND;
export const LINK_BRANDING = process.env.COMPANY_LINK;
export const LINK_COMPANY = process.env.COMPANY_PRIVACY;
// Message thrown by backend indicating session expiration
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
