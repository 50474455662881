import React, { createContext, useState, useContext } from 'react';

interface InteractionPoint {
  bounding: {
    x: number;
    y: number;
    height: number;
    width: number;
  };
  data: object;
}

type SelectInteractionState = [
  InteractionPoint | null,
  (value: InteractionPoint | null) => void,
];

const Context = createContext<SelectInteractionState | null>(null);

export const SelectInteractionProvider: React.FC = ({ children }) => {
  const state = useState<InteractionPoint | null>(null);

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

export const useSelectInteraction = (): SelectInteractionState | null => {
  const state = useContext(Context);

  return state;
};
