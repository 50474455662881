import React from 'react';

export interface ChartArea {
  x: Axis;
  y: Axis;
}

export interface Axis {
  min: number;
  max: number;
  scaleMarks: number[];
  step?: number;
  format?: (value: number) => string;
  name?: string;
}

export interface AxisConfig {
  min: number;
  max: number;
  step?: number;
  format?: (value: number) => string;
  scaleDivisors?: number[];
  scaleOffset?: number;
  name?: string;
}

export const DEFAULT_AXIS: Axis = {
  min: 0,
  max: 1,
  step: 0.1,
  scaleMarks: [],
};

const DEFAULT_STATE: ChartArea = {
  x: DEFAULT_AXIS,
  y: DEFAULT_AXIS,
};

export const ChartAreaContext = React.createContext<ChartArea>(DEFAULT_STATE);

export function formatValueOnAxis(axis: Axis, value: number): string {
  if (axis.format) {
    return axis.format(value);
  }

  return value.toLocaleString();
}
