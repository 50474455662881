import React from 'react';
import styled, { css } from 'styled-components';

import Text from '../Text';

export interface PageNavItemProps {
  selected: boolean;
  title: string;
  href?: string;
  linkComponent?: React.ComponentType<any>;
}

export const PageNavItem: React.FC<PageNavItemProps> = ({
  selected,
  href,
  title,
  linkComponent,
}) => {
  return (
    <Container selected={selected}>
      <Link href={href} as={linkComponent}>
        <Text variant="h4">{title}</Text>
      </Link>
    </Container>
  );
};

const selectedState = css`
  ::after {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 2px;
    height: 4px;
    background: ${({ theme }) => theme.navItem.color.border};
    transform: translateY(50%);
  }
`;

const Container = styled.li<{ selected: boolean }>`
  display: block;
  position: relative;

  ${({ selected }) => selected && selectedState};

  margin-right: ${({ theme }) => theme.tab.layout.gap};

  :last-child {
    margin-right: 0;
  }
`;

const Link = styled.a`
  padding: ${({ theme }) => theme.tab.shape.padding};
  display: block;
`;
