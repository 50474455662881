import React from 'react';
import styled from 'styled-components';

interface BulletListProps {
  color: string;
  className?: string;
}

const BulletItemWrapper = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const BulletItem = styled.i<{ color: string }>`
  display: flex;
  align-items: center;
  margin-right: 5px;
  border-radius: 50%;
  background-color: ${props => props.color};
  width: 10px;
  height: 10px;
`;

const BulletListItem: React.FC<BulletListProps> = ({
  color,
  className,
  children,
}) => (
  <BulletItemWrapper className={className}>
    <BulletItem color={color} />
    {children}
  </BulletItemWrapper>
);

export default BulletListItem;
