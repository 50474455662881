import { Color } from '../../theme/primitives';

export enum OptionsListVariant {
  BASE = 'base',
  DARK = 'dark',
}

const theme = {
  optionsList: {
    [OptionsListVariant.BASE]: {
      default: {
        color: Color.grey600,
        backgroundColor: Color.white,
        borderColor: Color.grey600,
        padding: '8px 16px',
      },
      hover: {
        color: Color.grey600,
        backgroundColor: Color.blue100,
      },
      active: {
        color: Color.grey600,
        backgroundColor: Color.blue100,
      },
    },
    [OptionsListVariant.DARK]: {
      default: {
        color: Color.grey600,
        backgroundColor: Color.white,
        borderColor: Color.grey600,
        padding: '8px 16px',
      },
      hover: {
        color: Color.white,
        backgroundColor: Color.grey500,
      },
      active: {
        color: Color.white,
        backgroundColor: Color.grey400,
      },
    },
  },
};

export default theme;
