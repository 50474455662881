import React, { useState } from 'react';
import styled from 'styled-components';

import { Color } from '../../theme/primitives';

export interface DropdownMenuProps {
  options: {
    label: string;
    value: string;
    color?: string;
    show: boolean;
    disabled: boolean;
  }[];
  menuLabel?: any;
  onChange: Function;
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  options,
  menuLabel,
  onChange,
}) => {
  const DefaultMenuLabel: React.FC = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-three-dots-vertical"
        viewBox="0 0 16 16"
      >
        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
      </svg>
    );
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggleDropDown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option: any) => {
    if (option.disabled === true) {
      return;
    }
    setIsOpen(false);
    onChange(option.value);
  };

  const handleOptionBlur = () => setIsOpen(false);

  return (
    <Main tabIndex={0} onBlur={handleOptionBlur}>
      <DropDownContainer>
        <DropDownHeader onClick={toggleDropDown}>
          {menuLabel || <DefaultMenuLabel />}
        </DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              {options
                .filter(option => option.show !== false)
                .map(option => (
                  <ListItem
                    onClick={() => handleOptionClick(option)}
                    key={Math.random()}
                    color={option.color}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </ListItem>
                ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </Main>
  );
};

const Main = styled('div')`
  font-family: sans-serif;
  background: #f0f0f0;
`;

const DropDownContainer = styled('div')`
  margin: 0 auto;
  position: relative;
`;

const DropDownHeader = styled('div')`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  background: ${Color.white};
  border: 1px solid ${Color.grey400};
  border-radius: 4px;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    color: ${Color.white};
    background: ${Color.grey700};
  }
  &:focus {
    background: ${Color.grey100};
  }
`;

const DropDownListContainer = styled('div')`
  position: absolute;
  z-index: 999;
  top: 45px;
  right: 0px;
`;

const DropDownList = styled('ul')`
  padding: 0;
  margin: 0;
  border: 1px solid ${Color.grey400};
  border-radius: 4px;
  background: ${Color.white};
`;

const ListItem = styled.li<{
  disabled: boolean;
}>`
  list-style: none;
  padding: 10px 20px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${props => (props.color ? props.color : '')};
  background: ${props => (props.disabled ? Color.grey100 : '')};
  user-select: none;
  &:hover,
  &:focus {
    background: ${Color.grey100};
  }
`;
