import React, { useState } from 'react';

import { ZoomInteractionStateValue, INITIAL_STATE } from '../zoomConstants';
import { useCloseOnClickOutsideCanvas } from '../hooks';

import { ZoomInteractionContext } from './ContextZoomInteraction';

export const ZoomInteractionProvider: React.FC = ({ children }) => {
  const zoomInteractionState = useState<ZoomInteractionStateValue | null>(
    INITIAL_STATE,
  );

  const [, setZoomInteractionState] = zoomInteractionState;

  useCloseOnClickOutsideCanvas(setZoomInteractionState);

  return (
    <ZoomInteractionContext.Provider value={zoomInteractionState}>
      {children}
    </ZoomInteractionContext.Provider>
  );
};
