import { Size } from '../../theme/primitives';

const theme = {
  tab: {
    shape: {
      padding: Size.space200,
    },
    layout: {
      gap: Size.space800,
    },
  },
};

export default theme;
