import React, { memo } from 'react';
import styled from 'styled-components';

import { Color } from '../../../theme/primitives';
import { Icon, IconProp } from '../../../atoms/Icon';

interface Props {
  label: string;
  value: string;
  icon?: IconProp;
  badge?: React.ReactNode;
  height: number;
  width?: number;
  active: boolean;
  hover: boolean;
  onClick: (value: string) => void;
  onHover: (actionData: { action: string; value: string }) => void;
}

const CustomListOption: React.FC<Props> = ({
  label,
  icon,
  badge,
  width,
  height,
  active,
  hover,
  value,
  onHover,
  onClick,
}) => {
  const handleClick = () => {
    onClick?.(value);
  };

  const handleEnter = () => {
    onHover?.({ action: 'over', value });
  };

  const handleLeave = () => {
    onHover?.({ action: 'out', value });
  };

  return (
    <Container
      data-cy={`option-${label.toLowerCase()}`}
      width={width}
      height={height}
      onClick={handleClick}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
    >
      <Text active={active} isIcon={!!icon} title={label}>
        {label}
        {badge}
      </Text>
      <Background active={active} hover={hover} />
      {icon && <OptionIcon icon={icon} iconSize="24" />}
    </Container>
  );
};

interface IContainer {
  width?: number;
  height?: number;
}

const Container = styled.div.attrs<IContainer>(({ width, height }) => ({
  style: {
    width,
    height,
  },
}))<IContainer>`
  position: relative;
  cursor: pointer;
`;

interface IBackground {
  active?: boolean;
  hover?: boolean;
}

const Background = styled.div.attrs<IBackground>(
  ({ active, hover, theme }) => ({
    style: {
      backgroundColor: theme?.optionsList?.base?.hover?.color
        ? active
          ? theme?.optionsList?.base?.active?.backgroundColor
          : hover
          ? theme?.optionsList?.base?.hover?.backgroundColor
          : theme?.optionsList?.base?.default?.backgroundColor
        : active
        ? Color.grey600
        : hover
        ? Color.grey100
        : Color.white,
    },
  }),
)<IBackground>`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  transition: all 0.2s;
`;

interface IText {
  active?: boolean;
  isIcon?: boolean;
}

const Text = styled.div.attrs<IText>(({ active, theme, isIcon }) => ({
  style: {
    padding: theme?.optionsList?.base?.default?.padding || 10,
    ...(isIcon ? { paddingRight: 42 } : {}),
    color: theme?.optionsList?.base?.active?.color
      ? active
        ? theme?.optionsList?.base?.active?.color
        : theme?.optionsList?.base?.default?.color
      : active
      ? Color.white
      : Color.grey500,
  },
}))<IText>`
  width: 100%;
  height: 100%;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.35px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const OptionIcon = styled(Icon)`
  color: ${Color.grey400};
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  cursor: initial;
  pointer-events: none;
`;

export default memo(CustomListOption);
