import React from 'react';
import styled from 'styled-components';

import Text from '../Text';
import { TimePeriodButton, TimePeriodOption } from '../Calendar/utilComponents';
import CalendarContext from '../Calendar/CalendarContext';

const TimePeriodContainer = styled.div`
  span {
    margin-left: 10px;
    display: inline-block;
    margin-top: 10px;
  }
`;

const TimePeriodList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  &:last-of-type {
    margin-top: 5px;
  }
`;

const TimePeriodText = styled(Text)`
  font-size: 14px;
  font-weight: 700;
`;

export interface TimePeriodProps {
  value: number | null;
  onChange: (value: number | null) => void;
}

export const TimePeriod: React.FC<TimePeriodProps> = ({
  value: selectedValue,
  onChange,
}) => {
  const { translations, timeRangeOptions } = React.useContext(CalendarContext);

  return (
    <TimePeriodContainer>
      <TimePeriodText>{translations.timePeriodTitle}</TimePeriodText>
      <TimePeriodList>
        {timeRangeOptions.map(({ value, label }) => (
          <TimePeriodOption key={value}>
            <TimePeriodButton
              isSelected={selectedValue === value}
              onClick={() => {
                onChange(value);
              }}
            >
              {label}
            </TimePeriodButton>
          </TimePeriodOption>
        ))}
        <TimePeriodOption>
          <TimePeriodButton
            isSelected={selectedValue === null}
            onClick={() => {
              onChange(null);
            }}
          >
            {translations.customTimePeriod}
          </TimePeriodButton>
        </TimePeriodOption>
      </TimePeriodList>
    </TimePeriodContainer>
  );
};
