import React from 'react';
import styled from 'styled-components';

import theme from '../../../atoms/GraphAxis/theme';
import { Size } from '../../../theme/primitives';
import { AXIS_Y_OFFSET, AXIS_Y_WIDTH } from '../constants';

export interface AxisYProps {
  height: number;
  marks: string[];
}

const AxisY: React.FC<AxisYProps> = ({ height, marks }) => (
  <AxisContainer width={AXIS_Y_WIDTH} height={height}>
    {marks.map((mark, index) => (
      <AxisMarkWrapper key={index} height={height / marks.length}>
        <AxisMark>
          <MarkLabel>{mark}</MarkLabel>
        </AxisMark>
      </AxisMarkWrapper>
    ))}
  </AxisContainer>
);

export default AxisY;

const axisTextColor = theme.axis.color.default.text;
const axisBorderColor = theme.axis.color.default.border;

const AxisContainer = styled.div<{
  width: number;
  height: number;
}>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  position: absolute;
  top: 0;
  left: ${AXIS_Y_OFFSET}px;
  display: flex;
  flex-direction: column;
  border-right: solid 1px ${axisBorderColor};
  box-sizing: border-box;
  pointer-events: none;
`;

const AxisMarkWrapper = styled.div<{ height: number }>`
  position: relative;
  display: flex;
  width: 100%;
  height: ${({ height }) => height}px;
  left: -${AXIS_Y_OFFSET}px;
`;

const AxisMark = styled.div`
  color: ${axisTextColor};
  width: 100%;
  height: 100%;
  margin: 0;
  padding-right: ${Size.space200};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

const MarkLabel = styled.label`
  text-align: right;
  white-space: nowrap;
  width: 160px;
  margin-left: -100px;
  font-size: 11px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
`;
