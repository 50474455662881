/* eslint-disable no-underscore-dangle */
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Color } from '../../theme/primitives';
import { addPropsToChildren } from '../../utils/propsUtils';
import { AdvancedOption } from '../AdvancedOption';

interface AdvancedOptionsListProps {
  children: ReactNode;
  onClick?: (value: string) => void;
  __type?: 'AdvancedOptionsList';
}

export const AdvancedOptionsList: React.FC<AdvancedOptionsListProps> = ({
  children,
  onClick,
}) => {
  return (
    <OptionsListContainer>
      {addPropsToChildren(children, [
        {
          type: AdvancedOption.defaultProps?.__type,
          events: {
            onClick: onClick && ((value: string) => onClick(value)),
          },
        },
      ])}
    </OptionsListContainer>
  );
};

AdvancedOptionsList.defaultProps = {
  __type: 'AdvancedOptionsList',
};

const OptionsListContainer = styled.div`
  overflow-y: auto;
  margin-top: 14px;
  border-top: 1px solid ${Color.grey300};

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: ${Color.grey400};
    border-radius: 8px;
  }
`;
