import React, { useEffect, useRef, useState } from 'react';
import styled, { DefaultTheme } from 'styled-components';

import { Icon } from '../../atoms/Icon';
import { colorStates, typography } from '../../theme/themeUtils';

export interface WidgetFilterProps {
  filterOptions: Array<{ value: string; label: string }>;
  onFilterOptionChange: (value: string) => void;
  selectedFilter: string;
  variant?: keyof DefaultTheme['widgetFilter'];
}

export type WithFilterProps<T extends any> = T & WidgetFilterProps;

export const WidgetFilter = ({
  filterOptions,
  onFilterOptionChange,
  selectedFilter,
  variant,
}: WidgetFilterProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const selectedOption =
    filterOptions.find(option => option.value === selectedFilter) || undefined;
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!isOpened) {
      return;
    }

    const eventHandler = (e: Event): void => {
      if (
        !containerRef?.current ||
        containerRef?.current.contains(e.target as Node)
      ) {
        return;
      }
      setIsOpened(false);
    };

    document.addEventListener('mousedown', eventHandler);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('mousedown', eventHandler);
    };
  }, [isOpened]);
  const toggle = () => setIsOpened(!isOpened);
  return (
    <DropDownContainer ref={containerRef} className="filter-dropdown-container">
      <DropDownInputContainer
        tabIndex={0}
        variant={variant}
        isOpened={isOpened}
        onMouseDownCapture={toggle}
        onKeyDown={({ key }) => {
          if (key === 'Enter' || key === ' ') {
            toggle();
          }
        }}
      >
        <DropDownValue>{selectedOption?.label || ''}</DropDownValue>
        <IconContainer isOpened={isOpened}>
          <Icon iconSize="xs" icon="arrowDown" />
        </IconContainer>
      </DropDownInputContainer>
      {isOpened && (
        <Options>
          {filterOptions.map(option => (
            <OptionWrapper
              key={option.value}
              tabIndex={0}
              variant={variant}
              onMouseDownCapture={() => {
                onFilterOptionChange(option.value);
                setIsOpened(false);
              }}
              onKeyDown={({ key }) => {
                if (key === 'Enter' || key === ' ') {
                  onFilterOptionChange(option.value);
                  setIsOpened(false);
                }
              }}
            >
              <OptionItem variant={variant}>{option.label}</OptionItem>
              <OptionSeparator />
            </OptionWrapper>
          ))}
        </Options>
      )}
    </DropDownContainer>
  );
};

const IconContainer = styled.div<{
  isOpened: boolean;
}>`
  ${({ isOpened }) => isOpened && 'transform: rotate(180deg);'};
`;

const DropDownValue = styled.div`
  ${typography('default')};
`;

const DropDownInputContainer = styled.div<{
  isOpened: boolean;
  variant?: keyof DefaultTheme['widgetFilter'];
}>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  height: 30px;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  border-radius: 3px;
  & > ${IconContainer} {
    fill: ${({ isOpened, theme, variant }) =>
      isOpened
        ? theme.widgetFilter[variant || 'default'].active.iconColor
        : theme.widgetFilter[variant || 'default'].inActive.iconColor};
  }
  ${({ theme, variant, isOpened }) =>
    isOpened
      ? {
          color: theme.widgetFilter[variant || 'default'].active.text,
          backgroundColor:
            theme.widgetFilter[variant || 'default'].active.background,
          borderColor: theme.widgetFilter[variant || 'default'].active.border,
        }
      : colorStates(theme.widgetFilter[variant || 'default'])}

  &:focus {
    border-width: 1px;
    border-style: solid;
    & > ${IconContainer} {
      fill: currentColor;
    }
  }

  &:hover {
    & > ${IconContainer} {
      fill: currentColor;
    }
  }
`;

const DropDownContainer = styled.div`
  width: 100%;
  position: relative;
`;

const Options = styled.div<{ variant?: keyof DefaultTheme['widgetFilter'] }>`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 99;
  box-sizing: border-box;
  border-radius: ${({ variant, theme }) =>
    theme.widgetFilter[variant || 'default'].active.borderRadius};
  border: ${({ variant, theme }) =>
    `1px solid ${theme.widgetFilter[variant || 'default'].active.border}`};
  overflow: hidden;
  margin-top: 5px;
  max-height: 122px;
  width: 100%;
  overflow-y: auto;
  background: ${({ variant, theme }) =>
    theme.widgetFilter[variant || 'default'].active.background};
`;

const OptionItem = styled.div<{
  variant?: keyof DefaultTheme['widgetFilter'];
}>`
  ${typography('default')};
  width: 100%;
`;

const OptionSeparator = styled.div`
  position: absolute;
  height: 1px;
  width: calc(100% - 20px);
  bottom: 0;
  left: 10px;
`;

const OptionWrapper = styled.div<{
  variant?: keyof DefaultTheme['widgetFilter'];
}>`
  position: relative;
  display: flex;
  height: 30px;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  padding: ${({ theme, variant }) =>
    `0 ${theme.widgetFilterOption.shape[variant || 'default'].padding}`};
  & ${OptionSeparator} {
    background-color: ${({ theme, variant }) =>
      theme.widgetFilterOption.color[variant || 'default'].inActive.border};
  }
  &:hover {
    background-color: ${({ theme, variant }) =>
      theme.widgetFilterOption.color[variant || 'default'].hover.background};
  }
`;
