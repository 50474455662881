import React from 'react';
import styled from 'styled-components';

import { Line } from '../../organisms/LineGraph/types';
import BulletListItem from '../BulletListItem';

const Container = styled.ul<{
  height: number;
}>`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: ${({ height }) => height}px;
`;

const StyledBulletListItem = styled(BulletListItem)<{ isFirstItem: boolean }>`
  &:first-child {
    margin-left: 0;
  }
  margin-left: 10px;
`;

const LegendText = styled.span`
  margin-left: 2px;
`;

interface LineChartLegendProps {
  lines: Line[];
  height: number;
  className?: string;
}

/* eslint-disable import/prefer-default-export */
export const LineChartLegend: React.FC<LineChartLegendProps> = ({
  lines,
  height,
  className,
}) => {
  return (
    <Container className={className} height={height}>
      {lines.map((line, index) => (
        <StyledBulletListItem
          key={line.label}
          color={line.color}
          isFirstItem={index === 0}
        >
          <LegendText>{line.label}</LegendText>
        </StyledBulletListItem>
      ))}
    </Container>
  );
};
