import React from 'react';
import styled from 'styled-components';

import { Color, Size } from '../../theme/primitives';
import { Table, TableHead, TableRow, TableBody } from '../../atoms/Table';
import { Loader } from '../../atoms/Loader';

import { ROW_HEIGHT } from './constants';
import { useDataReportTableContext } from './hooks';
import { HoverableHeaderCell } from './components';

export interface DataReportTableProps {
  renderRow: (item: any, index?: number, dataArray?: any[]) => JSX.Element;
  renderOnError?: () => JSX.Element;
  renderEmptyState?: () => JSX.Element;
  height?: number;
  className?: string;
}

export const DataReportTable: React.FC<DataReportTableProps> = ({
  renderRow,
  renderOnError,
  renderEmptyState,
  height,
  className,
}) => {
  const {
    isError,
    isLoading,
    tableState,
    sortDirection,
    sortField,
    handleTableHeaderMouseIn,
    handleTableHeaderMouseOut,
    handleTableHeaderCellClick,
  } = useDataReportTableContext();

  const { columns, headerState, displayedData } = tableState;
  const { isHovered, direction, sortedColumnId } = headerState;

  return (
    <TableContainer height={height} className={className}>
      <StyledTable>
        <StyledTableHead>
          <TableRow
            onMouseEnter={handleTableHeaderMouseIn}
            onMouseLeave={handleTableHeaderMouseOut}
          >
            {columns.map((column, index) => (
              <HoverableHeaderCell
                key={`report-data-table-key-${index}`}
                id={column.id}
                isHovered={isHovered}
                sortedColumnId={sortField || sortedColumnId}
                direction={sortDirection || direction}
                onClick={handleTableHeaderCellClick}
                width={column.width}
              >
                {column.label}
              </HoverableHeaderCell>
            ))}
          </TableRow>
        </StyledTableHead>
        {!isError && !isLoading && (
          <TableBody rowHeight={ROW_HEIGHT}>
            {displayedData.map(renderRow)}
          </TableBody>
        )}
      </StyledTable>
      {(isLoading || isError || !displayedData.length) && (
        <MessageContainer>
          {!isError && isLoading && <Loader />}
          {!isLoading && isError && renderOnError?.()}
          {!isLoading &&
            !isError &&
            !displayedData.length &&
            renderEmptyState?.()}
        </MessageContainer>
      )}
    </TableContainer>
  );
};

const TableContainer = styled.div<{
  height?: number;
}>`
  height: ${({ height }) => `${height}px`};
  overflow-y: auto;

  background: ${Color.white};
  box-sizing: border-box;
  border-top: 1px solid ${Color.grey300};
`;

const StyledTable = styled(Table)`
  thead {
    box-shadow: inset 0 -1px 0 0 ${Color.grey300};
    background-color: ${Color.white};

    tr {
      th {
        padding: ${Size.space200} ${Size.space300};
        border-left: 0;
        border-right: 0;

        cursor: pointer;
      }
    }
  }

  tbody {
    padding-bottom: ${Size.space600};

    tr {
      td {
        border-color: ${Color.white};
      }
      &:nth-child(2n) {
        background-color: ${Color.grey050};
        td {
          border-color: ${Color.grey050};
        }
      }
    }

    tr:first-child {
      td {
        border-top: 0;
      }
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 4px;
    }

    tr:last-child td:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;

const StyledTableHead = styled(TableHead)`
  height: 40px;
`;

const MessageContainer = styled.div`
  height: calc(100% - 50px);
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  border-top: 1px solid ${Color.grey300};
`;
