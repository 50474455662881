import React, { ReactNode, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Color, Size } from '../../theme/primitives';
import { Icon } from '../../atoms/Icon';
import { InputField } from '../../atoms/InputField';
import { ActionTable } from '../ActionTable';
import { ActionTableTheme } from '../ActionTable/constants';

export interface DomainsWidgetProps {
  items: string[];
  header: string;
  description: string;
  tooltip?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  customLoadingIndicator?: ReactNode;
  onDomainAdd: (domain: string) => void;
  onDomainsRemove: (domains: any[]) => void;
  placeholder: string;
  noItemsText?: string;
  addButtonTitle?: string;
  removeButtonTitle?: string;
}

interface TableHeaderInterface {
  id?: string;
  title?: string;
  width?: string;
}

const DomainsWidget = ({
  items,
  isDisabled,
  onDomainAdd,
  onDomainsRemove,
  placeholder,
  noItemsText = 'No items added.',
  addButtonTitle,
  removeButtonTitle,
}: DomainsWidgetProps) => {
  const [domain, setDomain] = useState('');
  const [error, setError] = useState<any>('');

  const handleInputChange = (e: any) => {
    setDomain(e.target.value);
  };

  const removeSelectedDomains = (domainsToRemove: number[]) => {
    onDomainsRemove(domainsToRemove);
  };

  const handleAddClick = () => {
    if (domain && domain.trim() !== '') {
      const domainRegex = /^([\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)$/i;

      if (domain.match(domainRegex)) {
        if (items.includes(domain)) {
          setError('This domain already exists.');
        } else {
          try {
            onDomainAdd(domain);
            setDomain('');
            setError('');
          } catch (err) {
            setError(err as any);
          }
        }
      } else {
        setError('Please enter a valid domain.');
      }
    } else {
      setError('Please enter a valid domain.');
    }
  };

  const tableData = useMemo(() => {
    return {
      headers: [{ title: 'Domain' }] as TableHeaderInterface[],
      rows: items.map((item, index) => {
        return {
          id: index,
          columns: [
            {
              id: 'domain',
              value: item,
            },
          ],
        };
      }),
    };
  }, [items]);

  const { headers = [], rows = [] as any[] } = tableData;

  return (
    <ContentContainer>
      {!isDisabled && (
        <DomainInputContainer>
          <DomainInput
            disabled={isDisabled}
            placeholder={placeholder}
            value={domain}
            errorMessage={error}
            onChange={handleInputChange}
            data-form-type="other"
          />
          <AddButtonContainer title={addButtonTitle} onClick={handleAddClick}>
            <Icon icon="plus" iconSize="14" fill={Color.grey600} />
          </AddButtonContainer>
        </DomainInputContainer>
      )}

      <TableContainer>
        <ActionTable
          headers={headers}
          rowItems={rows}
          headerHeight="40px"
          rowHeight="40px"
          noItemsText={noItemsText}
          removeButtonTitle={removeButtonTitle}
          isActionVisible={!isDisabled}
          tableTheme={
            isDisabled ? ActionTableTheme.DISABLED : ActionTableTheme.SECONDARY
          }
          isRowDeletionEnabled={!isDisabled}
          onRowsRemove={removeSelectedDomains}
        />
      </TableContainer>
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const TableContainer = styled.div`
  max-height: 10%;
`;

const AddButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  padding: 0;
  border: 0;
  transition: all 0.2s;
  border-radius: 6px;
  margin-left: 8px;

  &:hover,
  &:focus {
    background-color: ${Color.grey100};
  }
`;

const DomainInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
  max-width: 300px;

  @media (max-width: ${Size.mobile}) {
    max-width: 100%;
  }
`;

const DomainInput = styled(InputField)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  input {
    border-radius: 8px;
  }
`;

export default DomainsWidget;
