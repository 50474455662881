import React from 'react';
import styled from 'styled-components';

export interface SiteLayoutProps {
  navbar: React.ReactNode;
  content: React.ReactNode;
}

export const SiteLayout: React.FC<SiteLayoutProps> = ({ navbar, content }) => {
  return (
    <Container>
      <Main>{content}</Main>
      <NavBar>{navbar}</NavBar>
    </Container>
  );
};

const Container = styled.section`
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const NavBar = styled.section`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
`;

const Main = styled.main`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
`;
