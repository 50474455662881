import { useContext } from 'react';

import { DataReportProviderValue } from '../constants';
import { DataReportTableContext } from '../context';

export const useDataReportTableContext = () => {
  const state = useContext(DataReportTableContext);

  if (state === null) {
    throw new Error(
      'DataReportTable state missing. Likely the component isn’t wrapped with DataReportTableProvider',
    );
  }

  return state as DataReportProviderValue;
};
