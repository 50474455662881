import React from 'react';
import styled from 'styled-components';

import BulletListItem from '../../../atoms/BulletListItem';
import List from '../../../atoms/List';
import { Color, Size } from '../../../theme/primitives';
import { useLineGraphInteraction } from '../hooks/useLineGraphInteraction';

interface TooltipProps {
  format?: (arg0: number) => number;
  hideCount?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  format = value => Math.floor(value),
  hideCount = false,
}) => {
  const lineGraphInteraction = useLineGraphInteraction();

  if (
    lineGraphInteraction === null ||
    lineGraphInteraction.isActive === false
  ) {
    return null;
  }

  const { nearestDataColumn, nearestDataPoint } = lineGraphInteraction;

  const sum = nearestDataColumn.reduce((acc, item) => acc + item.point.y, 0);

  return (
    <TooltipContainer hideCount={hideCount}>
      {!hideCount && (
        <Header>
          <HeaderText>Count:&nbsp;</HeaderText>
          <HeaderValue>{format(sum).toLocaleString()}</HeaderValue>
        </Header>
      )}
      <TooltipItemsList hideCount={hideCount}>
        {nearestDataColumn.map(({ id, color, point, label }) => (
          <StyledListItem
            isActive={id === nearestDataPoint.id}
            color={color}
            key={id}
          >
            <ItemContainer>
              <Col>{label}</Col>
              <Col>|</Col>
              <Col>{format(point.y).toLocaleString()}</Col>
            </ItemContainer>
          </StyledListItem>
        ))}
      </TooltipItemsList>
    </TooltipContainer>
  );
};

const TooltipContainer = styled.div<{ hideCount: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${Size.space200};
  box-sizing: border-box;
  width: auto;

  border: 1px solid ${Color.grey500};
  border-radius: ${Size.radius200};

  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.1);
  background-color: ${Color.white};
`;

const TooltipItemsList = styled(List)<{ hideCount: boolean }>`
  margin-top: ${({ hideCount }) => (hideCount ? '5px' : 0)};
`;

const StyledListItem = styled(BulletListItem)<{ isActive: boolean }>`
  opacity: ${({ isActive }) => (isActive ? '1' : '0.3')};
`;

const ItemContainer = styled.div`
  display: flex;

  flex-direction: row;
  flex: 1;
  flex-wrap: nowrap;
  align-contents: center;

  white-space: nowrap;

  width: auto;
  font-size: 12px;
`;

const Header = styled.div`
  display: flex;
  border-bottom: 1px solid ${() => Color.grey400};
  margin-bottom: ${Size.space100};
  height: 25px;
`;

const HeaderText = styled.div`
  font-size: 12px;
  font-weight: 400;
  position: relative;
  display: inline-block;
  height: 15px;
  line-height: 15px;
`;

const HeaderValue = styled.div`
  font-size: 12px;
  font-weight: 600;
  position: relative;
  display: inline-block;
  height: 15px;
  line-height: 15px;
`;

const Col = styled.div`
  display: inline-block;
  margin-left: ${Size.space100};
  position: relative;
  width: auto;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default Tooltip;
