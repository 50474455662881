import React, { createContext, useState, useContext } from 'react';

interface InteractionPoint {
  graphId: string;
  x: number;
  y: number;
  showMarker?: boolean;
}

type InteractionState = [
  InteractionPoint | null,
  (value: InteractionPoint | null) => void,
];

const Context = createContext<InteractionState | null>(null);

export const GraphInteractionProvider: React.FC = ({ children }) => {
  const state = useState<InteractionPoint | null>(null);

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

export const useInteraction = (): InteractionState => {
  const state = useContext(Context);

  if (state === null) {
    throw new Error(
      'useInteraction must be used within a GraphInteractionProvider',
    );
  }

  return state;
};
