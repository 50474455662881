import React from 'react';
import styled from 'styled-components';

import { colorStates, shape } from '../../theme/themeUtils';

export type ButtonVariant =
  | 'outlined'
  | 'warning'
  | 'danger'
  | 'default'
  | 'confirm'
  | 'simple'
  | 'base'
  | 'contained'
  | 'legacyOutlinedPrimary'
  | 'legacyOutlinedSecondary'
  | 'cancel'
  | 'link';
export type ButtonColor = 'primary' | 'critical';

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: ButtonVariant;
  color?: ButtonColor;
  startIcon?: React.ReactNode;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, color, startIcon, children, ...buttonProps }, ref) => {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <StyledButton {...buttonProps} variant={variant} color={color} ref={ref}>
        {startIcon}
        {children}
      </StyledButton>
    );
  },
);

const StyledButton = styled.button<{
  variant?: ButtonVariant;
  color?: ButtonColor;
}>`
  ${({ theme, variant }) =>
    colorStates(theme.button.color[variant || 'contained'])};
  ${({ theme }) => shape(theme.button.shape.default)};
  cursor: pointer;

  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  white-space: nowrap;

  :disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
`;
