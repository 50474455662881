import React from 'react';

interface ExternalLinkProps {
  className?: string;
  href: string;
  target?: string;
  rel?: string;
  children?: React.ReactChild | React.ReactChild[];
}

const ExternalLink: React.FC<ExternalLinkProps> = ({
  className = '',
  href,
  target = '_blank',
  rel = 'noreferrer',
  children,
}) => {
  return (
    <a href={href} target={target} rel={rel} className={className}>
      {children}
    </a>
  );
};

export default ExternalLink;
