import React from 'react';
import styled from 'styled-components';

import theme from '../../../atoms/GraphAxis/theme';

export interface AxisXProps {
  height: number;
  width: number;
  marks: number[] | string[];
  formatScalemark?: (arg0: number | string) => number | string;
  padding?: number;
  label?: string;
}

const AxisX: React.FC<AxisXProps> = ({
  width,
  height,
  marks,
  padding,
  label,
}) => {
  return (
    <AxisContainer width={width} height={height}>
      {marks.map((scalemark, index) => {
        return (
          <AxisMarkWrapper
            key={index}
            width={width / marks.length}
            padding={padding || 0}
          >
            <MarkTick />
            <AxisMark>
              <MarkLabel data-cy={`x-label-value=${scalemark}`}>
                {scalemark}
              </MarkLabel>
            </AxisMark>
          </AxisMarkWrapper>
        );
      })}
      {label && <AxisLabel>{label}</AxisLabel>}
    </AxisContainer>
  );
};

export default AxisX;

const axisTextColor = theme.axis.color.default.text;
const axisBorderColor = theme.axis.color.default.border;

const AxisContainer = styled.div<{
  width: number;
  height: number;
}>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  border-top: solid 1px ${axisBorderColor};
  box-sizing: border-box;
`;

const AxisMarkWrapper = styled.div<{ width: number; padding: number }>`
  position: relative;
  width: ${({ width, padding }) => width - padding}px;
  height: 50px;
  margin-right: ${({ padding }) => padding}px;
`;

const AxisMark = styled.div`
  color: ${axisTextColor};
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const MarkTick = styled.div`
  position: absolute;
  background-color: ${axisBorderColor};
  width: 1px;
  height: 5px;
  top: 0;
  left: 50%;
`;

const MarkLabel = styled.label`
  text-align: center;
  white-space: nowrap;
  font-size: 11px;
  font-weight: 600;
`;

const AxisLabel = styled.label`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
`;
