/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import { color, shape } from '../../theme/themeUtils';

import TableSectionContext from './TableSectionContext';

interface TdProps
  extends React.DetailedHTMLProps<
    React.TdHTMLAttributes<HTMLTableCellElement>,
    HTMLTableCellElement
  > {
  variant?: 'data';
}

interface ThProps
  extends React.DetailedHTMLProps<
    React.ThHTMLAttributes<HTMLTableHeaderCellElement>,
    HTMLTableHeaderCellElement
  > {
  variant: 'header';
}

export type TableCellProps = ThProps | TdProps;

export const TableCell = React.forwardRef<HTMLTableCellElement, TableCellProps>(
  ({ children, variant, ...props }, ref) => {
    const section = useContext(TableSectionContext);

    if (!section) {
      throw new Error(
        'TableCell is expected to be a child of TableBody or TableHead',
      );
    }

    if (section.variant === 'head' || variant === 'header') {
      const scope = section.variant === 'head' ? 'col' : 'row';

      return (
        <StyledTableHeaderCell {...props} scope={scope} ref={ref}>
          {children}
        </StyledTableHeaderCell>
      );
    }

    return (
      <StyledTableDataCell {...props} ref={ref} height={section.rowHeight}>
        {children}
      </StyledTableDataCell>
    );
  },
);

const cellStyle = css`
  ${({ theme }) => color(theme.table.color.default)};
  ${({ theme }) => shape(theme.table.shape)};

  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledTableDataCell = styled.td`
  ${cellStyle}
`;

const StyledTableHeaderCell = styled.th`
  ${cellStyle}

  &[scope=col] {
    border-bottom-color: ${({ theme }) => theme.table.color.header.border};
  }
`;
