/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled, { css } from 'styled-components';

import { typography } from '../../theme/themeUtils';
import { PaginationItemState } from '../../molecules/Pagination';

export type PaginationItemProps = PaginationItemState & {
  current: number;
  getHref?: (page: number) => string;
  LinkComponent?: React.ComponentType<any>;
  prevLabel?: string;
  nextLabel?: string;
  onClick?: (page: number) => void;
};

export const PaginationItem: React.FC<PaginationItemProps> = ({
  LinkComponent,
  getHref,
  current,
  prevLabel = '<',
  nextLabel = '>',
  onClick,
  ...item
}) => {
  const linkProps: Partial<{
    href: string;
    onClick: () => void;
    linkComponent: React.ComponentType<any>;
  }> = {};

  if ('page' in item) {
    linkProps.href = getHref && getHref(item.page);
    linkProps.onClick = onClick && (() => onClick(item.page));
    linkProps.linkComponent = LinkComponent;
  }

  switch (item.type) {
    case 'start-ellipsis':
    case 'end-ellipsis': {
      return <PaginationLinkItem title="..." disabled />;
    }
    case 'previous':
    case 'next': {
      const label = item.type === 'next' ? nextLabel : prevLabel;

      if ('page' in item) {
        return <PaginationLinkItem title={label} {...linkProps} />;
      }

      return <PaginationLinkItem disabled title={label} />;
    }
    default: {
      return (
        <PaginationLinkItem
          title={item.page.toString()}
          selected={item.page === current}
          {...linkProps}
        />
      );
    }
  }
};

const PaginationLinkItem: React.FC<{
  selected?: boolean;
  disabled?: boolean;
  title: string;
  href?: string;
  linkComponent?: React.ComponentType<any>;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}> = ({ selected, href, title, linkComponent, onClick, disabled }) => {
  const label = <Item>{title}</Item>;
  return (
    <Container selected={selected}>
      {disabled ? (
        label
      ) : (
        <Link onClick={onClick} href={href} as={linkComponent}>
          {label}
        </Link>
      )}
    </Container>
  );
};

const selectedState = css`
  ::after {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 2px;
    height: 2px;
    background: currentColor;
  }
`;

const Container = styled.li<{ selected?: boolean }>`
  display: block;
  position: relative;
  margin-right: ${({ theme }) => theme.pagination.layout.gap};

  :last-child {
    margin-right: 0;
  }

  ${({ selected }) => selected && selectedState};
`;

const Item = styled.div`
  ${typography('default')};
  padding: ${({ theme }) => theme.pagination.shape.padding};
  min-width: 2em;
  text-align: center;
`;

const Link = styled.a`
  display: block;
  border-radius: ${({ theme }) => theme.pagination.shape.borderRadius};

  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.navItem.color.hover};
  }
`;
