import {
  useRef,
  useLayoutEffect,
  useEffect,
  useReducer,
  Reducer,
  Ref,
} from 'react';
import {
  addWeeks,
  differenceInCalendarMonths,
  differenceInCalendarWeeks,
  endOfMonth,
  endOfWeek,
  isAfter,
  isBefore,
  isSameMonth,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { enGB as locale } from 'date-fns/locale';

import { OriginDirection } from '../constants';

type localeProp = typeof locale;

const rowsBetweenDates = (startDate: Date, endDate: Date, locale: localeProp) =>
  differenceInCalendarWeeks(endDate, startDate, { locale }) + 1;

const rowsInMonth = (date: Date, locale: localeProp) =>
  rowsBetweenDates(startOfMonth(date), endOfMonth(date), locale);

const getStartDate = (date: Date, locale: localeProp) =>
  startOfWeek(startOfMonth(date), { locale });

const getEndDate = (date: Date, locale: localeProp) =>
  endOfWeek(addWeeks(endOfMonth(date), 6 - rowsInMonth(date, locale)), {
    locale,
  });

type localeType = typeof locale;

interface CreateInitialProps {
  startDate: Date;
  endDate: Date;
  prevCurrentMonth: Date;
  cellHeight: number;
  isWide: boolean;
  locale: localeType;
  offset: number;
  origin: OriginDirection;
  transition: boolean;
}

type Action =
  | { type: 'setCellHeight'; value: number }
  | { type: 'setIsWide'; value: boolean }
  | { type: 'reset'; value: Date }
  | { type: 'transitionToCurrentMonth'; value: Date };

const createInitialState = (
  currentMonth: Date,
  locale: localeProp,
): CreateInitialProps => {
  return {
    startDate: getStartDate(currentMonth, locale),
    endDate: getEndDate(currentMonth, locale),
    cellHeight: 0,
    isWide: false,
    prevCurrentMonth: currentMonth,
    locale,
    offset: 0,
    origin: OriginDirection.ORIGIN_TOP,
    transition: false,
  };
};

const reducer = (
  state: CreateInitialProps,
  action: Action,
  // eslint-disable-next-line consistent-return
): CreateInitialProps => {
  switch (action.type) {
    case 'setCellHeight':
      return { ...state, cellHeight: action.value };
    case 'setIsWide':
      return { ...state, isWide: action.value };
    case 'reset':
      return {
        ...createInitialState(action.value, state.locale),
        cellHeight: state.cellHeight,
        isWide: state.isWide,
      };
    case 'transitionToCurrentMonth': {
      const { value: currentMonth } = action;
      const { prevCurrentMonth, startDate, endDate, cellHeight } = state;

      const newState = {
        ...state,
        lastCurrentMonth: currentMonth,
        transition: true,
      };

      if (isAfter(currentMonth, prevCurrentMonth)) {
        const offset =
          -(rowsBetweenDates(startDate, currentMonth, state.locale) - 1) *
          cellHeight;

        return {
          ...newState,
          endDate: getEndDate(currentMonth, state.locale),
          offset,
          origin: OriginDirection.ORIGIN_TOP,
        };
      }

      if (isBefore(currentMonth, prevCurrentMonth)) {
        const gridHeight = cellHeight * 6;
        const offset =
          rowsBetweenDates(currentMonth, endDate, state.locale) * cellHeight -
          gridHeight;

        return {
          ...newState,
          startDate: getStartDate(currentMonth, state.locale),
          offset,
          origin: OriginDirection.ORIGIN_BOTTOM,
        };
      }

      return state;
    }
  }
};

interface GridConfig {
  locale: typeof locale;
  month: Date;
  transitionDuration: number;
}

interface Grid {
  startDate: Date;
  endDate: Date;
  cellHeight: number;
  containerElementRef: Ref<HTMLElement>;
  offset: number;
  origin: OriginDirection;
  transition: boolean;
  isWide: boolean;
}

export default function useGrid({
  locale,
  month: currentMonth,
  transitionDuration,
}: GridConfig): Grid {
  const timeoutRef = useRef();
  const containerElementRef = useRef<HTMLElement | null>(null);
  const initialDragPositionRef = useRef(0);

  const [state, dispatch] = useReducer<Reducer<CreateInitialProps, Action>>(
    reducer,
    createInitialState(currentMonth, locale),
  );

  const {
    startDate,
    endDate,
    cellHeight,
    prevCurrentMonth,
    offset,
    origin,
    transition,
    isWide,
  } = state;

  useLayoutEffect(() => {
    const notDragging = !initialDragPositionRef.current;

    if (!isSameMonth(prevCurrentMonth, currentMonth) && notDragging) {
      clearTimeout(timeoutRef.current);

      if (
        Math.abs(differenceInCalendarMonths(currentMonth, prevCurrentMonth)) <=
        3
      ) {
        dispatch({ type: 'transitionToCurrentMonth', value: currentMonth });

        // @ts-ignore
        timeoutRef.current = setTimeout(() => {
          dispatch({ type: 'reset', value: currentMonth });
        }, transitionDuration);
      } else {
        dispatch({ type: 'reset', value: currentMonth });
      }
    }
  }, [currentMonth, prevCurrentMonth, transitionDuration]);

  useEffect(() => {
    const handleResize = () => {
      const containerElement = containerElementRef.current;
      // @ts-ignore
      const containerWidth = containerElement.offsetWidth;
      const cellWidth = containerWidth / 7;
      let newCellHeight = 1;
      let isWide = false;

      if (cellWidth > 60) {
        newCellHeight += Math.round(cellWidth * 0.75);
        isWide = true;
      } else {
        newCellHeight += Math.round(cellWidth);
      }

      dispatch({ type: 'setIsWide', value: isWide });
      dispatch({ type: 'setCellHeight', value: newCellHeight });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    startDate,
    endDate,
    cellHeight,
    containerElementRef,
    offset,
    origin,
    transition,
    isWide,
  };
}
