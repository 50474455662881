import { createContext } from 'react';

import { Coordinates } from '../../constants';

type CoordinateContextValue = [
  Coordinates | null,
  React.Dispatch<React.SetStateAction<Coordinates | null>>,
];

export const CoordinatesContext = createContext<CoordinateContextValue | null>(
  null,
);
