import { useState } from 'react';

/**
 * returns the start date of the month on the calendar
 * @param value
 * @param onChange
 */
function useControllableState<T>(
  value: T,
  onChange: ((value: T) => void) | undefined,
): [T, (value: T) => void] {
  const [state, setState] = useState(value);

  return onChange ? [value, onChange] : [state, setState];
}

export default useControllableState;
