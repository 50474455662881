/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Select, SelectProps } from '../../atoms/Select';
import { FormField } from '../../templates/FormFieldLayout';

export interface SelectFieldProps extends Omit<SelectProps, 'error'> {
  label?: React.ReactNode;
  error?: string;
}

export const SelectField = React.forwardRef<HTMLInputElement, SelectFieldProps>(
  ({ label, error, ...inputProps }, ref) => {
    return (
      <FormField
        label={label}
        error={error}
        render={id => (
          <Select {...inputProps} error={!!error} id={id} ref={ref} />
        )}
      />
    );
  },
);
