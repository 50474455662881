import React, { useCallback } from 'react';
import * as PIXI from 'pixi.js-legacy';
import * as ReactPIXI from '@inlet/react-pixi/legacy';

import { ColumnBounding } from '../types';

const { Graphics } = ReactPIXI;

export interface GraphColumnProps {
  drawData: {
    property: string;
    bounding: ColumnBounding;
    color: string;
  }[];
  isFaded?: boolean;
  selectedSerie?: string | null;
}

const OPAQUE_VALUE = 0.4;

export const GraphColumn: React.FC<GraphColumnProps> = ({
  drawData,
  isFaded,
  selectedSerie,
}) => {
  const drawColumn = useCallback(
    graphics => {
      graphics.clear();

      drawData.forEach((serie, index, data) => {
        const {
          x: rectX,
          y: rectY,
          width: rectWidth,
          height: rectHeight,
        } = serie.bounding;

        let opacity = isFaded ? OPAQUE_VALUE : 1;

        if (selectedSerie) {
          opacity = selectedSerie === serie.property ? 1 : OPAQUE_VALUE;
        }

        graphics.beginFill(PIXI.utils.string2hex(serie.color), opacity);
        graphics.drawRect(rectX, rectY, rectWidth, rectHeight);
        graphics.endFill();
      });
    },
    [drawData, isFaded, selectedSerie],
  );

  return <Graphics draw={drawColumn} />;
};
