import { Styles } from 'react-select';

import { Color } from '../../theme/primitives';

import {
  GroupType,
  OptionType,
  ModifierStylesProps,
} from './MultiSelectTags.types';

const getBorderColor = (
  hasError: boolean,
  isFocused: boolean,
  isHover?: boolean,
) => {
  if (hasError) {
    return Color.red500;
  }

  if (isFocused) {
    return Color.grey600;
  }

  return isHover ? Color.grey600 : Color.grey400;
};

export const getStyles = ({
  hasError,
}: ModifierStylesProps): Partial<Styles<OptionType, true, GroupType>> => ({
  control: (baseStyles, state) => {
    return {
      ...baseStyles,
      minHeight: '40px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '4px',
      borderColor: getBorderColor(hasError, state.isFocused),
      ':hover': {
        borderColor: getBorderColor(hasError, state.isFocused, true),
      },
      boxShadow: 'none',
    };
  },
  placeholder: baseStyles => ({
    ...baseStyles,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: Color.grey400,
  }),
  valueContainer: baseStyles => ({
    ...baseStyles,
    padding: '4px 14px',
  }),
  indicatorsContainer: baseStyles => {
    return {
      ...baseStyles,
      padding: '0px 8px',
    };
  },
  dropdownIndicator: baseStyles => ({
    ...baseStyles,
    color: `${Color.grey400} !important`,
  }),
  multiValue: baseStyles => ({
    ...baseStyles,
    backgroundColor: Color.blue100,
    borderRadius: '4px',
    boxShadow: `0 0 0 1px ${Color.blue400}`,
    color: Color.blue500,
  }),
  multiValueLabel: baseStyles => ({
    ...baseStyles,
    color: Color.blue500,
    fontSize: '12px',
    lineHeight: '14px',
    paddingTop: '5px',
    paddingRight: '0',
    paddingLeft: '8px',
    paddingBottom: '5px',
  }),
  multiValueRemove: baseStyles => ({
    ...baseStyles,
    color: Color.blue500,
    ':hover': {
      color: 'unset',
      cursor: 'pointer',
    },
  }),
  menu: baseStyles => ({
    ...baseStyles,
    borderRadius: '4px',
    boxShadow: `0 0 0 1px inset ${Color.grey400}`,
    overflow: 'hidden',
  }),
  menuList: baseStyles => ({
    ...baseStyles,
    padding: '0 0 8px 0',
  }),
  option: baseStyles => {
    return {
      ...baseStyles,
      backgroundColor: 'unset',
      padding: '4px 12px',
      cursor: 'pointer',
      ':active': {
        backgroundColor: 'unset',
      },
    };
  },
});
