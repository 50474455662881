import { DEFAULT_LABEL } from 'shared/constants';

export const capitalize = (string: string) =>
  !string ? '' : string.charAt(0).toUpperCase() + string.slice(1);

export const getDisplayedValue = (value?: string | number | null) => {
  if (typeof value === 'number') {
    return value;
  }

  return value || DEFAULT_LABEL;
};
