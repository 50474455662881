import ReactSelect, { Props } from 'react-select';
import styled from 'styled-components';

import { color, typography } from '../../theme/themeUtils';

export const Select = styled(ReactSelect).attrs({
  classNamePrefix: 'react-select',
  styles: {
    input: () => ({
      margin: 0,
      padding: 0,
    }),
    container: () => ({
      pointerEvents: 'auto',
      label: 'container',
      position: 'relative',
    }),
  },
})`
  .react-select__control {
    cursor: pointer;
    ${typography('default')};
    ${({ theme }) => color(theme.input.color.default.inactive)};

    border-width: ${({ theme }) => theme.input.shape.default.borderWidth};
    border-style: ${({ theme }) => theme.input.shape.default.borderStyle};
    border-radius: ${({ theme }) => theme.input.shape.default.borderRadius};

    padding: 0;

    min-height: ${({ minHeight }) => minHeight || '38px'}};

    :hover {
      ${({ theme }) => color(theme.input.color.default.hover)};
    }

    &--is-disabled {
      border-color: ${({ theme }) => theme.input.color.default.disabled.border};
      background: ${({ theme }) =>
        theme.input.color.default.disabled.background};
    }
  }

  .react-select__value-container {
    padding: ${({ theme }) => theme.input.shape.default.padding};
  }

  .react-select__single-value {
    &--is-disabled {
      color: ${({ theme }) => theme.input.color.default.disabled.text};
    }
  }
`;

export type SelectProps = Props;
