/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';

import { Label } from '../Label';
import Text from '../Text';
import { Color } from '../../theme/primitives';

import { BaseMultiSelectTagsProps } from './MultiSelectTags.types';
import { BaseMultiSelectTags } from './MultiSelectTags.components';

type MultiSelectTagsProps = {
  label: string;
  name?: string;
  required?: boolean;
  error?: string;
  infoButtonText?: string;
} & BaseMultiSelectTagsProps;

export const getTagOptionsFromRawValues = (rawValues: string[] = []) => {
  return rawValues.map(value => ({ value, label: value }));
};

export function MultiSelectTags({
  name,
  label,
  options,
  onChange,
  value,
  placeholder,
  required = false,
  error,
  infoButtonText,
  menuDescriptionLabel,
}: MultiSelectTagsProps) {
  return (
    <Container>
      {label && (
        <Label
          htmlFor={name}
          title={label}
          isRequired={required}
          isError={!!error}
          infoButtonText={infoButtonText}
        />
      )}
      <BaseMultiSelectTags
        hasError={!!error}
        name={name}
        placeholder={placeholder}
        options={options}
        onChange={onChange}
        value={value}
        menuDescriptionLabel={menuDescriptionLabel}
      />
      {error && <ErrorMessage variant="default">{error}</ErrorMessage>}
    </Container>
  );
}

const ErrorMessage = styled(Text)`
  display: flex;
  margin: 4px 0 0;
  font-size: 10px;
  line-height: 14px;
  color: ${Color.red400};
`;

const Container = styled.div``;
