import * as React from 'react';
import * as ReactDOM from 'react-dom';

const dashboardModalPortalID = document.getElementById(
  'dashboard-modal-portal',
);

export interface TogglePortalContentProps {
  childContent: (showModal: () => void) => React.ReactNode;
  portalContent: (hideModal: () => void) => React.ReactNode;
}

/**
  Launch a modal from anywhere!

  Usage:
  <TogglePortalContent
    childContent={(showModal) => <button onClick=showModal>...</button>}
    portalContent={(hideModal) => <Modal toggle=hideModal>...</Modal>}

  Explanation:
  This component creates a React portal into "dashboard-modal-portal" div in our index.html
  hide & show callbacks are used to control whether the modal should be rendered or not.
 */
export const TogglePortalContent: React.FC<TogglePortalContentProps> = ({
  childContent,
  portalContent,
}) => {
  const [isShown, setIsShown] = React.useState(false);
  const hidePortalContent = () => setIsShown(false);
  const showPortalContent = () => setIsShown(true);

  return (
    <>
      {childContent(showPortalContent)}
      {isShown && dashboardModalPortalID
        ? ReactDOM.createPortal(
            portalContent(hidePortalContent),
            dashboardModalPortalID,
          )
        : null}
    </>
  );
};
