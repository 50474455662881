import React, { useState } from 'react';

import { MouseEventContext } from './ContextMouseEvent';
import { MouseEventContextState } from './contextMouseEventConstants';

export const MouseEventContextProvider: React.FC = ({ children }) => {
  const state = useState<MouseEventContextState | null>(null);

  return (
    <MouseEventContext.Provider value={state}>
      {children}
    </MouseEventContext.Provider>
  );
};
