import { useContext } from 'react';

import { ChartAreaContext } from '../../../charts/atoms/ChartAreaProvider/ChartAreaContext';
import { Color } from '../../../theme/primitives';
import { range } from '../../../utils/mathUtils';
import { AXIS_X_HEIGHT, AXIS_Y_WIDTH } from '../constants';
import { Column } from '../types';

import { useBarGraphContext } from './BarGraphContext';

// eslint-disable-next-line import/prefer-default-export
export const useColumn = () => {
  const {
    data,
    columnPadding,
    width: graphWidth,
    height: graphHeight,
    xProperty,
  } = useBarGraphContext();
  const { x: chartAreaX } = useContext(ChartAreaContext);
  const columnHeight =
    (graphHeight - AXIS_X_HEIGHT) / (data?.length || 1) - (columnPadding || 0);
  const columnYs = data.map((_, index) =>
    index ? index * (columnHeight + (columnPadding || 0)) : 0,
  );

  const columnDrawData = data.map((dataItem: any, index) => {
    const column: Column = {
      bounding: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
      color: dataItem?.positive_impact ? Color.red300 : Color.blue400,
      label: dataItem?.name,
      shap: dataItem?.shap,
      feature_value: dataItem?.feature_value,
    };

    column.bounding.y = columnYs[index];
    column.bounding.width = range(
      (dataItem as any)[xProperty],
      chartAreaX.min,
      chartAreaX.max,
      0,
      graphWidth - AXIS_Y_WIDTH,
    );
    column.bounding.height = columnHeight;

    return column;
  });

  return { columnDrawData };
};
