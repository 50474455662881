import React from 'react';
import styled from 'styled-components';

import { Color, Size } from '../../theme/primitives';

export interface ErrorMessageProps {
  className?: string;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  className,
  children,
}) => <Container className={className}>{children}</Container>;

const Container = styled.div`
  color: ${Color.red400};
  margin-top: ${Size.space100};
`;
