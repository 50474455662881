import styled from 'styled-components';
import React from 'react';

export interface IconProps {
  iconSize?: IconSize;
  fill?: string;
  stroke?: string;
}

export type IconSize = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl' | string;

const SIZE: Record<IconSize, number> = {
  xxs: 8,
  xs: 12,
  s: 16,
  m: 24,
  l: 32,
  xl: 48,
  xxl: 64,
  xxxl: 72,
};

const setSize = (iconSize: IconSize = 'm') =>
  SIZE[iconSize]
    ? `height: ${SIZE[iconSize]}px; width: ${SIZE[iconSize]}px;`
    : `height: ${iconSize}px; width: ${iconSize}px;`;

const setFillColor = (color?: string) => color && `path { fill: ${color}; }`;
const setStrokeColor = (color?: string) =>
  color && `path { stroke: ${color}; }`;

// todo: fix types
export const createIconFromSvg = (icon: React.ComponentType | any) =>
  styled(icon).withConfig({
    shouldForwardProp: (prop, defaultValidator) => defaultValidator(prop),
  })(
    ({ iconSize, fill, stroke }: IconProps) => `
    display: block;
    ${setSize(iconSize)};
    ${setFillColor(fill)};
    ${setStrokeColor(stroke)};
    `,
  );
