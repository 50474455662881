import React, { useMemo } from 'react';
import styled from 'styled-components';

import List from '../../../atoms/List';
import BulletListItem from '../../../atoms/BulletListItem';
import { Color, Size } from '../../../theme/primitives';
import { useBarGraphContext } from '../hooks/BarGraphContext';
import { useBarGraphInteraction } from '../hooks/useBarGraphInteraction';
import { HoveredColumnData } from '../types';
import { SortDirection } from '../../../constants';

interface BarGraphInfoWidgetProps {
  formatXValue?: Function;
  sortDirection?: SortDirection;
}

const BarGraphInfoWidget: React.FC<BarGraphInfoWidgetProps> = ({
  formatXValue,
  sortDirection,
}) => {
  const barGraphInteraction = useBarGraphInteraction();
  const {
    columnSeriesSettings,
    xProperty,
    yProperty,
    yLabel,
  } = useBarGraphContext();

  const sortedSeries = useMemo(() => {
    if (!sortDirection) {
      return barGraphInteraction?.seriesData || [];
    }

    if (!barGraphInteraction?.seriesData) {
      return [];
    }

    return barGraphInteraction?.seriesData.sort(
      (prevSerie: HoveredColumnData, nextSerie: HoveredColumnData) => {
        const prevValue =
          sortDirection === SortDirection.ASC
            ? prevSerie.value
            : nextSerie.value;
        const nextValue =
          sortDirection === SortDirection.ASC
            ? nextSerie.value
            : prevSerie.value;

        if (typeof prevValue === 'number' && typeof nextValue === 'number') {
          return prevValue - nextValue;
        }

        if (prevValue === nextValue || !prevValue || !nextValue) {
          return 0;
        }

        return prevValue > nextValue ? 1 : -1;
      },
    );
  }, [barGraphInteraction?.seriesData, sortDirection]);

  if (
    !barGraphInteraction ||
    !barGraphInteraction.seriesData ||
    !barGraphInteraction.isActive
  ) {
    return null;
  }

  const xFeatureColumnValue = barGraphInteraction[xProperty];
  const yFeatureColumnValue = barGraphInteraction[yProperty];

  const seriesToDisplay =
    sortedSeries?.filter(({ value }: HoveredColumnData) => value > 0) || [];

  return (
    <GraphInfoWidgetContainer>
      <Header withSeparator={seriesToDisplay.length > 0}>
        <HeaderText>{yLabel || yProperty}&nbsp;</HeaderText>
        <HeaderValue>{yFeatureColumnValue?.toLocaleString()}</HeaderValue>
      </Header>
      <List>
        {seriesToDisplay.map(
          ({ value, label: serieLabel }: HoveredColumnData) => {
            const { label, color } =
              columnSeriesSettings.find(
                // @ts-ignore todo: fix types
                settings => settings.name === serieLabel,
              ) || {};
            return color ? (
              <BulletListItem key={label} color={color}>
                <ListItemContent>
                  <ListItemColumn>{label}</ListItemColumn>
                  <ListItemColumn>|</ListItemColumn>
                  <ListItemColumn>{value?.toLocaleString()}</ListItemColumn>
                </ListItemContent>
              </BulletListItem>
            ) : null;
          },
        )}
      </List>
      {formatXValue && (
        <TimestampContainer>
          <Timestamp>{formatXValue(xFeatureColumnValue)}</Timestamp>
        </TimestampContainer>
      )}
    </GraphInfoWidgetContainer>
  );
};

export default BarGraphInfoWidget;

const GraphInfoWidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Size.space200};
  box-sizing: border-box;
  width: auto;

  border: 1px solid ${Color.grey500};
  border-radius: ${Size.radius200};

  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.1);
  background-color: ${Color.white};
`;

const Header = styled.div<{ withSeparator: boolean }>`
  display: flex;
  border-bottom: ${({ withSeparator }) =>
    withSeparator ? `1px solid ${Color.grey400}` : 'none'};
  margin-bottom: ${Size.space100};
  height: 25px;
`;

const HeaderText = styled.div`
  font-size: 12px;
  font-weight: 400;
  position: relative;
  display: inline-block;
  height: 15px;
  line-height: 15px;
  white-space: nowrap;
`;

const HeaderValue = styled.div`
  font-size: 12px;
  font-weight: 600;
  position: relative;
  display: inline-block;
  height: 15px;
  line-height: 15px;
`;

const ListItemContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: nowrap;
  align-content: center;
  white-space: nowrap;
  width: auto;
  font-size: 12px;
`;

const ListItemColumn = styled.div`
  display: inline-block;
  margin-left: ${Size.space100};
  position: relative;
  width: auto;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TimestampContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: ${Size.space200};
  font-size: 12px;
  height: 15px;
  line-height: 15px;
`;

const Timestamp = styled.span`
  background-color: ${Color.grey100};
  padding: 2.5px 5px 1px 5px;
  height: 100%;
  font-weight: 600;
  border-radius: 12px;
`;
