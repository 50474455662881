import React, { useState } from 'react';
import styled from 'styled-components';

import { CircleData } from '../../organisms/PieChart';
import formatPieChartData, {
  FormattedCircleData,
} from '../../organisms/PieChart/helpers/formatPieChartData';
import Text from '../Text';
import BulletListItem from '../BulletListItem';
import List from '../List';
import { round } from '../../utils/mathUtils';

interface PieChartInfoProps {
  data: CircleData[];
  renderHoveredRow?: (data: FormattedCircleData) => React.ReactNode | null;
}

const Percentage = styled(Text)`
  font-weight: 700;
`;

const PieChartInfo: React.FC<PieChartInfoProps> = ({
  data,
  renderHoveredRow,
}) => {
  const formattedData = formatPieChartData(data);
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };
  const handleMouseEnter = (index: number) => () => {
    setHoveredRowIndex(index);
  };

  return (
    <List>
      {formattedData
        .filter(({ label }) => Boolean(label))
        .map((item, index) => {
          const { id, color, normalizedValue, label } = item;
          const isHovered = hoveredRowIndex === index;
          return (
            <RowWrapper
              onMouseEnter={handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              {isHovered && renderHoveredRow ? (
                renderHoveredRow(item)
              ) : (
                <BulletListItem color={color} key={id}>
                  <Percentage variant="small">
                    {round(normalizedValue * 100, 1)}%
                  </Percentage>
                  &nbsp;
                  <Text variant="small">{label}</Text>
                </BulletListItem>
              )}
            </RowWrapper>
          );
        })}
    </List>
  );
};

const RowWrapper = styled.li``;

export default PieChartInfo;
