import React from 'react';
import styled from 'styled-components';

import { Color, Size } from '../../../theme/primitives';
import { Icon } from '../../Icon';
import Text from '../../Text';

export interface OptionProps {
  optionText: string;
  isChecked: boolean;
  onClick: () => void;
}

export const Option: React.FC<OptionProps> = ({
  optionText,
  isChecked,
  onClick,
}) => (
  <Container onClick={onClick}>
    <Checkmark icon="checkMark" isChecked={isChecked} />
    <OptionText isChecked={isChecked}>{optionText}</OptionText>
  </Container>
);

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  margin-bottom: ${Size.space100};
  padding: ${Size.space100} ${Size.space300};

  &:last-child {
    margin-bottom: ${Size.space200};
  }

  &:hover {
    background: ${Color.grey100};
  }
`;

const Checkmark = styled(Icon)<{ isChecked: boolean }>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid
    ${({ isChecked }) => (isChecked ? Color.blue400 : Color.grey500)};
  background-color: ${({ isChecked }) =>
    isChecked ? Color.blue400 : Color.white};
`;

const OptionText = styled(Text)<{ isChecked: boolean }>`
  margin-left: ${Size.space200};
  font-weight: ${({ isChecked }) => (isChecked ? 600 : 400)};
`;
