import React from 'react';
import { eachDayOfInterval, endOfWeek, startOfWeek, format } from 'date-fns';
import styled from 'styled-components';
import { enGB as locale } from 'date-fns/locale';

const DatesWeekHeader = styled.div`
  box-shadow: 0 1px 0 rgba(#000, 0.06);
  display: flex;
  padding: 8px 0;
`;

const DatesWeekHeaderDay = styled.span`
  color: #5c6f8a;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  text-transform: capitalize;
  width: calc(100% / 7);
`;

interface CalendarWeekHeaderType {
  locale: typeof locale;
  weekdayFormat: string;
}

const CalendarWeekHeader: React.FC<CalendarWeekHeaderType> = ({
  locale,
  weekdayFormat,
}) => {
  const today = new Date();
  const weekDays = eachDayOfInterval({
    start: startOfWeek(today, { locale }),
    end: endOfWeek(today, { locale }),
  }).map(date => format(date, weekdayFormat, { locale }));

  return (
    <DatesWeekHeader>
      {weekDays.map(day => (
        <DatesWeekHeaderDay key={day}>{day}</DatesWeekHeaderDay>
      ))}
    </DatesWeekHeader>
  );
};

export default CalendarWeekHeader;
