import React from 'react';
import styled from 'styled-components';

import { Color } from '../../../../../theme/primitives';
import { DEFAULT_MARKER_WIDTH } from '../../zoomConstants';
import { WidgetEdge } from '../constantsCrop';

export interface ValueIndicatorProps {
  value: number;
  edge: WidgetEdge;
  width?: number;
  formatValueIndicator?: (displayValue: number) => string;
}

export const ValueIndicator: React.FC<ValueIndicatorProps> = ({
  value,
  edge,
  width,
  formatValueIndicator,
}) => (
  <Display edge={edge} width={width || DEFAULT_MARKER_WIDTH}>
    {formatValueIndicator?.(value)}
  </Display>
);

const Display = styled.div<{
  edge: WidgetEdge;
  width: number;
}>`
  width: ${({ width }) => width}px;
  height: 20px;

  position: absolute;
  bottom: -24px;
  left: ${({ edge, width }) =>
    edge === WidgetEdge.LEFT ? `${-((width + 20) / 2)}px` : 'unset'};
  right: ${({ edge, width }) =>
    edge === WidgetEdge.RIGHT ? `${-((width + 20) / 2)}px` : 'unset'};

  background-color: ${Color.grey900};
  color: ${Color.white};

  padding: 2px 10px;
  border-radius: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
`;
