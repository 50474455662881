import { Size } from '../../theme/primitives';

const theme = {
  form: {
    layout: {
      gap: Size.space300,
    },
  },
};

export default theme;
