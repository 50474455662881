import React, { useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { NAV_OFFSET } from './constants';

interface DrawerPortalProps {
  children: React.ReactNode;
  position?: Position;
  setRef: (node: HTMLDivElement | null) => void;
}

export type Position = 'right' | 'left';

export const DrawerPortal: React.FC<DrawerPortalProps> = ({
  children,
  position = 'right',
  setRef,
}) => {
  const [wrapperElement, setWrapperElement] = useState<HTMLDivElement | null>(
    null,
  );

  useLayoutEffect(() => {
    const createAndAppendWrapper = () => {
      const elementToAdd = document.createElement('div');
      elementToAdd.style.position = 'fixed';
      elementToAdd.style.top = `${NAV_OFFSET}px`;
      elementToAdd.style.zIndex = '99';
      elementToAdd.style[position] = '0';
      document.body.appendChild(elementToAdd);

      return elementToAdd;
    };
    const element = createAndAppendWrapper();
    setRef(element);

    setWrapperElement(element);

    return () => {
      document.body.removeChild(element);
    };
  }, [position, setRef]);

  if (wrapperElement === null) {
    return null;
  }

  return createPortal(children, wrapperElement);
};
