export const isRedirectUrlAllowed = (url: string) => {
  try {
    const { hostname, protocol } = new URL(url);

    if (process.env.APP_ENV === 'development') {
      return true;
    }

    const isHTTPS = protocol === 'https:';
    const matchesDomain = [
      process.env.COMPANY_DOCS,
      process.env.APP_DOMAIN,
    ].some(envUrl => envUrl?.includes(hostname));

    return isHTTPS && matchesDomain;
  } catch (e) {
    return false;
  }
};

export const constructSafeUrl = (url: string, hash?: string) => {
  try {
    const { origin, pathname } = new URL(url);

    return `${origin}${pathname}${hash ?? ''}`;
  } catch (e) {
    return '';
  }
};

export const getUrlWithBackurlRedirect = (url: string) => {
  const backurl = new URLSearchParams(window.location.search).get('backurl');

  if (backurl && isRedirectUrlAllowed(backurl)) {
    const urlInstance = new URL(url);
    const urlSearchParams = new URLSearchParams(urlInstance.search);

    urlSearchParams.append('next', constructSafeUrl(backurl));
    urlInstance.search = urlSearchParams.toString();

    return urlInstance.toString();
  }

  return url;
};
