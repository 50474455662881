import React from 'react';
import styled from 'styled-components';

import ChartAreaProvider from '../../charts/atoms/ChartAreaProvider';
import AxisY from '../../charts/atoms/GraphAxis/AxisY';
import GraphInteractionLayer from '../../atoms/GraphInteractionLayer';
import { BarGraphLegend } from '../../atoms/BarGraphLegend';
import { SelectInteractionLayer } from '../../charts/atoms/select-interaction-layer';

import { AXIS_X_HEIGHT, AXIS_Y_WIDTH, LEGEND_HEIGHT } from './constants';
import { BarGraphProvider } from './hooks/BarGraphContext';
import { BarGraphCanvas } from './components/BarGraphCanvas';
import AxisX from './components/AxisX';
import { BarGraphAxisConfigX, BarGraphAxisConfigY } from './types';

export interface BarGraphProps {
  data: object[];
  width: number;
  height: number;
  xAxis: BarGraphAxisConfigX;
  yAxis: BarGraphAxisConfigY;
  showLegend?: boolean;
  showBackgroundLines?: boolean;
  tooltip?: React.ReactNode;
  tooltipRenderer?: (value: number | null) => JSX.Element;
  showMarker?: boolean;
  markerWidth?: number;
  markerValueDisplay?: React.ReactNode;
  className?: string;
  onSelectDataPoint?: (data: any) => void;
  selectedDataPointIndex?: number | null;
  selectedMarkerDisplay?: React.ReactNode;
  selectedSerie?: string | null;
}

export const BarGraph: React.FC<BarGraphProps> = ({
  data,
  width,
  height,
  xAxis,
  yAxis,
  showLegend,
  tooltip,
  tooltipRenderer,
  showBackgroundLines,
  showMarker,
  markerWidth,
  markerValueDisplay,
  className,
  onSelectDataPoint,
  selectedDataPointIndex,
  selectedMarkerDisplay,
  selectedSerie,
}) => {
  return (
    <BarGraphContainer
      width={width}
      height={showLegend ? height + LEGEND_HEIGHT : height}
    >
      {showLegend && (
        <BarGraphLegend
          columnSeries={yAxis.source.series}
          height={LEGEND_HEIGHT}
        />
      )}
      <BarGraphCanvasWrapper
        data={data}
        width={width}
        height={height}
        xAxis={xAxis}
        yAxis={yAxis}
        tooltip={tooltip}
        tooltipRenderer={tooltipRenderer}
        showBackgroundLines={showBackgroundLines}
        showMarker={showMarker}
        markerWidth={markerWidth}
        markerValueDisplay={markerValueDisplay}
        className={className}
        onSelectDataPoint={onSelectDataPoint}
        selectedDataPointIndex={selectedDataPointIndex}
        selectedMarkerDisplay={selectedMarkerDisplay}
        selectedSerie={selectedSerie}
      />
    </BarGraphContainer>
  );
};

const BarGraphCanvasWrapper: React.FC<{
  data: object[];
  columnStep?: number;
  columnPadding?: number;
  width: number;
  height: number;
  xAxis: BarGraphAxisConfigX;
  yAxis: BarGraphAxisConfigY;
  tooltip?: React.ReactNode;
  tooltipRenderer?: (value: number | null) => JSX.Element;
  showBackgroundLines?: boolean;
  showMarker?: boolean;
  markerWidth?: number;
  markerValueDisplay?: React.ReactNode;
  className?: string;
  onSelectDataPoint?: (data: any) => void;
  selectedDataPointIndex: any;
  selectedMarkerDisplay: React.ReactNode;
  selectedSerie?: string | null;
}> = ({
  data,
  width,
  height,
  xAxis,
  yAxis,
  tooltip,
  tooltipRenderer,
  showBackgroundLines,
  showMarker,
  markerWidth,
  markerValueDisplay,
  className,
  onSelectDataPoint,
  selectedDataPointIndex,
  selectedMarkerDisplay,
  selectedSerie,
}) => {
  return (
    <CanvasWrapper width={width} height={height}>
      <ChartAreaProvider x={xAxis.scale} y={yAxis.scale}>
        <BarGraphProvider
          data={data}
          width={width}
          height={height}
          xProperty={xAxis.source.property}
          yProperty={yAxis.source.property}
          yLabel={yAxis.source.label}
          columnStep={xAxis?.source.step || xAxis.scale.step}
          columnPadding={xAxis?.source.padding || 0}
          columnSeriesSettings={yAxis.source.series}
        >
          <BarGraphCanvas
            width={width - AXIS_Y_WIDTH}
            height={height - AXIS_X_HEIGHT}
            showBackgroundLines={showBackgroundLines}
            className={className}
            onSelectDataPoint={onSelectDataPoint}
            selectedDataPointIndex={selectedDataPointIndex}
            selectedSerie={selectedSerie}
            sortDirection={yAxis.source.sortDirection}
          />
          <BarGraphAxisX
            width={width - AXIS_Y_WIDTH}
            height={AXIS_X_HEIGHT}
            marks={xAxis.source?.marks || []}
            padding={xAxis.source?.padding || 0}
          />
          <BarGraphAxisY height={height - AXIS_X_HEIGHT} />
          <BarGraphSelectInteractionLayer
            width={width - AXIS_Y_WIDTH}
            height={height - AXIS_X_HEIGHT}
            selectedMarkerDisplay={selectedMarkerDisplay}
            markerWidth={markerWidth}
          />
          <BarGraphInteractionLayer
            width={width - AXIS_Y_WIDTH}
            height={height - AXIS_X_HEIGHT}
            tooltip={tooltip}
            tooltipRenderer={tooltipRenderer}
            showMarker={showMarker}
            markerWidth={markerWidth}
            markerValueDisplay={markerValueDisplay}
          />
        </BarGraphProvider>
      </ChartAreaProvider>
    </CanvasWrapper>
  );
};

const BarGraphContainer = styled.div<{
  width: number;
  height: number;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const CanvasWrapper = styled.div<{
  width: number;
  height: number;
}>`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const BarGraphAxisX = styled(AxisX)<{ width: number }>`
  width: ${props => props.width}px;
  height: ${AXIS_X_HEIGHT}px;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const BarGraphAxisY = styled(AxisY)<{ height: number }>`
  height: ${props => props.height}px;
  width: ${AXIS_Y_WIDTH}px;
  position: absolute;
  top: 0;
  left: 0;
`;

const BarGraphInteractionLayer = styled(GraphInteractionLayer)`
  position: absolute;
  top: 0;
  right: 0;
`;

const BarGraphSelectInteractionLayer = styled(SelectInteractionLayer)`
  position: absolute;
  top: 0;
  right: 0;
`;
