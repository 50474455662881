import { useContext } from 'react';

import { useInteraction } from '../../../atoms/GraphInteractionLayer/GraphInteractionContext';
import { ChartAreaContext } from '../../../charts/atoms/ChartAreaProvider/ChartAreaContext';
import { floorWithStep } from '../../../utils/mathUtils';
import { CellsTable } from '../types';
import HeatMapDataContext from '../HeatMapStateProvider/HeatMapDataContext';
import { getColor } from '../HeatMapStateProvider/utils';

interface Cell {
  x: number;
  y: number;
  z: number;
}

export interface HeatMapDataPoint {
  id: string;
  cell: Cell;
  color: string;
}

export const useHeatMapInteraction = (): {
  nearestDataColumn: HeatMapDataPoint[];
  nearestDataPoint: HeatMapDataPoint | null;
  isActive: boolean;
} | null => {
  const area = useContext(ChartAreaContext);
  const [interactionState] = useInteraction();

  const { cells, legend, graphId } = useContext(HeatMapDataContext);

  if (
    interactionState === null ||
    interactionState.x < area.x.min ||
    interactionState.x >= area.x.max
  ) {
    return null;
  }

  const x = floorWithStep(
    interactionState.x,
    area.x.step!,
    getXOffset(cells, area.x.min),
  );
  const y = floorWithStep(interactionState.y, area.y.step!, area.y.min);

  const nearestDataColumn = cells.has(x)
    ? Array.from(cells.get(x)!.entries()).map(([y, z]) => {
        return {
          id: `${y}`,
          color: getColor(z, legend),
          cell: {
            x,
            y,
            z,
          },
        };
      })
    : [];

  const nearestDataPoint =
    nearestDataColumn.find(
      ({ cell }) => floorWithStep(cell.y, area.y.step!, area.y.min) === y,
    ) || null;

  return {
    isActive: graphId === interactionState.graphId,
    nearestDataColumn,
    nearestDataPoint,
  };
};

function getXOffset(cells: CellsTable, defaultOffset: number): number {
  {
    const anyXValueResult = cells.keys().next();
    if (!anyXValueResult.done) {
      return anyXValueResult.value;
    }
  }

  return defaultOffset;
}
