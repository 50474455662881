import React, { memo } from 'react';
import styled from 'styled-components';
import { Size } from '@packages/ui';
import { useTranslation } from 'react-i18next';
import { Metric, MetricProp, useAnalytics } from '@packages/analytics';

import {
  LOGO,
  TEXT_LOGO,
  ENTERPRISE_LOGO_TEXT,
  PRO_LOGO_TEXT,
} from './logo-helpers';

import { IS_FOUNDATION, TEXT_BRANDING } from 'shared/config/Config';
import { useRootStore } from 'shared/stores';
import History from 'shared/models/History';

// Styled HTML Elements
const LogoIcon = styled.div<{ theme?: string }>`
  width: ${props => (props.width !== undefined ? `${props.width}px` : '100%')};
  height: ${props =>
    props.height !== undefined ? `${props.height}px` : '100%'};
  margin: 0 auto;

  display: ${props => (props.stack ? 'block' : 'inline-block')};
  filter: ${props =>
    props.theme === 'light' && IS_FOUNDATION
      ? 'brightness(0) invert(1);'
      : 'none'};

  background: url(${LOGO});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  @media (max-width: ${Size.mobile}) {
    margin: 0;
  }
`;
const LogoText = styled.div<{ theme?: string }>`
  width: ${props => (props.width !== undefined ? `${props.width}px` : '100%')};
  height: ${props =>
    props.height !== undefined ? `${props.height}px` : '100%'};

  margin: ${props => (props.stack ? 'auto' : '3px auto auto 10px')};

  display: ${props =>
    props.visible ? (props.stack ? 'block' : 'inline-block') : 'none'};
  filter: ${props => (props.theme === 'light' ? 'brightness(100)' : 'none')};

  background: url(${props => props.logoTextUrl});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
`;
const LogoDiv = styled.div`
  width: ${props => (props.width !== undefined ? `${props.width}px` : '100%')};
  height: ${props =>
    props.height !== undefined ? `${props.height}px` : '100%'};

  display: flex;
  flex-direction: ${props => (props.stack ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
`;

const getLogoTextByRole = (role: string) => {
  switch (role) {
    case 'pro':
      return PRO_LOGO_TEXT;

    case 'enterprise':
      return ENTERPRISE_LOGO_TEXT;

    default:
      return TEXT_LOGO;
  }
};

// Type Checking for Logo Componenet
type Props = {
  width: number;
  height: number;
  padding?: number;
  compact?: boolean;
  stack?: boolean;
  theme?: string;
  title?: string;
  className?: any;
  onClick?: () => void;
};

// Logo Component
function Logo({
  width,
  height,
  compact = false,
  stack = false,
  theme = 'dark',
  padding = 10,
  title,
  className,
  onClick,
}: Props) {
  const { t } = useTranslation('generic');
  const { trackMetric } = useAnalytics();

  let iconWidth = height;
  let iconHeight = height;
  const textAspect = 86 / 19;
  let textHeight = height;
  let textWidth = width - iconWidth - padding;

  if (stack) {
    textWidth = width;
    textHeight = textWidth / textAspect;
    iconWidth = 64;
    iconHeight = iconWidth;
  }

  const handleClick = event => {
    trackMetric(Metric.LOGO_CLICK, {
      action: event.detail ? MetricProp.ACTION_CLICK : MetricProp.ACTION_ENTER,
    });

    if (onClick) {
      onClick();

      return;
    }

    History.push('/');
  };

  const user = useRootStore().userStore;
  return (
    <LogoDiv
      aria-label={t('{{brand}} logo', {
        brand: TEXT_BRANDING,
      })}
      role="img"
      tabIndex={0}
      width={width}
      height={height}
      stack={stack}
      className={className}
      onClick={handleClick}
      onKeyPress={handleClick}
      title={title}
    >
      <LogoIcon
        width={iconWidth}
        height={iconHeight}
        stack={stack}
        // @ts-ignore
        theme={theme}
        className={IS_FOUNDATION ? 'foundationIcon' : ''}
      />
      {!IS_FOUNDATION && (
        <LogoText
          width={textWidth}
          height={textHeight}
          visible={!compact}
          stack={stack}
          // @ts-ignore
          theme={theme}
          logoTextUrl={getLogoTextByRole(user.role)}
        />
      )}
    </LogoDiv>
  );
}

Logo.defaultProps = {
  padding: 10,
  compact: false,
  stack: false,
  theme: 'dark',
  className: null,
};
export default memo(Logo);
