/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled, { DefaultTheme } from 'styled-components';

import { colorStates, shape, typography } from '../../theme/themeUtils';

const KEY_CODES = {
  ENTER: 'Enter',
};

export interface TextareaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  error?: boolean;
  isEnterDisabled?: boolean;
  variant?: keyof DefaultTheme['textarea']['color'];
  onKeyPress?: any;
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ error, isEnterDisabled, onKeyPress, ...props }, ref) => {
    const handleKeyPress = (
      event: React.KeyboardEvent<HTMLTextAreaElement>,
    ) => {
      const { key } = event;

      if (isEnterDisabled && key === KEY_CODES.ENTER) {
        event.preventDefault();
      }

      onKeyPress?.(event);
    };

    return (
      <StyledTextarea
        {...props}
        error={error}
        ref={ref}
        onKeyPress={handleKeyPress}
      />
    );
  },
);

const StyledTextarea = styled.textarea<{
  variant?: keyof DefaultTheme['textarea']['color'];
  error?: boolean;
}>`
  ${typography('default')};
  ${({ theme }) => shape(theme.textarea.shape.default)};
  ${({ theme, variant }) =>
    colorStates(theme.textarea.color[variant || 'default'])};
  ${({ theme, error }) => error && colorStates(theme.textarea.color.invalid)};

  display: block;
`;

export default Textarea;
