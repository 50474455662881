import React from 'react';
import styled from 'styled-components';

import BulletListItem from '../BulletListItem';

interface BarGraphLegendProps {
  columnSeries: { name: string; label?: string; color: string }[];
  height: number;
  className?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const BarGraphLegend: React.FC<BarGraphLegendProps> = ({
  columnSeries,
  height,
  className,
}) => {
  return (
    <Container height={height} className={className}>
      {columnSeries.map(serie => (
        <LegendItem key={serie.color} color={serie.color}>
          <LegendText>{serie.label}</LegendText>
        </LegendItem>
      ))}
    </Container>
  );
};

const Container = styled.ul<{
  height: number;
}>`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: ${({ height }) => height}px;
`;

const LegendItem = styled(BulletListItem)`
  &:first-child {
    margin-left: 0;
  }
  margin-left: 10px;
`;

const LegendText = styled.span`
  margin-left: 2px;
`;
