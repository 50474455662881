import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import {
  FormatOptionLabelMeta,
  components as ReactSelectComponents,
} from 'react-select';

import { Color } from '../../theme/primitives';
import { Icon } from '../../atoms/Icon';
import { typography, color, shape } from '../../theme/themeUtils';
import { SelectField } from '../SelectField';

const DropdownIndicator = (props: any) => {
  return (
    /* eslint-disable-next-line */
    <ReactSelectComponents.DropdownIndicator {...props}>
      <RotatedArrow icon="chevronRight" iconSize="24" />
    </ReactSelectComponents.DropdownIndicator>
  );
};

type Variant = keyof DefaultTheme['cardsDropdown']['color'] &
  keyof DefaultTheme['cardsDropdown']['shape'];

export type CardOption = {
  label: string;
  value: string;
  description?: string;
  isDisabled?: boolean;
};

export interface CardsDropdownProps {
  variant?: Variant;
  options: CardOption[];
  value?: string | CardOption;
  onChange: (option: CardOption) => void;
  label?: React.ReactNode;
  placeholder?: string;
  isDisabled?: boolean;
  menuPlacement?: 'bottom' | 'top' | 'auto';
  isError?: boolean;
}

export const CardsDropdown: React.FC<CardsDropdownProps> = ({
  variant,
  options,
  onChange,
  value,
  placeholder,
  label,
  isDisabled,
  menuPlacement = 'auto',
  isError,
}) => {
  const cardOptionValue =
    typeof value === 'string'
      ? options.find(option => option.value === value)
      : value;

  return (
    <StyledSelectField
      isError={isError}
      menuPlacement={menuPlacement}
      isDisabled={isDisabled}
      variant={variant}
      components={{
        DropdownIndicator,
        IndicatorSeparator: () => null,
      }}
      options={options}
      onChange={onChange}
      value={cardOptionValue}
      placeholder={placeholder}
      getOptionLabel={(data: CardOption) => data.label}
      getOptionValue={(data: CardOption) => data.value}
      label={label}
      isSearchable={false}
      formatOptionLabel={(
        data: CardOption,
        meta: FormatOptionLabelMeta<CardOption, false>,
      ) =>
        meta.context === 'value' ? (
          data.label
        ) : (
          <OptionWrapper>
            <OptionInfoWrapper>
              <OptionLabel>{data.label}</OptionLabel>
              {data.description && (
                <OptionDescription>{data.description}</OptionDescription>
              )}
            </OptionInfoWrapper>
          </OptionWrapper>
        )
      }
    />
  );
};

const RotatedArrow = styled(Icon)`
  transform: rotate(90deg);
  color: ${Color.grey400};
`;

const OptionWrapper = styled.div`
  cursor: pointer;
  background-color: ${Color.white};
  border-radius: 2px;
  margin: 4px;
`;

const OptionInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  padding: 8px 16px;
`;

const OptionLabel = styled.span`
  ${typography('default')};
  font-weight: 700;
  color: ${Color.grey700};
`;

const OptionDescription = styled.span`
  ${typography('small')};
  font-weight: 500;
  color: ${Color.grey500};
`;

const StyledSelectField = styled(SelectField)<{
  isError?: boolean;
  variant?: Variant;
}>`
  .react-select__control {
    box-sizing: border-box;
    height: 40px;

    ${({ theme, variant }) =>
      shape(theme.cardsDropdown.shape[variant || 'default'])};

    ${({ theme, variant }) =>
      color(theme.cardsDropdown.color[variant || 'default'].inactive)};

    ${({ isError, theme, variant }) =>
      isError && color(theme.cardsDropdown.color[variant || 'default'].error)};

    padding: 0;

    :hover,
    &--is-focused {
      outline: none;
      box-shadow: none;
    }

    &--is-disabled {
      cursor: not-allowed;
    }

    :hover {
      ${({ theme, variant }) =>
        color(theme.cardsDropdown.color[variant || 'default'].hover)};
    }

    &--is-focused {
      ${({ theme, variant }) =>
        color(theme.cardsDropdown.color[variant || 'default'].focus)};
    }

    &--is-disabled:hover {
      ${({ theme, variant }) =>
        color(theme.cardsDropdown.color[variant || 'default'].inactive)};
    }
  }

  .react-select__placeholder {
    color: ${({ theme, variant }) =>
      theme.cardsDropdown.color[variant || 'default'].inactive.placeholderText};
  }

  .react-select__value-container {
    padding: 8px 16px;
    color: ${({ theme, variant }) =>
      theme.cardsDropdown.color[variant || 'default'].inactive.text};

    .react-select__single-value {
      ${typography('default')};

      padding: 0;
      font-weight: 400;
      color: ${({ theme, variant }) =>
        theme.cardsDropdown.color[variant || 'default'].inactive.text};
    }
  }

  .react-select__menu {
    z-index: 2;
  }

  .react-select__menu-list {
    padding: 0;
  }

  .react-select__option {
    border-radius: 2px;
    padding: 0;
    background-color: ${Color.white};

    &--is-focused {
      background-color: ${Color.white};

      ${OptionWrapper} {
        background-color: ${Color.blue100};
      }

      ${OptionLabel} {
        color: ${Color.blue900};
      }

      ${OptionDescription} {
        color: ${Color.blue600};
      }
    }

    &:active {
      background-color: ${Color.white};
    }

    &--is-selected {
      background-color: ${Color.white};

      ${OptionWrapper} {
        background-color: ${Color.blue400};
      }

      ${OptionLabel} {
        color: ${Color.white};
      }

      ${OptionDescription} {
        color: ${Color.white};
      }
    }

    &--is-disabled {
      ${OptionWrapper} {
        cursor: not-allowed;
      }

      ${OptionLabel},
      ${OptionDescription} {
        color: ${Color.grey400};
      }
    }
  }

  .react-select__single-value {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
  }

  .react-select__control--menu-is-open {
    ${RotatedArrow} {
      transform: rotate(-90deg);
      color: ${Color.grey600};
    }
  }
`;
