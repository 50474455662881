import React from 'react';
import styled from 'styled-components';

import Text from '../../../atoms/Text';
import { Icon } from '../../../atoms/Icon';
import { TileSelectorOption as Option } from '../../../atoms/TileSelectorOption';

type PrimitieValue = number | string | boolean;

export interface OptionInterface {
  value: PrimitieValue;
  label: string;
  labelTerm?: string;
  description?: string;
  tooltipDescription?: string;
  isDisabled?: boolean;
  icon?: string;
  tooltipButtonTitle?: string;
  warning?: string;
  content?: React.ReactNode;
  suboptions?: {
    title: string;
    description?: string;
    list: OptionInterface[] | [];
  };
}

export interface OptionsListProps {
  isReadOnly?: boolean;
  width?: string;
  direction?: string;
  optionWidth?: string;
  options: OptionInterface[] | [];
  onChange: (value: PrimitieValue) => void;
  className?: string;
  selectedValue?: PrimitieValue;
  onTooltipButtonClick?: () => void;
  isActive?: boolean;
}

const OptionsList: React.FC<OptionsListProps> = ({
  className = '',
  isReadOnly,
  selectedValue,
  options = [],
  optionWidth = '',
  width = '',
  direction = '',
  onChange,
  onTooltipButtonClick,
  isActive = true,
}) => {
  const selectedOption = options.find(option => option.value === selectedValue);

  const handleChange = (value: PrimitieValue) => {
    if (value === selectedValue) {
      return;
    }

    onChange?.(value);
  };

  const handleTooltipButtonClick = () => {
    onTooltipButtonClick?.();
  };

  return (
    <Container className={className}>
      <OptionsContainer
        width={width}
        direction={direction}
        isReadOnly={isReadOnly}
      >
        {options?.map(option => (
          <Option
            key={`${option.value}${option.label}`}
            width={optionWidth}
            direction={direction}
            isReadOnly={isReadOnly}
            isSelected={option.value === selectedValue}
            isActive={isActive}
            data={option}
            onChange={handleChange}
            onTooltipButtonClick={handleTooltipButtonClick}
          />
        ))}
      </OptionsContainer>

      {Boolean(selectedOption?.warning) && (
        <Warning>
          <WarningIcon icon="warning" iconSize="s" />
          <Text variant="default">{selectedOption?.warning}</Text>
        </Warning>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionsContainer = styled.div<{
  width?: string;
  direction?: string;
  isReadOnly?: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ direction }) => direction || 'row'};

  margin: ${({ direction }) =>
    direction === 'column' ? '0' : '0 -12px -24px -12px'};

  max-width: ${({ width, direction }) =>
    width ||
    (direction === 'column' ? 'calc(100% + 12px)' : 'calc(100% + 24px)')};

  & > * {
    flex-grow: 1;

    margin: ${({ direction, isReadOnly }) => {
      if (isReadOnly && direction === 'row') {
        return '0 12px 12px';
      }
      return direction === 'column' ? '0 0 16px 0' : '0 12px 24px 12px';
    }};

    padding: ${({ isReadOnly }) => isReadOnly && '0'};
  }

  @media (min-width: 520px) and (max-width: 1024px) {
    ${({ direction }) =>
      direction === 'column'
        ? `
          max-width: 100%;
          & > * {
            width: 100%;
          }
        `
        : `
          max-width: calc(100% + 24px);
          & > * {
            width: calc(50% - 24px);
          }
        `}
  }

  @media (min-width: 1024px) {
    justify-content: ${({ width }) => (width ? 'unset' : 'space-between')};
  }
`;

const Warning = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 0;
`;

const WarningIcon = styled(Icon)`
  margin-right: 8px;
`;

export default OptionsList;
