/* eslint camelcase: ["error", {allow: ["csrf_token", "api_key", "signing_key", "auth_res"]}] */
import { startAuthentication } from '@simplewebauthn/browser';

// Config
// eslint-disable-next-line import/order
import { APP, USER_LOGGED_OUT } from '../config/Config';

import User from './User';
import Logger from './Logger';
import History from './History';

import { Perm } from 'pages/sites/src/data/api';
import { navigateToVerifyLogin } from 'shared/utils';
import ApiClient from 'shared/api/apiClient';
import i18n from 'i18n';

const GenericError = 'An error has occurred, please try again.';

/**
 * Account model used to sign up, login, and logout a user.
 * @type {Object}
 */
const Account = {
  loggedIn: false,
  loaded: false,

  setLoggedIn(value: boolean) {
    this.loggedIn = value;
  },

  setLoaded(value: boolean) {
    this.loaded = value;
  },

  async retrieve() {
    const response = await fetch(`${APP.endpoint}/user`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const { ok } = response;
    const data = await response.json();

    if (
      !!data.redirect &&
      !window.location.href.includes('/email_confirmation') &&
      !window.location.href.includes('/login') &&
      !window.location.href.includes('/invalid_confirmation_link')
    ) {
      const { pathname } = new URL(data.redirect);
      History.push(pathname);
    }

    if (data.csrf_token) {
      User.setCSRFToken(data.csrf_token);
    }

    if (!ok) {
      throw new Error(GenericError);
    }

    User.setData(data);
    // check if user had language set in db, if not, set by the language detected by users browser
    const { language } = data;
    i18n.changeLanguage(language || i18n.languages[0]);
    const userData = User.getData();
    // User gets logged in if its data property got populated
    this.loggedIn = Object.keys(userData)?.length;
    this.loaded = true;
    return true;
  },

  /**
   * Account login
   * @param  {String} username
   * @param  {String} password
   * @return {[type]}          [description]
   */
  async login(email: string, password: string, token?: string) {
    // support org-scoped email logins
    const urlForLogin =
      typeof window.organization !== 'undefined'
        ? `${APP.endpoint}/org/${window.organization}/email/login`
        : `${APP.endpoint}/login`;
    const response = await fetch(urlForLogin, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': User.getCSRFToken(),
      },
      body: JSON.stringify({
        email,
        password,
        token,
      }),
    });
    const { status, ok } = response;
    let csrfToken;
    let is2FA;

    try {
      const responseData = await response.json();

      if ((status === 302 || status === 301) && !!responseData.redirect) {
        const { pathname, search } = new URL(responseData.redirect);
        window.location.href = `${window.location.origin}${pathname}${search}`;
      }

      csrfToken = responseData.csrf_token;
      is2FA = responseData.is2fa;
    } catch (e) {
      Logger.error(`Error while parsing login response: ${e}`);
      throw new Error(GenericError);
    }

    if (csrfToken) {
      User.setCSRFToken(csrfToken);
    }

    if (!ok) {
      throw new Error(
        status === 401 ? 'Invalid Email / Password' : GenericError,
      );
    }

    if (is2FA) {
      navigateToVerifyLogin();

      return true;
    }

    this.loggedIn = true;
    return true;
  },

  async verifyLoginViaPasskey(getToken) {
    const { data } = await ApiClient().post(`/passkeys/prepare-login`, {
      token: await getToken(),
    });
    const assertionResponse = await startAuthentication(data);

    const verificationResponse = await ApiClient().post(
      `/2fa/passkeys/verify-credential`,
      {
        auth_res: JSON.stringify(assertionResponse),
        token: await getToken(),
      },
    );
    const verificationResult = await verificationResponse;

    if (verificationResult.data.verified) {
      this.loggedIn = true;
    }

    return verificationResult;
  },

  /**
   * Account logout
   * @return {[type]} [description]
   */
  async logout() {
    const response = await fetch(`${APP.endpoint}/logout`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': User.getCSRFToken(),
      },
    });
    const { ok, status } = response;
    const {
      csrf_token = false,
      message = GenericError,
    } = await response.json();

    if (!ok) {
      if (csrf_token) {
        User.setCSRFToken(csrf_token);
      }

      if (status !== 401) {
        throw new Error(message);
      }
    }

    this.loggedIn = false;
    this.loaded = false;
    return true;
  },

  /**
   * Account signup
   * @return {[type]} [description]
   */
  async signup(
    email: string,
    country: string,
    token?: string,
    awsToken?: string,
    type: string = 'publisher',
    // @ts-ignore
    ref: string,
    language: string = 'en',
    gclid: string = '',
  ) {
    const proSignupData =
      type === 'pro'
        ? {
            pro_signup: true, // eslint-disable-line
          }
        : {};
    let endpoint: any = null;

    if (type === 'publisher') {
      endpoint = 'webmaster/signup';
    } else if (type === 'requester') {
      endpoint = 'requester/signup';
    } else if (type === 'accessibility') {
      endpoint = 'accessibility/signup';
    } else {
      endpoint = 'webmaster/signup';
    }
    const response = await fetch(`${APP.endpoint}/${endpoint}`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': User.getCSRFToken(),
      },
      body: JSON.stringify({
        email,
        country,
        token,
        ref,
        language,
        gclid,
        'aws-token': awsToken ? decodeURIComponent(awsToken) : null,
        ...proSignupData,
      }),
    });
    const { ok, status } = response;
    const { csrf_token = false, error = GenericError } = await response.json();

    if (!ok) {
      if (csrf_token) {
        User.setCSRFToken(csrf_token);
      }

      if (status === 400) {
        throw new Error(error);
      } else {
        throw new Error(GenericError);
      }
    }

    if (type !== 'accessibility') {
      this.loggedIn = true;
    }

    return true;
  },

  /**
   * Account set password
   * @param {String} password
   */
  async changePassword(password: string, oldPass: string) {
    const response = await fetch(`${APP.endpoint}/password_change`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': User.getCSRFToken(),
      },
      body: JSON.stringify({
        password,
        // eslint-disable-next-line camelcase
        old_password: oldPass,
      }),
    });
    const { ok } = response;
    const { message = GenericError } = await response.json();

    if (!ok) {
      throw new Error(message);
    }

    // User gets logged out of back end, user must log back in
    this.loggedIn = false;
    this.loaded = false;
    return true;
  },

  /**
   * Account set password
   * @param {String} password
   */
  async resetPassword(email: string, token: string) {
    const response = await fetch(`${APP.endpoint}/password_reset`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': User.getCSRFToken(),
      },
      body: JSON.stringify({
        email,
        token,
      }),
    });
    const { ok, status } = response;
    const { csrf = false, message = GenericError } = await response.json();

    if (csrf) {
      User.setCSRFToken(csrf);
    }

    if (!ok) {
      if (status === 404) {
        throw new Error('User email was not found.');
      }

      throw new Error(message);
    }

    return true;
  },

  /**
   * Account change payout email
   * @param {String} email
   */
  async changePayoutEmail(email: string) {
    const response = await fetch(`${APP.endpoint}/user`, {
      method: 'PATCH',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': User.getCSRFToken(),
      },
      body: JSON.stringify({
        // eslint-disable-next-line camelcase
        payout_email: email,
      }),
    });
    const { ok } = response;
    const { message = GenericError } = await response.json();

    if (!ok) {
      throw new Error(message);
    }

    return true;
  },

  /**
   * Generate new api key for user
   */
  async newApiKey() {
    const response = await fetch(`${APP.endpoint}/user/apikey`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': User.getCSRFToken(),
      },
    });
    const { ok } = response;
    const { api_key, csrf_token = false } = await response.json();

    if (!ok) {
      if (csrf_token) {
        User.setCSRFToken(csrf_token);
      }

      throw new Error('Was unable to request new api key, please try again.');
    }

    return api_key;
  },

  /**
   * Generate new api key for user
   */
  async newSigningKey() {
    const response = await fetch(`${APP.endpoint}/user/signing_key`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': User.getCSRFToken(),
      },
    });
    const { ok } = response;
    const { signing_key, csrf_token = false } = await response.json();

    if (!ok) {
      if (csrf_token) {
        User.setCSRFToken(csrf_token);
      }

      throw new Error('Unable to fetch new signing key, please try again.');
    }

    return signing_key;
  },

  /**
   * Generate new secret key for enterprise user
   */
  async newSecretKey() {
    const response = await fetch(`${APP.endpoint}/user/secret_key`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': User.getCSRFToken(),
      },
    });
    const { ok } = response;
    const {
      secret,
      secret_updates_left: secretRotationsLeft,
      csrf_token = false,
    } = await response.json();

    if (!ok) {
      if (csrf_token) {
        User.setCSRFToken(csrf_token);
      }

      throw new Error(
        'Was unable to request new secret key, please try again.',
      );
    }

    return {
      secret,
      secretRotationsLeft,
    };
  },

  /**
   * Generate new account-level secret key for enterprise user (adds one per sitekey)
   */
  async getNewAccountESecretKey(days: number | null = 365, targets: string[]) {
    const response = await fetch(`${APP.endpoint}/user/generate_global_key`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': User.getCSRFToken(),
      },
      // how long should this new key last before expiry?
      body: JSON.stringify({
        // eslint-disable-next-line camelcase
        expiry_days: days,
        targets,
      }),
    });
    const { ok } = response;
    const {
      success,
      // eslint-disable-next-line camelcase
      new_es_key,
      // eslint-disable-next-line camelcase
      sitekey_results,
      csrf_token = false,
    } = await response.json();

    if (!ok || !success) {
      if (csrf_token) {
        User.setCSRFToken(csrf_token);
      }

      if (!ok) {
        throw new Error(
          'Unable to request new expiring secret key, please try again.',
        );
      } else {
        // user exceeded max # of secrets
        throw new Error(
          'Unable to add new secret key to all sitekeys. Note that a maximum of five expiring secrets are allowed. Please delete one from your sitekeys to add more.',
        );
      }
    }

    return {
      // eslint-disable-next-line camelcase
      new_es_key,
      // eslint-disable-next-line camelcase
      sitekey_results,
    };
  },

  /**
   * Generate new account-level secret key for enterprise user (adds one per sitekey)
   */
  async getNewApiKey(days: number | null = 365, perms: Perm[] = ['full']) {
    const response = await fetch(`${APP.endpoint}/user/apikey`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': User.getCSRFToken(),
      },
      body: JSON.stringify({
        expiration: days,
        permissions: perms,
      }),
    });
    const { ok } = response;
    // eslint-disable-next-line camelcase
    const { api_key, csrf_token } = await response.json();

    if (!ok) {
      if (csrf_token) {
        User.setCSRFToken(csrf_token);
      }

      if (!ok) {
        throw new Error('Unable to request new API key, please try again.');
      } else {
        // user exceeded max # of secrets
        throw new Error(
          'Unable to add new API key. Note that a maximum of five API keys are allowed. Please delete one to add more.',
        );
      }
    }

    return {
      // eslint-disable-next-line camelcase
      api_key,
    };
  },

  /**
   * Disable legacy account secret key for enterprise users
   */
  async disableLegacySecret() {
    const response = await fetch(`${APP.endpoint}/user/disable_secret_key`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': User.getCSRFToken(),
      },
    });
    const { ok } = response;
    const { success, csrf_token = false } = await response.json();

    if (!ok || !success) {
      if (csrf_token) {
        User.setCSRFToken(csrf_token);
      }

      throw new Error('Unable to disable Legacy Secret, please try again.');
    }

    return {
      success,
    };
  },

  async deleteAccount() {
    try {
      const response = await ApiClient().delete(
        '/dashboard/request_account_delete',
      );

      return response?.data;
    } catch (error) {
      if (error.message === USER_LOGGED_OUT) {
        // eslint-disable-next-line consistent-return
        return {};
      }

      if (error?.response?.data?.error) {
        throw new Error(error.response.data.error);
      }

      throw error;
    }
  },

  async resendConfirmationEmail(): Promise<{
    res: { ok: boolean; reason?: string } | null;
    error: Error | null;
  }> {
    try {
      const response = await fetch(`${APP.endpoint}/verify_email_resend`, {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': User.getCSRFToken(),
        },
      });

      const { ok } = response;
      const result = await response.json();

      if (!ok) {
        throw Error(result?.message || GenericError);
      }

      return { res: result, error: null };
    } catch (error) {
      return { res: null, error };
    }
  },
};
export default Account;
export { User };
