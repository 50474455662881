import { Color } from '../../theme/primitives';

const theme = {
  siteKeysSelectOptionState: {
    color: {
      default: {
        background: Color.white,
        border: Color.grey200,
      },
      selected: {
        background: Color.grey500,
        border: Color.grey500,
      },
    },
  },
};

export default theme;
