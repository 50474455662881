/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';

import TableSectionContext, { TableSection } from './TableSectionContext';

export interface TableHeadProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
  > {}

const HEAD_SECTION: TableSection = {
  variant: 'head',
};

export const TableHead = React.forwardRef<
  HTMLTableSectionElement,
  TableHeadProps
>(({ children, ...props }, ref) => {
  return (
    <StyledTableHead {...props} ref={ref}>
      <TableSectionContext.Provider value={HEAD_SECTION}>
        {children}
      </TableSectionContext.Provider>
    </StyledTableHead>
  );
});

const StyledTableHead = styled.thead`
  text-align: left;
`;
