import React from 'react';
import styled from 'styled-components';

import Text from '../Text';
import { KeyCode } from '../../constants';
import { Color } from '../../theme/primitives';

interface InteractivePillProps {
  className?: string;
  width?: number;
  children?: React.ReactNode;
  id?: string;
  isSelected?: boolean;
  onClick?: (id?: string) => void;
}

export const InteractivePill: React.FC<InteractivePillProps> = ({
  className = '',
  children,
  id,
  isSelected,
  onClick,
}) => {
  const handleClick = () => {
    onClick?.(id);
  };

  const handleEnterKeyDown = (callback: Function) => (
    event: React.KeyboardEvent<HTMLElement>,
  ) => {
    const { key } = event;

    if (key === KeyCode.ENTER) {
      callback?.();
    }
  };

  return (
    <Container
      className={className}
      tabIndex={0}
      isSelected={isSelected}
      onClick={handleClick}
      onKeyDown={handleEnterKeyDown(handleClick)}
    >
      <Title>{children}</Title>
    </Container>
  );
};

const Title = styled(Text)`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
`;

const Container = styled.div<{
  isSelected?: boolean;
}>`
  display: inline-flex;
  border-radius: 4px;
  border: 1px solid;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  width: auto;
  max-width: 150px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  border-color: ${Color.grey200};
  color: ${Color.grey500};

  ${({ isSelected }) =>
    isSelected
      ? `
        background-color: ${Color.blue100};
        border-color: ${Color.blue400};
        color: ${Color.blue400};
      `
      : ''};

  &:hover,
  &:focus {
    background-color: ${Color.blue100};
    border-color: ${Color.blue400};
    color: ${Color.blue400};
  }
`;
