import styled, { DefaultTheme } from 'styled-components';

import { color, shape } from '../../theme/themeUtils';

const Panel = styled.div<{
  variant?: keyof DefaultTheme['panel']['shape'] &
    keyof DefaultTheme['panel']['color'];
}>`
  box-sizing: border-box;
  ${({ theme, variant }) => color(theme.panel.color[variant || 'default'])};
  ${({ theme, variant }) => shape(theme.panel.shape[variant || 'default'])};
`;

export default Panel;
