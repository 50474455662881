// NPM
import * as Sentry from '@sentry/browser';

import { AllowedAccount } from 'main/data/types/AllowedAccount';

const User = {
  data: {},
  CSRFToken: null,
  gclid: null,

  setCSRFToken(token: string) {
    this.CSRFToken = token;
  },

  getCSRFToken() {
    return this.CSRFToken;
  },

  getUserId() {
    return this.data?.id;
  },

  getUserEmail() {
    return this.data?.email;
  },

  getRoleId() {
    return this.data?.role_id;
  },

  setData(data: any) {
    this.data = data;
    Sentry.configureScope(scope => {
      scope.setExtra('User data', {
        Email: data.email,
        'Is Requester': data.is_requester,
        'Is Webmaster': data.is_webmaster,
        'Is Pro': data.is_pro_publisher,
        'Is Enterprise Publisher': data.is_enterprise_publisher,
        'Is Accessibility': data.is_accessibility,
      });
    });
  },

  getData() {
    return this.data;
  },

  isRequester() {
    const { is_requester: isRequester = false } = this.data;
    return isRequester;
  },

  isPublisher() {
    const { is_webmaster: isWebmaster = false } = this.data;
    return isWebmaster;
  },

  isProPublisher() {
    const { is_pro_publisher: isProPublisher = false } = this.data;
    return isProPublisher;
  },

  isPartner() {
    const { is_partner: isPartner = false } = this.data;
    return isPartner;
  },

  isOrgOwner() {
    const { organizations } = this.data;

    return !!organizations?.length;
  },

  hasOrgManagementPermission() {
    const {
      org_mgmt_permission: hasOrgManagementPermission = false,
    } = this.data;

    return hasOrgManagementPermission;
  },

  isEnterprisePublisher() {
    const {
      is_enterprise_publisher: isEnterprisePublisher = false,
    } = this.data;
    return isEnterprisePublisher;
  },

  isAnalyst() {
    const { is_analyst: isAnalyst = false } = this.data;
    return isAnalyst;
  },

  isAccessibility() {
    const { is_accessibility: isAccessibility = false } = this.data;
    return isAccessibility;
  },

  isStaff() {
    const { is_staff: isStaff = false } = this.data;
    return isStaff;
  },

  isSalesEng() {
    const { features } = this.data;

    return (this.isStaff() || this.isSupport()) && !!features?.sales_eng;
  },

  isSalesRep() {
    const { features } = this.data;

    return (this.isStaff() || this.isSupport()) && !!features?.sales_rep;
  },

  isBetaSVRules() {
    const { is_staff: isStaff = false } = this.data;
    return isStaff;
  },

  isSupport() {
    const { is_support: isSupport = false } = this.data;
    return isSupport;
  },

  isRuleReviewEnabled() {
    const { rule_review_enabled: isReviewEnabled = false } = this.data;
    return isReviewEnabled;
  },

  getRuleReviewWebhooks() {
    const {
      rule_review_webhooks: ruleReviewWebhooks = {
        slack: [],
        email: [],
      },
    } = this.data;
    return ruleReviewWebhooks;
  },

  getRetroTaggingWebhooks() {
    const {
      retro_tagging_webhooks: retroTaggingWebhooks = {
        slack: [],
        email: [],
      },
    } = this.data;
    return retroTaggingWebhooks;
  },

  getPlan() {
    if (this.isEnterprisePublisher()) {
      return 'enterprise';
    }

    if (this.isProPublisher()) {
      return 'pro';
    }

    return 'free';
  },

  getUserAllowedAccounts(): AllowedAccount[] {
    const { available_accounts: allowedAccounts = [] } = this.data;
    return allowedAccounts;
  },

  hasAllowedAccounts() {
    const { available_accounts: allowedAccounts = [] } = this.data;
    return allowedAccounts.length > 1;
  },

  hasVerifiedEmail() {
    const { email_verified: hasVerifiedEmail = false } = this.data;
    return hasVerifiedEmail;
  },

  hasSelectedUser() {
    const { logged_in_from_another_account } = this.data;
    return logged_in_from_another_account !== null;
  },

  hasMultiUser() {
    const { features } = this.data;
    return features?.multiuser;
  },

  isPl() {
    const { features } = this.data;
    return features?.pl;
  },

  isSsoUser() {
    return !!this.data?.is_sso_from;
  },

  isZoneRestrictedAccount() {
    return !!this.data?.is_zone_restricted_account;
  },

  isSharedAccount() {
    return !!this.data?.is_shared;
  },

  hasPasswordSet() {
    return !!this.data?.has_password;
  },

  is2FaEnabled() {
    return !!this.data?.is_2fa_enabled;
  },

  setGclid(gclid?: any) {
    // This is unique ad click id that is being used to match user visit with specific google ad.
    // We send this to sing-up endpoint to track conversions & posthog to understand which users come from specific ads
    this.gclid = gclid;
  },

  getGclid() {
    return this.gclid;
  },

  getAccountType() {
    const availableAccounts =
      (this.data?.available_accounts as AllowedAccount[]) || [];

    // TODO: Allow the api to return the current account type
    const currentAccount = availableAccounts.find(
      account => account.email === this.getUserEmail(),
    );

    return currentAccount?.account_type;
  },
};
export default User;
