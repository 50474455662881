import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';

import { EmptyGraphView } from '../../templates/EmptyGraphView';
import { ArrowPagination } from '../../molecules/ArrowPagination';
import { typography } from '../../theme/themeUtils';
import { LinearProgress } from '../../atoms/LinearProgress';
import { WidgetContainerWithFilter } from '../../molecules/WidgetContainerWithFilter';
import { WidgetContainer } from '../../molecules/WidgetContainer';
import { WithFilterProps } from '../../molecules/WidgetContainerWithFilter/WidgetFilter';
import { round } from '../../utils/mathUtils';

export interface ListItem {
  label: string;
  value: number;
}

export interface FormattedListItem extends ListItem {
  formattedValue: string | number;
}

export interface PercentageBarsListWidgetProps {
  items: ListItem[];
  header: string;
  tooltip?: string;
  tooltipDocsReference?: string;
  isLoading?: boolean;
  customLoadingIndicator?: ReactNode;
  onTooltipFocus?: () => void;
  onTooltipMouseOver?: () => void;
  formatItems?: boolean;
  nonPerCentValues?: boolean;
  formatBarWidths?: (arg0: number) => number;
  emptyStateTitle?: string;
  emptyStateDescription?: string;
  renderEmptyState?: () => JSX.Element;
  renderHoveredRow?: (item: FormattedListItem) => React.ReactNode | null;
}

const WidgetBody = ({
  items,
  formatItems,
  nonPerCentValues,
  formatBarWidths,
  renderHoveredRow,
}: {
  items: ListItem[];
  formatItems?: boolean;
  nonPerCentValues?: boolean;
  formatBarWidths?: (arg0: number) => number;
  renderHoveredRow?: (item: FormattedListItem) => React.ReactNode | null;
}) => {
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };
  const handleMouseEnter = (index: number) => () => {
    setHoveredRowIndex(index);
  };

  const sortedItems = items.sort((itemA, itemB) => itemB.value - itemA.value);
  return (
    <ArrowPagination items={sortedItems}>
      {pageItems => (
        <BarListWrapper key="bars-wrapper">
          {pageItems.map((item, index) => {
            const itemValue = nonPerCentValues
              ? item.value
              : round(item.value * 100, 1);
            const isHovered = hoveredRowIndex === index;
            const valueToDisplay = `${
              formatItems ? itemValue.toLocaleString() : itemValue
            }${nonPerCentValues ? '' : '%'}`;
            return (
              <RowWrapper
                isHovered={isHovered}
                key={index}
                onMouseEnter={handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                {isHovered && renderHoveredRow ? (
                  renderHoveredRow({
                    label: item.label,
                    formattedValue: valueToDisplay,
                    value: item.value,
                  })
                ) : (
                  <Row>
                    <Label>{item.label}</Label>
                    <PercentageLabel>{valueToDisplay}</PercentageLabel>
                    <LinearProgress
                      index={index}
                      value={
                        formatBarWidths
                          ? formatBarWidths(item.value)
                          : item.value * 100
                      }
                    />
                  </Row>
                )}
              </RowWrapper>
            );
          })}
        </BarListWrapper>
      )}
    </ArrowPagination>
  );
};

export const PercentageBarsListWidgetWithFilter = ({
  items,
  header,
  filterOptions,
  selectedFilter,
  onFilterOptionChange,
  tooltip,
  tooltipDocsReference,
  isLoading,
  customLoadingIndicator,
  onTooltipFocus,
  onTooltipMouseOver,
  formatItems,
  nonPerCentValues,
  formatBarWidths,
  renderEmptyState,
  emptyStateTitle,
  emptyStateDescription,
  renderHoveredRow,
}: WithFilterProps<PercentageBarsListWidgetProps>) => {
  return (
    <WidgetContainerWithFilter
      header={header}
      onFilterOptionChange={onFilterOptionChange}
      filterOptions={filterOptions}
      selectedFilter={selectedFilter}
      tooltip={tooltip}
      tooltipDocsReference={tooltipDocsReference}
      isLoading={isLoading}
      customLoadingIndicator={customLoadingIndicator}
      onTooltipFocus={onTooltipFocus}
      onTooltipMouseOver={onTooltipMouseOver}
      isEmpty={!items?.length}
    >
      {!items?.length ? (
        <>
          {renderEmptyState ? (
            renderEmptyState()
          ) : (
            <EmptyGraphView
              title={emptyStateTitle}
              description={emptyStateDescription}
            />
          )}
        </>
      ) : (
        <WidgetBody
          items={items}
          formatItems={formatItems}
          nonPerCentValues={nonPerCentValues}
          formatBarWidths={formatBarWidths}
          renderHoveredRow={renderHoveredRow}
        />
      )}
    </WidgetContainerWithFilter>
  );
};

export const PercentageBarsListWidget = ({
  items,
  header,
  tooltip,
  tooltipDocsReference,
  isLoading,
  customLoadingIndicator,
  onTooltipFocus,
  onTooltipMouseOver,
}: PercentageBarsListWidgetProps) => {
  return (
    <WidgetContainer
      header={header}
      tooltip={tooltip}
      tooltipDocsReference={tooltipDocsReference}
      isLoading={isLoading}
      customLoadingIndicator={customLoadingIndicator}
      onTooltipFocus={onTooltipFocus}
      onTooltipMouseOver={onTooltipMouseOver}
    >
      <WidgetBody items={items} />
    </WidgetContainer>
  );
};

const BarListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const RowWrapper = styled.div<{ isHovered: boolean }>`
  width: 100%;
  padding: 7.5px 0;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
`;

const Row = styled.div`
  width: 100%;
  grid-template-columns: 2fr auto 1fr;
  display: grid;
  grid-gap: 15px;
`;

const PercentageLabel = styled.span`
  word-break: break-word;
  ${typography('small')};
  color: ${({ theme }) => theme.linearProgress.color.default.filledBackground};
`;

const Label = styled.span`
  text-align: left;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${typography('small')}
  color: ${({ theme }) => theme.linearProgress.color.default.filledBackground};
`;
