export const getElementPagePosition = (element: any) => {
  if (!element) return {};
  const rect = element.getBoundingClientRect();
  const win = element.ownerDocument.defaultView;
  return {
    top: rect.top + win.pageYOffset,
    left: rect.left + win.pageXOffset,
  };
};

export const isElementOutOfScreen = (element: any) => {
  if (!element) return false;
  const position = getElementPagePosition(element);
  const topOffset = position.top;
  const elementHeight = element.clientHeight;
  const windowHeight = window.innerHeight;

  return topOffset + elementHeight > windowHeight;
};

const domUtil = {
  getElementPagePosition,
  isElementOutOfScreen,
};

export default domUtil;
