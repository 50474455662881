import React from 'react';
import styled from 'styled-components';

export interface BackdropProps {
  className?: string;
  children: React.ReactNode;
}

const Backdrop: React.FC<BackdropProps> = ({ className, children }) => {
  return <Container className={className}>{children}</Container>;
};

const Container = styled.div`
  height: 100%;
  position: fixed;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99999;
`;

export default Backdrop;
