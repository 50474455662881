/* eslint-disable consistent-return */
import React from 'react';
import styled from 'styled-components';

import { Color } from '../../theme/primitives';
import Text from '../Text';

import { PillVariant } from './types';

export interface PillProps {
  variant: PillVariant;
  text: string;
  title?: string;
  className?: string;
}

export const Pill: React.FC<PillProps> = ({
  variant,
  text,
  title,
  className,
}) => (
  <PillContainer variant={variant} title={title} className={className}>
    <PillText>{text}</PillText>
  </PillContainer>
);

const PillContainer = styled.div<{
  variant?: PillVariant;
}>`
  box-sizing: border-box;

  width: fit-content;
  height: 18px;
  font-size: 12px;
  text-transform: capitalize;

  background-color: ${({ variant }) => {
    switch (variant) {
      case 'purple':
        return Color.purple100;
      case 'blue':
        return Color.blue100;
    }
  }};
  color: ${({ variant }) => {
    switch (variant) {
      case 'purple':
        return Color.purple700;
      case 'blue':
        return Color.blue500;
    }
  }};
  border: 1px solid
    ${({ variant }) => {
      switch (variant) {
        case 'purple':
          return Color.purple400;
        case 'blue':
          return Color.blue400;
      }
    }};

  padding: 2px 8px;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  border-radius: 9px;
`;

const PillText = styled(Text)`
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
