import { Color } from '../../theme/primitives';

const theme = {
  checkbox: {
    color: {
      default: {
        inactive: {
          background: Color.white,
          border: Color.grey400,
        },
        selected: {
          background: Color.white,
          border: Color.grey400,
          color: Color.grey600,
        },
        disabled: {
          background: Color.grey100,
          border: Color.grey200,
          color: Color.grey400,
        },
        focus: {
          boxShadowColor: Color.grey200,
        },
      },
      standard: {
        inactive: {
          background: Color.white,
          border: Color.grey400,
        },
        selected: {
          background: Color.blue400,
          border: Color.blue400,
          color: Color.white,
        },
        disabled: {
          background: Color.grey100,
          border: Color.grey200,
          color: Color.grey400,
        },
        focus: {
          boxShadowColor: Color.grey200,
        },
      },
    },
  },
};

export default theme;
