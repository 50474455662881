import { createContext } from 'react';

import { DataReportProviderValue } from '../constants';
import { SortDirection } from '../../../constants';

const emptyState: DataReportProviderValue = {
  tableState: {
    headerState: {
      isHovered: false,
      direction: SortDirection.NONE,
      sortedColumnId: '',
    },
    columns: [],
    sourceData: [],
    currentData: [],
    displayedData: [],
  },
  handleTableHeaderMouseIn: () => {},
  handleTableHeaderMouseOut: () => {},
  handleTableHeaderCellClick: () => {},
  handlePageChange: () => {},
};

export const DataReportTableContext = createContext(emptyState);
