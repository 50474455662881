import React from 'react';
import styled, { DefaultTheme } from 'styled-components';

import Text from '../Text';
import { color, shape } from '../../theme/themeUtils';

type Variant = keyof DefaultTheme['radioButton']['color'] &
  keyof DefaultTheme['radioButton']['shape'];

export interface RadioButtonProps {
  className?: string;
  variant?: Variant;
  onChange: (value: string) => void;
  value: string;
  isDisabled?: boolean;
  isChecked: boolean;
  children: React.ReactNode | React.ReactNode[];
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  className,
  variant,
  onChange,
  value,
  isChecked,
  isDisabled,
  children,
}) => (
  <RadioButtonWrapper
    key={value}
    isDisabled={isDisabled}
    variant={variant}
    checked={isChecked}
    className={className}
  >
    <HiddenRadio
      type="radio"
      value={value}
      checked={isChecked}
      onChange={event => onChange(event.target.value)}
      disabled={isDisabled}
    />
    <Title variant="small">{children}</Title>
  </RadioButtonWrapper>
);

const HiddenRadio = styled.input`
  display: none;
`;

const RadioButtonWrapper = styled.label<{
  isDisabled?: boolean;
  variant?: Variant;
  checked: boolean;
}>`
  position: relative;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  align-items: center;

  ${({ theme, variant }) =>
    shape(theme.radioButton.shape[variant || 'default'])};
  ${({ theme, variant }) =>
    color(theme.radioButton.color[variant || 'default'].inactive)};

  ${({ checked, theme, variant }) =>
    checked ? color(theme.radioButton.color[variant || 'default'].active) : ''}

  ${({ isDisabled, theme, variant }) =>
    isDisabled
      ? color(theme.radioButton.color[variant || 'default'].disabled)
      : ''}
`;

const Title = styled(Text)`
  font-weight: 500;
`;
