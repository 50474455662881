import React from 'react';
import styled from 'styled-components';

import { shape } from '../../theme/themeUtils';
import { Icon } from '../../atoms/Icon';
import { Theme } from '../../styled';

interface AdvancedOptionProps {
  optionValue: string;
  isSelected: boolean;
  children: any;
  isDisabled?: boolean;
  hasSelectionIcon?: boolean;
  onClick?: (value: string) => void;
  __type?: 'AdvancedOption';
}

export const AdvancedOption: React.FC<AdvancedOptionProps> = ({
  optionValue,
  isSelected,
  children,
  isDisabled = false,
  hasSelectionIcon = true,
  onClick,
}) => {
  return (
    <OptionContainer
      isSelected={isSelected}
      isDisabled={isDisabled}
      onClick={() => onClick?.(optionValue)}
    >
      <OptionExternalContainer>
        <OptionInternalContainer>{children}</OptionInternalContainer>
        {hasSelectionIcon && <SelectionState isSelected={isSelected} />}
      </OptionExternalContainer>
    </OptionContainer>
  );
};

AdvancedOption.defaultProps = {
  __type: 'AdvancedOption',
};

const SelectionState = ({ isSelected }: { isSelected: boolean }) => (
  <SelectionStateWrapper isSelected={isSelected}>
    <Icon
      icon="checkMark"
      iconSize="s"
      fill={isSelected ? 'white' : 'transparent'}
    />
  </SelectionStateWrapper>
);

const OptionContainer = styled.div<{
  isSelected: boolean;
  isDisabled: boolean;
}>`
  ${({ theme }) => shape(theme.selectOption.shape)};
  cursor: ${({ isDisabled }) => (isDisabled ? 'inherit' : 'pointer')};

  &:hover {
    background: ${({ theme, isDisabled }) =>
      isDisabled ? 'inherit' : theme.selectOption.color.selected.background};
  }

  border-bottom: 1px solid
    ${({ theme }) => theme.selectOption.color.default.border};

  :last-child {
    border-bottom: none;
  }
`;

const OptionExternalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const OptionInternalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SelectionStateWrapper = styled.div`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected
        ? theme.siteKeysSelectOptionState.color.selected.border
        : theme.siteKeysSelectOptionState.color.default.border};
  background-color: ${({
    isSelected,
    theme,
  }: {
    isSelected: boolean;
    theme: Theme;
  }) =>
    isSelected
      ? theme.siteKeysSelectOptionState.color.selected.background
      : theme.siteKeysSelectOptionState.color.default.background};
`;
