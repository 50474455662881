import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import Text from '../../atoms/Text';

import OptionsList from './components/OptionsList';

type PrimitieValue = number | string | boolean;

export interface OptionInterface {
  value: PrimitieValue;
  label: string;
  labelTerm?: string;
  description?: string;
  tooltipDescription?: string;
  isDisabled?: boolean;
  icon?: string;
  tooltipButtonTitle?: string;
  warning?: string;
  content?: React.ReactNode;
  suboptions?: {
    title: string;
    description?: string;
    list: OptionInterface[] | [];
  };
}

export interface TileSelectorProps {
  isReadOnly?: boolean;
  title?: string;
  description?: string;
  options: OptionInterface[] | [];
  width?: string;
  direction?: string;
  optionWidth?: string;
  onChange: (value: PrimitieValue, subValue?: PrimitieValue) => void;
  className?: string;
  selectedValue?: PrimitieValue;
  defaultValue?: PrimitieValue;
  onTooltipButtonClick?: () => void;
  isActive?: boolean;
}

export const TileSelector: React.FC<TileSelectorProps> = ({
  className = '',
  isReadOnly,
  title = '',
  description = '',
  defaultValue,
  options = [],
  optionWidth = '',
  width = '',
  direction = '',
  onChange,
  onTooltipButtonClick,
  isActive = true,
}) => {
  const selectedValueByDefault = defaultValue ?? options[0].value;
  const getSelectedValue = useCallback(
    (selectedValueByDefault: PrimitieValue) =>
      options.find(
        option =>
          option.value === selectedValueByDefault ||
          option?.suboptions?.list?.find(
            // @ts-ignore todo: fix types
            suboption => suboption.value === selectedValueByDefault,
          ),
      )?.value ?? '',
    [options],
  );
  const getSelectedOption = useCallback(
    (value: PrimitieValue) => options.find(option => option.value === value),
    [options],
  );
  const getSelectedSuboptionValue = useCallback(
    (
      selectedOption?: OptionInterface,
      selectedValueByDefault?: PrimitieValue,
    ) =>
      selectedOption?.suboptions?.list?.find(
        // @ts-ignore todo: fix types
        suboption => suboption.value === selectedValueByDefault,
      )?.value ??
      selectedOption?.suboptions?.list?.[0]?.value ??
      '',
    [],
  );
  const [selectedValue, setSelectedValue] = useState<PrimitieValue>(
    getSelectedValue(selectedValueByDefault),
  );
  const selectedOption = getSelectedOption(selectedValue);
  const [selectedSuboptionValue, setSelectedSuboptionValue] = useState<
    PrimitieValue
  >(getSelectedSuboptionValue(selectedOption, selectedValueByDefault));

  useEffect(() => {
    function updateValues() {
      const selectedValueByDefault = defaultValue ?? options[0].value;
      const newValue = getSelectedValue(selectedValueByDefault);
      setSelectedValue(newValue);
      const selectedOption = getSelectedOption(newValue);
      setSelectedSuboptionValue(
        getSelectedSuboptionValue(selectedOption, selectedValueByDefault),
      );
    }

    updateValues();
  }, [
    defaultValue,
    options,
    getSelectedValue,
    getSelectedSuboptionValue,
    getSelectedOption,
  ]);

  const handleChange = (
    value: PrimitieValue,
    suboptionValue: PrimitieValue,
  ) => {
    onChange?.(value, suboptionValue);
  };

  const handleValueChange = (value: PrimitieValue) => {
    const suboptionValue =
      getSelectedOption(value)?.suboptions?.list?.[0]?.value || '';
    setSelectedValue(value);
    setSelectedSuboptionValue(suboptionValue);
    handleChange(value, suboptionValue);
  };

  const handleSuboptionValueChange = (suboptionValue: PrimitieValue) => {
    setSelectedSuboptionValue(suboptionValue);
    handleChange(selectedValue, suboptionValue);
  };

  return (
    <Container className={className}>
      <MainContainer isActive={isActive}>
        {title && (
          <Title variant="default" className="option-title">
            {title}
          </Title>
        )}
        {description && (
          <Description
            variant="small"
            className="option-description"
            isReadOnly={isReadOnly}
          >
            {description}
          </Description>
        )}
        <List
          isReadOnly={isReadOnly}
          selectedValue={selectedValue}
          options={options}
          onChange={handleValueChange}
          optionWidth={optionWidth}
          width={width}
          direction={direction}
          onTooltipButtonClick={onTooltipButtonClick}
          isActive={isActive}
        />
      </MainContainer>
      {selectedOption?.suboptions && (
        <SubContainer className="suboption-container">
          {selectedOption.suboptions?.title && (
            <Title variant="default" className="suboption-title">
              {selectedOption.suboptions.title}
            </Title>
          )}
          {selectedOption?.suboptions?.description && (
            <Description
              variant="small"
              className="suboption-description"
              isReadOnly={isReadOnly}
            >
              {selectedOption?.suboptions.description}
            </Description>
          )}
          <OptionsSubList
            isReadOnly={isReadOnly}
            selectedValue={selectedSuboptionValue}
            options={selectedOption?.suboptions?.list}
            onChange={handleSuboptionValueChange}
            optionWidth={optionWidth}
            onTooltipButtonClick={onTooltipButtonClick}
            isActive={isActive}
          />
        </SubContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Text)`
  font-weight: 600;
  line-height: 1.5;
`;

const Description = styled(Text)<{
  isActive?: boolean;
  isReadOnly?: boolean;
}>`
  line-height: 1.15;
  overflow: auto;
  margin-top: 8px;
  margin-bottom: ${({ isReadOnly }) => isReadOnly && '-20px'};
`;

const MainContainer = styled.div<{
  isActive?: boolean;
}>`
  display: flex;
  flex-direction: column;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

const List = styled(OptionsList)`
  margin: 32px 0 0;
`;

const OptionsSubList = styled(List)``;
