import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Icon } from '../../atoms/Icon';
import { InputField } from '../../atoms/InputField';
import { Color } from '../../theme/primitives';
import OptionsList from '../OptionsList';

type Option = {
  value: string;
  label: string;
};

interface AutocompleteDropdownListProps {
  menuPosition?: 'top' | 'bottom';
  selection?: string;
  options: Option[];
  onChange: (value: string) => void;
  placeholder?: string;
  onOpen?: (isOpen: boolean) => void;
  width?: number;
  isDisabled?: boolean;
}

const AutocompleteDropdownList: React.FC<AutocompleteDropdownListProps> = ({
  menuPosition,
  selection,
  options,
  onChange,
  placeholder,
  onOpen,
  width,
  isDisabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setFilteredOptions(options);
    setInputValue(options.find(opt => opt.value === selection)?.label || '');
  }, [options, selection]);

  const handleOptionClick = (selectedValue: string) => {
    setIsOpen(false);
    onOpen?.(false);
    onChange(selectedValue);
    setInputValue(
      options.find(opt => opt.value === selectedValue)?.label || '',
    );
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    setInputValue(newValue || '');
    setFilteredOptions(
      options.filter(
        ({ label }) =>
          !newValue || label.toLowerCase().includes(newValue.toLowerCase()),
      ),
    );

    if (!isOpen) {
      setIsOpen(true);
      onOpen?.(true);
    }
  };

  const handleHideOptions = () => {
    setIsOpen(false);
    onOpen?.(false);

    if (selection) {
      const label = options.find(opt => opt.value === selection)?.label;

      if (label !== inputValue) {
        setInputValue(label || '');
      }
    }
  };

  const handleShowOptions = () => {
    if (!isOpen) {
      setIsOpen(true);
      onOpen?.(true);
    }
  };

  const handleEnter = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleHideOptions();
    } else if (event.key === 'Enter') {
      const { value } = event.target as any;
      const option = options.filter(opt =>
        opt.label.toLowerCase().includes(value?.toLowerCase()),
      );

      if (option.length === 1) {
        handleOptionClick(option[0].value);
      }
    }
  };

  return (
    <OptionsList
      options={filteredOptions}
      open={isOpen && filteredOptions.length > 0}
      width={width}
      onBlur={handleHideOptions}
      onChange={handleOptionClick}
      position={menuPosition}
    >
      <DropdownInput
        minWidth={width}
        value={inputValue}
        onChange={handleValueChange}
        onKeyDown={handleEnter}
        onFocus={handleShowOptions}
        onClick={handleShowOptions}
        placeholder={placeholder}
        disabled={isDisabled}
      />
      <LeftChevronIcon isRotated={isOpen} icon="arrowDownAlt" iconSize="14" />
    </OptionsList>
  );
};

const StyledInput = styled(InputField)<{
  minWidth?: number;
  maxWidth?: number;
  invalid?: boolean;
  bold?: boolean;
}>`
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  color: ${({ color }) => color || Color.grey600};
  background-color: ${Color.white};
  border-color: ${({ invalid }) => (invalid ? Color.red400 : Color.grey200)};
  border-radius: 4px;
  margin-top: 0;
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : 'auto')};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'auto')};
`;

const DropdownInput = styled(StyledInput)`
  margin-bottom: 0;

  input {
    padding-right: 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const LeftChevronIcon = styled(Icon)<{
  isRotated?: boolean;
}>`
  position: absolute;
  top: 14px;
  right: 10px;
  transform: ${({ isRotated }) =>
    isRotated ? 'rotate(180deg)' : 'rotate(0deg)'};
  display: inline-block;
  margin-right: 4px;
  pointer-events: none;
`;

export default AutocompleteDropdownList;
