import React from 'react';
import styled from 'styled-components';

import AxisX from '../../charts/atoms/GraphAxis/AxisX';
import AxisY from '../../charts/atoms/GraphAxis/AxisY';
import GraphInteractionLayer from '../../atoms/GraphInteractionLayer';
import { ZoomInteractionLayer } from '../../charts/atoms/graph-zoom';
import { MouseEventContextProvider } from '../../contexts/MouseEvent';
import { CoordinatesContextProvider } from '../../contexts/CanvasCoordinates';
import { HeatMapLegend } from '../../atoms/HeatMapLegend';
import { AxisConfig } from '../../charts/atoms/ChartAreaProvider/ChartAreaContext';
import ChartAreaProvider from '../../charts/atoms/ChartAreaProvider';

import { Matrix } from './Matrix';
import { HeatMapDataConfig } from './types';
import HeatMapStateProvider from './HeatMapStateProvider';

const AXIS_Y_WIDTH = 50;
const AXIS_X_HEIGHT = 70;

const LEGEND_WIDTH = 180;
const LEGEND_HEIGHT = 70;

export interface HeatMapProps {
  /**
   * Data configuration
   */
  dataConfig: HeatMapDataConfig;
  width: number;
  height: number;
  x: AxisConfig & { step: number };
  y: AxisConfig & { step: number };
  /**
   * Show legend above the graph
   */
  showLegend?: boolean;
  dataCy?: string;
  tooltip?: React.ReactNode;
  palette?: string[];
  /**
   * Sets value display node to show
   */
  showMarker?: boolean;
  /**
   * Width of the value display marker
   */
  markerWidth?: number;
  /**
   * Value display node (shows on top of x axis along with the interactive marker)
   */
  markerValueDisplay?: React.ReactNode;
  /**
   * Callback triggered on clicking the zoom-in button (if zoom widget is setup)
   */
  onZoomIn?: (newMinX: number, newMaxX: number) => void;
  /**
   * Formats what's displayed in value indicators (e.g. in zoom crop widget)
   */
  formatValueIndicator?: (displayValue: number) => string;
}

const PALETTE = [
  '#8cece2',
  '#72d4d5',
  '#5abbc7',
  '#47a3b7',
  '#378ca6',
  '#2b7493',
  '#235e7f',
  '#1d486a',
  '#183355',
  '#131f3f',
  '#0e0a29',
];

export const HeatMap: React.FC<HeatMapProps> = ({
  dataConfig,
  width,
  height,
  showLegend,
  dataCy,
  tooltip,
  x,
  y,
  palette = PALETTE,
  showMarker,
  markerWidth,
  markerValueDisplay,
  onZoomIn,
  formatValueIndicator,
}) => {
  return (
    <ChartAreaProvider x={x} y={y}>
      <HeatMapStateProvider dataConfig={dataConfig} palette={palette}>
        <HeatMapContainer
          width={width}
          height={height}
          data-cy={dataCy}
          showLegend={showLegend}
        >
          {showLegend && (
            <HeatMapLegend width={LEGEND_WIDTH} height={LEGEND_HEIGHT} />
          )}
          <HeatMapGraph
            width={width}
            height={showLegend ? height - LEGEND_HEIGHT : height}
            tooltip={tooltip}
            showMarker={showMarker}
            markerWidth={markerWidth}
            markerValueDisplay={markerValueDisplay}
            onZoomIn={onZoomIn}
            formatValueIndicator={formatValueIndicator}
          />
        </HeatMapContainer>
      </HeatMapStateProvider>
    </ChartAreaProvider>
  );
};

interface HeatMapGraphProps {
  width: number;
  height: number;
  tooltip?: React.ReactNode;
  showMarker?: boolean;
  markerWidth?: number;
  markerValueDisplay?: React.ReactNode;
  onZoomIn?: (newMinX: number, newMaxX: number) => void;
  formatValueIndicator?: (displayValue: number) => string;
}

export const HeatMapGraph: React.FC<HeatMapGraphProps> = ({
  width,
  height,
  tooltip,
  showMarker,
  markerWidth,
  markerValueDisplay,
  onZoomIn,
  formatValueIndicator,
}) => {
  const stageWidth = width - AXIS_Y_WIDTH;
  const stageHeight = height - AXIS_X_HEIGHT;

  return (
    <GraphContainer width={width} height={height}>
      <MouseEventContextProvider>
        <CoordinatesContextProvider>
          <StyledMatrix
            height={height - AXIS_X_HEIGHT}
            width={width - AXIS_Y_WIDTH}
          />
          <StyledAxisY height={stageHeight} />
          <StyledAxisX width={stageWidth} />
          <ZoomInteractionLayer
            width={stageWidth}
            height={stageHeight}
            markerWidth={markerWidth}
            onZoomIn={onZoomIn}
            formatValueIndicator={formatValueIndicator}
          />
          <StyledInteractionLayer
            width={width - AXIS_Y_WIDTH}
            height={height - AXIS_X_HEIGHT}
            tooltip={tooltip}
            showMarker={showMarker}
            markerWidth={markerWidth}
            markerValueDisplay={markerValueDisplay}
          />
        </CoordinatesContextProvider>
      </MouseEventContextProvider>
    </GraphContainer>
  );
};

const StyledMatrix = styled(Matrix)`
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledInteractionLayer = styled(GraphInteractionLayer)`
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledAxisX = styled(AxisX)<{ width: number }>`
  width: ${props => props.width}px;
  height: ${AXIS_X_HEIGHT}px;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const StyledAxisY = styled(AxisY)<{ height: number }>`
  height: ${props => props.height}px;
  width: ${AXIS_Y_WIDTH}px;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column-reverse;
`;

const GraphContainer = styled.div<{
  width: number;
  height: number;
}>`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const HeatMapContainer = styled.div<{
  width: number;
  height: number;
  showLegend?: boolean;
}>`
  ${props =>
    props.showLegend
      ? `display: flex;
    flex-direction: column;
    align-items: flex-end;`
      : 'position: relative;'}
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;
