import React from 'react';
import styled from 'styled-components';

import List from '../../../atoms/List';
import BulletListItem from '../../../atoms/BulletListItem';
import { Color, Size } from '../../../theme/primitives';
import { useBarGraphContext } from '../hooks/BarGraphContext';
import { useBarGraphInteraction } from '../hooks/useBarGraphInteraction';

interface BarGraphInfoWidgetProps {
  formatXValue?: Function;
}

const BarGraphInfoWidget: React.FC<BarGraphInfoWidgetProps> = ({
  formatXValue,
}) => {
  const barGraphInteraction = useBarGraphInteraction();
  const { xProperty } = useBarGraphContext();

  if (
    !barGraphInteraction ||
    !barGraphInteraction.columnData ||
    !barGraphInteraction.isActive
  ) {
    return null;
  }

  const xFeatureColumnValue = barGraphInteraction[xProperty];
  const { columnData } = barGraphInteraction;
  const { label, color, shap, feature_value } = columnData as any;

  return (
    <GraphInfoWidgetContainer>
      <Header>
        <HeaderText>{label}&nbsp;</HeaderText>
      </Header>
      <List>
        <BulletListItem key={label} color={color}>
          <ListItemContent>
            {feature_value ? (
              <>
                <ListItemColumn>{feature_value}</ListItemColumn>
                <ListItemColumn>|</ListItemColumn>
              </>
            ) : null}
            <ListItemColumn>{shap?.toLocaleString()}</ListItemColumn>
          </ListItemContent>
        </BulletListItem>
      </List>
      {formatXValue && (
        <TimestampContainer>
          <Timestamp>{formatXValue(xFeatureColumnValue)}</Timestamp>
        </TimestampContainer>
      )}
    </GraphInfoWidgetContainer>
  );
};

export default BarGraphInfoWidget;

const GraphInfoWidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Size.space200};
  box-sizing: border-box;
  width: auto;

  border: 1px solid ${Color.grey500};
  border-radius: ${Size.radius200};

  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.1);
  background-color: ${Color.white};
`;

const Header = styled.div`
  display: flex;
  border-bottom: 1px solid ${Color.grey400};
  margin-bottom: ${Size.space100};
  height: 35px;
`;

const HeaderText = styled.div`
  font-size: 12px;
  font-weight: 400;
  position: relative;
  display: inline-block;
  height: 15px;
  line-height: 15px;
`;

const ListItemContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: nowrap;
  align-contents: center;
  white-space: nowrap;
  width: auto;
  font-size: 12px;
`;

const ListItemColumn = styled.div`
  display: inline-block;
  margin-left: ${Size.space100};
  position: relative;
  width: auto;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TimestampContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: ${Size.space200};
  font-size: 12px;
  height: 15px;
  line-height: 15px;
`;

const Timestamp = styled.span`
  background-color: ${Color.grey100};
  padding: 2.5px 5px 1px 5px;
  height: 100%;
  font-weight: 600;
  border-radius: 12px;
`;
