import React from 'react';
import styled from 'styled-components';

import { KeyCode } from '../../constants';
import { Color } from '../../theme/primitives';

export interface TabsProps {
  className?: string;
  value: string;
  isActive?: boolean;
  isDisabled?: boolean;
  onSelect?: (tabValue: string) => void;
}

export const Tab: React.FC<TabsProps> = ({
  className,
  value,
  isActive,
  isDisabled,
  children,
  onSelect,
}) => {
  const handleClick = (tabValue = '') => {
    if (isDisabled) {
      return;
    }

    onSelect?.(tabValue);
  };

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLElement>,
    tabValue = '',
  ) => {
    if (isDisabled) {
      return;
    }

    const { key } = event;

    if (key === KeyCode.ENTER) {
      onSelect?.(tabValue);
    }
  };

  return (
    <Container
      className={className}
      isActive={isActive}
      isDisabled={isDisabled}
      tabIndex={isDisabled ? -1 : 0}
      onClick={() => handleClick(value)}
      onKeyPress={event => handleKeyPress(event, value)}
    >
      {children}
    </Container>
  );
};

const Container = styled.div<{
  isDisabled?: boolean;
  isActive?: boolean;
}>`
  padding: 24px 0;
  display: inline-block;
  cursor: pointer;
  position: relative;
  z-index: 2;

  &:hover {
    color: ${Color.grey900};
  }

  &::after {
    content: '';
    width: 0;
    height: 3px;
    background-color: ${Color.teal300};
    border-radius: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: color, width 0.25s ease-in-out;
    z-index: 2;
  }

  ${({ isActive }) =>
    isActive
      ? `
        color: ${Color.grey900};
        &::after {
          width: 100%;
        }
      `
      : ''};
  ${({ isDisabled }) =>
    isDisabled
      ? `
        color: ${Color.grey400};
        pointer-events: none;
        user-select: none;
      `
      : ''}
`;

export default Tab;
