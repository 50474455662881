import { Color } from '../../theme/primitives';

const theme = {
  actionMenu: {
    color: {
      default: {
        background: Color.white,
        text: Color.grey400,
        border: Color.grey400,
      },
    },
    shape: {
      default: {
        padding: '16px 0',
        borderRadius: '8px',
        borderStyle: 'solid',
        borderWidth: '1px',
      },
    },
  },
  actionMenuItem: {
    color: {
      default: {
        text: Color.grey700,
        background: Color.white,
      },
      disabled: {
        text: Color.grey700,
        background: Color.white,
      },
      hover: {
        background: Color.grey100,
      },
      focus: {
        background: Color.grey100,
      },
    },
    shape: {
      default: {
        padding: '8px 24px',
      },
    },
  },
  actionMenuSectionDivider: {
    color: {
      default: {
        border: Color.grey200,
      },
    },
    shape: {
      default: {
        padding: '16px',
        borderStyle: 'solid',
        borderWidth: '1px',
      },
    },
  },
};

export default theme;
