import React from 'react';
import styled from 'styled-components';

import { Color } from '../../theme/primitives';
import Text from '../Text';
import { Icon } from '../Icon';

export interface WarningPanelProps {
  className?: string;
  message: React.ReactNode;
}

const WarningPanel: React.FC<WarningPanelProps> = ({
  className = '',
  message = '',
}) => {
  return (
    <Container className={className}>
      <WarningIcon icon="warning" iconSize="24" />
      {message && <Text variant="default">{message}</Text>}
    </Container>
  );
};

const Container = styled.div`
  border-radius: 4px;
  border: 1px solid ${Color.yellow300};
  background-color: ${Color.yellow100};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  margin: 32px 0;
`;

const WarningIcon = styled(Icon)`
  margin-right: 16px;
  flex-shrink: 0;

  path {
    fill: ${Color.yellow100};
    stroke: ${Color.yellow300};
    stroke-width: 1;

    &:last-of-type {
      stroke: ${Color.yellow300};
    }
  }
`;

export default WarningPanel;
