import React from 'react';
import styled from 'styled-components';

import { useInteraction } from '../../../atoms/GraphInteractionLayer/GraphInteractionContext';

interface MarkerValueDisplayProps {
  className?: string;
  formatValue?: Function;
}

const MarkerValueDisplay: React.FC<MarkerValueDisplayProps> = ({
  className,
  formatValue,
}) => {
  const [interactionState] = useInteraction();

  const displayValue = interactionState?.x ? (interactionState as any).x : null;

  return (
    <MarkerContent className={className}>
      {formatValue ? formatValue(displayValue) : displayValue}
    </MarkerContent>
  );
};

export default MarkerValueDisplay;

const MarkerContent = styled.span`
  display: flex;
  padding: 0;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
`;
