import { SortDirection } from '../constants';

export const getSortedObjectArray = (
  items: any[],
  property: string,
  order: SortDirection = SortDirection.ASC,
) =>
  items.sort((a, b) => {
    // asc or desc, null values are always sorted after
    if (a[property] === null) {
      return 1;
    }

    if (b[property] === null) {
      return -1;
    }

    if (a[property] < b[property]) {
      return order === 'asc' ? -1 : 1;
    }

    if (a[property] > b[property]) {
      return order === 'asc' ? 1 : -1;
    }

    return 0;
  });
