import React from 'react';
import styled from 'styled-components';

import { Size, Color } from '../../theme/primitives';
import Text from '../Text';
import { ActionMenu } from '../../molecules/ActionMenu';

import { Option } from './components';
import { StateOption } from './constants';

export interface StateSelectorProps {
  title?: string;
  selected: StateOption | null;
  options: StateOption[];
  onChange: (option: StateOption) => void;
  className?: string;
}

export const StateSelector: React.FC<StateSelectorProps> = ({
  title,
  selected,
  options,
  onChange,
  className,
}) => {
  const handleOptionChange = (option: StateOption) => {
    onChange?.(option);
  };

  return (
    <StyledActionMenu className={className}>
      <Header>
        <Title>{title}</Title>
        <Divisor />
      </Header>
      {options.map(option => (
        <Option
          key={option.label}
          optionText={option.label}
          isChecked={selected?.value ? option.value === selected.value : false}
          onClick={() => handleOptionChange(option)}
        />
      ))}
    </StyledActionMenu>
  );
};

const StyledActionMenu = styled(ActionMenu)`
  .itemsContainer {
    min-width: 150px;
  }
`;

const Header = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0 ${Size.space300};
`;

const Title = styled(Text)`
  color: ${Color.grey400};
`;

const Divisor = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${Color.grey300};
  border: none;
`;
