export const DEFAULT_FONT_FAMILY = `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
Helvetica, Arial, 'Apple Color Emoji', 'Segoe UI Emoji',
'Segoe UI Symbol', sans-serif`;

export const MONO_FONT_FAMILY = `SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace`;

// DO NOT add any more colors here before checking the Figma Document below
// https://www.figma.com/file/QdzoZi92oK4w6rHgv0j7rb/Guidelines?type=design&node-id=0-1&t=jav46y7APASV0hbg-0
export enum Color {
  // Defaults
  transparent = 'transparent',
  black = '#000',
  white = '#FFFFFF',
  // Grey
  grey050 = '#F9FAFB',
  grey100 = '#F0F2F5',
  grey200 = '#D1D7E0',
  grey300 = '#B2BDCC',
  grey400 = '#94A3B8',
  grey500 = '#6E829E',
  grey600 = '#5C6F8A',
  grey700 = '#47566B',
  grey800 = '#3D4A5C',
  grey900 = '#262D38',
  grey1000 = '#12171B',
  // Red
  red0 = '#FFF0F0',
  red050 = '#FFFAFA',
  red100 = '#FFF0F0',
  red200 = '#FECFCF',
  red300 = '#FBAEAE',
  red400 = '#FF6464',
  red500 = '#EF3A3A',
  red600 = '#DB1212',
  red700 = '#C10A0A',
  red800 = '#A20D0D',
  red900 = '#610909',
  red1000 = '#3B0404',
  // Orange
  orange100 = '#FFF7F0',
  orange200 = '#FFD9BE',
  orange300 = '#FEBB8C',
  orange400 = '#FD954B',
  orange500 = '#D65A00',
  orange600 = '#B84D00',
  orange700 = '#A84100',
  orange800 = '#903700',
  orange900 = '#742D00',
  orange1000 = '#311501',
  // Yellow
  yellow100 = '#FFFBF0',
  yellow200 = '#FCDB79',
  yellow300 = '#F9C31F',
  yellow400 = '#DFA907',
  yellow500 = '#A27A00',
  yellow600 = '#8B6901',
  yellow700 = '#7E5F00',
  yellow800 = '#694E00',
  yellow900 = '#564204',
  yellow1000 = '#271D00',
  // Teal
  teal050 = '#F2FDFC',
  teal100 = '#EAFBFA',
  teal200 = '#8CECE2',
  teal300 = '#4DE1D2',
  teal400 = '#00D4BF',
  teal500 = '#01A796',
  teal600 = '#077C75',
  teal700 = '#086C66',
  teal800 = '#06615C',
  teal900 = '#013733',
  teal1000 = '#01211F',
  // Blue
  blue100 = '#E7F7FF',
  blue200 = '#ABE1FB',
  blue300 = '#73CDF8',
  blue400 = '#3FA6F0',
  blue500 = '#0091E5',
  blue600 = '#0074BF',
  blue700 = '#025FA1',
  blue800 = '#00538A',
  blue900 = '#002D4C',
  blue1000 = '#001119',
  // Purple
  purple100 = '#EAE7FF',
  purple200 = '#A398F0',
  purple300 = '#A398F0',
  purple400 = '#8E81E9',
  purple500 = '#7B6CE5',
  purple600 = '#6756E1',
  purple700 = '#5241CB',
  purple800 = '#4334B3',
  purple900 = '#362A8F',
  purple1000 = '#0E0A29',

  green200 = '#27AE60',
}

export enum Size {
  space0 = '0px',
  space100 = '5px',
  space150 = '8px',
  space200 = '10px',
  space300 = '15px',
  space400 = '20px',
  space500 = '25px',
  space600 = '30px',
  space700 = '35px',
  space800 = '40px',

  radius100 = '2px',
  radius200 = '4px',
  radius300 = '8px',
  radius400 = '12px',

  font100 = '12px',
  font200 = '14px',
  font300 = '16px',
  font400 = '18px',
  font500 = '24px',

  mobile = '720px',
  tablet = '1024px',
  desktop = '1280px',
}

export const addPixelsToSize = (
  pixelIncrement: number,
  currentSize: string,
) => {
  const sizeAsInteger = parseInt(currentSize, 10);
  return `${sizeAsInteger + pixelIncrement}px`;
};
