import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

import { shape } from '../../theme/themeUtils';

type Variant = keyof DefaultTheme['linearProgress']['color'] &
  keyof DefaultTheme['linearProgress']['shape'];

export interface LinearProgressProps {
  value: number;
  variant?: Variant;
  index?: number;
}

export const LinearProgress = ({
  value,
  variant,
  index = 0,
}: LinearProgressProps) => (
  <LinearProgressWrapper variant={variant}>
    <LinearProgressBar index={index} value={value} />
  </LinearProgressWrapper>
);

const LinearProgressWrapper = styled.div<{ variant?: Variant }>`
  ${({ variant, theme }) =>
    css`
      background-color: ${theme.linearProgress.color[variant || 'default']
        .background};
      ${shape(theme.linearProgress.shape[variant || 'default'])};
    `}

  overflow: hidden;
  width: 100%;
`;

const LinearProgressBar = styled.div<LinearProgressProps>`
  height: 100%;
  background-color: ${({ theme, variant }) =>
    theme.linearProgress.color[variant || 'default'].filledBackground};
  width: ${props => `calc(1% * ${props.value})`};
  transition: ${({ index = 0 }) =>
    `width 0.35s cubic-bezier(0.33, 1, 0.68, 1) ${(index * 2) / 100}s`};
`;
