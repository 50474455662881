import { CircleData } from '../PieChartCanvas';

export interface FormattedCircleData extends CircleData {
  normalizedValue: number;
}

const formatPieChartData = (data: CircleData[]): FormattedCircleData[] => {
  const sum = data.reduce((acc, { value }) => acc + value, 0);

  return data.map(d => ({
    ...d,
    normalizedValue: d.value / sum,
  }));
};

export default formatPieChartData;
