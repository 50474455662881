import { Locale } from 'date-fns';
import React from 'react';

export interface CalendarConfig {
  timeRangeOptions: { value: number; label: string }[];
  locale: Locale | undefined;
  translations: Record<
    | 'endDateBeforeStart'
    | 'dateOutsideRange'
    | 'dateInputStart'
    | 'dateInputEnd'
    | 'timePeriodTitle'
    | 'granularityTitle'
    | 'granularityAuto'
    | 'configTitle'
    | 'formatLabel'
    | 'resetButton'
    | 'applyButton'
    | 'customTimePeriod'
    | 'startDateAfterEnd'
    | 'timeFormat24Hours'
    | 'timeFormat12Hours',
    string
  >;
}

export const defaultCalendarConfig: CalendarConfig = {
  locale: undefined,
  timeRangeOptions: [],
  translations: {
    endDateBeforeStart: 'End date cannot be before start date',
    dateOutsideRange: 'Date outside range',
    dateInputStart: 'Start',
    dateInputEnd: 'End',
    timePeriodTitle: 'Time Period',
    granularityTitle: 'Granularity',
    granularityAuto: 'auto',
    configTitle: 'Config',
    formatLabel: 'Format',
    resetButton: 'Reset',
    applyButton: 'Apply',
    customTimePeriod: 'Custom',
    startDateAfterEnd: 'Start date cannot be after end date',
    timeFormat24Hours: '24 hours',
    timeFormat12Hours: '12 hours',
  },
};

const CalendarContext = React.createContext<CalendarConfig>(
  defaultCalendarConfig,
);

export default CalendarContext;
