import React from 'react';
import * as PIXI from 'pixi.js-legacy';
import * as ReactPIXI from '@inlet/react-pixi/legacy';

// Helpers
import formatPieChartData from './helpers/formatPieChartData';

const { Stage, Graphics, Text } = ReactPIXI;

export interface CircleData {
  id: string;
  color: string;
  value: number;
  label?: string;
}

export interface PieChartCanvasProps {
  width: number;
  height: number;
  title?: string;
  description?: string;
  className?: string;
  data: CircleData[];
}

// TODO: Create react component with absolute position in center for
// title/description and reuse atoms/Text component
const titleStyle = new PIXI.TextStyle({
  align: 'center',
  fontSize: 24,
  fontWeight: '700',
  fill: ['#5C6F8A'],
});

const descriptionStyle = new PIXI.TextStyle({
  align: 'center',
  fontSize: 12,
  fontWeight: '400',
  fill: ['#5C6F8A'],
});

// TODO: move into separate package @packages/math-utils
interface Point {
  x: number;
  y: number;
}

const PieChartCanvas: React.FC<PieChartCanvasProps> = ({
  width,
  height,
  className,
  data,
  title,
  description,
}) => {
  const centerPosition: Point = { x: width / 2, y: height / 2 };
  const titlePosition: Point = {
    x: centerPosition.x,
    y: centerPosition.y - 10,
  };
  const descriptionPosition: Point = {
    x: centerPosition.x,
    y: centerPosition.y + 10,
  };

  const formattedData = formatPieChartData(data);

  return (
    <Stage
      width={width}
      height={height}
      className={className}
      options={{
        backgroundAlpha: 0,
        resolution: 2,
        antialias: true,
        autoDensity: true,
      }}
    >
      <Graphics
        draw={g => {
          const colorsData = data.map<string>((d: CircleData) => d.color);
          const radius = Math.min(width, height) / 2;

          formattedData.reduce((startAngle, { normalizedValue }, i) => {
            const endAngle = startAngle + Math.PI * 2 * normalizedValue;

            pieSector(
              g,
              centerPosition,
              radius,
              radius / 2,
              startAngle,
              endAngle,
              PIXI.utils.string2hex(colorsData[i]),
            );

            return endAngle;
          }, -(Math.PI / 2));
        }}
      />
      {title && (
        <Text
          style={titleStyle}
          text={title}
          anchor={0.5}
          x={titlePosition.x}
          y={titlePosition.y}
        />
      )}
      {description && (
        <Text
          style={descriptionStyle}
          text={description}
          anchor={0.5}
          x={descriptionPosition.x}
          y={descriptionPosition.y}
        />
      )}
    </Stage>
  );
};

const pieSector = (
  g: PIXI.Graphics,
  position: Point,
  externalRadius: number,
  internalRadius: number,
  startAngle: number,
  endAngle: number,
  color: number,
): void => {
  g.beginFill(color);

  g.arc(position.x, position.y, externalRadius, startAngle, endAngle);
  g.arc(position.x, position.y, internalRadius, endAngle, startAngle, true);

  g.endFill();
};

export default PieChartCanvas;
