import { createGlobalStyle } from 'styled-components';

import { color, typography } from '../../theme/themeUtils';

const GlobalStyle = createGlobalStyle`
  // Disable the following lines to make scroll work in legacy.
  // The following code should be also removed for enterprise bundle
  // as it was used to make sticky header but it's better to use
  // position: sticky instead

  /* html,
  body,
  #root {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  } */

  body {
    ${typography('default')};
    ${({ theme }) => color(theme.global.color)};

    margin: 0;
    padding: 0;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }
`;

export default GlobalStyle;
