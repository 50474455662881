import { Locale } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

import humanDuration from '../../utils/humanDuration';
import CalendarContext from '../Calendar/CalendarContext';
import { TimePeriodButton, TimePeriodOption } from '../Calendar/utilComponents';
import Text from '../Text';

const TimePeriodGranularityContainer = styled.div`
  width: 160px;

  span {
    margin-left: 10px;
    display: inline-block;
    margin-top: 15px;
  }
`;

const TimePeriodGranularityList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  &:last-of-type {
    margin-top: 5px;
  }
`;

export interface TimePeriodGranularityProp {
  handlerClick: (value: number | null) => void;
  value: number | null;
  locale: Locale;
  options: number[];
  defaultValue: number;
}

export const TimePeriodGranularity: React.FC<TimePeriodGranularityProp> = ({
  handlerClick,
  value: selectedValue,
  locale,
  options,
  defaultValue,
}) => {
  const { translations } = React.useContext(CalendarContext);

  return (
    <TimePeriodGranularityContainer>
      <Text variant="h5">{translations.granularityTitle}</Text>
      <TimePeriodGranularityList>
        {options.map(value => (
          <TimePeriodOption key={value}>
            <TimePeriodButton
              isSelected={selectedValue === value}
              onClick={() => handlerClick(value)}
            >
              {`${humanDuration(value, locale)}${
                value === defaultValue
                  ? ` (${translations.granularityAuto})`
                  : ''
              }`}
            </TimePeriodButton>
          </TimePeriodOption>
        ))}
      </TimePeriodGranularityList>
    </TimePeriodGranularityContainer>
  );
};
