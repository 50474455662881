import React from 'react';
import styled from 'styled-components';

export interface PageLayoutProps {
  header: React.ReactNode;
  content: React.ReactNode;
  navbar?: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = ({
  header: title,
  navbar,
  content,
}) => {
  return (
    <Container>
      <Title>{title}</Title>
      {navbar && <NavBar>{navbar}</NavBar>}
      <Content>{content}</Content>
    </Container>
  );
};

const Container = styled.section``;

const Section = styled.div`
  padding-left: 180px;
  padding-right: 180px;
`;

const Title = styled(Section)`
  padding-top: 60px;
  padding-bottom: 15px;
`;

const NavBar = styled(Section)`
  border-bottom: 1px solid ${({ theme }) => theme.global.color.border};
  border-top: 1px solid ${({ theme }) => theme.global.color.border};
`;

const Content = styled(Section)`
  padding-top: ${({ theme }) => theme.page.shape.padding};

  & > * {
    margin-bottom: ${({ theme }) => theme.page.layout.gap};

    :last-child {
      margin-bottom: 0;
    }
  }
`;
