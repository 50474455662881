import React from 'react';
import styled, { DefaultTheme } from 'styled-components';

import { typography } from '../../../../theme/themeUtils';
import { Icon, ICONS_MAP } from '../../../../atoms/Icon';

type Variant = keyof DefaultTheme['actionMenuItem']['color'] &
  keyof DefaultTheme['actionMenuItem']['shape'];

export interface ActionMenuItemProps {
  className?: string;
  title?: string;
  label: string;
  icon?: keyof typeof ICONS_MAP;
  variant?: Variant;
  onClick: () => void;
  onKeyPress?: () => void;
  isDisabled?: boolean;
}

export const ActionMenuItem: React.FC<ActionMenuItemProps> = ({
  className,
  title,
  icon,
  label,
  variant,
  onClick,
  isDisabled,
  onKeyPress,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (isDisabled) return;
    onClick();
  };

  const handleKeyPress = () => {
    if (isDisabled) return;
    onKeyPress?.();
  };

  return (
    <MenuItem
      className={className}
      title={title}
      variant={variant}
      onKeyPress={handleKeyPress}
      onClick={handleClick}
      tabIndex={0}
      isDisabled={isDisabled}
    >
      {icon && <Icon className="menu-item-icon" iconSize="24" icon={icon} />}
      {label}
    </MenuItem>
  );
};

const MenuItem = styled.div<{
  variant?: Variant;
  isDisabled?: boolean;
}>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  color: ${({ variant, theme, isDisabled }) =>
    isDisabled
      ? theme.actionMenuItem.color.disabled.text
      : theme.actionMenuItem.color[variant || 'default'].text};
  padding: ${({ variant, theme }) =>
    theme.actionMenuItem.shape[variant || 'default'].padding};
  ${typography('default')};
  align-items: center;
  box-sizing: border-box;
  width: 100%;

  &:hover {
    background-color: ${({ theme, isDisabled }) =>
      isDisabled
        ? theme.actionMenuItem.color.disabled.background
        : theme.actionMenuItem.color.hover.background};
  }

  &:focus {
    background-color: ${({ theme, isDisabled }) =>
      isDisabled
        ? theme.actionMenuItem.color.disabled.background
        : theme.actionMenuItem.color.focus.background};
  }
`;
