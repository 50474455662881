/* eslint-disable import/extensions */
import React from 'react';
import styled from 'styled-components';

import Text from '../../atoms/Text';
import { Icon } from '../../atoms/Icon';
import { Color, Size } from '../../theme/primitives';

export interface EmptyGraphViewProps {
  className?: string;
  title?: string;
  description?: string;
}

const EmptyGraphView: React.FC<EmptyGraphViewProps> = ({
  className,
  title,
  description,
}) => {
  if (!title && !description) {
    return null;
  }

  return (
    <Container className={className}>
      <Icon icon="graphWithMagnifier" iconSize="120" />
      {title && <Title variant="h3">{title}</Title>}
      {description && <Description>{description}</Description>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
`;

const Title = styled(Text)`
  text-align: center;
  color: ${Color.grey900};
  margin-top: ${Size.space600};
`;

const Description = styled(Text)`
  margin-top: ${Size.space200};
`;

export default EmptyGraphView;
