import React from 'react';
import styled from 'styled-components';

import { Color } from '../../../../../theme/primitives';
import { Icon } from '../../../../../atoms/Icon';
import { ZOOM_BUTTON_DIAMETER } from '../../zoomConstants';

export const ZoomButton: React.FC = () => (
  <Container>
    <Icon icon="zoomIn" iconSize="24" stroke={Color.white} />
  </Container>
);

const Container = styled.div`
  width: ${ZOOM_BUTTON_DIAMETER}px;
  height: ${ZOOM_BUTTON_DIAMETER}px;
  border-radius: 20px;

  background-color: ${Color.grey900};

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -25%);

  display: flex;
  justify-content: center;
  align-items: center;
`;
