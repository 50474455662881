import React from 'react';
import styled from 'styled-components';

import { Color, Size } from '../../theme/primitives';

export interface ModalErrorProps {
  className?: string;
  error?: string;
}

export const ModalError: React.FC<ModalErrorProps> = ({ className, error }) => (
  <ErrorContainer className={className}>
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </ErrorContainer>
);

const ErrorContainer = styled.div`
  height: ${Size.space400};
`;

const ErrorMessage = styled.div`
  color: ${Color.red500};
`;
