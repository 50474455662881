import { useContext } from 'react';

import { GraphCheckPointsContext } from '../context';
import { CheckpointsContextValue, CheckpointsGraphData } from '../constants';

export const useCheckpointsContext: () => CheckpointsContextValue = () => {
  const checkpointsContext = useContext(GraphCheckPointsContext);

  if (!checkpointsContext) {
    return {
      checkpoints: [] as CheckpointsGraphData[],
      hoveredMarkerId: null,
      activeCheckpointId: null,
    };
  }

  return checkpointsContext;
};
