import React from 'react';
import styled from 'styled-components';

import { Color } from '../../theme/primitives';
import Text from '../Text';

export interface RoundedSelectProps {
  className?: string;
  items?: {
    id: string;
    label: string;
  }[];
  selectedItemId: string;
  onChange?: (id: string) => void;
}

const RoundedSelect: React.FC<RoundedSelectProps> = ({
  className = '',
  items,
  selectedItemId,
  onChange,
}) => {
  return (
    <Container className={className}>
      {items?.map(({ id, label }) => (
        <Option
          key={id}
          variant="default"
          title={label}
          isSelected={selectedItemId === id}
          onClick={() => onChange?.(id)}
        >
          {label}
        </Option>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: inline-flex;
  flex-direction: row;
  border: 1px solid ${Color.grey600};
  border-radius: 50px;
  padding: 4px;
  overflow: hidden;
`;

const Option = styled(Text)<{
  isSelected?: boolean;
}>`
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
  font-weight: ${({ isSelected }) => (isSelected ? 500 : 400)};
  color: ${({ isSelected }) => (isSelected ? Color.white : Color.blue500)};
  background-color: ${({ isSelected }) =>
    isSelected ? Color.blue400 : Color.white};
  z-index: ${({ isSelected }) => (isSelected ? 1 : 0)};
  border-radius: 55px;
  padding: 4px 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;

  & + & {
    margin-left: 8px;
  }

  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? Color.blue400 : Color.blue200};
  }
`;

export default RoundedSelect;
