/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';

import { color } from '../../theme/themeUtils';

export interface TableRowProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  > {
  onClick?: () => void;
}

export const TableRow = React.forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ className, tabIndex, children, onClick, ...props }, ref) => {
    return (
      <StyledTableRow
        className={className}
        tabIndex={tabIndex}
        onClick={onClick}
        {...props}
        ref={ref}
      >
        {children}
      </StyledTableRow>
    );
  },
);

const StyledTableRow = styled.tr<{ height?: number }>`
  ${({ theme }) => color(theme.tableRow.color.default)};

  :nth-child(2n) {
    ${({ theme }) => color(theme.tableRow.color.alternate)};
  }
`;
