import { Color } from '../../theme/primitives';

const theme = {
  searchField: {
    shape: {
      default: {
        padding: '8px 16px',
        borderRadius: '8px',
        borderStyle: 'solid',
        borderWidth: '1px',
      },
    },
    color: {
      default: {
        active: {
          background: Color.white,
          text: Color.grey800,
          border: Color.grey400,
          placeholderText: Color.grey500,
          icon: Color.grey600,
        },
        inactive: {
          background: Color.white,
          text: Color.grey800,
          border: Color.grey400,
          placeholderText: Color.grey500,
          icon: Color.grey600,
        },
        hover: {
          background: Color.white,
          text: Color.grey800,
          border: Color.grey400,
          placeholderText: Color.grey500,
          icon: Color.grey600,
        },
        focus: {
          background: Color.white,
          text: Color.grey800,
          border: Color.grey400,
          placeholderText: Color.grey500,
          icon: Color.grey600,
        },
        disabled: {
          background: Color.white,
          text: Color.grey800,
          border: Color.grey400,
          placeholderText: Color.grey500,
          icon: Color.grey600,
        },
      },
    },
  },
};

export default theme;
