import { Color, Size } from '../../theme/primitives';

const theme = {
  icon: {
    color: {
      default: {
        background: Color.grey500,
      },
    },
    shape: {
      default: {
        borderRadius: Size.radius200,
        width: Size.space500,
        height: Size.space500,
      },
    },
  },
};

export default theme;
