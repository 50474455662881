import React from 'react';
import styled from 'styled-components';

import { OptionsListVariant } from '../theme';
import { Color } from '../../../theme/primitives';
import { isElementOutOfScreen } from '../../../utils/domUtil';
import { clamp, range } from '../../../utils/mathUtil';
import Scroll from '../../../utils/scrollUtil';

import ListOptions from './CustomListOptions';
import ListHandle from './CustomListHandle';

interface ScrollEvent {
  x: number;
  y: number;
  action?: string | null;
  original: (WheelEvent | TouchEvent | KeyboardEvent) | null;
  last: { x: number; y: number };
  touch: { x: number; y: number };
  delta: { x: number; y: number };
}

interface ListOption {
  name?: string;
  value: string;
  label: string;
}

interface Props {
  options: ListOption[];
  width?: number;
  itemHeight: number;
  selected?: string;
  position?: 'top' | 'bottom';
  top?: number;
  open: boolean;
  onChange: (value: string) => void;
  onBlur: (evt: MouseEvent) => void;
  children?: any;
  className?: string;
  dataCy?: string;
  variant?: OptionsListVariant;
  additionalInfoNode?: React.ReactNode;
}

interface State {
  highlight: string;
  index: number;
  listHeight: number;
  totalHeight: number;
  handleHeight: number;
  scroll: {
    handle: number;
    list: number;
  };
  position: {
    last: number;
    current: number;
  };
}

class CustomList extends React.PureComponent<Props, State> {
  ref: { current: null | HTMLElement | any };

  listRef: { current: null | HTMLElement | any };

  scroll: Scroll | any;

  constructor(props: Props) {
    super(props);

    this.scroll = {};
    this.ref = React.createRef<HTMLElement>();
    this.listRef = React.createRef<HTMLElement>();

    this.state = {
      highlight: '',
      index: -1,
      listHeight: 0,
      totalHeight: 0,
      handleHeight: 0,
      scroll: {
        handle: 0,
        list: 0,
      },
      position: {
        last: 0,
        current: 0,
      },
    };
  }

  componentDidMount() {
    const { itemHeight } = this.props;
    this.changeAlignment();

    document.addEventListener('click', this.handleBlur, false);

    const scrollConfig = { keyStep: itemHeight, mouseMult: 0.5 };
    const scrollElement = this.ref.current;

    if (scrollElement) {
      this.scroll = new Scroll(scrollElement, scrollConfig);

      this.scroll.pause = false;
      // $FlowFixMe
      this.scroll.on(this.handleUpdate as () => void);
      this.scroll.addListeners();
    }

    // Setup scrolling state
    this.initialState();
  }

  componentDidUpdate(prevProps: Props) {
    const { options, open, top } = this.props;

    if (prevProps.options.length !== options.length) {
      this.initialState();
    }

    if (this.scroll && this.scroll.pause !== !open) {
      this.scroll.pause = !open;
    }

    if (prevProps.open !== open || prevProps.top !== top) {
      this.changeAlignment();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleBlur, false);
  }

  initialState = () => {
    const { itemHeight, options } = this.props;

    const listHeight = clamp(options.length * itemHeight, 0, 175);
    const totalHeight = options.length * itemHeight;

    let handleHeight =
      (listHeight / (options.length * itemHeight)) * listHeight;
    handleHeight = clamp(handleHeight, 40, 175) || 0;

    this.setState({
      highlight: '',
      index: -1,
      listHeight,
      totalHeight,
      handleHeight,
      scroll: {
        handle: 0,
        list: 0,
      },
      position: {
        last: 0,
        current: 0,
      },
    });

    this.scroll.clampY(true, -totalHeight + listHeight, 0);
  };

  handleUpdate = (event: ScrollEvent) => {
    const { options } = this.props;
    const { listHeight, totalHeight, handleHeight, index } = this.state;

    const min = 0;
    const max = -(totalHeight - listHeight);
    let percent = range(event.y, min, max, 0, 1) || 0;

    let next = index;

    if (index === -1) {
      percent = 0;
      this.scroll.y = 0;
    }

    if (event.action === 'arrowdown') {
      next += 1;

      if (next > options.length - 1) {
        next = 0;
        this.scroll.y = 0;
        percent = 0;
      }
    } else if (event.action === 'arrowup') {
      next -= 1;

      if (next < 0) {
        next = options.length - 1;
        this.scroll.y = max;
        percent = 1;
      }
    }

    this.setState({
      index: next,
      highlight: options[next] ? options[next].value : '',
      scroll: {
        handle: (listHeight - handleHeight - 4) * percent,
        list: this.scroll.y,
      },
    });
  };

  handleMove = (y: number) => {
    const { position, listHeight, totalHeight, handleHeight } = this.state;

    const min = 0;
    const max = listHeight - handleHeight - 2;
    const last = position.last === 0 ? y : position.last;
    const percent = range(clamp(y - last, min, max), min, max, 0, 1);

    this.scroll.y = percent * -(totalHeight - listHeight);

    this.setState({
      position: {
        current: y,
        last,
      },
      scroll: {
        handle: (listHeight - handleHeight - 4) * percent,
        list: this.scroll.y,
      },
    });
  };

  handleItemHover = (hover: { action: string; value: string }) => {
    const { options } = this.props;

    if (hover.action === 'over') {
      const current = options.find(option => option.value === hover.value);
      const value = current ? current.value : '';
      const index = options.findIndex(option => option.value === hover.value);
      this.setState({ highlight: value, index });
    }
  };

  handleBlur = (evt: MouseEvent) => {
    const { onBlur } = this.props;
    const { target = null } = evt;

    if (
      !this.ref.current ||
      this.ref.current === target ||
      // The way to fix this can be found here: https://github.com/facebook/flow/issues/4799
      // However React is deprecating findDOMNode
      // $FlowFixMe
      this.ref.current.contains(target as Node)
    )
      return;

    if (onBlur) onBlur(evt);
  };

  handleKeyDown = (evt: any) => {
    const { key } = evt;
    const { options, onBlur, onChange } = this.props;
    const { index } = this.state;

    if (key === 'Tab' && onBlur) {
      onBlur(evt);
    } else if (key === 'Enter' && onChange) {
      if (options[index]) {
        onChange(options[index].value);
      }
    }
  };

  setTopAlignMent = () => {
    this.listRef.current.style.top = 'unset';
    this.listRef.current.style.bottom = 'calc(100% + 5px)';
  };

  setBottomAlignMent = () => {
    this.listRef.current.style.top = `${this.props.top}px` || '41px';
  };

  changeAlignment = () => {
    const { position, open } = this.props;

    if (!this.listRef.current || !open) {
      return;
    }

    // open to the bottom
    if (position === 'bottom') {
      this.setBottomAlignMent();

      return;
    }

    // open to the top
    if (position === 'top') {
      this.setTopAlignMent();

      return;
    }

    // default behaviour
    this.setBottomAlignMent();

    if (isElementOutOfScreen(this.listRef.current)) {
      this.setTopAlignMent();
    }
  };

  render() {
    const {
      scroll,
      highlight,
      handleHeight,
      listHeight,
      totalHeight,
    } = this.state;

    const {
      width,
      options,
      open,
      children,
      selected,
      onChange,
      className,
      itemHeight,
      dataCy,
      variant = OptionsListVariant.BASE,
      additionalInfoNode,
    } = this.props;

    return (
      <Container
        data-cy={dataCy}
        ref={this.ref}
        onKeyDown={this.handleKeyDown}
        className={className}
      >
        <ViewWrapper
          ref={this.listRef}
          open={open}
          variant={variant}
          width={width}
        >
          <OptionsWrapper
            open={open}
            height={listHeight}
            data-cy="list-wrapper"
          >
            {options.length > 5 && (
              <ListHandle
                height={handleHeight}
                onMove={this.handleMove}
                visible={open}
                y={scroll.handle}
              />
            )}
            <ListOptions
              options={options}
              scroll={scroll.list}
              selected={selected}
              highlight={highlight}
              itemHeight={itemHeight}
              listHeight={listHeight}
              width={width}
              height={totalHeight}
              onHover={this.handleItemHover}
              onChange={onChange}
              variant={variant}
            />
          </OptionsWrapper>
          {additionalInfoNode && (
            <AdditionalInfoContainer>
              <AdditionalInfo isBordered={!!options?.length}>
                {additionalInfoNode}
              </AdditionalInfo>
            </AdditionalInfoContainer>
          )}
        </ViewWrapper>
        {children}
      </Container>
    );
  }
}

interface IViewWrapper {
  open?: boolean;
  variant: OptionsListVariant;
  width?: number;
}

interface IOptionsWrapper {
  width?: number;
  height?: number;
  open?: boolean;
}

const Container = styled.div<{
  width?: number;
}>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  position: relative;
`;

const ViewWrapper = styled.div.attrs<IViewWrapper>(
  ({ open, variant, theme, width }) => ({
    style: {
      display: open ? 'block' : 'none',
      width: width ? `${width}px` : '100%',
      border: `1px solid ${theme?.optionsList?.[variant]?.default
        ?.borderColor || Color.grey300}`,
    },
  }),
)<IViewWrapper>`
  box-sizing: border-box;
  position: absolute;
  top: 48px;
  left: 0;
  z-index: 100;
  border-radius: 4px;
  overflow: hidden;
`;

const OptionsWrapper = styled.div.attrs<IOptionsWrapper>(
  ({ height, open }) => ({
    style: {
      height: open ? `${height}px` : '0',
    },
  }),
)<IOptionsWrapper>`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
`;

const AdditionalInfoContainer = styled.div`
  background-color: ${Color.white};
  padding: 0 16px;
`;

const AdditionalInfo = styled.div<{
  isBordered?: boolean;
}>`
  padding: 8px 0;
  ${({ isBordered }) => (isBordered ? 'border-top: 1px solid grey' : '')};
`;

export default CustomList;
