import React from 'react';

import PieChartInfo from '../../atoms/PieChartInfo';
import Text from '../../atoms/Text';

import PieChartCanvas, { CircleData } from './PieChartCanvas';
import { PieChartLayout } from './templates/PieChartLayout';
import { FormattedCircleData } from './helpers/formatPieChartData';

const CANVAS_WIDTH = 180;
const CANVAS_HEIGHT = 180;

export interface PieChartProps {
  diagonal?: number;
  isLegendHidden?: boolean;
  legendTitle?: string;
  title?: string;
  description?: string;
  data: CircleData[];
  className?: string;
  renderHoveredRow?: (data: FormattedCircleData) => React.ReactNode | null;
}

export const PieChart: React.FC<PieChartProps> = ({
  isLegendHidden,
  legendTitle,
  title,
  description,
  data,
  renderHoveredRow,
  diagonal,
}) => (
  <PieChartLayout
    title={legendTitle ? <Text variant="h4">{legendTitle}</Text> : undefined}
    legend={
      !isLegendHidden ? (
        <PieChartInfo renderHoveredRow={renderHoveredRow} data={data} />
      ) : null
    }
    chart={
      <PieChartCanvas
        title={title}
        description={description}
        data={data}
        width={diagonal || CANVAS_WIDTH}
        height={diagonal || CANVAS_HEIGHT}
      />
    }
  />
);

export * from './PieChartCanvas';
