import { SortDirection } from '../../constants';

export type HeaderState = {
  isHovered: boolean;
  direction: SortDirection;
  sortedColumnId: string;
};

export type ColumnData = {
  id: string;
  label: string;
  width?: number;
  customSorter?: (items: any[], order: 'asc' | 'desc' | 'none') => any[];
};

export type DataReportTableConfig = {
  sourceData: object[];
  columns: ColumnData[];
  itemsPerPage?: number;
  // for server-side pagination/sorting, set these values
  isLoading?: boolean;
  isError?: boolean;
  currentPage?: number;
  sortField?: string;
  sortDirection?: SortDirection;
  onHeaderCellClick?: (columnId: string) => void;
};

export type DataReportTableState = {
  headerState: HeaderState;
  columns: ColumnData[];
  sourceData: object[];
  currentData: object[];
  displayedData: object[];
  currentPage?: number | null;
  itemsPerPage?: number | null;
  handleTableHeaderHover?: () => void;
  handleTableHeaderCellClick?: (columnId: string) => void;
  handlePageChange?: (page: number) => void;
};

export type DataReportProviderValue = {
  tableState: DataReportTableState; // use tableState for client-side pagination/sorting
  handleTableHeaderMouseIn: () => void;
  handleTableHeaderMouseOut: () => void;
  handleTableHeaderCellClick: (columnId: string) => void;
  handlePageChange: (page: number) => void;
  // use these values for server-side pagination/sorting
  isLoading?: boolean;
  isError?: boolean;
  currentPage?: number;
  sortField?: string;
  sortDirection?: SortDirection;
  onHeaderCellClick?: (columnId: string) => void;
};

export const SORT_DIRECTIONS = [
  SortDirection.NONE,
  SortDirection.DESC,
  SortDirection.ASC,
];

export const ROW_HEIGHT = 40;

export type RatioData = {
  label: string;
  count: number;
  color: string;
};
