/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import styled from 'styled-components';

import TableSectionContext, { TableSection } from './TableSectionContext';

export interface TableBodyProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
  > {
  rowsNumber?: number;
  rowHeight?: number;
}

export const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  TableBodyProps
>(({ children, rowsNumber, rowHeight, ...props }, ref) => {
  const [contextValue] = useState<TableSection>({
    variant: 'body',
    rowsNumber,
    rowHeight,
  });

  const height: number | undefined =
    rowsNumber && rowHeight ? rowHeight * rowsNumber : undefined;

  return (
    <StyledTableBody {...props} ref={ref} height={height}>
      <TableSectionContext.Provider value={contextValue}>
        {children}
      </TableSectionContext.Provider>
    </StyledTableBody>
  );
});

const StyledTableBody = styled.tbody<{ height?: number }>``;
