import styled, { DefaultTheme } from 'styled-components';
import { StandardProperties } from 'csstype';

import { typography } from '../../theme/themeUtils';

const Text = styled.span<
  StandardProperties & { variant?: keyof DefaultTheme['typography'] }
>`
  ${({ variant }) => variant && typography(variant)}
`;

export default Text;
