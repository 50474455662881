import { Color, Size } from '../../theme/primitives';

const theme = {
  tooltip: {
    default: {
      borderColor: Color.grey700,
      borderWidth: '1px',
      background: Color.grey700,
      padding: Size.space200,
      text: Color.white,
      arrowHasStroke: false,
    },
    classic: {
      borderColor: Color.grey900,
      borderWidth: '1px',
      background: Color.grey900,
      padding: Size.space150,
      text: Color.white,
      arrowHasStroke: true,
    },
    light: {
      borderColor: Color.grey400,
      borderWidth: '1px',
      background: Color.white,
      padding: Size.space200,
      text: Color.grey600,
      arrowHasStroke: true,
    },
  },
};

export default theme;
