import React, { useState } from 'react';

import { Coordinates } from '../../constants';

import { CoordinatesContext } from './ContextCoordinates';

export const CoordinatesContextProvider: React.FC = ({ children }) => {
  const state = useState<Coordinates | null>(null);

  return (
    <CoordinatesContext.Provider value={state}>
      {children}
    </CoordinatesContext.Provider>
  );
};
