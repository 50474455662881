import React from 'react';
import styled from 'styled-components';

import { Tooltip } from '../../atoms/Tooltip';
import { Color } from '../../theme/primitives';
import { typography } from '../../theme/themeUtils';
import { Icon, ICONS_MAP } from '../../atoms/Icon';

export interface InfoButtonProps {
  text: string;
  docsReference?: string;
  onFocus?: () => void;
  onMouseOver?: () => void;
  icon?: keyof typeof ICONS_MAP;
  className?: string;
  size?: number;
}

export const InfoButton = ({
  text,
  docsReference,
  onFocus,
  onMouseOver,
  icon = 'info',
  className,
  size = 16,
}: InfoButtonProps) => (
  <Tooltip text={text} docsReference={docsReference} maxWidth={300}>
    <ButtonWrapper
      tabIndex={0}
      onFocus={onFocus}
      onMouseOver={onMouseOver}
      className={className}
      size={size}
    >
      <Icon icon={icon} iconSize={`${size}`} />
      <HighlightBackground />
    </ButtonWrapper>
  </Tooltip>
);

const HighlightBackground = styled.div`
  display: none;
  position: absolute;
  border: 2px solid rgba(77, 225, 210, 0.7);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  border-radius: 50%;
  box-sizing: border-box;
`;

const ButtonWrapper = styled.div<{ size: number }>`
  ${typography('default')};
  position: relative;
  box-sizing: border-box;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Color.grey300};
  cursor: pointer;

  & svg {
    pointer-events: none;
    z-index: 1;
  }

  &:hover {
    border-color: ${Color.grey500};
    color: ${Color.grey500};
  }

  &:focus {
    color: ${Color.grey500};
    outline: none;
    ${HighlightBackground} {
      display: block;
    }
  }
`;
