import React from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';

// https://github.com/reactjs/react-modal/issues/627#issuecomment-578814799
const ReactModalAdapter: React.FC<Omit<ReactModal.Props, 'portalClassName'> & {
  modalClassName?: string;
  className?: string;
}> = ({ className, modalClassName, ...props }) => {
  return (
    <ReactModal
      className={modalClassName}
      portalClassName={className}
      bodyOpenClassName="portalOpen"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

const ModalLayout = styled(ReactModalAdapter).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Modal',
})`
  & .Overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    overflow: auto;
  }
  & .Modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: auto;
    outline: none;
    cursor: auto;
    margin: 40px auto;
    max-width: 600px;
  }

  /* &[class*='--after-open'] {
    ...;
  }
  &[class*='--before-close'] {
    ...;
  } */
`;

export default ModalLayout;
