import React, { useRef, useState } from 'react';
import styled, { DefaultTheme } from 'styled-components';

import { Icon } from '../Icon';
import { KeyCode } from '../../constants';
import { colorStates, shape } from '../../theme/themeUtils';
import { DEFAULT_FONT_FAMILY, Color } from '../../theme/primitives';

export interface SearchFieldProps {
  placeholder: string;
  onChange: (value: string) => void;
  className?: string;

  // todo: fix types
  value?: string;
  variant?: keyof DefaultTheme['searchField']['color'];
  isDisabled?: boolean;
  inputRef?: React.MutableRefObject<HTMLInputElement>;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onMouseDown?: (event: React.MouseEvent<HTMLInputElement>) => void;
}

export const SearchField: React.FC<SearchFieldProps> = ({
  variant,
  onChange,
  className,
  value,
  placeholder,
  isDisabled,
  inputRef,
  onFocus,
  onBlur,
  onClear,
  onMouseDown,
}) => {
  const hasValue = !!value && value.length > 0;
  const focusedInputElement = useRef<HTMLInputElement | null>(null);
  const [shouldStyleFocusedIcon, setShouldStyleFocusedIcon] = useState(false);

  const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    focusedInputElement.current = document.activeElement as HTMLInputElement;
    onFocus?.(event);
  };

  const handleInputKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === KeyCode.TAB) {
      setShouldStyleFocusedIcon(true);
    }
  };

  const handleClearvalue = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (!hasValue) return;

    if ((event as React.KeyboardEvent)?.key === KeyCode.ENTER) {
      focusedInputElement?.current?.focus();
    }

    setShouldStyleFocusedIcon(false);
    onChange('');
    onClear?.();
  };

  return (
    <InputWrapper className={className}>
      <Input
        ref={inputRef}
        value={value}
        placeholder={placeholder}
        onFocus={handleInputFocus}
        onBlur={onBlur}
        onChange={event => onChange(event.target.value)}
        onKeyDown={handleInputKeyDown}
        onMouseDown={onMouseDown}
        disabled={isDisabled}
      />
      <SearchIcon
        tabIndex={hasValue ? 0 : undefined}
        icon={hasValue ? 'close' : 'searchMono'}
        iconSize="s"
        variant={variant}
        onClick={handleClearvalue}
        onKeyDown={handleClearvalue}
        isClickable={hasValue && !isDisabled}
        shouldStyleFocusedIcon={shouldStyleFocusedIcon}
      />
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const SearchIcon = styled(Icon)<{
  variant?: keyof DefaultTheme['searchField']['color'];
  isClickable: boolean;
  shouldStyleFocusedIcon: boolean;
}>`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme, variant }) =>
    theme.searchField.color[variant || 'default'].inactive.icon};
  pointer-events: ${({ isClickable }) => (isClickable ? 'auto' : 'none')};
  cursor: pointer;

  ${({ shouldStyleFocusedIcon }) =>
    shouldStyleFocusedIcon
      ? `
        &:focus {
          background-color: ${Color.grey100};
          outline: 3px solid ${Color.grey100};
          border-radius: 50%;
        }
      `
      : `
        &:focus {
          outline: none;
        }
      `}
`;

const Input = styled.input<{
  variant?: keyof DefaultTheme['searchField']['color'];
  disabled?: boolean;
}>`
  box-sizing: border-box;
  width: 100%;
  ${({ theme }) => shape(theme.searchField.shape.default)};
  ${({ theme, variant }) =>
    colorStates(theme.searchField.color[variant || 'default'])}
  background-color: ${({ disabled }) =>
    disabled ? Color.grey100 : Color.white} !important;
  padding-right: 40px;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  &::placeholder {
    color: ${({ theme, variant }) =>
      theme.searchField.color[variant || 'default'].inactive.placeholderText};
  }

  &:focus-visible {
    outline: none;
  }
`;
