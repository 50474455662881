import React from 'react';
import styled from 'styled-components';

import Browser from '../../utils/browserUtil';
import { IconProp } from '../../atoms/Icon';

import { OptionsListVariant } from './theme';
import NativeList from './native/NativeList';
import CustomList from './custom/CustomList';

interface ListOption {
  name?: string;
  value: string;
  label: string;
  icon?: IconProp;
  badge?: React.ReactNode;
}

interface Props {
  options: Array<ListOption>;
  itemHeight?: number;
  position?: 'top' | 'bottom';
  top?: number;
  width?: number;
  selected?: string | null;
  open: boolean;
  className?: string;
  children?: any;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  dataCy?: string;
  variant?: OptionsListVariant;
  additionalInfoNode?: React.ReactNode;
}

interface State {
  value: string;
}

class List extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: props.selected || '',
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { selected } = this.props;
    const { value } = this.state;

    if (prevProps.selected !== selected && selected !== value) {
      this.updateState(selected || '');
    }
  }

  handleChange = (value: string) => {
    const { onChange } = this.props;

    this.updateState(value);
    if (onChange) onChange(value);
  };

  updateState = (value: string) => {
    this.setState({ value });
  };

  render() {
    const {
      options,
      width,
      itemHeight = 35,
      position,
      top,
      open,
      children,
      className,
      dataCy,
      variant,
      onFocus = () => {},
      onBlur = () => {},
      additionalInfoNode,
    } = this.props;
    const { value } = this.state;

    if (Browser.isMobile)
      return (
        <NativeContainer data-cy={dataCy} className={className}>
          <NativeList
            data-cy={dataCy}
            options={options}
            selected={value}
            onChange={this.handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {children}
        </NativeContainer>
      );

    return (
      <CustomList
        width={width}
        dataCy={dataCy}
        options={options}
        itemHeight={itemHeight}
        position={position}
        top={top}
        open={open && !!options?.length}
        selected={value}
        onChange={this.handleChange}
        onBlur={onBlur}
        className={className}
        variant={variant}
        additionalInfoNode={additionalInfoNode}
      >
        {children}
      </CustomList>
    );
  }
}

const NativeContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
`;

export default List;
