import React from 'react';
import styled from 'styled-components';

import { Color } from '../../../theme/primitives';

interface Props {
  height: number;
  visible: boolean;
  y: number;
  onMove: (y: number) => void;
}

interface State {
  isDragging: boolean;
}

class CustomListHandle extends React.PureComponent<Props, State> {
  y: number;

  constructor(props: Props) {
    super(props);

    this.state = {
      isDragging: false,
    };

    this.y = 0;
  }

  componentDidUpdate() {
    const { visible } = this.props;
    const { isDragging } = this.state;

    if (!visible && isDragging) {
      this.handleUp();
    }
  }

  handleDown = () => {
    this.setState({ isDragging: true });
  };

  handleMove = (evt: any) => {
    const { onMove } = this.props;
    const { isDragging } = this.state;

    if (!isDragging) return;
    if (onMove) onMove(evt.clientY);
  };

  handleUp = () => {
    this.setState({ isDragging: false });
  };

  render() {
    const { y = 0, height } = this.props;
    return (
      <Hitbox
        height={height}
        onMouseDown={this.handleDown}
        onMouseMove={this.handleMove}
        onMouseUp={this.handleUp}
        y={y}
      >
        <Handle height={height} />
      </Hitbox>
    );
  }
}

interface IHitbox {
  height?: number;
  y?: number;
}

const Hitbox = styled.div.attrs<IHitbox>(({ height, y }) => ({
  style: {
    height,
    transform: `translateY(${y}px)`,
  },
}))<IHitbox>`
  position: absolute;

  width: 25px;
  pointer-events: none;

  top: 0;
  right: 0;

  z-index: 20;
`;

interface IHandle {
  height?: number;
}

const Handle = styled.div.attrs<IHandle>(({ height }) => ({
  style: {
    height,
  },
}))<IHandle>`
  position: absolute;

  width: 4px;

  border-radius: 8px;

  right: 3px;
  top: 2px;

  background: ${Color.grey400};
  ${Hitbox}:hover & {
    background: ${Color.grey600};
  }
`;

export default CustomListHandle;
