import React, { useState } from 'react';
import { getDate, isToday } from 'date-fns';
import styled, { css } from 'styled-components';
import { enGB as locale } from 'date-fns/locale';

import { DayCellState } from '../../atoms/DateRangePicker/utils';
import { Tooltip } from '../../atoms/Tooltip';

interface DatesDayProps {
  height?: number;
  receivedModifiers: DayCellState;
  onClick: (event: MouseEvent) => void;
  onMouseEnter: (event: MouseEvent) => void;
  onMouseLeave: (event: MouseEvent) => void;
  onTouchEnd: (event: MouseEvent) => void;
}

const DatesDay = styled.span.attrs(
  ({
    receivedModifiers: { selectedMiddle, selectedStart, selectedEnd, disabled },
  }: DatesDayProps) => ({
    selected: selectedMiddle || selectedStart || selectedEnd,
    currentSelected: selectedStart || selectedEnd,
    firstSelectedDate: selectedStart,
    lastSelectedDate: selectedEnd,
    disabled,
  }),
)<DatesDayProps & { selected: boolean }>`
  border-top: 1px solid transparent;
  box-sizing: border-box;
  color: ${({ receivedModifiers, currentSelected }) =>
    `${
      receivedModifiers.outside
        ? '#D1D7E0'
        : currentSelected
        ? '#fff'
        : '#5c6f8a'
    }`};
  font-weight: ${props =>
    `${props.receivedModifiers.today ? 'bold' : 'normal'}`};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed;' : 'pointer;')};
  font-size: 14px;
  position: relative;
  text-align: center;
  width: calc(100% / 7);
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: ${props => `${props.height}px`};

  ${({ disabled, currentSelected }) =>
    disabled
      ? ''
      : css`
          &:hover {
            background-color: #f0f2f5;
            border-radius: 10px;

            &:before {
              background-color: ${`${!currentSelected && '#D1D7E0'}`};
            }
          }
        `}

  &:after,
  &:before {
    bottom: 0;
    top: 0;
    content: '';
    box-sizing: border-box;
    position: absolute;
  }

  &:before {
    z-index: 1;
    background-color: ${({ currentSelected }) =>
      `${currentSelected ? '#5c6f8a' : '#f0f2f5'}`};
    left: ${props => `${props.receivedModifiers.wide ? 12.5 : 0}%`};
    right: ${props => `${props.receivedModifiers.wide ? 12.5 : 0}%`};
    opacity: ${props => `${props.selected ? 1 : 0}`};
    border-top-left-radius: ${({ firstSelectedDate }) =>
      `${firstSelectedDate ? 5 : 0}px`};
    border-bottom-left-radius: ${({ firstSelectedDate }) =>
      `${firstSelectedDate ? 5 : 0}px`};
    border-top-right-radius: ${({ lastSelectedDate }) =>
      `${lastSelectedDate ? 5 : 0}px`};
    border-bottom-right-radius: ${({ lastSelectedDate }) =>
      `${lastSelectedDate ? 5 : 0}px`};
  }

  &:after {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    left: ${props => `${props.receivedModifiers.wide ? 12.5 : 0}%`};
    right: ${props => `${props.receivedModifiers.wide ? 12.5 : 0}%`};
    opacity: ${props => `${props.selected ? 1 : 0}`};
  }
`;

const DatesDayDate = styled.span`
  transition: 150ms color;
  will-change: color;
  z-index: 3;
`;

interface CalendarDayProp {
  date: Date;
  height?: number;
  locale: typeof locale;
  modifiers?: DayCellState;
  onClick?: (value: Date) => void;
  onHover?: (value: Date | null) => void;
  validationMessage?: string;
}

const DEFAULT_TOOLTIP_DELAY = 300;

const CalendarDay: React.FC<CalendarDayProp> = ({
  date,
  height,
  locale,
  modifiers: receivedModifiers,
  onClick,
  onHover,
  validationMessage,
}) => {
  const tooltipDelayRef = React.useRef<number | null>(null);
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const dayOfMonth: number = getDate(date);
  const modifiers: DayCellState = {
    today: isToday(date),
    ...receivedModifiers,
  };

  const handleClick = (): void => {
    if (modifiers.disabled) return;
    onClick!(date);
  };

  const handleClearTooltipDelayTimeout = () => {
    if (tooltipDelayRef.current) {
      clearTimeout(tooltipDelayRef.current);
    }
  };

  const handleMouseEnter = () => {
    if (modifiers.disabled) {
      handleClearTooltipDelayTimeout();

      tooltipDelayRef.current = window.setTimeout(() => {
        setTooltipVisible(true);
      }, DEFAULT_TOOLTIP_DELAY);

      return;
    }

    onHover && onHover(date);
  };
  const handleMouseLeave = () => {
    if (modifiers.disabled) {
      handleClearTooltipDelayTimeout();

      setTooltipVisible(false);
      return;
    }

    onHover && onHover(null);
  };

  return (
    <Tooltip show={isTooltipVisible} controlled text={validationMessage}>
      <DatesDay
        receivedModifiers={modifiers}
        height={height}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchEnd={handleClick}
      >
        <DatesDayDate>{dayOfMonth}</DatesDayDate>
      </DatesDay>
    </Tooltip>
  );
};

export default CalendarDay;
