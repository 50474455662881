import { normalize } from '../../../utils/mathUtils';

import { Legend } from './types';

/* eslint-disable import/prefer-default-export */
export function getColor(value: number, { palette, min, max }: Legend): string {
  const index = Math.round((palette.length - 1) * normalize(min, max, value));

  return palette[index];
}
