import React from 'react';
import styled from 'styled-components';

import { Color } from '../../theme/primitives';

interface TableHeaderColumnProps {
  label: string;
  className?: string;
}

export const TableHeaderColumn = ({
  label,
  className,
}: TableHeaderColumnProps) => (
  <ColumnDiv className={className}>{label}</ColumnDiv>
);

const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  color: ${Color.grey600};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  user-select: none;
`;

export default TableHeaderColumn;
