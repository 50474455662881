import User from 'shared/models/User';

export const arePasskeysBrowserSupported = async () => {
  const publicKeyCredential = window.PublicKeyCredential;

  if (!publicKeyCredential) {
    return false;
  }

  const availableMethods = await Promise.all([
    publicKeyCredential?.isUserVerifyingPlatformAuthenticatorAvailable?.(),
    // @ts-ignore TODO: fix type
    publicKeyCredential?.isConditionalMediationAvailable?.(),
  ]);

  return availableMethods.every(Boolean);
};

export const arePasskeysSsoRestricted = () => {
  return User.isSsoUser();
};

export const arePasskeysZoneRestricted = () => {
  return User.isZoneRestrictedAccount();
};

export const arePasskeysSharedAccountRestricted = () => {
  return User.isSharedAccount();
};

export const arePasskeysRestricted = () => {
  return (
    arePasskeysSsoRestricted() ||
    arePasskeysZoneRestricted() ||
    arePasskeysSharedAccountRestricted()
  );
};

export const isPasskeyPreviouslyRegistered = error => {
  return (
    error.name === 'InvalidStateError' &&
    error.code === 'ERROR_AUTHENTICATOR_PREVIOUSLY_REGISTERED'
  );
};

export const navigateToVerifyLogin = () => {
  const { origin, search } = new URL(window.location.href);

  // take into account backUrl query param to redirect the user to Docs or other pages
  window.location.href = `${origin}/verify-login${search}`;
};
