import React from 'react';
import styled from 'styled-components';

export interface PageContentProps {
  className?: string;
  children: React.ReactNode;
}

export const PageContent: React.FC<PageContentProps> = ({
  className,
  children,
}) => {
  return <Container className={className}>{children}</Container>;
};

const Container = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden auto;

  @media (min-width: 738px) {
    width: 610px;
  }

  @media (min-width: 1000px) {
    width: 804px;
  }

  @media (min-width: 1200px) {
    width: 1120px;
  }
`;

export default PageContent;
