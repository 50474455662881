import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { InputSlider } from '../../atoms/InputSlider';
import { InfoButton } from '../../molecules/InfoButton';
import { Color } from '../../theme/primitives';

const KEYBOARD_KEY = {
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_LEFT: 'ArrowLeft',
};

const Focus = css`
  box-shadow: 0 0 0 2px rgba(77, 225, 210, 0.7);
`;

const TextInput = styled.input`
  border: 1px solid ${Color.grey300};
  width: 35px;
  height: 20px;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  color: ${Color.grey600};

  &:focus {
    outline: none;
    ${Focus}
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const SliderHeader = styled.div<{
  width: number;
  buffer: number;
  align: string;
}>`
  display: flex;
  width: ${({ width }) => width}px;
  justify-content: space-between;
  align-items: ${({ align }) => align};
  height: ${({ buffer }) => buffer + 30}px;
  position: relative;
`;

const SliderContainer = styled.div<{
  width: number;
}>`
  width: ${({ width }) => width}px;
  position: relative;
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

const Description = styled.span`
  font-size: 12px;
  font-weight: 400;
  height: 15px;
  line-height: 15px;
`;

const DefaultValues = styled.div<{
  width: number;
}>`
  display: flex;
  width: ${({ width }) => width}px;
  justify-content: space-between;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TooltipWrapper = styled.div`
  margin-left: 10px;
`;

const MinValue = styled.div``;
const MaxValue = styled.div``;

export interface SliderProps {
  min?: number;
  max?: number;
  value?: number;
  step?: number;
  label?: string;
  tooltip?: string;
  description?: string;
  onChange: (value: number) => void;
  width?: number;
  className?: string;
  isDisabled?: boolean;
}

export const Slider: React.FC<SliderProps> = ({
  step,
  min = 0,
  max = 100,
  value,
  label,
  tooltip,
  description,
  width = 200,
  onChange,
  className,
  isDisabled,
}) => {
  const [current, setCurrent] = useState<number>(value || min);

  const handleChange = (number: number) => {
    let inputValue = number < min ? min : number;

    if (inputValue > max) {
      inputValue = max;
    }

    setCurrent(inputValue);
    onChange && onChange(inputValue);
  };

  const handleTextInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = Number(event.target.value);

    if (Number.isNaN(inputValue)) {
      return;
    }

    handleChange(inputValue);
  };

  const handleTextInputKeyUp = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const { key } = event;

    let increment = 0;

    if (key === KEYBOARD_KEY.ARROW_UP || key === KEYBOARD_KEY.ARROW_RIGHT) {
      increment = 1;
    }

    if (key === KEYBOARD_KEY.ARROW_LEFT || key === KEYBOARD_KEY.ARROW_DOWN) {
      increment = -1;
    }

    const number = current + increment;

    handleChange(number);
  };

  return (
    <SliderContainer width={width} className={className}>
      <SliderHeader
        width={width}
        buffer={description ? 15 : 0}
        align={description ? 'baseline' : 'center'}
      >
        <HeaderText>
          <Title>
            <Label>{label}</Label>
            {tooltip && (
              <TooltipWrapper>
                <InfoButton text={tooltip} />
              </TooltipWrapper>
            )}
          </Title>
          {description && <Description>{description}</Description>}
        </HeaderText>
        <TextInput
          disabled={isDisabled}
          value={current.toString()}
          onChange={handleTextInputChange}
          min={min}
          max={max}
          aria-label="range-input"
          onKeyUp={handleTextInputKeyUp}
        />
      </SliderHeader>
      <InputSlider
        min={min}
        max={max}
        step={step}
        value={current}
        isDisabled={isDisabled}
        onChange={handleChange}
      />
      <DefaultValues width={width}>
        <MinValue>{min}</MinValue>
        <MaxValue>{max}</MaxValue>
      </DefaultValues>
    </SliderContainer>
  );
};
