import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Color, Size } from '../../theme/primitives';

export interface ModalActionsProps {
  className?: string;
  children: ReactNode;
}

export const ModalActions: React.FC<ModalActionsProps> = ({
  className,
  children,
}) => (
  <Wrapper>
    <Container className={className}>{children}</Container>
  </Wrapper>
);

const Wrapper = styled.div`
  width: auto;
  margin-top: ${Size.space400};
  height: ${Size.space800};
  background: ${Color.white};
`;

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  justify-content: flex-end;

  & > * {
    margin-left: ${Size.space200};
  }

  & > *:first-child {
    margin-left: 0;
  }

  @media (max-width: ${Size.mobile}) {
    display: relative;
    margin-top: ${Size.space600};
  }
`;
