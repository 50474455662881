import React from 'react';
import styled from 'styled-components';

import { Color, Size } from '../../theme/primitives';

export interface ModalTitleProps {
  className?: string;
}

export const ModalTitle: React.FC<ModalTitleProps> = ({
  children,
  className,
}) => <Container className={className}>{children}</Container>;

const Container = styled.div`
  font-size: ${Size.font400};
  font-weight: 400;
  color: ${Color.grey600};
  margin-bottom: ${Size.space500};
`;
