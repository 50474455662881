import React, { AllHTMLAttributes } from 'react';
import styled from 'styled-components';

import { color, shape } from '../../theme/themeUtils';

import { createIconFromSvg, IconProps } from './iconCreator';
import { ReactComponent as Avatar } from './IconImages/avatar.svg';
import { ReactComponent as CheckMark } from './IconImages/checkMark.svg';
import { ReactComponent as ArrowDown } from './IconImages/arrowDown.svg';
import { ReactComponent as ArrowDownAlt } from './IconImages/arrowDownAlt.svg';
import { ReactComponent as ArrowDownStroked } from './IconImages/arrowDownStroked.svg';
import { ReactComponent as ArrowRight } from './IconImages/arrowRight.svg';
import { ReactComponent as Bookmark } from './IconImages/bookmark.svg';
import { ReactComponent as BookmarkPlus } from './IconImages/bookmark-plus.svg';
import { ReactComponent as Settings } from './IconImages/settings.svg';
import { ReactComponent as ConfirmationLetter } from './IconImages/confirmationLetter.svg';
import { ReactComponent as Download } from './IconImages/download.svg';
import { ReactComponent as DownloadAlternate } from './IconImages/downloadAlternate.svg';
import { ReactComponent as DropDownArrowUp } from './IconImages/dropDownArrowUp.svg';
import { ReactComponent as DropDownArrowDown } from './IconImages/dropDownArrowDown.svg';
import { ReactComponent as TooltipArrow } from './IconImages/tooltipArrow.svg';
import { ReactComponent as Info } from './IconImages/info.svg';
import { ReactComponent as InfoAlt } from './IconImages/infoAlt.svg';
import { ReactComponent as SuccessCheckmark } from './IconImages/successCheckmark.svg';
import { ReactComponent as SecretKey } from './IconImages/secretKey.svg';
import { ReactComponent as SuccessCheckmarkAlternate } from './IconImages/successCheckmarkAlternate.svg';
import { ReactComponent as Refresh } from './IconImages/refresh.svg';
import { ReactComponent as Close } from './IconImages/close.svg';
import { ReactComponent as CloseAlt } from './IconImages/closeAlt.svg';
import { ReactComponent as Dash } from './IconImages/dash.svg';
import { ReactComponent as LightningStrike } from './IconImages/lightningStrike.svg';
import { ReactComponent as Lightning } from './IconImages/lightning.svg';
import { ReactComponent as DoubleLightning } from './IconImages/doubleLightning.svg';
import { ReactComponent as Warning } from './IconImages/warning.svg';
import { ReactComponent as ArrowUpRight } from './IconImages/arrowUpRight.svg';
import { ReactComponent as Check } from './IconImages/check.svg';
import { ReactComponent as Archive } from './IconImages/archive.svg';
import { ReactComponent as Minus } from './IconImages/minus.svg';
import { ReactComponent as Plus } from './IconImages/plus.svg';
import { ReactComponent as PlayCircle } from './IconImages/playCircle.svg';
import { ReactComponent as CloseCircle } from './IconImages/closeCircle.svg';
import { ReactComponent as Rotate } from './IconImages/rotate.svg';
import { ReactComponent as HelpCircle } from './IconImages/helpCircle.svg';
import { ReactComponent as Sort } from './IconImages/sort.svg';
import { ReactComponent as ChevronRight } from './IconImages/chevronRight.svg';
import { ReactComponent as ChevronShiftRight } from './IconImages/chevronShiftRight.svg';
import { ReactComponent as Search } from './IconImages/search.svg';
import { ReactComponent as DotsVertical } from './IconImages/dots-vertical.svg';
import { ReactComponent as Trash } from './IconImages/trash.svg';
import { ReactComponent as Decode } from './IconImages/decode.svg';
import { ReactComponent as Copy } from './IconImages/copy.svg';
import { ReactComponent as CopyAlternate } from './IconImages/copyAlternate.svg';
import { ReactComponent as Filter } from './IconImages/filter.svg';
import { ReactComponent as Blocked } from './IconImages/blocked.svg';
import { ReactComponent as LightningStrikeLight } from './IconImages/lightningStrikeLight.svg';
import { ReactComponent as ArrowRightAlternate } from './IconImages/arrowRightAlternate.svg';
import { ReactComponent as GraphWithMagnifier } from './IconImages/graphWithMagnifier.svg';
import { ReactComponent as Eye } from './IconImages/eye.svg';
import { ReactComponent as XIcon } from './IconImages/x.svg';
import { ReactComponent as XIconAlternate } from './IconImages/xAlternate.svg';
import { ReactComponent as CircleSmall } from './IconImages/circle-small.svg';
import { ReactComponent as BellOff } from './IconImages/bell-off.svg';
import { ReactComponent as Flag } from './IconImages/flag.svg';
import { ReactComponent as NewTabLink } from './IconImages/newTabLink.svg';
import { ReactComponent as Bell } from './IconImages/bell.svg';
import { ReactComponent as ThreatXRay } from './IconImages/threatXRay.svg';
import { ReactComponent as Insights } from './IconImages/insights.svg';
import { ReactComponent as Clock } from './IconImages/clock.svg';
import { ReactComponent as ClockCheck } from './IconImages/clock-check.svg';
import { ReactComponent as Pencil } from './IconImages/pencil.svg';
import { ReactComponent as SearchMono } from './IconImages/search-mono.svg';
import { ReactComponent as AlignLeft } from './IconImages/align-left.svg';
import { ReactComponent as Code } from './IconImages/code.svg';
import { ReactComponent as Calendar } from './IconImages/calendar.svg';
import { ReactComponent as User } from './IconImages/user.svg';
import { ReactComponent as CopyThin } from './IconImages/copy-thin.svg';
import { ReactComponent as Image } from './IconImages/image.svg';
import { ReactComponent as Audit } from './IconImages/audit.svg';
import { ReactComponent as History } from './IconImages/history.svg';
import { ReactComponent as TryItout } from './IconImages/tryItout.svg';
import { ReactComponent as WarningRoundedYellow } from './IconImages/warningRoundedYellow.svg';
import { ReactComponent as WarningCircle } from './IconImages/warningCircle.svg';
import { ReactComponent as WarningOutlined } from './IconImages/warningOutlined.svg';
import { ReactComponent as Maximize } from './IconImages/maximize.svg';
import { ReactComponent as Minimize } from './IconImages/minimize.svg';
import { ReactComponent as DrillDown } from './IconImages/drillDown.svg';
import { ReactComponent as DrillDownSlick } from './IconImages/drillDownSlick.svg';
import { ReactComponent as ZoomIn } from './IconImages/zoomIn.svg';
import { ReactComponent as Passkey } from './IconImages/passkey.svg';
import { ReactComponent as Edit } from './IconImages/edit.svg';
import { ReactComponent as EditAlt } from './IconImages/editAlt.svg';
import { ReactComponent as Password } from './IconImages/password.svg';
import { ReactComponent as RateLimit } from './IconImages/speedometer.svg';
import { ReactComponent as UserX } from './IconImages/userX.svg';
import { ReactComponent as Safety } from './IconImages/safety.svg';
import { ReactComponent as Logout } from './IconImages/logout.svg';
import { ReactComponent as FavoriteSeleted } from './IconImages/favoriteSelected.svg';
import { ReactComponent as FavoriteUnselected } from './IconImages/favoriteUnselected.svg';
import { ReactComponent as ChevronUp } from './IconImages/chevronUp.svg';
import { ReactComponent as ChevronDown } from './IconImages/chevronDown.svg';
import { ReactComponent as Document } from './IconImages/document.svg';
import { ReactComponent as ArrowRightRounded } from './IconImages/arrowRightRounded.svg';
import { ReactComponent as Crosshair } from './IconImages/crosshair.svg';
import { ReactComponent as LinkExpired } from './IconImages/link-expired.svg';
import { ReactComponent as VerificationExpired } from './IconImages/expiredVerification.svg';
import { ReactComponent as VerificationLinkExpired } from './IconImages/verificationLinkExpired.svg';
import { ReactComponent as ArrowDownBold } from './IconImages/arrowDownBold.svg';

export const ICONS_MAP = {
  avatar: createIconFromSvg(Avatar),
  checkMark: createIconFromSvg(CheckMark),
  arrowDown: createIconFromSvg(ArrowDown),
  arrowDownAlt: createIconFromSvg(ArrowDownAlt),
  arrowDownStroked: createIconFromSvg(ArrowDownStroked),
  arrowRight: createIconFromSvg(ArrowRight),
  bookmark: createIconFromSvg(Bookmark),
  bookmarkPlus: createIconFromSvg(BookmarkPlus),
  settings: createIconFromSvg(Settings),
  download: createIconFromSvg(Download),
  downloadAlternate: createIconFromSvg(DownloadAlternate),
  dropDownArrowUp: createIconFromSvg(DropDownArrowUp),
  dropDownArrowDown: createIconFromSvg(DropDownArrowDown),
  confirmationLetter: createIconFromSvg(ConfirmationLetter),
  tooltipArrow: createIconFromSvg(TooltipArrow),
  info: createIconFromSvg(Info),
  infoAlt: createIconFromSvg(InfoAlt),
  successCheckmark: createIconFromSvg(SuccessCheckmark),
  secretKey: createIconFromSvg(SecretKey),
  successCheckmarkAlternate: createIconFromSvg(SuccessCheckmarkAlternate),
  refresh: createIconFromSvg(Refresh),
  close: createIconFromSvg(Close),
  closeAlt: createIconFromSvg(CloseAlt),
  dash: createIconFromSvg(Dash),
  lightningStrike: createIconFromSvg(LightningStrike),
  lightning: createIconFromSvg(Lightning),
  doubleLightning: createIconFromSvg(DoubleLightning),
  warning: createIconFromSvg(Warning),
  arrowUpRight: createIconFromSvg(ArrowUpRight),
  check: createIconFromSvg(Check),
  archive: createIconFromSvg(Archive),
  minus: createIconFromSvg(Minus),
  plus: createIconFromSvg(Plus),
  playCircle: createIconFromSvg(PlayCircle),
  closeCircle: createIconFromSvg(CloseCircle),
  rotate: createIconFromSvg(Rotate),
  helpCircle: createIconFromSvg(HelpCircle),
  sort: createIconFromSvg(Sort),
  chevronShiftRight: createIconFromSvg(ChevronShiftRight),
  chevronRight: createIconFromSvg(ChevronRight),
  search: createIconFromSvg(Search),
  dotsVertical: createIconFromSvg(DotsVertical),
  trash: createIconFromSvg(Trash),
  decode: createIconFromSvg(Decode),
  copy: createIconFromSvg(Copy),
  copyAlternate: createIconFromSvg(CopyAlternate),
  filter: createIconFromSvg(Filter),
  blocked: createIconFromSvg(Blocked),
  lightningStrikeLight: createIconFromSvg(LightningStrikeLight),
  arrowRightAlternate: createIconFromSvg(ArrowRightAlternate),
  graphWithMagnifier: createIconFromSvg(GraphWithMagnifier),
  eye: createIconFromSvg(Eye),
  xIcon: createIconFromSvg(XIcon),
  xIconAlternate: createIconFromSvg(XIconAlternate),
  circleSmall: createIconFromSvg(CircleSmall),
  bellOff: createIconFromSvg(BellOff),
  flag: createIconFromSvg(Flag),
  newTabLink: createIconFromSvg(NewTabLink),
  bell: createIconFromSvg(Bell),
  threatXRay: createIconFromSvg(ThreatXRay),
  insights: createIconFromSvg(Insights),
  clock: createIconFromSvg(Clock),
  clockCheck: createIconFromSvg(ClockCheck),
  pencil: createIconFromSvg(Pencil),
  searchMono: createIconFromSvg(SearchMono),
  alignLeft: createIconFromSvg(AlignLeft),
  code: createIconFromSvg(Code),
  calendar: createIconFromSvg(Calendar),
  user: createIconFromSvg(User),
  copyThin: createIconFromSvg(CopyThin),
  image: createIconFromSvg(Image),
  audit: createIconFromSvg(Audit),
  history: createIconFromSvg(History),
  tryItout: createIconFromSvg(TryItout),
  warningRoundedYellow: createIconFromSvg(WarningRoundedYellow),
  warningCircle: createIconFromSvg(WarningCircle),
  warningOutlined: createIconFromSvg(WarningOutlined),
  maximize: createIconFromSvg(Maximize),
  minimize: createIconFromSvg(Minimize),
  drillDown: createIconFromSvg(DrillDown),
  drillDownSlick: createIconFromSvg(DrillDownSlick),
  zoomIn: createIconFromSvg(ZoomIn),
  passkey: createIconFromSvg(Passkey),
  edit: createIconFromSvg(Edit),
  editAlt: createIconFromSvg(EditAlt),
  password: createIconFromSvg(Password),
  rateLimit: createIconFromSvg(RateLimit),
  userX: createIconFromSvg(UserX),
  safety: createIconFromSvg(Safety),
  logout: createIconFromSvg(Logout),
  favoriteSelected: createIconFromSvg(FavoriteSeleted),
  favoriteUnselected: createIconFromSvg(FavoriteUnselected),
  chevronUp: createIconFromSvg(ChevronUp),
  chevronDown: createIconFromSvg(ChevronDown),
  document: createIconFromSvg(Document),
  arrowRightRounded: createIconFromSvg(ArrowRightRounded),
  crosshair: createIconFromSvg(Crosshair),
  linkExpired: createIconFromSvg(LinkExpired),
  verificationExpired: createIconFromSvg(VerificationExpired),
  verificationLinkExpired: createIconFromSvg(VerificationLinkExpired),
  arrowDownBold: createIconFromSvg(ArrowDownBold),
};

export type IconProp = keyof typeof ICONS_MAP;

type Props = {
  icon: IconProp;
} & IconProps &
  AllHTMLAttributes<SVGElement>;

export const IconPlaceholder = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidator) => defaultValidator(prop),
})`
  ${({ theme }) => shape(theme.icon.shape.default)};
  ${({ theme }) => color(theme.icon.color.default)};
`;

export const Icon = (props: Props) => {
  const { icon } = props;
  const IconComponent = ICONS_MAP[icon] as React.ComponentType<
    Omit<Props, 'icon'>
  >;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <IconComponent {...props} />;
};
