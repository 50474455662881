import React, { useState } from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';

import { HorizontalHandle } from '../../../charts/atoms/graph-zoom/crop/elements/InterfaceHandle';
import { Color } from '../../../theme/primitives';
import { AXIS_Y_WIDTH } from '../constants';

const StyledStageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const HorizontalLine = styled.div<{ top: number; width: number }>`
  position: absolute;
  width: ${props => props.width - AXIS_Y_WIDTH}px;
  top: ${props => props.top}px;
  height: 2px;
  margin-left: ${AXIS_Y_WIDTH}px;
  background-color: ${Color.grey800};
`;

const DraggedSection = styled.div<{ width: number }>`
  position: absolute;
  height: 20px; // More convenient dragging to be higher than line
  width: 100%;
`;

const ThresholdHandle = styled(HorizontalHandle)<{
  maxY: number;
  thresholdMaxPercentage: number;
}>`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: ${({ maxY, thresholdMaxPercentage }) =>
    `calc(${maxY * thresholdMaxPercentage}px - 6px)`};
`;

export function ThresholdLine(props: {
  maxX: number;
  maxY: number;
  threshold: number;
  maxYValue: number;
  thresholdTooltip?: string;
  onThresholdChange: any;
}) {
  const thresh = Math.min(props.threshold, props.maxYValue);
  const [localThreshold, setLocalThreshold] = useState(thresh);
  const [isDragging, setIsDragging] = useState(false);

  const thresholdMaxPercentage = 1 - thresh! / props.maxYValue;

  const handleStart = (e: any, data: { y: any }) => {
    setIsDragging(true);
  };

  const handleDrag = (e: any, data: { y: any }) => {
    const newThreshold = -data.y / props.maxY;
    const newValue = Math.floor(newThreshold * props.maxYValue);
    setLocalThreshold(newValue);
  };

  const handleStop = (e: any, data: { y: any }) => {
    props.onThresholdChange({
      threshold: Math.max(thresh + localThreshold, 0),
      setFromBar: true,
      defaultState: false,
    });
    setIsDragging(false);
  };

  return (
    <StyledStageWrapper>
      <Draggable
        axis="y"
        bounds={{
          bottom: (thresh / props.maxYValue) * props.maxY,
          top: -(props.maxY - (thresh / props.maxYValue) * props.maxY),
        }}
        onStart={handleStart}
        onDrag={handleDrag}
        onStop={handleStop}
      >
        <DraggedSection width={props.maxX}>
          <HorizontalLine
            width={props.maxX}
            top={props.maxY * thresholdMaxPercentage}
          />
          <ThresholdHandle
            className="handle"
            isDragging={isDragging}
            thresholdTooltip={props.thresholdTooltip}
            maxY={props.maxY}
            thresholdMaxPercentage={thresholdMaxPercentage}
          />
        </DraggedSection>
      </Draggable>
    </StyledStageWrapper>
  );
}

export default ThresholdLine;
