import * as PIXI from 'pixi.js-legacy';
import React, { useCallback, useContext, useState } from 'react';
import * as ReactPIXI from '@inlet/react-pixi/legacy';

import { useCanvasCoordinates, useMouseEvent } from '../../hooks';
import { Cursor, MouseEventType } from '../../constants';
import { lerp, normalize } from '../../utils/mathUtils';
import { useInteraction } from '../../atoms/GraphInteractionLayer/GraphInteractionContext';
import { ChartAreaContext } from '../../charts/atoms/ChartAreaProvider/ChartAreaContext';
import AxisYGuideLines from '../../charts/atoms/AxisYGuideLines';
import {
  useZoomInteraction,
  ZOOM_CANVAS_CLASS_NAME,
} from '../../charts/atoms/graph-zoom';

import HeatMapDataContext from './HeatMapStateProvider/HeatMapDataContext';
import { getColor } from './HeatMapStateProvider/utils';

const { Stage, Graphics, Container } = ReactPIXI;

export interface MatrixProps {
  width: number;
  height: number;
  className?: string;
}

export const Matrix: React.FC<MatrixProps> = ({ width, height, className }) => {
  const [, setInteractionState] = useInteraction();
  const zoomInteractionContext = useZoomInteraction();
  const zoomInteractionState = zoomInteractionContext?.[0];

  const [currentMouseEvent, setCurrentMouseEvent] = useMouseEvent();
  const [, setCoordinates] = useCanvasCoordinates();

  const area = useContext(ChartAreaContext);
  const state = useContext(HeatMapDataContext);
  const [isHovered, setIsHovered] = useState(false);

  const handleHoverWidgetDisplay = (event: PIXI.InteractionEvent) => {
    if (!isHovered) {
      setInteractionState(null);
      return;
    }

    const x = lerp(area.x.min, area.x.max, event.data.global.x / width);
    const y = lerp(area.y.max, area.y.min, event.data.global.y / height);

    setInteractionState({ x, y, graphId: state.graphId });
  };

  const handleMouseMove = (event: PIXI.InteractionEvent) => {
    const { x, y } = event.data.global;

    const isMouseActive = currentMouseEvent?.isActive;

    setCoordinates({ x, y });
    setCurrentMouseEvent({
      eventType: MouseEventType.MOVE,
      isActive: isMouseActive || false,
    });

    const cropWidgetBounds = zoomInteractionState?.bounds;

    if (!cropWidgetBounds && !isMouseActive) {
      handleHoverWidgetDisplay(event);
    }
  };

  const handleMouseDown = () => {
    if (!zoomInteractionContext) {
      return;
    }

    setCurrentMouseEvent({ eventType: MouseEventType.DOWN, isActive: true });

    setInteractionState(null);
  };

  const handleMouseUp = (event: PIXI.InteractionEvent) => {
    if (!zoomInteractionContext) {
      return;
    }

    // if zoom interaction context is setup but state is empty, show hover widget
    if (!zoomInteractionState?.bounds) {
      handleHoverWidgetDisplay(event);
    }

    setCurrentMouseEvent({ eventType: MouseEventType.UP, isActive: false });
  };

  const draw = useCallback(
    (graphics: PIXI.Graphics) => {
      const { cells } = state;

      const cellWidthNormalized = area.x.step! / (area.x.max - area.x.min);
      const cellHeightNormalized = area.y.step! / (area.y.max - area.y.min);

      const cellWidth = cellWidthNormalized * width;
      const cellHeight = cellHeightNormalized * width;

      const isGridShown = cellWidth > 13 && cellHeight > 13;

      const pixelX = 1 / width;
      const pixelY = 1 / height;

      graphics.clear();

      cells.forEach((column, x) => {
        column.forEach((value, y) => {
          const color = getColor(value, state.legend);
          const xPos = normalize(area.x.min, area.x.max, x);
          const yPos = normalize(area.y.min, area.y.max, y);

          graphics.beginFill(PIXI.utils.string2hex(color));
          graphics.drawRect(
            xPos,
            yPos,
            isGridShown
              ? cellWidthNormalized - pixelX
              : cellWidthNormalized + 0.5 * pixelX,
            isGridShown
              ? cellHeightNormalized - pixelY
              : cellHeightNormalized + 0.5 * pixelY,
          );
          graphics.endFill();
        });
      });
    },
    [state, area, width, height],
  );

  return (
    <Stage
      options={{
        backgroundAlpha: 0,
        autoDensity: true,
        antialias: true,
      }}
      width={width}
      height={height}
      className={
        zoomInteractionContext
          ? `${className} ${ZOOM_CANVAS_CLASS_NAME}`
          : className
      }
      raf={false}
      onMount={app => {
        // eslint-disable-next-line no-param-reassign
        app.renderer.plugins.interaction.moveWhenInside = true;
      }}
      onMouseOut={() => {
        setInteractionState(null);
        setIsHovered(false);
      }}
      onMouseOver={() => {
        setIsHovered(true);
      }}
      renderOnComponentChange
    >
      <Container
        interactive
        hitArea={new PIXI.Rectangle(0, 0, width, height)}
        mousemove={handleMouseMove}
        mouseup={handleMouseUp}
        mousedown={handleMouseDown}
        mouseout={() => {
          setCurrentMouseEvent({
            eventType: MouseEventType.UP,
            isActive: false,
          });
          setInteractionState(null);
        }}
        cursor={
          zoomInteractionContext ? zoomInteractionState?.cursor : Cursor.DEFAULT
        }
      >
        <AxisYGuideLines width={width} height={height} />
        <Graphics
          draw={draw}
          scale={new PIXI.Point(width, -height)}
          position={new PIXI.Point(0, height)}
        />
      </Container>
    </Stage>
  );
};
