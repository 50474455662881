import React from 'react';
import styled from 'styled-components';

// Components
import { Icon } from '../Icon';

const TimePeriodBackBtnContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 160px;
  height: 35px;
  border-radius: 5px;
  font-size: 14px;
  position: absolute;
  bottom: 20px;

  &:hover {
    background-color: ${({ theme }) => theme.global.color.background};
  }
`;

const IconWrapper = styled.button`
  border-style: none;
  background: transparent;
  transform: rotate(180deg);
`;

export interface TimePeriodBackBtnProp {
  handlerClick: (value: boolean) => void;
}

export const TimePeriodButton: React.FC<TimePeriodBackBtnProp> = ({
  handlerClick,
}) => (
  <TimePeriodBackBtnContainer onClick={() => handlerClick(false)}>
    <IconWrapper>
      <Icon icon="arrowRight" iconSize="12" />
    </IconWrapper>
    <span>Back</span>
  </TimePeriodBackBtnContainer>
);
