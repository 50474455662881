import { format } from 'date-fns';

export const formatDate = (date: Date | null): string =>
  date ? format(new Date(date), 'MM/dd/yy') : 'mm/dd/yy';

export const formatTime = (date: Date | null, timeFormat: string): string => {
  const isUTCFormat = timeFormat === '24';
  return date
    ? format(new Date(date), isUTCFormat ? 'HH:mm' : 'hh:mm a')
    : 'hh:mm';
};
