import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Message from '../Message';
import { Color } from '../../theme/primitives';
// Utils
import { copyToClipboard } from '../../utils/copyToClipboard';

type TableColumnProps = {
  value: ReactNode;
  label?: ReactNode;
  labelTitle?: string;
  className?: string;
  copyOnClick?: boolean;
};

export const TableColumn = ({
  value,
  label,
  labelTitle,
  className,
  copyOnClick,
}: TableColumnProps) => {
  const copyValueToClipboard = () => {
    if (
      !copyOnClick ||
      (typeof value !== 'string' && typeof value !== 'number')
    ) {
      return;
    }

    const copySuccess = copyToClipboard(value.toString());
    if (!copySuccess) return;
    setCopyStatus(true);
    timeoutRef.current = setTimeout(() => {
      setCopyStatus(false);
    }, 3000);
  };

  const [copyStatus, setCopyStatus] = useState<boolean>(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    return () => {
      // Will clear timeout when the component unmounts, so the component will not try to set state after unmounting
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const getValueTitle = (): string | undefined => {
    if (copyOnClick) return 'Click to copy';
    if (labelTitle) return labelTitle;
    if (typeof value === 'string') return value;

    return undefined;
  };

  return (
    <TableColumnDiv data-cy="TableColumn" className={className}>
      {copyStatus && <Message message="Copied to Clipboard" />}
      <Value
        title={getValueTitle()}
        onClick={copyValueToClipboard}
        copyOnClick={copyOnClick}
      >
        {value}
      </Value>
    </TableColumnDiv>
  );
};

const TableColumnDiv = styled.div`
  display: inline-block;
  color: ${Color.grey700};
  font-size: 14px;
  line-height: 1.2em;
  text-align: left;
  flex: 1;
  min-width: 0;

  @media (max-width: 720px) {
    margin-bottom: 10px;
    flex: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const Value = styled.div<{ copyOnClick?: boolean }>`
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
  white-space: nowrap;
  max-width: 100%;
  cursor: ${props => (props.copyOnClick ? 'pointer' : 'default')};
`;

export default TableColumn;
