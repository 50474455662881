import { OAuthButtonType } from 'main/data/types/OAuth';

export const oAuthOptions: OAuthButtonType[] = [
  { name: 'google' },
  { name: 'github' },
];

export enum ResetPasswordReason {
  EXPIRED = 'expired',
}
