import React from 'react';
import styled from 'styled-components';

import List from '../../../atoms/List';
import BulletListItem from '../../../atoms/BulletListItem';
import { Color, Size } from '../../../theme/primitives';
import {
  useGroupedBarGraphInteraction,
  useGroupedBarGraphContext,
} from '../hooks';

interface GroupedBarGraphWidgetProps {
  format?: (value: number) => number;
}

export const GroupedBarGraphWidget: React.FC<GroupedBarGraphWidgetProps> = ({
  format,
}) => {
  const graphInteraction = useGroupedBarGraphInteraction();
  const {
    xProperty,
    xLabel,
    tooltipProperty,
    tooltipLabel,
  } = useGroupedBarGraphContext();

  if (!graphInteraction) {
    return null;
  }

  const { widgetData } = graphInteraction;

  return (
    <Container>
      <Header>
        <HeaderText>{tooltipLabel || xLabel}</HeaderText>
        <HeaderValue>
          {(widgetData as any)[tooltipProperty || xProperty].toLocaleString()}
        </HeaderValue>
      </Header>
      <List>
        {widgetData?.series?.map((dataItem, index) => (
          <BulletListItem key={index} color={dataItem?.color}>
            <ListItemContent>
              <ListItemColumn>{dataItem?.label}</ListItemColumn>
              <ListItemColumn>|</ListItemColumn>
              <ListItemColumn>
                {format?.(dataItem?.value)?.toLocaleString() ??
                  dataItem?.value?.toLocaleString()}
              </ListItemColumn>
            </ListItemContent>
          </BulletListItem>
        ))}
      </List>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Size.space200};
  box-sizing: border-box;

  border: 1px solid ${Color.grey200};
  border-radius: ${Size.radius200};

  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.1);
  background-color: ${Color.white};
  font-size: 12px;
`;

const Header = styled.div`
  display: flex;
  border-bottom: 1px solid ${() => Color.grey400};
  margin-bottom: ${Size.space100};
  height: 25px;
`;

const HeaderText = styled.div`
  font-size: 12px;
  font-weight: 400;
  position: relative;
  display: inline-block;
  height: 15px;
  line-height: 15px;
`;

const HeaderValue = styled.div`
  font-size: 12px;
  font-weight: 600;
  position: relative;
  display: inline-block;
  height: 15px;
  line-height: 15px;
  margin-left: ${Size.space100};
`;

const ListItemContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: nowrap;
  align-content: center;
  white-space: nowrap;
  font-size: 12px;
  margin-right: 5px;
`;

const ListItemColumn = styled.div`
  display: inline-block;
  margin-left: ${Size.space100};
  position: relative;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
