import React, { createContext, useContext, useState } from 'react';

type ExpansionPanelState = [boolean, (state: boolean) => void];

export const ExpansionPanelContext = createContext<ExpansionPanelState | null>(
  null,
);

export const ExpansionPanelProvider: React.FC = ({ children }) => {
  const state = useState<boolean>(false);

  return (
    <ExpansionPanelContext.Provider value={state}>
      {children}
    </ExpansionPanelContext.Provider>
  );
};

export const useExpansionPanelState = (): ExpansionPanelState | null => {
  const state = useContext(ExpansionPanelContext);

  return state;
};
