import React from 'react';
import styled from 'styled-components';

import Text from '../../../../atoms/Text';

export interface CheckpointMarkerLabelProps {
  xPosition: number;
  label: string;
}

export const CheckpointMarkerLabel: React.FC<CheckpointMarkerLabelProps> = ({
  xPosition,
  label,
}) => <StyledText xPosition={xPosition}>{label}</StyledText>;

const StyledText = styled(Text)<{ xPosition: number }>`
  position: absolute;
  top: -36px;
  left: ${({ xPosition }) => xPosition}px;
  transform: translateX(-50%);

  white-space: nowrap;
`;
