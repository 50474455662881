import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ReactSize from 'react-sizeme';

import { normalize } from '../../utils/mathUtils';
import { Color, Size } from '../../theme/primitives';
import { ChartAreaContext } from '../../charts/atoms/ChartAreaProvider/ChartAreaContext';

import { useInteraction } from './GraphInteractionContext';

const { SizeMe } = ReactSize;

interface GraphInteractionLayerProps {
  width: number;
  height: number;
  className?: string;
  tooltip?: React.ReactNode;
  tooltipRenderer?: (value: number | null) => JSX.Element;
  showMarker?: boolean;
  markerWidth?: number;
  markerValueDisplay?: React.ReactNode;
}

const GraphInteractionLayer: React.FC<GraphInteractionLayerProps> = ({
  width,
  height,
  className,
  tooltip,
  tooltipRenderer,
  showMarker,
  markerWidth = 30,
  markerValueDisplay,
}) => {
  const [interactionState] = useInteraction();

  const {
    x: { min, max },
  } = useContext(ChartAreaContext);
  const groupRef = useRef<HTMLDivElement>(null);

  const value: number | null = interactionState && interactionState.x;
  const shouldShowMarker = interactionState?.showMarker && showMarker;

  let position: null | number = null;

  if (value !== null && value >= min && value < max) {
    const progress = normalize(min, max, value);
    position = width * progress;
  }

  useEffect(() => {
    if (groupRef.current) {
      if (position !== null) {
        groupRef.current.style.opacity = '1';
        groupRef.current.style.transform = `translateX(${position}px)`;
      } else {
        groupRef.current.style.opacity = '0';
      }
    }
  }, [position]);

  return (
    <Container className={className} width={width} height={height}>
      <Group ref={groupRef}>
        <Line />
        {position !== null && tooltip && (
          <ToolTipInteraction position={position} width={width}>
            {tooltip}
          </ToolTipInteraction>
        )}
        {position !== null && tooltipRenderer && (
          <ToolTipInteraction position={position} width={width}>
            {tooltipRenderer(value)}
          </ToolTipInteraction>
        )}
        {shouldShowMarker && (
          <BottomMarker
            width={markerWidth}
            markerValueDisplay={markerValueDisplay}
          />
        )}
      </Group>
    </Container>
  );
};

interface ToolTipInteractionProps {
  width: number;
  position: number;
  children?: any;
}

const ToolTipInteraction: React.FC<ToolTipInteractionProps> = ({
  position,
  width,
  children,
}) => {
  return (
    <SizeMe>
      {({ size }) => (
        <TooltipContainer isRightSide={position < width - (size.width || 0)}>
          {children}
        </TooltipContainer>
      )}
    </SizeMe>
  );
};

interface BottomMarkerProps {
  width: number;
  markerValueDisplay: React.ReactNode;
}

const BottomMarker: React.FC<BottomMarkerProps> = ({
  width,
  markerValueDisplay,
}) => {
  return (
    <BottomMarkerContainer width={width}>
      {markerValueDisplay}
    </BottomMarkerContainer>
  );
};

const Line = styled.div`
  position: absolute;
  height: 100%;
  width: 0;
  left: 0;
  top: 0;
  border-left: 1px dashed ${Color.grey400};

  ::after {
    content: '';
    display: block;
    border: 1px solid ${Color.grey400};
    border-radius: 100%;
    width: 5px;
    height: 5px;
    position: absolute;
    bottom: 100%;
    left: -4px;
  }
`;

const Group = styled.div`
  position: absolute;
  height: 100%;
  left: 0px;
  top: 0px;

  will-change: transform, opacity;
`;

const Container = styled.div<{ width: number; height: number }>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  position: relative;
  pointer-events: none;
  cursor: pointer;
`;

const TooltipContainer = styled.div<{
  isRightSide: boolean;
}>`
  position: absolute;
  width: auto;
  top: ${Size.space200};
  left: ${({ isRightSide }) => (isRightSide ? Size.space400 : 'auto')};
  right: ${({ isRightSide }) => (!isRightSide ? Size.space400 : 'auto')};
`;

const BottomMarkerContainer = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  position: absolute;
  bottom: -30px;
  left: ${({ width }) => -((width + 20) / 2)}px;
  background-color: ${Color.grey200};
  padding: 2.5px 10px 2px 10px;
  height: 20px;
  border-radius: 12px;
  z-index: -1;

  ::after {
    content: '';
    display: block;
    width: 1px;
    background-color: ${Color.grey400};
    position: absolute;
    top: -6px;
    left: ${({ width }) => (width + 20) / 2}px;
    height: 6px;
  }
`;

export default GraphInteractionLayer;
