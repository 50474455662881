import React, { createContext, useMemo } from 'react';

import useId from '../../../utils/useId';
import { SerieSetting } from '../types';

type GroupedBarGraphContextProps = {
  // Raw data passed to the graph
  data: object[];
  // Width of the graph container (in px)
  width: number;
  // Height of the graph container (in px)
  height: number;
  // property to be used as reference for the x axis
  xProperty: string;
  // label for xProperty
  xLabel?: string;
  // property in each data item providing the value for the tooltip header
  tooltipProperty?: string;
  tooltipLabel?: string;
  // Amount of right padding for each column group (in px)
  groupPadding?: number;
  // labels and colors for each of the column series
  columnSeries: SerieSetting[];
};

export interface GroupedBarGraphState extends GroupedBarGraphContextProps {
  graphId: string;
}

const defaultState: GroupedBarGraphState = {
  data: [],
  graphId: '',
  groupPadding: 0,
  columnSeries: [],
  width: 1,
  height: 1,
  xProperty: '',
  xLabel: '',
  tooltipProperty: '',
  tooltipLabel: '',
};

export const GroupedBarGraphContext = createContext<GroupedBarGraphState>(
  defaultState,
);

export const GroupedBarGraphProvider: React.FC<GroupedBarGraphContextProps> = ({
  data,
  width,
  height,
  xProperty,
  xLabel,
  tooltipProperty,
  tooltipLabel,
  groupPadding,
  columnSeries,
  children,
}) => {
  const graphId = useId('bar-graph');

  const state = useMemo(
    () => ({
      data,
      width,
      height,
      xProperty,
      xLabel,
      tooltipProperty,
      tooltipLabel,
      groupPadding,
      columnSeries,
      graphId,
    }),
    [
      data,
      width,
      height,
      xProperty,
      xLabel,
      tooltipProperty,
      tooltipLabel,
      groupPadding,
      columnSeries,
      graphId,
    ],
  );

  return (
    <GroupedBarGraphContext.Provider value={state}>
      {children}
    </GroupedBarGraphContext.Provider>
  );
};
