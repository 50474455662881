import React, { useState } from 'react';
import styled from 'styled-components';

import { Button } from '../Button';
import { Icon } from '../Icon';
import Text from '../Text';
import { Color } from '../../theme/primitives';

enum KeyboardKey {
  ENTER = 'Enter',
}

enum TooltipPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

type PrimitiveValue = number | string | boolean;

export interface OptionInterface {
  value: PrimitiveValue;
  label: string;
  labelTerm?: string;
  description?: string;
  tooltipDescription?: string;
  isDisabled?: boolean;
  icon?: string;
  tooltipButtonTitle?: string;
  warning?: string;
  content?: React.ReactNode;
  suboptions?: {
    title: string;
    description?: string;
    list: OptionInterface[] | [];
  };
}

export interface OptionProps {
  data: OptionInterface;
  isReadOnly?: boolean;
  isSelected?: boolean;
  isActive?: boolean;
  isChechboxLike?: boolean;
  width?: string;
  direction?: string;
  className?: string;
  onChange: (value: PrimitiveValue) => void;
  onTooltipButtonClick?: () => void;
}
const preservedCasedWords = ['hCaptcha', 'KDF'];

const capitalizeText = (text: string) => {
  return text.replace(/(\p{L}+)/gu, (word: string) => {
    if (preservedCasedWords.includes(word)) {
      return word;
    }

    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
};

const Option: React.FC<OptionProps> = ({
  className = '',
  isReadOnly,
  data: {
    labelTerm,
    isDisabled = false,
    value,
    label = '',
    description = '',
    tooltipDescription = '',
    tooltipButtonTitle = '',
    icon,
    content,
  },
  isSelected,
  isActive = false,
  isChechboxLike,
  width = '',
  direction = '',
  onChange,
  onTooltipButtonClick,
}) => {
  const [tooltipPosition, setTooltipPosition] = useState(TooltipPosition.TOP);

  const capitalizedLabel = capitalizeText(label);

  const handleChange = () => {
    if (isDisabled || isReadOnly) {
      return;
    }

    onChange?.(value);
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = event;

    if (key === KeyboardKey.ENTER) {
      handleChange();
    }

    handleTooltipPosition(event);
  };

  const handleTooltipPosition = (
    event: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement>,
  ) => {
    if (!isDisabled) {
      return;
    }

    const target = event.target as HTMLElement;
    const tooltip = target.querySelector(`${UpgradeTooltip}`);

    if (!tooltip) {
      return;
    }

    const optionBoundingRect = target.getBoundingClientRect();
    const tooltipBoundingRect = tooltip.getBoundingClientRect();
    const headerHeight = 60;
    const tooltipPosition =
      optionBoundingRect.top < headerHeight + tooltipBoundingRect.height
        ? TooltipPosition.BOTTOM
        : TooltipPosition.TOP;

    setTooltipPosition(tooltipPosition);
  };

  const handleTooltipButtonClick = () => {
    onTooltipButtonClick?.();
  };

  return (
    <Container
      className={className}
      tabIndex={0}
      isReadOnly={isReadOnly}
      isDisabled={isDisabled}
      isSelected={isSelected}
      isActive={isActive}
      onKeyUp={handleKeyUp}
      onClick={handleChange}
      width={width}
      direction={direction}
      onMouseEnter={handleTooltipPosition}
    >
      <OptionOutlineDefault
        isSelected={isSelected}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
      />

      <OptionOutlineSelected isSelected={isSelected} isActive={isActive} />

      <OptionHeader>
        <TitleContainer
          title={`${labelTerm || ''}${capitalizedLabel}`}
          isReadOnly={isReadOnly}
        >
          {labelTerm && <LabelTerm variant="default">{labelTerm}</LabelTerm>}
          <Title variant="default">{capitalizedLabel}</Title>
        </TitleContainer>

        {!isDisabled && (
          <SelectedIndicator
            isChecked={isSelected && isActive}
            isChechboxLike={isChechboxLike}
          >
            <CheckmarkIcon icon="checkMark" iconSize="s" />
          </SelectedIndicator>
        )}

        {isDisabled && icon && (
          <UpgradeIconContainer>
            <UpgradeIcon icon={icon as any} iconSize="12" />
            <UpgradeTooltip position={tooltipPosition}>
              <TooltipTitle variant="default">
                {icon && <UpgradeIcon icon={icon as any} iconSize="s" />}
                <span>{capitalizedLabel}</span>
              </TooltipTitle>
              {tooltipDescription && (
                <TooltipDescription variant="small">
                  {tooltipDescription}
                </TooltipDescription>
              )}
              {tooltipButtonTitle && (
                <UpgradeButton
                  variant="default"
                  onClick={handleTooltipButtonClick}
                >
                  {tooltipButtonTitle}
                </UpgradeButton>
              )}
            </UpgradeTooltip>
          </UpgradeIconContainer>
        )}
      </OptionHeader>

      {description && (
        <Description
          variant="small"
          title={description}
          isReadOnly={isReadOnly}
        >
          {description}
        </Description>
      )}

      {isSelected && content}
    </Container>
  );
};

const TooltipTitle = styled(Text)`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${Color.white};
  font-weight: 600;
  line-height: 1.5;
`;

const TooltipDescription = styled(Text)`
  color: ${Color.white};
  line-height: 1.5;
  text-transform: capitalize;
`;

const Tooltip = styled.div<{
  position: string;
}>`
  width: 220px;
  height: auto;
  padding: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: ${Color.grey600};
  color: ${Color.white};
  text-align: center;
  visibility: hidden;
  z-index: 10;
  position: absolute;
  transform: translate(-80%, 0);
  ${({ position }) =>
    position === TooltipPosition.TOP ? 'bottom: 36px' : 'top: 36px'};

  &::after {
    content: '';
    margin-top: -1px;
    border-width: 6px;
    border-style: solid;
    position: absolute;
    left: 80%;
    transform: translate(-50%);

    ${({ position }) =>
      position === TooltipPosition.TOP
        ? `
          top: 100%;
          border-color: ${Color.grey600} transparent transparent transparent;
        `
        : `
          bottom: 100%;
          border-color: transparent transparent ${Color.grey600} transparent;
        `};
  }
`;

const UpgradeTooltip = styled(Tooltip)`
  flex-direction: column;

  & > * + * {
    margin-top: 8px;
  }
`;

const OptionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const TitleContainer = styled.span<{
  isReadOnly?: boolean;
}>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;

  &:hover {
    cursor: ${({ isReadOnly }) => isReadOnly && 'not-allowed'};
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  line-height: 1.5;
`;

const LabelTerm = styled(Text)`
  font-weight: 400;
  line-height: 1.5;
  margin-right: 5px;
`;

const CheckmarkIcon = styled(Icon)``;

const SelectedIndicator = styled.div<{
  isChecked?: boolean;
  isChechboxLike?: boolean;
}>`
  transition: all 0.25s ease-out;
  width: 16px;
  height: 16px;
  border-radius: ${({ isChechboxLike }) => (isChechboxLike ? '4px' : '50%')};
  border: ${({ isChecked }) =>
    `1px solid ${isChecked ? Color.blue400 : Color.grey200}`};
  background-color: ${({ isChecked }) =>
    isChecked ? Color.blue400 : 'transparent'};
  opacity: ${({ isChecked }) => (isChecked ? 1 : 0)};
  transform: ${({ isChecked }) => (isChecked ? 'scale(1)' : 'scale(0.75)')};

  ${CheckmarkIcon} {
    transition: all 0.25s ease-out;
    visibility: ${({ isChecked }) => (isChecked ? 'visible' : 'hidden')};
    transform: ${({ isChecked }) => (isChecked ? 'scale(1)' : 'scale(0.75)')};
  }
`;

const Description = styled(Text)<{
  isReadOnly?: boolean;
}>`
  line-height: 1.15;
  margin-top: 8px;
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &:hover {
    cursor: ${({ isReadOnly }) => isReadOnly && 'not-allowed'};
  }
`;

const OptionOutline = styled.div<{
  isSelected?: boolean;
}>`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  box-sizing: border-box;
  border-radius: 8px;
  pointer-events: none;
  transition: all 0.25s ease-out;
`;

const OptionOutlineDefault = styled(OptionOutline)<{
  isSelected?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
}>`
  opacity: ${({ isSelected }) => (isSelected ? 0 : 1)};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ isDisabled, isReadOnly }) =>
    isDisabled || isReadOnly ? Color.grey100 : Color.grey200};
`;

const OptionOutlineSelected = styled(OptionOutline)<{
  isSelected?: boolean;
  isActive?: boolean;
}>`
  z-index: 1;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
  border: ${({ isActive }) =>
    isActive ? `2px solid ${Color.blue400}` : '0px'};
`;

const Container = styled.div<{
  isSelected?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  width?: string;
  direction?: string;
}>`
  position: relative;
  transition: all 0.25s ease-out, background 0.15s ease-out;
  box-sizing: border-box;
  display: ${({ isActive, isSelected }) =>
    isActive || (!isActive && isSelected) ? 'flex' : 'none'};

  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 16px;
  justify-content: center;
  border-radius: 4px;

  background-color: ${({ isDisabled, isReadOnly, isSelected, isActive }) =>
    !isActive || isDisabled || (isReadOnly && !isSelected)
      ? Color.grey050
      : Color.white};

  cursor: ${({ isDisabled, isReadOnly }) =>
    isDisabled || isReadOnly ? 'initial' : 'pointer'};

  @media (min-width: 520px) {
    width: ${({ width, direction }) =>
      width || (direction === 'column' ? '100%' : '184px')};

    max-width: ${({ width }) => width || 'auto'};
  }

  @media (min-width: 1024px) {
    flex: ${({ width }) => (width ? 'unset' : 1)};
  }

  ${Title},
  ${LabelTerm},
  ${Description} {
    color: ${({ isDisabled, isReadOnly, isSelected }) =>
      isDisabled || (isReadOnly && !isSelected)
        ? Color.grey400
        : Color.grey600};
  }

  &:hover,
  &:focus-visible,
  &:focus-within {
    ${({ isSelected, isReadOnly, isDisabled }) => {
      if (!isDisabled && !isReadOnly && !isSelected) {
        return `background-color: ${Color.grey050}`;
      }

      return '';
    }};

    ${UpgradeTooltip} {
      visibility: visible;
    }

    ${OptionOutlineDefault} {
      border-color: ${({ isDisabled, isSelected, isReadOnly }) => {
        if (isSelected) {
          return Color.blue400;
        }

        return isDisabled || isReadOnly ? Color.grey100 : Color.grey600;
      }};
    }
  }

  ${({ isDisabled }) => {
    return isDisabled
      ? `
        &:focus {
          outline: none;

          ${UpgradeTooltip} {
            visibility: visible;
            outline: none;
          }
        }
      `
      : '';
  }};
`;

const UpgradeButton = styled(Button)`
  width: 100%;
`;

const UpgradeIconContainer = styled.div`
  width: auto;
  height: auto;
  padding: 6px;
  background-color: ${Color.grey600};
  border-radius: 4px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
`;

const UpgradeIcon = styled(Icon)`
  width: auto;
  fill: ${Color.blue300};

  & + * {
    margin-left: 6px;
  }
`;

export default Option;
