/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled, { DefaultTheme } from 'styled-components';

import { typography, color, shape } from '../../theme/themeUtils';
import Text from '../Text';
import { Label } from '../Label';
import { Color } from '../../theme/primitives';

type Variant = keyof DefaultTheme['inputField']['color'] &
  keyof DefaultTheme['inputField']['shape'];

export interface InputFieldProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  variant?: Variant;
  isPressedEffect?: boolean;
  isError?: boolean;
  errorMessage?: string | null;
  isRequired?: boolean;
  label?: string;
  infoButtonText?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  value: string;
  className?: string;
  dataCy?: string;
  disabled?: boolean;
}

const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      className,
      variant,
      isPressedEffect,
      name = '',
      label,
      infoButtonText,
      isRequired,
      isError,
      errorMessage,
      value,
      onChange,
      onFocus,
      onBlur,
      disabled,
      ...inputProps
    },
    ref,
  ) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event);
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      onFocus?.(event);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      onBlur?.(event);
    };

    return (
      <Container className={className}>
        {label && (
          <Label
            htmlFor={name}
            title={label}
            isRequired={isRequired}
            isError={isError}
            infoButtonText={infoButtonText}
          />
        )}
        <Input
          {...inputProps}
          variant={variant}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
          value={value}
          id={name}
          isError={isError}
          isPressedEffect={isPressedEffect}
          ref={ref}
        />
        {errorMessage && (
          <ErrorMessage data-error={isError} variant="default">
            {errorMessage}
          </ErrorMessage>
        )}
      </Container>
    );
  },
);

const Container = styled.div``;

const ErrorMessage = styled(Text)`
  display: flex;
  margin: 4px 0 0;
  font-size: 10px;
  line-height: 14px;

  &[data-error='true'] {
    color: ${Color.red400};
  }
`;

const Input = styled.input<{
  variant?: Variant;
  isError?: boolean;
  isPressedEffect?: boolean;
  invalid?: boolean;
}>`
  ${typography('default')};

  display: block;
  width: 100%;
  height: 40px;
  box-sizing: border-box;

  ${({ theme, variant }) =>
    shape(theme.inputField.shape[variant || 'default'])};
  ${({ theme, variant, isError }) =>
    color(
      theme.inputField.color[variant || 'default'][
        isError ? 'error' : 'inactive'
      ],
    )};

  &:hover,
  &:focus {
    outline: none;
    box-sizing: border-box;

    ${({ theme, variant, isError }) =>
      color(
        theme.inputField.color[variant || 'default'][
          isError ? 'error' : 'hover'
        ],
      )};
  }

  &:focus {
    ${({ isPressedEffect }) =>
      isPressedEffect ? 'box-shadow: 0 4px 8px rgba(0, 0, 0, 0.24)' : ''};

    ${({ theme, variant, isError }) =>
      color(
        theme.inputField.color[variant || 'default'][
          isError ? 'error' : 'focus'
        ],
      )};
  }

  &::placeholder {
    ${typography('default')};

    color: ${({ theme, variant }) =>
      theme.inputField.color[variant || 'default'].inactive.placeholderText};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme, variant }) =>
      theme.inputField.color[variant || 'default'].disabled.background};
    border-color: ${({ theme, variant }) =>
      theme.inputField.color[variant || 'default'].disabled.border};
  }

  ${({ theme, variant }) =>
    `
      & + ${ErrorMessage} {
        color: ${theme.inputField.color[variant || 'default'].error.border};
      }
    `};
`;

export default InputField;
