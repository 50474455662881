import React from 'react';
import styled from 'styled-components';

export interface InternalMessageProps {
  title?: string;
  children: React.ReactNode;
}

export default function InternalMessage({
  title = 'Internal message:',
  children,
}: InternalMessageProps): React.ReactElement {
  return (
    <Container>
      <WarningIcon>⚠</WarningIcon>
      <Content>
        <Title>{title}</Title>
        <Message>{children}</Message>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin-top: 10px;
  background-color: salmon;
  color: lemonchiffon;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

const WarningIcon = styled.span`
  display: block;
  font-size: 24px;
  margin-bottom: -4px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  font-weight: 600;
  margin-left: 8px;
`;

const Message = styled.p`
  margin-left: 8px;
`;
