import React from 'react';
import styled from 'styled-components';

import { Size } from '../../../theme/primitives';
import Text from '../../../atoms/Text';
import { Tooltip } from '../../../atoms/Tooltip';
import { TableCell } from '../../../atoms/Table';
import { RatioData } from '../constants';

export interface RatioDisplayCellProps {
  id: string;
  configs: RatioData[];
  total: number;
  className?: string;
}

export const RatioDisplayCell: React.FC<RatioDisplayCellProps> = ({
  id,
  configs,
  total,
  className,
}) => {
  const getPercentage = (value: number) => (value / total) * 100;
  const areAllPercentagesNumbers = configs.some(
    ({ count }) => !isNaN(getPercentage(count)),
  );

  return (
    <TableCell className={className}>
      <Wrapper>
        {areAllPercentagesNumbers && (
          <Popover
            maxWidth={300}
            variant="light"
            content={
              <>
                {configs.map(config => (
                  <PopoverRow key={`${id}-${config.label}`}>
                    <PopoverDot color={config.color} />
                    <PopoverLabel>{config.label}</PopoverLabel>
                    <PopoverPercentage>
                      {getPercentage(config.count).toFixed(2)}%
                    </PopoverPercentage>
                  </PopoverRow>
                ))}
              </>
            }
          >
            <Bar>
              {configs.map(config =>
                total ? (
                  <Ratio
                    key={`${id}-${config.count}`}
                    width={getPercentage(config.count)}
                    color={config.color}
                  />
                ) : null,
              )}
            </Bar>
          </Popover>
        )}
      </Wrapper>
    </TableCell>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Popover = styled(Tooltip)``;

const PopoverRow = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: ${Size.space100};

  &:last-child {
    margin-bottom: 0;
  }
`;

const PopoverDot = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;

  background-color: ${({ color }) => color};
  margin-right: ${Size.space100};
`;

const PopoverLabel = styled(Text)`
  margin-right: ${Size.space100};
`;

const PopoverPercentage = styled(Text)`
  font-weight: 600;
`;

const Bar = styled.div`
  width: 90%;
  height: 4px;
  border-radius: 2px;

  display: flex;
  align-items: center;
  overflow: hidden;
`;

const Ratio = styled.div<{ width: number; color: string }>`
  height: 100%;
  width: ${({ width }) => width}%;

  background: ${({ color }) => color};
`;
