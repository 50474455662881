import { formatDuration, intervalToDuration, Locale } from 'date-fns';

export default function humanDuration(
  time: number,
  locale: Locale | undefined,
): string {
  return formatDuration(intervalToDuration({ start: 0, end: time }), {
    locale,
  });
}
