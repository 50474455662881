import { Color, Size } from '../../theme/primitives';

const theme = {
  table: {
    color: {
      default: {
        border: Color.grey200,
      },
      header: {
        border: Color.grey300,
      },
    },
    shape: {
      padding: `${Size.space200} ${Size.space300}`,
      borderStyle: 'solid',
      borderWidth: '1px',
    },
  },
  tableRow: {
    color: {
      default: {
        background: Color.white,
      },
      alternate: {
        background: Color.grey100,
      },
    },
  },
};

export default theme;
