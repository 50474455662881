import styled, { css } from 'styled-components';

export const TimePeriodOption = styled.li`
  display: flex;
`;

export const timePeriodButtonSelectedState = css`
  background-color: ${({ theme }) => theme.global.color.text};
  color: ${({ theme }) => theme.panel.color.default.background};
`;

export const timePeriodButtonDefaultState = css`
  background-color: ${({ theme }) => theme.panel.color.default.background};
  color: ${({ theme }) => theme.global.color.text};

  &:hover {
    background-color: ${({ theme }) => theme.global.color.background};
  }
`;

export const TimePeriodButton = styled.button<{ isSelected: boolean }>`
  border: none;
  background: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  text-align: left;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 25px;

  ${({ isSelected }) =>
    isSelected ? timePeriodButtonSelectedState : timePeriodButtonDefaultState}
`;
