import { clamp } from './mathUtil';
import Browser from './browserUtil';

declare global {
  interface Navigator {
    // @ts-ignore todo: fix types
    msPointerEnabled?: boolean;
  }
}

interface ScrollEvent {
  x: number;
  y: number;
  action?: string | null;
  original?: (WheelEvent | TouchEvent | KeyboardEvent) | null;
  last: { x: number; y: number };
  touch: { x: number; y: number };
  delta: { x: number; y: number };
}

interface ScrollOptions {
  keyStep?: number;
  firefoxMult?: number;
  touchMult?: number;
  mouseMult?: number;
}

export type ScrollElement = HTMLElement | Document;

type ScrollListener = null | ((event: ScrollEvent) => void);
export type ScrollListeners = Array<ScrollListener>;

const Key = {
  tab: 9,
  left: 37,
  right: 39,
  up: 38,
  down: 40,
};

const Events = {
  wheel: 'onwheel' in document,
  mouse_wheel: 'onmousewheel' in document,
  touch: 'ontouchstart' in document,
  touch_win: navigator.maxTouchPoints && navigator.maxTouchPoints > 1,
  pointer: !!window.navigator.msPointerEnabled,
  keydown: 'onkeydown' in document,
};

class Scroll {
  event: ScrollEvent;

  config: ScrollOptions;

  element: ScrollElement;

  pause: boolean;

  msBodyTouch?: CSSStyleDeclaration | null;

  listeners: ScrollListeners;

  clamp: {
    enabled: boolean;
    min: { x: number; y: number };
    max: { x: number; y: number };
  };

  // $FlowFixMe
  constructor(element: ScrollElement = document, options: ScrollOptions = {}) {
    const {
      keyStep = 120,
      firefoxMult = 15,
      touchMult = 2,
      mouseMult = 1,
    } = options;

    this.config = { keyStep, firefoxMult, touchMult, mouseMult };

    this.element = element;

    this.pause = false;
    this.msBodyTouch = null;

    this.listeners = [];

    this.event = {
      x: 0,
      y: 0,
      action: null,
      original: null,
      last: { x: 0, y: 0 },
      touch: { x: 0, y: 0 },
      delta: { x: 0, y: 0 },
    };

    this.clamp = {
      enabled: false,
      min: { x: 0, y: 0 },
      max: { x: 0, y: 0 },
    };
  }

  get x() {
    return this.event.x;
  }

  set x(value: number) {
    this.event.x = value;
  }

  get y() {
    return this.event.y;
  }

  set y(value: number) {
    this.event.y = value;
  }

  on = (callback: () => void) => {
    this.listeners.push(callback);
  };

  off = (callback: () => void) => {
    let found = false;
    let i = this.listeners.length;

    // eslint-disable-next-line no-plusplus
    while (--i && !found) {
      let listener = this.listeners[i];
      found = listener === callback;

      if (found) {
        this.listeners.splice(i, 1);
        listener = null;
      }
    }
  };

  addListeners = () => {
    const { element } = this;

    // Flow fix | Githut Issue around why: https://github.com/facebook/flow/issues/4783#issuecomment-326770766
    const { body }: { body: any } = document;

    if (Events.wheel)
      element.addEventListener('wheel', this.onWheel as (ev: Event) => any, {
        passive: false,
      });

    if (Events.touch) {
      element.addEventListener(
        'touchstart',
        this.onTouchStart as (ev: Event) => any,
      );
      element.addEventListener(
        'touchmove',
        this.onTouchMove as (ev: Event) => any,
      );
    }

    if (Events.pointer && Events.touch_win) {
      this.msBodyTouch = body.style.msTouchAction;
      body.style.msTouchAction = 'none';

      element.addEventListener(
        'MSPointerDown',
        this.onTouchStart as (ev: Event) => any,
        true,
      );
      element.addEventListener(
        'MSPointerMove',
        this.onTouchMove as (ev: Event) => any,
        true,
      );
    }

    if (Events.keydown) {
      element.addEventListener('keydown', this.onKeyDown as (ev: Event) => any);
    }
  };

  removeListeners = () => {
    const { element } = this;

    // Flow fix | Githut Issue around why: https://github.com/facebook/flow/issues/4783#issuecomment-326770766
    const { body }: { body: any } = document;

    if (Events.wheel)
      element.removeEventListener(
        'wheel',
        this.onWheel as (ev: Event) => any,
        { passive: true } as EventListenerOptions,
      );

    if (Events.touch) {
      element.removeEventListener(
        'touchstart',
        this.onTouchStart as (ev: Event) => any,
      );
      element.removeEventListener(
        'touchmove',
        this.onTouchMove as (ev: Event) => any,
      );
    }

    if (Events.pointer && Events.touch_win) {
      body.style.msTouchAction = this.msBodyTouch;

      element.addEventListener(
        'MSPointerDown',
        this.onTouchStart as (ev: Event) => any,
        true,
      );
      element.removeEventListener(
        'MSPointerMove',
        this.onTouchMove as (ev: Event) => any,
        true,
      );
    }

    if (Events.keydown) {
      element.removeEventListener(
        'keydown',
        this.onKeyDown as (ev: Event) => any,
      );
    }
  };

  onWheel = (e: WheelEvent) => {
    if (this.pause) return;
    e.preventDefault();

    const { mouseMult, firefoxMult } = this.config;
    const { delta } = this.event;

    delta.x = e.deltaX * -1;
    delta.y = e.deltaY * -1;

    if (Browser.type === 'firefox' && e.deltaMode === 1) {
      if (firefoxMult) {
        delta.x *= firefoxMult;
        delta.y *= firefoxMult;
      }
    }

    if (mouseMult) {
      delta.x *= mouseMult;
      delta.y *= mouseMult;
    }

    this.event.action = 'wheel';

    this.update(e);
  };

  onKeyDown = (e: KeyboardEvent) => {
    if (this.pause) return;
    if (e.metaKey) return;

    const { keyStep } = this.config;
    if (!keyStep) return;
    const { delta } = this.event;

    switch (e.keyCode) {
      case Key.down:
        e.preventDefault();
        delta.y = -keyStep;
        this.event.action = 'arrowdown';
        break;
      case Key.up:
        e.preventDefault();
        delta.y = keyStep;
        this.event.action = 'arrowup';
        break;
      case Key.left:
        delta.x = -keyStep;
        this.event.action = 'arrowleft';
        break;
      case Key.right:
        delta.x = keyStep;
        this.event.action = 'arrowright';
        break;
      default:
        return;
    }

    this.update(e);
  };

  onTouchStart = (e: TouchEvent) => {
    if (this.pause) return;

    const { touch } = this.event;
    const t: Touch = e.targetTouches[0];

    touch.x = t.pageX;
    touch.y = t.pageY;

    this.event.action = 'touchstart';

    this.update(e);
  };

  onTouchMove = (e: TouchEvent) => {
    if (this.pause) return;

    const { delta, touch } = this.event;
    const { touchMult } = this.config;
    const t: Touch = e.targetTouches[0];

    if (touchMult) {
      delta.x = (t.pageX - touch.x) * touchMult;
      delta.y = (t.pageY - touch.y) * touchMult;
    }

    touch.x = t.pageX;
    touch.y = t.pageY;

    this.event.action = 'touchmove';

    this.update(e);
  };

  update = (e: WheelEvent | TouchEvent | KeyboardEvent) => {
    if (this.pause) return;

    const { event } = this;
    const { enabled, min, max } = this.clamp;
    const { last, delta } = event;

    last.x = event.x;
    last.y = event.y;

    event.x += delta.x;
    event.y += delta.y;

    if (enabled) {
      event.x = clamp(event.x, min.x, max.x);
      event.y = clamp(event.y, min.y, max.y);
    }

    event.original = e;

    this.listeners.forEach(listener => listener?.({ ...event }));
  };

  reset = () => {
    this.event = {
      x: 0,
      y: 0,
      original: null,
      touch: { x: 0, y: 0 },
      last: { x: 0, y: 0 },
      delta: { x: 0, y: 0 },
    };
  };

  clampY = (enabled: boolean = false, min: number = 0, max: number = 0) => {
    this.clamp.enabled = enabled;
    this.clamp.min.y = min;
    this.clamp.max.y = max;
  };

  clampX = (enabled: boolean = false, min: number = 0, max: number = 0) => {
    this.clamp.enabled = enabled;
    this.clamp.min.x = min;
    this.clamp.max.x = max;
  };
}

export default Scroll;
