import React, { useContext } from 'react';
import styled from 'styled-components';

import HeatMapDataContext from '../../organisms/HeatMap/HeatMapStateProvider/HeatMapDataContext';
import { Color } from '../../theme/primitives';

interface HeatMapLegendProps {
  width: number;
  height: number;
  className?: string;
}

const MainContainer = styled.div<{
  width: number;
  height: number;
}>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

const CellsContainer = styled.div`
  display: flex;
  border-top: 6px solid #fff;
  border-right: 1px solid ${Color.grey500};
  border-bottom: 1px solid ${Color.grey500};
  border-left: 1px solid ${Color.grey500};
`;

const LegendCell = styled.div<{
  color: string;
  width: number;
}>`
  &:last-child {
    border-right: 0;
  }
  border-right: 0.25px solid #fff;
  background-color: ${({ color }) => color};
  width: ${props => props.width}px;
  height: 10px;
`;

const ValuesContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LegendValue = styled.div`
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: ${Color.grey500};
`;

/* eslint-disable import/prefer-default-export */
export const HeatMapLegend: React.FC<HeatMapLegendProps> = ({
  width,
  height,
  className,
}) => {
  const { legend } = useContext(HeatMapDataContext);

  const cellWidth = width / legend.palette.length;

  return (
    <MainContainer
      width={width}
      height={height}
      className={className}
      data-testid="heatmap-legend"
    >
      <ValuesContainer>
        <LegendValue>{legend.min.toLocaleString()}</LegendValue>
        <LegendValue>{legend.max.toLocaleString()}</LegendValue>
      </ValuesContainer>
      <CellsContainer>
        {legend.palette.map(color => (
          <LegendCell
            key={color}
            color={color}
            width={cellWidth}
            data-testid={color}
          />
        ))}
      </CellsContainer>
    </MainContainer>
  );
};
