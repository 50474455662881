import React, { useContext } from 'react';
import styled from 'styled-components';

import humanDuration from '../../utils/humanDuration';
import CalendarContext from '../Calendar/CalendarContext';
import { TimePeriodButton, TimePeriodOption } from '../Calendar/utilComponents';
import Text from '../Text';

const TimePeriodConfigContainer = styled.div`
  span {
    margin-left: 10px;
    display: inline-block;
    margin-top: 10px;
  }
`;

const TimePeriodConfigList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 5px;
`;

const ConfigText = styled(Text)`
  font-size: 14px;
  font-weight: 700;
`;

export interface TimePeriodConfigProp {
  onFormatChange: (value: '12' | '24') => void;
  onGranularityChange: (value: boolean) => void;
  granularity: number | null;
  format: '12' | '24';
}

export const TimePeriodConfig: React.FC<TimePeriodConfigProp> = ({
  onFormatChange,
  onGranularityChange,
  granularity,
  format,
}) => {
  const { translations, locale } = useContext(CalendarContext);

  return (
    <TimePeriodConfigContainer>
      <ConfigText>{translations.granularityTitle}</ConfigText>
      <TimePeriodConfigList>
        <TimePeriodOption>
          <TimePeriodButton
            isSelected={false}
            onClick={() => onGranularityChange(true)}
          >
            {granularity === null
              ? translations.granularityAuto
              : humanDuration(granularity, locale)}
          </TimePeriodButton>
        </TimePeriodOption>
        <ConfigText>{translations.formatLabel}</ConfigText>
      </TimePeriodConfigList>
      <TimePeriodConfigList>
        <TimePeriodOption>
          <TimePeriodButton
            isSelected={false}
            onClick={() => onFormatChange(format === '24' ? '12' : '24')}
          >
            {format === '24'
              ? translations.timeFormat24Hours
              : translations.timeFormat12Hours}
          </TimePeriodButton>
        </TimePeriodOption>
      </TimePeriodConfigList>
    </TimePeriodConfigContainer>
  );
};
