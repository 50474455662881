import React, { useState, memo, useContext } from 'react';
import styled from 'styled-components';
import * as PIXI from 'pixi.js-legacy';
import * as ReactPIXI from '@inlet/react-pixi/legacy';

import { lerp } from '../../../utils/mathUtils';
import { ChartAreaContext } from '../../../charts/atoms/ChartAreaProvider/ChartAreaContext';
import { useInteraction } from '../../../atoms/GraphInteractionLayer/GraphInteractionContext';
import { useGroupedBarGraphContext, useColumn } from '../hooks';

import { Column } from './ColumnGroup';

const { Container, Stage } = ReactPIXI;

export interface GraphCanvasProps {
  width: number;
  height: number;
}

const GraphCanvas: React.FC<GraphCanvasProps> = ({ width, height }) => {
  const area = useContext(ChartAreaContext);
  const { graphId } = useGroupedBarGraphContext();
  const [, setInteractionState] = useInteraction();
  const [isHovered, setIsHovered] = useState(false);

  const { groupDrawData } = useColumn();

  return (
    <GraphContainer>
      <Stage
        options={{
          backgroundAlpha: 0,
          autoDensity: true,
          antialias: true,
        }}
        width={width}
        height={height}
        raf={false}
        onMount={app => {
          // eslint-disable-next-line no-param-reassign
          app.renderer.plugins.interaction.moveWhenInside = true;
        }}
        onMouseOut={() => {
          setIsHovered(false);
        }}
        onMouseOver={() => {
          setIsHovered(true);
        }}
        renderOnComponentChange
      >
        <Container
          interactive
          hitArea={new PIXI.Rectangle(0, 0, width, height)}
          mousemove={event =>
            isHovered
              ? (() => {
                  const x = lerp(
                    area.x.min,
                    area.x.max,
                    event.data.global.x / width,
                  );
                  const y = lerp(
                    area.y.max,
                    area.y.min,
                    event.data.global.y / height,
                  );
                  setInteractionState({ x, y, graphId });
                })()
              : null
          }
          mouseout={() => {
            setInteractionState(null);
          }}
        >
          {groupDrawData.map(group =>
            group.map((column, index) => (
              <Column key={index} drawData={column} />
            )),
          )}
        </Container>
      </Stage>
    </GraphContainer>
  );
};

export default memo(GraphCanvas);

const GraphContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
